import React, { useState } from "react"
import PropTypes from 'prop-types';
import { Modal } from "reactstrap";

import { Search } from "components/Search/Search";

export const  MobileSearch = ({ query, isOpened, isLinkButton , onSearch, onToggle }) => {

  const handleClick = () => {
    onSearch()
    onToggle()
  }

  return (
    <Modal
      className="mobile-search"
      contentClassName="mobile-search__content"
      centered={true}
      fade={false}
      isOpen={isOpened}
      toggle={onToggle}
    >
      <Search isLinkButton={isLinkButton} query={query} insideModal onSearch={() => {}} onClick={handleClick} />
    </Modal>
  )
}

MobileSearch.propTypes = {
  query: PropTypes.object,
  isOpened: PropTypes.bool,
  isLinkButton : PropTypes.bool,
  onSearch: PropTypes.func,
  onToggle: PropTypes.func,
};
