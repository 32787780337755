import React from "react"
import PropTypes from "prop-types"

import { PlacesAutocomplete } from "components/PlacesAutocomplete/PlacesAutocomplete"

export const ProfileLocationAutocomplete = ({
  location,
  onChange,
}) => {
  return (
    <div className="profile-location-autocomplete">
      <h6 className="profile-location-autocomplete__title font-size-16 font-weight-semibold">Location</h6>
      <PlacesAutocomplete
        className="profile-location-autocomplete__autocomplete"
        isClearableAfterComplete={false}
        defaultValue={location}
        onChange={onChange}
      />
    </div>
  )
}

ProfileLocationAutocomplete.propTypes = {
  location: PropTypes.oneOfType([
    PropTypes.object, PropTypes.string,
  ]),
  onChange: PropTypes.func,
}