import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { BudgetRange } from 'components/BudgetRange/BudgetRange'

import { Step } from '../../common/Step/Step'

const defaultBudget = { min: 0, max: 5000000 }

export const BudgetStep = ({
  currentTab,
  questionNumber = '3',
  active,
  isRoom,
  description='How much are you looking to invest (USD)',
  onChange
}) => {
  const step = isRoom ? 3 : 4
  const classes = cx({ 'd-block': currentTab === step, 'd-none': currentTab !== step, })

  return (
    <Step
      className={classes}
      heading='Investment budget (USD)'
      description={description}
    >
      <BudgetRange range={active || defaultBudget} onChange={onChange} />
    </Step>
  )
}

BudgetStep.propTypes = {
  currentTab: PropTypes.number,
  questionNumber: PropTypes.string,
  active: PropTypes.object,
  description: PropTypes.string,
  isRoom: PropTypes.bool,
  onChange: PropTypes.func
};
