import React from "react"
import PropTypes from 'prop-types'
import cx from 'classnames'

import { ModalButton } from '../ModalButton/ModalButton'

export const ModalButtonList = ({
  items, isLargeButtons, className, onClick
}) => {
  const classes = cx('modal-button-list p-0 d-flex flex-wrap', className)
  const classesItem = cx('modal-button-list__item', `${className}-item`)
  const classesButton = cx(`${className}-button`)

  return (
    <ul className={classes}>
      {items.map((button) => {
        return (
          <li className={classesItem} key={button.id}>
            <ModalButton
              className={classesButton}
              icon={button.icon}
              title={button.name}
              active={button.active}
              description={button.description}
              isLarge={isLargeButtons}
              onClick={() => onClick(button.id)}
            />
          </li>
        );
      })}
    </ul>
  );
};

ModalButtonList.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
  isLargeButtons: PropTypes.bool,
  onClick: PropTypes.func,
};
