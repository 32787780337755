import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Button } from "reactstrap"

import { Icon } from "../Icon/Icon"

export const PrimaryButton = ({
  text,
  icon,
  size = "medium",
  error,
  disabled,
  className,
  type = "button",
  onClick,
  onIconClick,
}) => {
  const classes = cx("d-inline-flex button primary-button", `button--${size}`, className, {
    "is-error": error,
    "is-disabled": disabled,
  })

  const handleIconClick = e => {
    e.preventDefault()
    e.stopPropagation()
    onIconClick()
  }

  return (
    <Button className={classes} type={type} onClick={onClick}>
      {text}

      {icon && <Icon className="button__icon primary-button__icon" name={icon} onClick={handleIconClick} />}
    </Button>
  )
}

PrimaryButton.propTypes = {
  className: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
  icon: PropTypes.string,
  size: PropTypes.oneOfType(["large" | "medium" | "small" | "extra-small"]),
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onIconClick: PropTypes.func,
  type: PropTypes.string,
}
