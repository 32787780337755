import React from 'react';

const points = [ '1M', '2M', '3M', '4M' ]

export const BudgetRangePoints = () => {
return (
  <div className="budget-range-points w-100 d-flex justify-content-space-evenly">
    {points.map(point => {
      return (
        <div key={point} className="budget-range-points__point position-relative">
          <span className="budget-range-points__point-text color-gray-300 position-absolute d-block text-center font-size-13">{point}</span>
        </div>
      )
    })}
  </div>
  );
}
