import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/functions"
import "firebase/compat/database"
import { withRouter } from "react-router-dom"

import { PropertyAlert } from "components/PropertyAlert/PropertyAlert"
import CompleteProfile from "components/CompleteProfile/CompleteProfile"
import { Main } from "components/Main/Main"
import { PageTitle } from "components/PageTitle/PageTitle"
import { Activity } from "components/Activity/Activity"
import { Search } from "components/Search/Search"
import { RecommendedUsers } from "shared/RecommendedUsers/RecommendedUsers"
import { Sidebar } from "components/Sidebar/Sidebar"
import { StartGroup } from "components/StartGroup/StartGroup"
import { PromoBanner } from "components/PromoBanner/PromoBanner"
import { MobileSearch } from "components/MobileSearch/MobileSearch"
import { RecommendedUsersSlider } from "components/RecommendedUsersSlider/RecommendedUsersSlider"
import { useSearchUsersResults } from "hooks/useSearchUsersResults"
import { useSearchRoomsResults } from "hooks/useSearchRoomsResults"
import { GroupResults } from "components/GroupResults/GroupResults"
import { useProgress } from "hooks/useProgress"
import { OnboardingTooltip } from "components/OnboardingTooltip/OnboardingTooltip"
import groupsImage from "assets/images/groups.png"
import { getFirebaseBackend } from "helpers/firebase_helper"

import { showPromoAlertAction } from "../../store/layout/actions"
import { tooltipGroupsText, tooltipGroupText, tooltipSearchText } from "./text"

const tooltipGroupStyles = { width: "100%", height: "0.1px" }

const Home = props => {
  const { query, allRooms, allUsers, user, settings, history, showPromoAlert } = props
  const dispatch = useDispatch()
  const fireBaseBackend = getFirebaseBackend()

  const [promoBannerOpened, setPromoBannerOpened] = useState(false)
  const [openedMobileSearch, setOpenedMobileSearch] = useState(false)

  const { steps, progress, profileCardOpened } = useProgress(settings)

  const users = useSearchUsersResults(allUsers, null, settings, user?.uid)

  const rooms = useSearchRoomsResults(allRooms, null, settings, user?.uid)

  const [onboardingGroups, setOnboardingGroups] = useState(false)
  const [onboardingSearch, setOnboardingSearch] = useState(false)
  const [onboardingGroup, setOnboardingGroup] = useState(false)

  const isModalOpened = useSelector(state => state.Layout.showUpdateProfileModal)

  useEffect(() => {
    fireBaseBackend.updateUser({})
  }, [])

  useEffect(() => {
    if (!!settings && !isModalOpened) {
      if (!settings?.onboarding?.groups) {
        setOnboardingGroups(true)
        return
      } else {
        setOnboardingGroups(false)
      }
      if (!settings?.onboarding?.search) {
        setOnboardingSearch(true)
        return
      } else {
        setOnboardingSearch(false)
      }
      if (!settings?.onboarding?.createGroup) {
        setOnboardingGroup(true)
        return
      } else {
        setOnboardingGroup(false)
      }
    }
  }, [settings, isModalOpened, setOnboardingSearch, setOnboardingGroups])

  // useEffect(() => {
  //   if(!!user){
  //     const currenTime = new Date().getTime()
  //     const timeDifference = currenTime - user?.createdAt
  //     const result = (timeDifference / (1000 * 60 * 60)).toFixed()

  //     if(result < 48){
  //       const isProfileImage = !!settings?.profile_image

  //       if(isProfileImage){
  //         fireBaseBackend.cancelProfileReminder(settings?.notified.id)
  //       }
  //     }
  //   }

  // }, [user])

  return (
    <div className="home">
      <PropertyAlert
        location="Old Port"
        url="#"
        isOpen={showPromoAlert}
        onCloseClick={() => dispatch(showPromoAlertAction(false))}
        onClick={() => setPromoBannerOpened(true)}
      />

      <Main className="home__main">
        <PageTitle text="What&#039;s your next project?" />

        {profileCardOpened && <CompleteProfile maxValue={steps.toString()} progressValue={progress.toString()} />}

        <button data-element="experts-modal" className="search__link">
          Talk to an expert
        </button>

        <Search
          id="TooltipSearch"
          query={query}
          isLinkButton={true}
          onOpenMobileSearch={() => setOpenedMobileSearch(true)}
          onSearch={() => {}}
        />

        <OnboardingTooltip
          text={tooltipSearchText}
          opened={onboardingSearch}
          name="search"
          target="TooltipSearch"
          settings={settings?.onboarding}
        />

        <div style={tooltipGroupStyles} id="TooltipGroups"></div>
        <GroupResults
          settings={settings}
          user={user}
          options={rooms?.length > 0 ? rooms : allRooms}
          history={history}
        />
        <OnboardingTooltip
          text={tooltipGroupsText}
          image={groupsImage}
          alt="Groups image"
          opened={onboardingGroups}
          name="groups"
          target="TooltipGroups"
          settings={settings?.onboarding}
        />

        <RecommendedUsersSlider options={allUsers} history={history} id={user?.uid} />

        <Activity user={user} member={settings} history={history} />

        <Sidebar className="home__sidebar">
          <StartGroup id="TooltipGroup" history={history} />
          <button data-element="experts-modal" className="search__link">
            Talk to an expert
          </button>
          <OnboardingTooltip
            text={tooltipGroupText}
            opened={onboardingGroup}
            name="createGroup"
            target="TooltipGroup"
            settings={settings?.onboarding}
            placement="bottom"
          />

          <RecommendedUsers className="home__users" list={users?.length > 0 ? users : allUsers} />
        </Sidebar>
      </Main>

      <PromoBanner
        isOpened={promoBannerOpened}
        image={""}
        alt=""
        text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac elementum etiam blandit vitae id. Sodales sollicitudin et adipiscing platea augue condimentum. Lectus sociis nibh imperdiet risus."
        title="Promotion Heading"
        link="https://www.google.com/"
        onToggle={() => setPromoBannerOpened(false)}
      />

      <MobileSearch
        query={query}
        isOpened={openedMobileSearch}
        isLinkButton={true}
        onToggle={() => setOpenedMobileSearch(false)}
        onSearch={() => {}}
      />
    </div>
  )
}

Home.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
  settings: PropTypes.object,
  allRooms: PropTypes.array,
  allUsers: PropTypes.array,
  query: PropTypes.object,
  showPromoAlert: PropTypes.bool,
}

const mapStateToProps = ({ Account, chat, Layout }) => {
  return {
    user: Account.user,
    settings: Account.settings,
    allRooms: chat.allRooms,
    allUsers: chat.allUsers,
    query: chat.query,
    showPromoAlert: Layout.showPromoAlert,
  }
}

export default connect(mapStateToProps)(withRouter(Home))
