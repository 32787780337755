import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"

import { getFirebaseBackend } from "helpers/firebase_helper"
import loader from "assets/images/check-verde.gif"
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton"
import { MainModalHeader } from "modals/components/parts/MainModalHeader/MainModalHeader"
import { MainModalIndicator } from "modals/components/parts/MainModalIndicator/MainModalIndicator"
import { MainModal } from "modals/components/parts/MainModal/MainModal"
import { sharedModalSubtitle, sharedModalTitle } from "modals/text"

import { ShareModalItem } from "./components/ShareModalItem/ShareModalItem"

const defaultEmails = [
  { id: 1, email: "", name: "" },
  { id: 2, email: "", name: "" },
  { id: 3, email: "", name: "" },
]

export const ShareModal = ({ isOpened, onToggle }) => {
  const fireBaseBackend = getFirebaseBackend()

  const [items, setItems] = useState(defaultEmails)
  const [isLoading, setIsLoading] = useState(false)

  const isCanRecive = useMemo(() => {
    const availableItems = items.filter(item => item.email)

    return !!availableItems.length
  }, [items])

  const handleClick = () => {
    if (isCanRecive) {
      const sendEmails = items.filter(item => item.email)
      fireBaseBackend.shareWithFriends(sendEmails)
      setIsLoading(true)
    }
  }

  const handleChange = (key, value, id) => {
    setItems(
      items.map(item => {
        if (item.id === id) {
          return { ...item, [key]: value }
        }
        return item
      })
    )
  }

  useEffect(() => {
    if (isLoading) {
      const timeout = setTimeout(() => {
        setIsLoading(false)
        onToggle(false)
      }, 3000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [isLoading])

  return (
    <MainModal className="share-modal" isOpened={isOpened} toggle={onToggle}>
      {isLoading ? (
        <img src={loader} className="share-modal__loader" />
      ) : (
        <>
          <button className="border-0 rounded-circle position-absolute share-modal__close" onClick={onToggle} />

          <MainModalHeader
            title={sharedModalTitle}
            subtitle={sharedModalSubtitle}
          />

          <div className="share-modal__body">
            <MainModalIndicator allSteps={1} currentStep={1} />

            <div className="share-modal__list">
              {items.map(item => {
                return (
                  <ShareModalItem
                    key={item.id}
                    email={item.email}
                    name={item.name}
                    onNameChange={value => handleChange("name", value, item.id)}
                    onEmailChange={value => handleChange("email", value, item.id)}
                  />
                )
              })}
            </div>

            <div className="share-modal__footer">
              <PrimaryButton
                size="small"
                text="Send Invitation"
                className="share-modal__button"
                disabled={!isCanRecive}
                onClick={handleClick}
              />
            </div>
          </div>
        </>
      )}
    </MainModal>
  )
}

ShareModal.propTypes = {
  room: PropTypes.object,
  isOpened: PropTypes.bool,
  onToggle: PropTypes.func,
}
