import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import cx from "classnames"

import { CloseModalButton } from "components/CloseModalButton/CloseModalButton"
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton"
import { isValidPhone } from "helpers/isValidPhone"
import { useAddressByCoordinates } from "hooks/useAddressByCoordinates"

import { ProfileEditableItem } from "../ProfileEditableItem/ProfileEditableItem"
import { ProfileContactsItem } from "../ProfileContactsItem/ProfileContactsItem"
import { ProfilePhoneItem } from "../ProfilePhoneItem/ProfilePhoneItem"

export const contactsModel = PropTypes.shape({
  website: PropTypes.string,
  linkedin: PropTypes.string,
  phone: PropTypes.string,
})

export const ProfileContacts = ({
  isPrivate,
  location,
  contacts,
  name,
  isEditing,
  onUpdateDetails,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [currentEditing, setCurrentEditing] = useState(false)
  const [currentContacts, setCurrentContacts] = useState({})
  const [errorValidation, setErrorValidation] = useState(true)

  const handleOnToggle = () => {
    setIsOpen(prev => !prev)
  }

  const handleOnUpdateDetails = () => {
    if (isEditing || currentEditing) {
      setIsOpen(false)
      setCurrentContacts(currentContacts)
      onUpdateDetails(currentContacts)
    }
    if (!currentEditing) {
      setCurrentEditing(true)
    }
  }

  const handleOnChangeWebsite = event => {
    setCurrentContacts({
      ...currentContacts,
      website: event.target.value,
    })
  }

  const handleOnChangeLinkedin = event => {
    setCurrentContacts({
      ...currentContacts,
      linkedin: event.target.value,
    })
  }

  const handleOnChangePhone = event => {
    setCurrentContacts({
      ...currentContacts,
      phone: event.target.value,
    })

    if(event.target.value.length >= 10){
      setErrorValidation(isValidPhone(event.target.value))
    } else {
      setErrorValidation(true)
    }
  }

  const handlePhoneBlur = () => {
    if(currentContacts.phone){
      setErrorValidation(isValidPhone(currentContacts.phone))
    } else {
      setErrorValidation(true)
    }
  }

  useEffect(() => {
    setCurrentContacts(contacts)
  }, [contacts])

  const phoneClasses = cx("profile-contacts__phone", {
    "is-error": !errorValidation
  })

  const buttonClasses = cx("w-100 m-0 profile-contacts__confirm-button", {
    "is-disabled": !errorValidation
  })

  const currentLocation = useAddressByCoordinates(location)

  return (
    <div className="profile-contacts d-flex">
      <h4 className="profile-contacts__short-contacts font-size-16 mr-3 mb-3">
        {currentLocation}
      </h4>
      <button
        className="profile-contacts__button font-size-16 font-weight-semibold"
        type="button"
        onClick={handleOnToggle}
      >
        {isEditing && isPrivate ? "Update contact information" : "Contact details"}
      </button>
      <Modal isOpen={isOpen} centered={true} toggle={handleOnToggle}>
        <ModalHeader
          className="profile-contacts__modal-header p-4"
          close={<CloseModalButton onClick={handleOnToggle} />}
        >
          <span className="font-size-18 profile-contacts__name">
            {isEditing || currentEditing ? "Edit contact information" : "Contact information"}
          </span>
        </ModalHeader>
        <ModalBody className="p-4 pt-0">
          {(isEditing && isPrivate) || currentEditing ? (
            <>
              <ProfileEditableItem
                className="mb-3"
                title="Website"
                value={currentContacts?.website}
                onChange={handleOnChangeWebsite}
              />
              <ProfileEditableItem
                className="mb-3"
                title="LinkedIn"
                value={currentContacts?.linkedin}
                onChange={handleOnChangeLinkedin}
              />
              <ProfileEditableItem
                className={phoneClasses}
                title="Phone"
                isBlack={true}
                value={currentContacts?.phone}
                onChange={handleOnChangePhone}
                onBlur={handlePhoneBlur}
              />
            </>
          ) : (
            <>
              <ProfileContactsItem
                title="Website"
                link={currentContacts?.website}
              />
              <ProfileContactsItem
                title="LinkedIn"
                link={currentContacts?.linkedin}
              />
              <ProfilePhoneItem tel={currentContacts?.phone} />
            </>
          )}
        </ModalBody>
        {isPrivate && (
          <ModalFooter className="p-4 pt-0 border-top-0">
            <PrimaryButton
              className={buttonClasses}
              color="primary"
              text="Update details"
              size="small"
              onClick={handleOnUpdateDetails}
            />
          </ModalFooter>
        )}
      </Modal>
    </div>
  )
}

ProfileContacts.propTypes = {
  isPrivate: PropTypes.bool,
  location: PropTypes.oneOfType([
    PropTypes.object, PropTypes.string,
  ]),
  contacts: contactsModel,
  name: PropTypes.string,
  isEditing: PropTypes.bool,
  onUpdateDetails: PropTypes.func,
}
