import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"

import notFound from "assets/images/not-found.svg"

export const NoResultsFound = ({ className, withImage = true }) => {
  const classes = cx("no-results-found", className, {
    "no-results-found--with-image": withImage,
  })

  return (
    <div className={classes}>
      {withImage && <img src={notFound} className="no-results-found__image" />}

      <h3 className="no-results-found__title">No results found</h3>

      <p className="no-results-found__text" to="/explore">
        Try adjusting your search or filter to find what you’re looking for.
      </p>
    </div>
  )
}

NoResultsFound.propTypes = {
  withImage: PropTypes.bool,
  className: PropTypes.string,
}
