import React, { useMemo } from "react"
import PropTypes from "prop-types"
import firebase from "firebase/compat/app"
import "firebase/compat/storage"

import { AboutProfileSection } from "./components/AboutProfileSection/AboutProfileSection"
import { ProfileCard } from "./components/ProfileCard/ProfileCard"
import { ExperienceSkillsSection } from "./components/ExperienceSkillsSection/ExperienceSkillsSection"
import { getBudget } from "helpers/getBudget"
import { ShareFriendsSection } from "./components/ShareFriendsSection/ShareFriendsSection"
import { RecommendedUsersSlider } from "components/RecommendedUsersSlider/RecommendedUsersSlider"
import { residential } from "modals/variables"

export const Profile = ({
  userId,
  rooms,
  profileSettings,
  isAdmin,
  isPrivate = false,
  users,
  history,

  showIdentidyButton,

  onChangeAbout,
  onUpdateProfile,
  onUpdateContactDetails,
  onUpdateProfileDetails,
  onInviteToRooms,
  onMessage,
  onReportMember,
}) => {
  const residentialType = useMemo(
    () => profileSettings?.investment_types?.find(item => item.type === residential),
    [profileSettings?.investment_types]
  )

  const about = profileSettings?.bio
  const contacts = {
    shortContacts: profileSettings?.investment_location?.map(location => location.name).join(", "),
    website: profileSettings?.website,
    linkedin: profileSettings?.linkedin,
    phone: profileSettings?.phone,
  }

  const lookingFor = {
    type: residentialType?.property_type || [],
    budget: getBudget(profileSettings?.project_size),
    locations: profileSettings?.investment_location,
  }

  const handleOnUploadFiles = async (e, property) => {
    const file = e.target.files[0]

    if (!!file && file.type !== "image/gif") {
      const storageRef = firebase.storage().ref()
      const fileRef = storageRef.child(`files/${userId}/${file.name}`)

      const task = fileRef.put(file)

      task.on(
        "state_changed",
        snapshot => console.log("UPLOAD..."),
        error => console.log(error),
        () => {
          const filePath = `files/${userId}/${file.name}`

          const storageRef = firebase.storage().ref()
          const fileRef = storageRef.child(filePath)

          fileRef
            .getDownloadURL()
            .then(url => {
              const collection = firebase.firestore().collection("users")
              collection.doc(userId).update({
                ...profileSettings,
                [property]: url,
              })
            })
            .catch(error => {
              switch (error.code) {
                case "storage/object-not-found":
                  break
                case "storage/unauthorized":
                  break
                case "storage/canceled":
                  break
                case "storage/unknown":
                  break
              }
            })
        }
      )
    }
  }

  return (
    <div className="profile">
      <ProfileCard
        showIdentidyButton={showIdentidyButton}
        settings={profileSettings}
        rooms={rooms}
        firstName={profileSettings?.firstName}
        lastName={profileSettings?.lastName}
        profileBio={profileSettings?.profile_short_bio}
        location={profileSettings?.investor_location}
        contacts={contacts}
        lookingFor={lookingFor}
        profileImage={profileSettings?.profile_image}
        coverImage={profileSettings?.cover_image}
        isAdmin={isAdmin}
        isPrivate={isPrivate}
        onUpdateProfile={onUpdateProfile}
        onUpdateContactDetails={onUpdateContactDetails}
        onUpdateProfileAvatar={e => handleOnUploadFiles(e, "profile_image")}
        onUpdateProfileCoverImage={e => handleOnUploadFiles(e, "cover_image")}
        onUpdateProfileDetails={onUpdateProfileDetails}
        onInviteToRooms={onInviteToRooms}
        onMessage={onMessage}
        onReportMember={onReportMember}
      />
      <ExperienceSkillsSection
        isPrivate={isPrivate}
        skills={profileSettings?.skills}
        experience={profileSettings?.investor_experience}
        onEdit={onUpdateProfile}
      />
      <AboutProfileSection about={about} isPrivate={isPrivate} onChange={onChangeAbout} />
      {isPrivate && <ShareFriendsSection />}

      <RecommendedUsersSlider options={users} history={history} id={userId} />
    </div>
  )
}

Profile.propTypes = {
  userId: PropTypes.string,
  rooms: PropTypes.array,
  isAdmin: PropTypes.bool,
  isPrivate: PropTypes.bool,
  profileSettings: PropTypes.object,
  users: PropTypes.array,
  history: PropTypes.object,

  showIdentidyButton: PropTypes.bool,

  onUpdateProfile: PropTypes.func,
  onChangeAbout: PropTypes.func,
  onUpdateContactDetails: PropTypes.func,
  onUpdateProfileDetails: PropTypes.func,
  onInviteToRooms: PropTypes.func,
  onMessage: PropTypes.func,
  onReportMember: PropTypes.func,
}
