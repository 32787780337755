import React, { useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import SimpleBar from "simplebar-react"
import { Link } from "react-router-dom"

import { Avatar } from "components/Avatar/Avatar"
import { getFullName } from "components/Profile/Profile.helpers"
import { Members } from "components/Members/Members"
import { capitalizeFirstLetter } from "helpers/capitalizeFirstLetter"

export const MembersPopover = ({ currentUserId, members, avatarSize, className, history }) => {
  const [opened, setOpened] = useState(false)

  const classes = cx(className, "members-popover position-relative")

  const classesBody = cx("members-popover__body position-absolute pt-5", {
    "d-block": opened,
    "d-none": !opened,
  })

  const handleClick = state => {
    setOpened(state)
  }

  return (
    <div className={classes} onMouseEnter={() => handleClick(true)} onMouseLeave={() => handleClick(false)}>
      <Members
        className="members-popover__avatars cursor-pointer"
        avatarSize={avatarSize}
        members={members}
        history={history}
      />

      <div className={classesBody}>
        <SimpleBar className="members-popover__scroll background-color-white p-3 border-radius-4">
          <div className="list-group list-group-flush">
            {members.length > 0 &&
              members.map(member => {
                const name = getFullName(member.firstName, member.lastName)
                const link = member.id === currentUserId ? "profile" : `profile/${member.username}`
                return (
                  <Link
                    to={link}
                    key={member.username}
                    className="members-popover__list-item border-radius-4 p-2 d-flex align-items-center"
                  >
                    <Avatar image={member.profile_image} size="xs" className="members-popover__avatar" />

                    <span className="d-flex flex-column justify-content-center">
                      <span className="font-weight-bold font-size-13 text-color-primary line-height-140">
                        {capitalizeFirstLetter(name)}
                      </span>

                      {!!member.bio && (
                        <span className="font-size-12 text-color-primary line-height-140 mt-1">{member.bio}</span>
                      )}
                    </span>
                  </Link>
                )
              })}
          </div>
        </SimpleBar>
      </div>
    </div>
  )
}

MembersPopover.propTypes = {
  members: PropTypes.array,
  className: PropTypes.string,
  avatarSize: PropTypes.string,
  addButtonOff: PropTypes.bool,
  users: PropTypes.array,
  target: PropTypes.string,
  currentUserId: PropTypes.string,
  onClick: PropTypes.func,
  history: PropTypes.object,
}
