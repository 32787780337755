import React, { useState } from "react"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import PropTypes from "prop-types"
import { CloseModalButton } from "components/CloseModalButton/CloseModalButton"
import { ChooseRoomItem } from "../ChooseRoomItem/ChooseRoomItem"
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton"

export const InviteRoomModal = ({
  isOpen,
  rooms,
  onToggleModal,
  onInviteToRooms,
}) => {
  const [selectedRooms, setSelectedRooms] = useState([])
  const handleOnClickItem = result => {
    const { id, isSelected } = result

    if (isSelected) {
      setSelectedRooms([...selectedRooms, id])
      return
    }
    setSelectedRooms(selectedRooms.filter(roomId => roomId !== id))
  }

  const handleOnInviteToRooms = () => {
    onInviteToRooms(selectedRooms)
    onToggleModal()
  }

  return (
    <Modal
      className="invite-room-modal"
      isOpen={isOpen}
      centered={true}
      toggle={onToggleModal}
    >
      <ModalHeader
        className="p-4 border-bottom-0"
        close={<CloseModalButton onClick={onToggleModal} />}
      >
        <span className="font-size-18 profile-contacts__name">Choose room</span>
      </ModalHeader>
      <ModalBody className="p-4 pt-0 pb-0 invite-room-modal__body">
        {rooms.map(room => {
          return (
            <ChooseRoomItem
              key={room.id}
              id={room.id}
              name={room.name}
              image={room.image}
              members={room.users}
              onClick={handleOnClickItem}
            />
          )
        })}
        {!rooms.length ? "No Available Rooms" : null}
      </ModalBody>
      <ModalFooter className="p-4 pt-0 border-top-0">
        <PrimaryButton
          size="small"
          text={selectedRooms.length > 1 ? "Invite to rooms" : "Invite to room"}
          className="w-100 m-0 mt-4"
          onClick={handleOnInviteToRooms}
        />
      </ModalFooter>
    </Modal>
  )
}

InviteRoomModal.propTypes = {
  isOpen: PropTypes.bool,
  rooms: PropTypes.array,
  onToggleModal: PropTypes.func,
  onInviteToRooms: PropTypes.func,
}
