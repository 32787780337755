import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

export const Loader = ({ className }) => {
  const classes = cx("loader", className)
  const classesSpin = cx("loader__spin", `${className}-spin`)

  return (
    <div className={classes}>
      <span className={classesSpin}></span>
    </div>
  )
}

Loader.propTypes = {
  className: PropTypes.string,
}
