import {
  REGISTER_USER,
  UPDATE_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  SET_CURRENT_USER,
  DELETE_USER_SETTINGS,
  SET_CURRENT_MEMBER,
} from "./actionTypes"

const initialState = {
  registrationError: null,
  message: null,
  loading: false,
  user: null,
  settings: null,
}

const account = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER:
      state = {
        ...state,
        loading: true,
        registrationError: null,
      }
      break
    case UPDATE_USER:
      state = {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload.settings,
        },
        loading: true,
        registrationError: null,
      }
      break
    case REGISTER_USER_SUCCESSFUL:
      state = {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
        loading: false,
        registrationError: null,
      }
      break
    case REGISTER_USER_FAILED:
      state = {
        ...state,
        user: null,
        loading: false,
        registrationError: action.payload,
      }
      break
    case SET_CURRENT_MEMBER:
      state = {
        ...state,
        settings: action.payload,
      }
      break
    case SET_CURRENT_USER:
      state = {
        ...state,
        user: action.payload,
      }
      break;
    case DELETE_USER_SETTINGS:
      state = {
        ...state,
        user: null,
        settings: null,
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default account
