import React from "react"
import PropTypes from 'prop-types';
import { connect, useDispatch } from "react-redux";
import { Progress } from "reactstrap"
import { showUpdateProfileModalAction } from "store/actions";

import { SecondaryButton } from "components/SecondaryButton/SecondaryButton"
import { CardBlock } from 'components/CardBlock/CardBlock'
import { Avatar } from "components/Avatar/Avatar";

const CompleteProfile = ({ user, maxValue, progressValue }) => {
  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(showUpdateProfileModalAction(true))
  };

  return (
    <CardBlock
      className="complete-profile"
      header={(
        <Avatar
          image={user?.profile_image ? user?.profile_image : ""}
          size="l"
          className="rounded-circle complete-profile__image"
        />
      )}
      title="Complete your profile"
      text="Users with a complete profile find investment partners faster."
      >
        <div className="d-flex complete-profile__progress-wrapper">
          <Progress
            className="complete-profile__progress"
            barClassName="complete-profile__progress-bar"
            max={maxValue}
            min="0"
            value={progressValue}
          />

          <span className="font-size-13 complete-profile__progress-text">{progressValue}/{maxValue}</span>
        </div>

        <SecondaryButton size="small" text="Update profile" onClick={onClick}/>
      </CardBlock>
  )
}

CompleteProfile.propTypes = {
  user: PropTypes.object,
  maxValue: PropTypes.string,
  progressValue: PropTypes.string,
  onClick: PropTypes.func
};

const mapStateToProps = state => {
  return { user: state.Account.settings }
}

export default connect(mapStateToProps)(CompleteProfile)
