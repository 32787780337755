import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'reactstrap'

export const MainModal = ({ isOpened, children, onToggle = () => {}, className }) => {
  return (
    <Modal
      className={'main-modal' + (className ? ` ${className}` : '')}
      centered={true}
      fade={false}
      isOpen={isOpened}
      toggle={onToggle}
    >
      {children}
    </Modal>
  )
}

MainModal.propTypes = {
  children: PropTypes.node,
  isOpened: PropTypes.bool,
  subtitle: PropTypes.string,
  onToggle: PropTypes.func,
  className: PropTypes.string,
}
