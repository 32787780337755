import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed, setCurrentUser } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { postFakeRegister, postJwtRegister } from "../../../helpers/fakebackend_helper"

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend()

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.registerUser,
        user.email,
        user.firstName,
        user.lastName,
        user.password,
        user.username,
        user.phone,
      )
      yield put(registerUserSuccessful(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtRegister, "/post-jwt-register", user)
      yield put(registerUserSuccessful(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeRegister, user)
      yield put(registerUserSuccessful(response))
    }
    // const redirectLink = localStorage.getItem("redirectAfterLogin")
    // setTimeout(() => {
    //   if (redirectLink) {
    //     history.push(redirectLink)
    //   } else {
    //     history.push("/home")
    //   }
    // }, 1500)
  } catch (error) {
    yield put(registerUserFailed(error))
  }
}

function* getCurrentProfile() {
  const response = yield call(fireBaseBackend.getAuthenticatedUser)
  yield put(setCurrentUser(response))
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
  yield all([fork(getCurrentProfile)])
}

export default accountSaga
