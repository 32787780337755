import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { PrimaryButton } from 'components/PrimaryButton/PrimaryButton'
import { SecondaryButton } from 'components/SecondaryButton/SecondaryButton'

export const MainModalFooter = ({
  isDisabled,
  enabledSkipButton,
  enabledPrevButton,
  onSkipClick,
  onPrevClick,
  onNextClick
}) => {

  const classes = cx('main-modal-footer d-flex justify-content-end', { 'is-disabled': isDisabled });

  return (
    <div className={classes}>
      {enabledSkipButton && (
        <button
          className='main-modal-footer__button main-modal-footer__button-skip background-color-white p-2 font-size-13 border-0 color-black line-height-140'
          type='button'
          onClick={onSkipClick}
        >Skip for now</button>
      )}

      {enabledPrevButton && (
        <SecondaryButton
        className='main-modal-footer__button main-modal-footer__button-prev'
        size='small'
        text='Previous'
        onClick={onPrevClick}
      />
      )}

      <PrimaryButton
        className='main-modal-footer__button main-modal-footer__button-next'
        text='Next'
        size='small'
        onClick={onNextClick}
      />
    </div>
  );
};

MainModalFooter.propTypes = {
  isDisabled: PropTypes.bool,
  enabledSkipButton: PropTypes.bool,
  enabledPrevButton: PropTypes.bool,
  onSkipClick: PropTypes.func,
  onPrevClick: PropTypes.func,
  onNextClick: PropTypes.func,
};
