import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import { InputField } from "components/InputField/InputField"

export const ProfileEditableItem = ({
  className,
  title,
  value,
  isBlack = false,
  onChange,
  onBlur,
}) => {
  const classes = cx(className, "profile-editable-item", {
    "is-black": isBlack
  })
  return (
    <div className={classes}>
      <h6 className="profile-editable-item__title font-size-16 font-weight-semibold mb-1">
        {title}
      </h6>
      <InputField
        className="profile-editable-item__control"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  )
}

ProfileEditableItem.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
  isBlack: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
}
