import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import { Avatar } from "components/Avatar/Avatar"

import { EditButton } from "../EditButton/EditButton"

export const UploadProfileAvatar = ({
  isPrivate,
  image,
  className,
  onUpdateProfileAvatar,
  userName
}) => {
  const classes = cx('upload-profile-avatar', className)

  return (
    <div className={classes}>
      <Avatar size="xxl" className="profile-card__avatar" userName={userName} image={image} />
      {isPrivate && (
        <div className="upload-profile-avatar__action">
          <EditButton />
          <input className="upload-profile-avatar__input" type="file" name="file" onChange={onUpdateProfileAvatar} />
        </div>
      )}
    </div>
  )
}

UploadProfileAvatar.propTypes = {
  isPrivate: PropTypes.bool,
  image: PropTypes.string,
  className: PropTypes.string,
  userName: PropTypes.string,
  onUpdateProfileAvatar: PropTypes.func,
}
