import { takeEvery, put, call, all, fork } from "redux-saga/effects"

// Chat Redux States
import {
  GET_CHATS,
  GET_CONTACTS,
  GET_GROUPS,
  GET_ARCHIVED,
  GET_MESSAGES,
  POST_ADD_MESSAGE,
  REGISTER_ROOM,
} from "./actionTypes"
import {
  getChatsSuccess,
  getChatsFail,
  getGroupsSuccess,
  getGroupsFail,
  getContactsSuccess,
  getContactsFail,
  getMessagesSuccess,
  getMessagesFail,
  addMessageSuccess,
  addMessageFail,
  getArchivedSuccess,
  getArchivedFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getChats, getContacts, getMessages, addMessage } from "../../helpers/fakebackend_helper"
import { getFirebaseBackend } from "helpers/firebase_helper"

const fireBaseBackend = getFirebaseBackend()

function* onGetChats() {
  try {
    const response = yield call(getChats)
    yield put(getChatsSuccess(response))
  } catch (error) {
    yield put(getChatsFail(error))
  }
}

function* onGetGroups({ payload }) {
  try {
    const response = yield call(fireBaseBackend.getGroups, payload)
    yield put(getGroupsSuccess(response))
  } catch (error) {
    yield put(getGroupsFail(error))
  }
}

function* onGetArchived(arg) {
  try {
    const response = yield call(fireBaseBackend.getArchived, arg.payload)
    yield put(getArchivedSuccess(response))
  } catch (error) {
    yield put(getArchivedFail(error))
  }
}

function* onGetContacts() {
  try {
    const response = yield call(getContacts)
    yield put(getContactsSuccess(response))
  } catch (error) {
    yield put(getContactsFail(error))
  }
}

function* onGetMessages({ roomId }) {
  try {
    const response = yield call(getMessages, roomId)
    yield put(getMessagesSuccess(response))
  } catch (error) {
    yield put(getMessagesFail(error))
  }
}

function* onAddMessage({ message }) {
  try {
    const response = yield call(addMessage, message)
    yield put(addMessageSuccess(response))
  } catch (error) {
    yield put(addMessageFail(error))
  }
}

function* registerRoom({ payload: { settings, rooms, history } }) {
  try {
    const response = yield call(fireBaseBackend.registerRoom, settings, rooms)
    yield put(registerRoom(response))
  } catch (error) {
    console.log("ERROR")
  }
  if (history) {
    setTimeout(() => {
      history.push("/messages")
    }, 3000)
  }
}

export function* watchRegisterRoom() {
  yield takeEvery(REGISTER_ROOM, registerRoom)
}

function* chatSaga() {
  yield takeEvery(GET_CHATS, onGetChats)
  yield takeEvery(GET_GROUPS, onGetGroups)
  yield takeEvery(GET_ARCHIVED, onGetArchived)
  yield takeEvery(GET_CONTACTS, onGetContacts)
  yield takeEvery(GET_MESSAGES, onGetMessages)
  yield takeEvery(POST_ADD_MESSAGE, onAddMessage)
  yield all([fork(watchRegisterRoom)])
}

export default chatSaga
