import React, { useEffect, useState } from "react"
import { Avatar } from "components/Avatar/Avatar"
import PropTypes from "prop-types"
import cx from "classnames"
import { AvatarPopover } from "components/AvatarPopover/AvatarPopover"
import { getFirebaseBackend } from "helpers/firebase_helper"

export const Members = ({ members, className, avatarSize = "xs", popover, history, onClick }) => {
  const classes = cx(className, "members")
  const fireBaseBackend = getFirebaseBackend()
  const list = members?.length > 6 ? members?.slice(0, 6) : members
  const [users, setUsers] = useState([])

  useEffect(() => {
    const fetchUsers = async () => {
      const usersSettings = await fireBaseBackend.getUsersSettings(list)
      setUsers(usersSettings)
    }

    if (list) {
      fetchUsers()
    }
  }, [list, fireBaseBackend])

  return (
    <div className={classes}>
      {users.map((user, index) => {
        return (
          <span key={user.username} style={{ zIndex: list.length - index }} className="members__item">
            {popover ? (
              <AvatarPopover user={user} history={history} />
            ) : (
              <Avatar size={avatarSize} userName={user.firstName + ' ' + user.lastName} />
            )}
          </span>
        )
      })}

      {popover && members.length > 6 && (
        <div className="members__dots" onClick={onClick}>
          <Avatar image={members?.[6].profile_image} />
          <div className="members__dots-button">
            <i className="mdi mdi-dots-horizontal members__icon" />
          </div>
        </div>
      )}
    </div>
  )
}

Members.propTypes = {
  members: PropTypes.array,
  className: PropTypes.string,
  avatarSize: PropTypes.string,
  popover: PropTypes.bool,
  history: PropTypes.object,
  onClick: PropTypes.func,
}
