import firebase from "firebase/compat/app"

import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { openedRoomAction, setActiveCategory } from "store/actions"
import { getNotificationEntity } from "pages/Notifications/helpers/notifcations.helpers"
import { getFirebaseBackend } from "helpers/firebase_helper"

import { replaceUrlMessageWithDefault } from "../helpers"

export const useDirectUserToRoom = (user, member) => {
  const fireBaseBackend = getFirebaseBackend()
  const dispatch = useDispatch()

  useEffect(() => {
    const history = window.history.state?.state?.from
    if (history) {
      localStorage.setItem("redirectAfterLogin", `${history.pathname}${history.search}`)
    } else {
      localStorage.removeItem("redirectAfterLogin")
    }
    window.history.pushState([], "")
  }, [window.history.state?.state?.from])

  useEffect(async () => {
    const roomsParamsId = new URLSearchParams(window.location.search).get("room")
    const chatParamsId = new URLSearchParams(window.location.search).get("chat")
    const acceptRoomId = new URLSearchParams(window.location.search).get("accept_room")

    if (acceptRoomId && member) {
      const currentRoom = await firebase.firestore().collection("rooms").doc(acceptRoomId).get()
      const roomData = currentRoom.data()

      const memberNotifications = member.notifications.reverse()
      const notification = memberNotifications.find(notification => {
        if (
          notification.type === "have-new-request-to-join" &&
          notification.room_id === roomData.id &&
          !notification.accepted
        ) {
          return notification
        }
      })
      if (notification) {
        fireBaseBackend.updateUserNotificationById(
          roomData.admin_user,
          getNotificationEntity("request-to-join-accepted", {
            user,
            member,
            actorId: user.uid,
            roomId: roomData.id,
            roomName: roomData.name,
            room: roomData
          })
        )

        fireBaseBackend.acceptUserToRoom(roomData.id, user.uid, notification.id, roomData.admin_user, true, false)
      }

      dispatch(openedRoomAction(acceptRoomId))
      dispatch(setActiveCategory("groups"))
      replaceUrlMessageWithDefault("accept_room")
      localStorage.removeItem("redirectAfterLogin")
    }

    if (roomsParamsId || chatParamsId) {
      const queryRoomData = await firebase
        .firestore()
        .collection("rooms")
        .doc(roomsParamsId || chatParamsId)
        .get()
      const roomData = queryRoomData.data()

      const canAccess = [roomData.admin_user, ...roomData.users].includes(user.uid)

      if (roomsParamsId && canAccess) {
        dispatch(openedRoomAction(roomsParamsId))
        dispatch(setActiveCategory("groups"))
      }

      if (chatParamsId && canAccess) {
        dispatch(openedRoomAction(chatParamsId))
        dispatch(setActiveCategory("chats"))
      }

      replaceUrlMessageWithDefault(roomsParamsId ? "room" : "chat")
      localStorage.removeItem("redirectAfterLogin")
    }
  }, [user?.uid, window.location.search, member])
}
