import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { ModalUploadLoader } from './components/ModalUploadLoader/ModalUploadLoader'
import { ModalUploadBanner } from './components/ModalUploadBanner/ModalUploadBanner'
import { ModalUploadFileList } from './components/ModalUploadFileList/ModalUploadFileList'
import { useFileUpload } from './useFileUpload'
import { labelText } from './texts'

export const ModalUpload = ({
  className, onUpload, files = [], loader, imageOnly = true
}) => {
  const [ drag, setDrag ] = useState(false)
  const [ file, setFile ] = useState(null)

  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDrag(true)
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDrag(false)
  }

  const handleUpload = (e) => {
    setFile(e.target.files[0])
  }

  const handleChange = () => {
    onUpload(file)
  }

  const { text, banner } = useFileUpload( imageOnly, file, handleChange )

  const classes = cx('modal-upload position-relative', className, {
    'is-drag': drag,
    'is-load': loader
  });

  const labelClasses = cx('w-100 font-size-16 d-flex align-items-center mb-0 modal-upload__label', {
    [`${className}-label`]: !!className
  });

  return (
    <div className={classes}>
      {loader && <ModalUploadLoader />}

      {banner && <ModalUploadBanner text={text} />}

      <input
        id='file-upload'
        type='file'
        name='file'
        className='modal-upload__upload'
        onChange={(e) => {
          handleUpload(e)
          handleDragLeave(e)
        }}
        onDragOver={(e) => handleDragOver(e)}
        onDragLeave={(e) => handleDragLeave(e)}
      />
      <label
        className={labelClasses}
        htmlFor='file-upload'
      >
        <i className='mdi mdi-cloud-upload modal-upload__icon' />

        <span className='font-size-16 modal-upload__label-text'>{labelText}</span>
      </label>

      {files.length > 0 && <ModalUploadFileList files={files} />}
    </div>
  )
}

ModalUpload.propTypes = {
  className: PropTypes.string,
  onUpload: PropTypes.func,
  files: PropTypes.array,
  loader: PropTypes.bool,
  imageOnly: PropTypes.bool,
}
