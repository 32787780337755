import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import { Avatar } from "components/Avatar/Avatar"
import { ActivityInvite } from "../ActivityInvite/ActivityInvite"
import { ActivityItemActionMenu } from "../ActivityItemActionMenu/ActivityItemActionMenu"

export const ActivityItem = ({
  id,
  onDelete,
  isSmall = false,
  image,
  title,
  invite = false,
  time,
  onClick,
  className,
  dropdownOptions,
}) => {
  const timeAgo = timeSince(time)
  const classes = cx("d-flex align-items-center activity-item position-relative", className, {
    "activity-item--small": isSmall,
  })
  const titleClasses = cx("text-muted font-size-16 activity-item__title", `${className}-title`)
  const avatarClasses = cx("activity-item__avatar", `${className}-avatar`)

  return (
    <div className={classes}>
      <Avatar image={image} size="l" className={avatarClasses} />

      <div className="align-self-center overflow-hidden me-auto">
        <p className={titleClasses}>{title}</p>

        {invite && <ActivityInvite onAcceptClick={() => onClick("accept")} onDenyClick={() => onClick("deny")} />}
      </div>

      <ActivityItemActionMenu id={id} onDelete={onDelete} time={timeAgo} dropdownOptions={dropdownOptions} />
    </div>
  )
}

ActivityItem.propTypes = {
  id: PropTypes.number,
  onDelete: PropTypes.func,
  isSmall: PropTypes.bool,
  dropdownOptions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  image: PropTypes.string,
  title: PropTypes.node,
  invite: PropTypes.bool,
  time: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
  className: PropTypes.string,
}

export function timeSince(date) {
  let seconds = Math.floor((new Date() - date) / 1000)
  let divisors = [31536000, 2592000, 86400, 3600, 60, 1]
  let description = [" year ago", " month ago", " days ago", "h", "m", "s"]
  let result = []

  let interval = seconds

  for (let i = 0; i < divisors.length; i++) {
    interval = Math.floor(seconds / divisors[i])
    if (interval > 1) {
      result.push(interval + "" + description[i])
    }
    seconds -= interval * divisors[i]
  }

  return result[0]
}
