import React, { useMemo, useEffect } from "react"
import { Switch, BrowserRouter as Router } from "react-router-dom"
import Authmiddleware from "routes/route"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { useDocumentData } from "react-firebase-hooks/firestore"
import firebase from "firebase/compat/app"
import { useDispatch } from "react-redux"
import { setCurrentMember } from "store/actions"
import { useDirectUserToRoom } from "pages/Messages/hooks/useDirectUserToRoom"

export const Inner = ({ publicRoutes, authProtectedRoutes, layout, nonAuthLayout }) => {
  const dispatch = useDispatch()
  const user = JSON.parse(localStorage.getItem("authUser"))

  const userData = useMemo(() => {
    if (user?.uid) {
      return firebase.firestore().collection("users").doc(user.uid)
    }
  }, [user?.uid])

  const [memberData] = useDocumentData(userData)

  useEffect(() => {
    dispatch(setCurrentMember(memberData))
  }, [memberData, user?.uid])

  useDirectUserToRoom(user, memberData)

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={nonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  )
}

Inner.propTypes = {
  publicRoutes: PropTypes.array,
  authProtectedRoutes: PropTypes.array,
  layout: PropTypes.object,
  nonAuthLayout: PropTypes.func,
}

export default connect()(Inner)
