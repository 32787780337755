import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import { Avatar } from "components/Avatar/Avatar"
import { capitalizeFirstLetter } from "helpers/capitalizeFirstLetter"

export const ChatMainMessage = ({ text, avatar, name, isLeft }) => {
  const classes = cx("d-flex chat-main-message", {
    "is-left": isLeft,
    "chat-main-message--with-name": !!name,
  })

  return (
    <div className={classes}>
      <Avatar size="xs" image={avatar} className="chat-main-message__avatar" />

      <div className="position-relative chat-main-message__text-wrapper">
        {!!name && isLeft && <span className="chat-main-message__name">{capitalizeFirstLetter(name)}</span>}

        <p className="font-size-13 mb-0 chat-main-message__text" dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </div>
  )
}

ChatMainMessage.propTypes = {
  text: PropTypes.string,
  avatar: PropTypes.string,
  name: PropTypes.string,
  isLeft: PropTypes.bool,
}
