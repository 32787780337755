module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "796427251948-pf1jbtq4b4bs2gp6qp886ru511kaovlf.apps.googleusercontent.com",
    SECRET: "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",
  },
  facebook: {
    APP_ID: "1298209734036268",
    // 513307790945223
    // 1298209734036268
  },
}
