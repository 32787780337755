import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Nav, TabContent } from "reactstrap"

import { ChatNavLink } from "../ChatNavLink/ChatNavLink"
import { ChatTabPane } from "../ChatTabPane/ChatTabPane"

const navTabs = [
  { id: "chats", name: "Chats" },
  { id: "groups", name: "Groups" },
  { id: "archived", name: "Archived" },
]

export const ChatLeftSidebarNav = ({ room, chats, groups, archived, activeCategory, onClick, onToggleTab }) => {
  const handleToggleTab = tab => {
    if (activeCategory !== tab) {
      onToggleTab(tab)
    }
  }

  const handleClick = (id, tab) => {
    onClick(id)
    handleToggleTab(tab)
  }

  return (
    <div className="chat-left-sidebar-nav">
      <Nav pills justified>
        {navTabs.map(tab => {
          return (
            <ChatNavLink
              key={tab.id}
              text={tab.name}
              active={activeCategory === tab.id}
              onClick={() => {
                handleToggleTab(tab.id)
              }}
            />
          )
        })}
      </Nav>

      <TabContent activeTab={activeCategory} className="chat-left-sidebar-nav__content">
        <ChatTabPane
          id={navTabs[0].id}
          tabId={navTabs[0].id}
          room={room}
          options={chats}
          onClick={id => handleClick(id, "chats")}
        />

        <ChatTabPane
          id={navTabs[1].id}
          tabId={navTabs[1].id}
          room={room}
          options={groups}
          onClick={id => handleClick(id, "groups")}
        />

        <ChatTabPane
          id={navTabs[2].id}
          tabId={navTabs[2].id}
          room={room}
          options={archived}
          onClick={id => handleClick(id, "archived")}
        />
      </TabContent>
    </div>
  )
}

ChatLeftSidebarNav.propTypes = {
  room: PropTypes.object,
  chats: PropTypes.array,
  groups: PropTypes.array,
  archived: PropTypes.array,
  activeCategory: PropTypes.string,
  onClick: PropTypes.func,
  onToggleTab: PropTypes.func,
}
