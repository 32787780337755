import React, { useEffect, useState } from "react"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import PropTypes from "prop-types"

import { PrimaryButton } from "components/PrimaryButton/PrimaryButton"
import { CloseModalButton } from "components/CloseModalButton/CloseModalButton"

import { ProfileEditableItem } from "../ProfileEditableItem/ProfileEditableItem"
import { ProfileLocationAutocomplete } from "../ProfileLocationAutocomplete/ProfileLocationAutocomplete"

export const profileDetails = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  bio: PropTypes.string,
  location: PropTypes.oneOfType([
    PropTypes.object, PropTypes.string,
  ]),
})

export const EditDetailsModal = ({
  isOpen,
  details,
  onToggle,
  onUpdateDetails,
}) => {
  const [currentDetails, setCurrentDetails] = useState({})

  const handleOnChangeFirstname = event => {
    setCurrentDetails({
      ...currentDetails,
      firstName: event.target.value,
    })
  }
  const handleOnChangeLastname = event => {
    setCurrentDetails({
      ...currentDetails,
      lastName: event.target.value,
    })
  }

  const handleOnChangeBio = event => {
    setCurrentDetails({
      ...currentDetails,
      bio: event.target.value,
    })
  }

  const handleOnUpdateDetails = () => {
    onUpdateDetails(currentDetails)
    onToggle()
  }

  const handleOnChangeLocation = address => {
    setCurrentDetails({
      ...currentDetails,
      location: address.label,
    })
  }

  useEffect(() => {
    setCurrentDetails(details)
  }, [details])

  return (
    <Modal isOpen={isOpen} centered={true} toggle={onToggle}>
      <ModalHeader
        className="edit-details-modal__header p-4"
        close={<CloseModalButton onClick={onToggle} />}
      >
        <span className="font-size-18 edit-details-modal__name">
          Edit details
        </span>
      </ModalHeader>
      <ModalBody className="p-4 pt-0">
        <ProfileEditableItem
          className="mb-3"
          title="Firstname"
          isBlack={true}
          value={currentDetails?.firstName}
          onChange={handleOnChangeFirstname}
        />
        <ProfileEditableItem
          className="mb-3"
          title="Lastname"
          isBlack={true}
          value={currentDetails?.lastName}
          onChange={handleOnChangeLastname}
        />
        <ProfileEditableItem
          className="mb-3"
          title="Bio"
          isBlack={true}
          value={currentDetails?.bio}
          onChange={handleOnChangeBio}
        />
        <ProfileLocationAutocomplete
          location={currentDetails?.location}
          onChange={handleOnChangeLocation}
        />
      </ModalBody>
      <ModalFooter className="p-4 pt-0 border-top-0">
        <PrimaryButton
          className="w-100 m-0"
          color="primary"
          text="Update details"
          size="small"
          onClick={handleOnUpdateDetails}
        />
      </ModalFooter>
    </Modal>
  )
}

EditDetailsModal.propTypes = {
  isOpen: PropTypes.bool,
  details: profileDetails,

  onToggle: PropTypes.func,
  onUpdateDetails: PropTypes.func,
}
