import { getBudgetString } from "./getBudgetString"

export const getBudget = budget => {
  if(typeof budget === "string"){
    return budget
  }
  if(!!budget){
    return `${getBudgetString(budget.min)} - ${getBudgetString(budget.max)}`
  }
}
