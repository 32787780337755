import React, { useState } from "react"
import PropTypes from "prop-types"
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap"

export const ActivityItemActionMenu = ({ time, dropdownOptions, id, onDelete }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen(prev => !prev)
  }

  return (
    <div className="d-flex flex-column align-items-end activity-item-action-menu">
      <span className="font-size-13 activity-item-action-menu__time">{time}</span>
      <div
        className="d-flex align-items-center justify-content-center  activity-item-action-menu__button"
        onClick={() => onDelete(id)}
        role="button"
      >
        <i className="mdi mdi-close activity-item-action-menu__icon"></i>
      </div>
    </div>
  )
}

ActivityItemActionMenu.propTypes = {
  dropdownOptions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  time: PropTypes.string,
  id: PropTypes.number,
  onDelete: PropTypes.func,
}
