import React from "react"
import PropTypes from "prop-types"

import { Icon } from "components/Icon/Icon"

export const CloseModalButton = ({ onClick }) => {
  return (
    <button
      className="close-modal-button d-flex justify-content-center align-items-center"
      onClick={onClick}
    >
      <Icon className="close-modal-button__icon" name="cross-small" />
    </button>
  )
}

CloseModalButton.propTypes = {
  onClick: PropTypes.func,
}
