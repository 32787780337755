import React from "react"
import PropTypes from "prop-types"

import { Link } from "react-router-dom"

import technologies from "assets/images/technologies.svg"

export const ChatNoMessages = ({ buttonText }) => {
  return (
    <div className="chat-no-messages">
      <img src={technologies} className="chat-no-messages__image" />

      <h3 className="chat-no-messages__title">
        When you have new messages or join a room, you’ll see them here.
      </h3>

      <Link className="chat-no-messages__link" to="/explore">{buttonText}</Link>
  </div>
  )
}

ChatNoMessages.propTypes = {
  buttonText: PropTypes.string,
}
