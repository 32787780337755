import React, { useCallback, useEffect, useState } from 'react'
import firebase from 'firebase/compat/app'
import PropTypes from 'prop-types'
import 'firebase/compat/storage'
import 'firebase/compat/analytics'
import { useDispatch, connect } from 'react-redux'

import { openedRoomAction, registerRoom, setActiveCategory } from 'store/actions'
import { newRoomSteps } from 'modals/variables'
import { newRoomModalSubtitle, newRoomModalTitle } from 'modals/text'
import { RoomModal } from 'modals/components/generic/RoomModal/RoomModal'
import { getRoomDefaultSettings, getTypes, onChangeLocation, onChangeSimpleValue, onChangeValue, updateRoomCoverImage } from 'modals/helpers'
import { useDisabledButtons } from 'modals/hooks/useDisabledButtons'

const NewRoomModal = ({ user, rooms, history, isOpened, onToggle }) => {
  const dispatch = useDispatch()
  const analytics = firebase.analytics()

  const roomDefaultSettings = getRoomDefaultSettings(user?.uid)

  const [currentTab, setCurrentTab] = useState(0)
  const [dataTypes, setDataTypes] = useState([])
  const [dataPropertyTypes, setDataPropertyTypes] = useState([])
  const [dataInvestmentTypes, setDataInvestmentTypes] = useState([])
  const [dataInvestmentLocation, setDataInvestmentLocation] = useState([])
  const [dataBudget, setDataBudget] = useState({ min: 0, max: 5000000 })
  const [dataMain, setDataMain] = useState({
    name: '',
    description: '',
    cover_image: '',
  })

  const handleCreateRoom = useCallback(() => {
    const time = Date.now()
    const types = getTypes(dataTypes, dataInvestmentTypes, dataPropertyTypes)

    dispatch(registerRoom({
      ...roomDefaultSettings,
      investment_types: types,
      name: dataMain.name,
      description: dataMain.description,
      cover_image: "",
      investment_location: dataInvestmentLocation,
      project_size: dataBudget,
      createdAt: time
    }, rooms ))

    firebase.firestore().collection("rooms").where("admin_user","==",user?.uid).get().then(res => {
      res.docs.find(item => {
        if(item.data().createdAt === time){
          // update current room on Message page
          dispatch(openedRoomAction(item.id))
          dispatch(setActiveCategory("groups"))

          if(!!dataMain.cover_image){
            updateRoomCoverImage(item.id, dataMain.cover_image)
          }

          analytics.logEvent('groups_created', {
            room_id: item.id,
            room_name: dataMain.name
          });
        }
      })
    })
  })

  useEffect(() => {
    if (currentTab == newRoomSteps && !!dataMain.name) {
      setTimeout(() => {
        handleToggle()
      }, '2500')

      setTimeout(() => {
        dispatch(setActiveCategory('groups'))
        history.push('/messages')
      }, '4000')

      handleCreateRoom()
    }
  }, [currentTab, newRoomSteps])

  const handleToggle = useCallback(() => {
    onToggle()

    setDataTypes([])
    setDataPropertyTypes([])
    setDataInvestmentTypes([])
    setDataInvestmentLocation([])
    setDataBudget("")
    setDataMain({ name: "", description: "", cover_image: "" })
  })

  const disabledButtons = useDisabledButtons(dataMain?.name, currentTab)

  return (
    <RoomModal
      isOpened={isOpened}
      title={newRoomModalTitle}
      subtitle={newRoomModalSubtitle}
      steps={newRoomSteps}
      currentTab={currentTab}
      types={dataTypes}
      investmentTypes={dataInvestmentTypes}
      propertyTypes={dataPropertyTypes}
      investmentLocation={dataInvestmentLocation}
      budget={dataBudget}
      form={dataMain}
      disabledButtons={disabledButtons}
      onChangeForm={values => onChangeSimpleValue(values, setDataMain)}
      onChangeTypes={(id) => onChangeValue(dataTypes, setDataTypes, id)}
      onChangePropertyTypes={(id) => onChangeValue(dataPropertyTypes, setDataPropertyTypes, id)}
      onChangeInvestmentTypes={(id) => onChangeValue(dataInvestmentTypes, setDataInvestmentTypes, id)}
      onChangeInvestmentLocation={location => onChangeLocation(location, dataInvestmentLocation, setDataInvestmentLocation)}
      onChangeBudget={(values) => onChangeSimpleValue(values, setDataBudget)}
      onToggle={onToggle}
      onChangeTab={setCurrentTab}
    />
  )
}

NewRoomModal.propTypes = {
  user: PropTypes.object,
  rooms: PropTypes.array,
  userSettings: PropTypes.object,
  history: PropTypes.object,
  isOpened: PropTypes.bool,
  onToggle: PropTypes.func,
}

const mapStateToProps = state => {
  return { user: state.Account.user, rooms: state.Account.settings?.rooms }
}

export default connect(mapStateToProps)(NewRoomModal)