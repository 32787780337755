import React from "react"
import PropTypes from "prop-types"
import { Alert, Button } from "reactstrap"

export const PropertyAlert = ({ location, isOpen, onClick, onCloseClick }) => {
  return (
    <Alert
      className="m-0 w-100 font-size-13 border-0 rounded-0 d-flex justify-content-center align-items-center position-fixed property-alert"
      isOpen={isOpen}
      closeClassName="p-0 property-alert__close-button"
      toggle={onCloseClick}
    >
      <div className="property-alert__marquee">
        <div className="d-flex align-items-center position-relative property-alert__marquee-item">
          <p className="m-0 property-alert__text">
            We are live! Welcome to Investium. As we&apos;re still in our beta phase, we&apos;d appreciate your feedback.
            <a className="property-alert__link" href="mailto:admin@investium.ai">Click here</a> to email us.
          </p>

          {/* <Button
            className="border-0 text-decoration-underline property-alert__button"
            type="button"
            onClick={onClick}
          >
            Click here
          </Button> */}
        </div>
      </div>
    </Alert>
  )
}

PropertyAlert.propTypes = {
  location: PropTypes.oneOfType([
    PropTypes.object, PropTypes.string,
  ]),
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  onCloseClick: PropTypes.func,
}
