import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import { useOnClickOutside } from "helpers/clickOutside"
import { SearchSelectButton } from "../SearchSelectButton/SearchSelectButton"

import options from "common/data/schemas/investment_types.js"

export const TypesSelect = ({ selected, className, onSelect }) => {
  const ref = useRef(null)

  const [isActive, setIsActive] = useState(false)

  useOnClickOutside(ref, () => setIsActive(false))

  const onClick = () => {
    setIsActive(active => !active)
  }

  const classes = cx("position-relative types-select", className, {
    "is-active": isActive,
  })

  return (
    <div className={classes} ref={ref}>
      <SearchSelectButton
        className="types-select__button"
        text="Type of investment"
        isActive={isActive}
        onClick={onClick}
      />

      {isActive && (
        <ul className="position-absolute mb-0 types-select__list">
          {options.map(option => {
            const isSelected = selected?.find(item => item === option?.type)
            const classes = cx("w-100 font-size-11 types-select__list-button", { "is-active": isSelected })

            return (
              <li key={option.type} className="types-select__list-item">
                <button
                  className={classes}
                  onClick={() => {
                    onSelect({ label: option.type, value: option.type })
                    setIsActive(false)
                  }}
                >
                  {option.type}
                </button>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

TypesSelect.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.array,
  onSelect: PropTypes.func,
}
