import React from "react"
import PropTypes from 'prop-types';
import cx from "classnames"
import {
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"

export const FaqNav = ({ activeTab, onClick }) => {
  return (
    <Nav className="faq-nav" pills>
      <NavItem className="faq-nav__item">
        <NavLink
          className={cx("faq-nav__link", { active: activeTab === "#questions" })}
          onClick={() => onClick("questions")}
        >
          <i className="bx bx-question-mark d-block check-nav-icon faq-nav__icon" />
          <p className="font-weight-bold mb-0 faq-nav__text">General Questions</p>
        </NavLink>
      </NavItem>

      <NavItem className="faq-nav__item">
        <NavLink
          className={cx("faq-nav__link", { active: activeTab === "#privacy" })}
          onClick={() => onClick("privacy")}
        >
          <i className="bx bx-check-shield d-block check-nav-icon faq-nav__icon" />
          <p className="font-weight-bold mb-0 faq-nav__text">Privacy Policy</p>
        </NavLink>
      </NavItem>

      <NavItem className="faq-nav__item">
        <NavLink
          className={cx("faq-nav__link", { active: activeTab === "#terms" })}
          onClick={() => onClick("terms")}
        >
          <i className="bx bx-check-shield d-block check-nav-icon faq-nav__icon" />
          <p className="font-weight-bold mb-0 faq-nav__text">Terms & Conditions</p>
        </NavLink>
      </NavItem>

      <NavItem className="faq-nav__item">
        <NavLink
          className={cx("faq-nav__link", { active: activeTab === "#support" })}
          onClick={() => onClick("support")}
        >
          <i className="bx bx-support d-block check-nav-icon faq-nav__icon" />
          <p className="font-weight-bold mb-0 faq-nav__text">Support</p>
        </NavLink>
      </NavItem>
    </Nav>
  )
}

FaqNav.propTypes = {
  activeTab: PropTypes.string,
  onClick: PropTypes.func
};
