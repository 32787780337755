import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import startOptions, { rolesIconsMap } from 'common/data/schemas/investment_roles'

import { Step } from '../../common/Step/Step'
import { ModalButtonList } from '../../common/ModalButtonList/ModalButtonList'

export const RolesStep = ({
  active, currentTab, onChange
}) => {

  const options = useMemo(() => {
    return startOptions.map((option) => {
      return {
        id: option,
        icon: rolesIconsMap[option],
        name: option,
        description:  "",
        active: active === option
      }
    })
  }, [startOptions])

  const currentOptions = useMemo(() => {
    return options.map((option) => {
      return {
        ...option,
        active: active === option.id
      }
    })
  }, [options, active])

  return (
    <Step
      className={cx({ 'd-block': currentTab === 0, 'd-none': currentTab !== 0, })}
      heading='About you'
      description='Which one of the following best describe you:'
    >
      <ModalButtonList className='roles-step' items={currentOptions} onClick={onChange} />
    </Step>
  )
}

RolesStep.propTypes = {
  currentTab: PropTypes.number,
  active: PropTypes.oneOfType([
    PropTypes.array, PropTypes.string,
  ]),
  onChange: PropTypes.func,
}
