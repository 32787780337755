const stepAfterMillion = 100000

 export const getBudgetString = number => {
  if(number < 1000000){
    return `$${number/1000}K`
  }
  if(number >= 1000000 && number <5000000){
    const currentNumber = Math.ceil(number/stepAfterMillion)*stepAfterMillion
    return `$${currentNumber/1000000}M`
  }
  if(number >= 5000000){
    return `$${number/1000000}M+`
  }
}
