import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
// import { FormGroup } from "reactstrap"
import cx from "classnames"
import { useDispatch } from "react-redux"

import { CardBlock } from "components/CardBlock/CardBlock"
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton"
import { getSearchQuery } from "store/actions"
import { Icon } from "components/Icon/Icon"
import { InputField } from "components/InputField/InputField"

import { SelectedSearchCriteria } from "./components/SelectedSearchCriteria/SelectedSearchCriteria"
import { BudgetSelect } from "./components/BudgetSelect/BudgetSelect"
import { LocationSelect } from "./components/LocationSelect/LocationSelect"
import { TypesSelect } from "./components/TypesSelect/TypesSelect"
import { Link } from "react-router-dom"

export const Search = ({ query, onOpenMobileSearch, insideModal, isLinkButton, id, onSearch, onClick = () => {} }) => {
  const ref = useRef(null)
  const dispatch = useDispatch()

  const [searchFocused, setSearchFocused] = useState(false)
  const [clear, setClear] = useState(false)

  const isCriteriaListEnabled = !!query.types?.length || !!query.size || !!query.locations?.length

  const handleSearch = e => {
    if (e) {
      e.preventDefault()
    }
    onSearch()
  }

  const onSelectLocation = location => {
    dispatch(
      getSearchQuery({
        ...query,
        locations: [location.label],
        // locations: !!query?.locations?.length ? [...query.locations, location.label] : [location.label],
      })
    )
  }

  const onClearLocation = value => {
    setClear(true)
    const updatedLocations = query.locations.filter(item => item !== value)
    dispatch(
      getSearchQuery({
        ...query,
        locations: updatedLocations,
      })
    )
  }

  const onSelectType = type => {
    const isTypeAbsent = !query.types?.find(item => item === type?.value)
    if (isTypeAbsent) {
      dispatch(
        getSearchQuery({
          ...query,
          types: !!query?.types?.length ? [...query?.types, type.value] : [type.value],
        })
      )
    } else {
      onClearType(type.value)
    }
  }

  const onClearType = value => {
    setClear(true)
    const updatedTypes = query.types.filter(item => item !== value)
    dispatch(
      getSearchQuery({
        ...query,
        types: updatedTypes,
      })
    )
  }

  const onSelectBudget = value => {
    dispatch(
      getSearchQuery({
        ...query,
        size: value,
      })
    )
  }

  const onClearBudget = () => {
    setClear(true)
    dispatch(
      getSearchQuery({
        ...query,
        size: null,
      })
    )
  }

  const handleRemoveText = () => {
    setClear(true)
    dispatch(
      getSearchQuery({
        ...query,
        name: "",
      })
    )
  }

  useEffect(() => {
    if (clear) {
      setClear(false)
      onSearch()
    }
  }, [query])

  const classes = cx("searh", {
    "search--inside-modal": insideModal,
  })

  const wrapperClasses = cx("position-relative search__search-wrapper search__field", {
    "is-focused": searchFocused,
  })

  return (
    <CardBlock
      className={classes}
      title="Explore users and existing groups"
      text="Search by location, type of investment, budget or keywords."
      bodyClass="search__wrapper"
    >
      <div id={id} ref={ref} className="search__body">
        <form className="m-0 d-flex search__form" onSubmit={handleSearch}>
          <div className={wrapperClasses}>
            <InputField
              type="text"
              placeholder="Search"
              value={query.name}
              className="search__search"
              onChange={e => dispatch(getSearchQuery({ ...query, name: e.target.value }))}
              onBlur={() => setSearchFocused(false)}
              onFocus={() => setSearchFocused(true)}
            />

            <span className="position-absolute bx bx-search-alt search__search-icon" />

            {!!query.name && (
              <span className="position-absolute bx bx-x search__remove-icon" onClick={handleRemoveText} />
            )}
          </div>

          <TypesSelect className="search__field search__field--type" selected={query.types} onSelect={onSelectType} />

          <BudgetSelect
            className="search__field search__field--budget"
            selected={query.size}
            onSelect={onSelectBudget}
          />

          <LocationSelect className="search__field search__field--location" onSelect={onSelectLocation} />

          {isLinkButton ? (
            <Link to="/explore?fromHome=true" className="search__link">
              Search
            </Link>
          ) : (
            <PrimaryButton size="small" text="Search" className="search__button" type="submit" onClick={onClick} />
          )}

          <button
            type="button"
            className="rounded-circle border-0  justify-content-center align-items-center search__mobile-button"
            onClick={onOpenMobileSearch}
          >
            <Icon name="arrow-forward" />
          </button>
        </form>

        {isCriteriaListEnabled && (
          <SelectedSearchCriteria
            types={query.types}
            budget={query.size}
            locations={query.locations}
            onTypeClick={onClearType}
            onBudgetClick={onClearBudget}
            onLocationClick={onClearLocation}
          />
        )}
      </div>
    </CardBlock>
  )
}

Search.propTypes = {
  id: PropTypes.string,
  query: PropTypes.object,
  startQuery: PropTypes.object,
  insideModal: PropTypes.bool,
  isLinkButton: PropTypes.bool,
  onOpenMobileSearch: PropTypes.func,
  onSearch: PropTypes.func,
  onClick: PropTypes.func,
}
