import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Link } from "react-router-dom"

import { ActivityItem } from "components/Activity/components/ActivityItem/ActivityItem"

export const NotificationRequestToJoin = ({
  isSmall,
  id,
  read,
  decline,
  actor_id,
  room_name,
  room_id,
  full_name,
  username,
  profile_image,
  accepted,
  time,
  onClick,
  onRead,
  onDelete,
  onRoomClick,
}) => {
  const path = username ? `/profile/${username}` : "/"
  const handleClick = () => {
    onRoomClick(room_id)
  }

  const title = (
    <>
      <Link to={path} className="activity-item__link">
        {full_name}
      </Link>{" "}
      requested to join your{" "}
      <Link to="/messages" className="activity-item__link" onClick={handleClick}>
        {room_name}
      </Link>{" "}
      group
    </>
  )

  return (
    <ActivityItem
      onDelete={onDelete}
      id={id}
      isSmall={isSmall}
      title={title}
      image={profile_image}
      invite={!accepted && !decline}
      time={time}
      onClick={action => onClick(action, actor_id, room_name, room_id, id, true)}
      dropdownOptions={
        <>
          {/* <button
            className={cx("border-0 dropdown-item font-size-11 notifications-block__button", {
              "is-disabled": read,
            })}
            onClick={() => onRead(id)}
          >
            Mark as read
          </button> */}

          {/* <button
            className="border-0 dropdown-item font-size-11 notifications-block__button"
            onClick={() => onDelete(id)}
          >
            Remove notification
          </button> */}
        </>
      }
    />
  )
}

NotificationRequestToJoin.propTypes = {
  isSmall: PropTypes.bool,
  decline: PropTypes.bool,
  accepted: PropTypes.bool,
  full_name: PropTypes.string,
  username: PropTypes.string,
  profile_image: PropTypes.string,
  read: PropTypes.bool,
  room_users: PropTypes.array,
  id: PropTypes.number,
  time: PropTypes.number,
  actor_id: PropTypes.string,
  room_id: PropTypes.string,
  room_name: PropTypes.string,
  onClick: PropTypes.func,
  onRead: PropTypes.func,
  onDelete: PropTypes.func,
  onRoomClick: PropTypes.func,
}
