import React, { useState } from "react"
import { Card, CardBody, CardText, CardTitle } from "reactstrap"

import { PrimaryButton } from "components/PrimaryButton/PrimaryButton"
import { ShareModal } from "modals/ShareModal/ShareModal"

export const ShareFriendsSection = () => {
  const [isModalOpened, setIsModalOpened] = useState(false)
  return (
    <>
      <Card className="share-friends-section p-4">
        <CardBody className="p-0">
          <CardTitle tag="h4" className="share-friends-section__title font-size-18 mb-3 d-flex align-items-center">
            Share with friends
          </CardTitle>
          <CardText className="share-friends-section__body">
            <PrimaryButton size="small" text="Share" onClick={() => setIsModalOpened(true)} />
          </CardText>
        </CardBody>
      </Card>
      <ShareModal isOpened={isModalOpened} onToggle={() => setIsModalOpened(false)} />
    </>
  )
}
