import React from "react"
import {
  getFullName,
  getProfileImageAvailability,
} from "components/Profile/Profile.helpers"
import { NotificationNewGroupActivity } from "../components/NotificationNewGroupActivity/NotificationNewGroupActivity"
import { NotificationNewGroupMessage } from "../components/NotificationNewGroupMessage/NotificationNewGroupMessage"
import { NotificationNewIndividualMessage } from "../components/NotificationNewIndividualMessage/NotificationNewIndividualMessage"
import { NotificationRequestToInvite } from "../components/NotificationRequestToInvite/NotificationRequestToInvite"
import { NotificationRequestToJoin } from "../components/NotificationRequestToJoin/NotificationRequestToJoin"
import { NotificationsRequestAccept } from "../components/NotificationsRequestAccept/NotificationsRequestAccept"

export const notificationComponentsRegistry = {
  "have-new-request-to-join": {
    entity: ({ member, user, room }) => {
      const timestamp = Date.now()
      return {
        type: "have-new-request-to-join",
        actor_id: user.uid,
        room_id: room.id,
        room_name: room.name,
        createdAt: timestamp,
        id: timestamp,
        full_name: getFullName(member.firstName, member.lastName),
        username: member.username,
        profile_image: getProfileImageAvailability(room?.cover_image),
        read: false,
        accepted: false,
        decline: false,
        room: room
      }
    },
    component: props => <NotificationRequestToInvite {...props} />,
  },
  "request-to-join-accepted": {
    entity: ({ user, member, actorId, roomId, roomName, room }) => {
      const timestamp = Date.now()
      return {
        type: "request-to-join-accepted",
        actor_id: user.uid,
        createdAt: timestamp,
        room_name: roomName,
        room_id: roomId,
        target_id: actorId,
        username: member.username,
        full_name: getFullName(member.firstName, member.lastName),
        profile_image: getProfileImageAvailability(room?.cover_image),
        id: timestamp,
        read: false,
        decline: false,
        accepted: true,
        room: room
      }
    },
    component: props => <NotificationsRequestAccept {...props} />,
  },
  "request-to-join-declined": {
    entity: ({ user, member, actorId, roomId, roomName, room }) => {
      const timestamp = Date.now()
      return {
        type: "request-to-join-declined",
        actor_id: user.uid,
        createdAt: timestamp,
        room_name: roomName,
        target_id: actorId,
        room_id: roomId,
        username: member.username,
        full_name: getFullName(member.firstName, member.lastName),
        profile_image: getProfileImageAvailability(room?.cover_image),
        id: timestamp,
        read: false,
        accepted: false,
        decline: true,
        room: room
      }
    },
    component: props => <NotificationsRequestAccept {...props} />,
  },
  "individual-message": {
    entity: ({ user, member, room }) => {
      const timestamp = Date.now()
      return {
        type: "individual-message",
        actor_id: user.uid,
        createdAt: timestamp,
        username: member.username,
        full_name: getFullName(member.firstName, member.lastName),
        profile_image: getProfileImageAvailability(member.profile_image),
        id: timestamp,
        read: false,
        room_id: room.id
      }
    },
    component: props => <NotificationNewIndividualMessage {...props} />,
  },
  "group-new-message": {
    entity: ({ user, member, room }) => {
      const timestamp = Date.now()
      return {
        type: "group-new-message",
        actor_id: user.uid,
        createdAt: timestamp,
        full_name: getFullName(member.firstName, member.lastName),
        profile_image: getProfileImageAvailability(room?.cover_image),
        room_name: room.name,
        id: timestamp,
        read: false,
        room: room
      }
    },
    component: props => <NotificationNewGroupMessage {...props} />,
  },
  "request-to-join-in-your-group": {
    entity: ({ user, member, room }) => {
      const timestamp = Date.now()
      return {
        type: "request-to-join-in-your-group",
        actor_id: user.uid,
        profile_image: getProfileImageAvailability(room?.cover_image),
        full_name: getFullName(member.firstName, member.lastName),
        room_name: room.name,
        room_id: room.id,
        username: member.username,
        createdAt: timestamp,
        id: timestamp,
        read: false,
        accepted: false,
        decline: false,
        room: room
      }
    },
    component: props => <NotificationRequestToJoin {...props} />,
  },
  "group-new-activity": {
    entity: ({ room, roomName, member, activityType }) => {
      const timestamp = Date.now()
      return {
        type: "group-new-activity",
        createdAt: timestamp,
        room_id: room.id,
        room_name: roomName,
        profile_image: getProfileImageAvailability(room?.cover_image),
        activity_type: activityType,
        chat_type: room.status,
        id: timestamp,
        read: false,
        room: room
      }
    },
    component: props => <NotificationNewGroupActivity {...props} />,
  },
}
