import { useEffect, useState } from "react"

const getAddressByCoordinates = (coordinates, type) => {
  const mapKey = process.env.REACT_APP_MAPAPIKEY

  return new Promise(resolve => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates?.lat},${coordinates?.lng}&language=en&result_type=${type}&key=${mapKey}`
    )
      .then(response => response.json())
      .then(data => resolve(data))
  })
}

const locality = "locality"
const area = "administrative_area_level_1"
const country = "country"

export const useAddressByCoordinates = coordinates => {
  const [address, setAddress] = useState("")
  const [addressData, setAddressData] = useState({
    city: "",
    area: "",
    country: "",
  })
  const [object, setObject] = useState(false)

  const getData = (coordinates, type) => {
    getAddressByCoordinates(coordinates, type)
      .then(result => {
        if (!!result.results?.[0]?.formatted_address) {
          if (type === locality) {
            setAddressData({ ...addressData, city: result.results?.[0]?.formatted_address })
            return
          } else if (type === country) {
            setAddressData({ ...addressData, country: result.results?.[0]?.formatted_address })
            return
          } else if (type === area) {
            setAddressData({ ...addressData, area: result.results?.[0]?.formatted_address })
          }
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (typeof coordinates === "string") {
      setAddress(coordinates)
    } else if (typeof coordinates === "object" && !!coordinates?.lat) {
      setObject(true)

      getData(coordinates, locality)

      getData(coordinates, area)

      getData(coordinates, country)
    } else {
      setAddress("")
    }
  }, [coordinates])

  useEffect(() => {
    if (object) {
      if (!!addressData.city) {
        setAddress(addressData.city)
      }
    }
  }, [addressData.city, address])

  useEffect(() => {
    if (object) {
      if (!!addressData.area && !address) {
        setAddress(addressData.area)
      }
    }
  }, [addressData.area, address])

  useEffect(() => {
    if (object) {
      if (!!addressData.country && !address) {
        setAddress(addressData.country)
      }
    }
  }, [addressData.country, address])

  return address
}
