import React, { useEffect, useState } from "react"

export const useSort = (users, rooms, value) => {
  const [sortedUsers, setSortedUsers] = useState(null)
  const [sortedRooms, setSortedRooms] = useState(null)

  useEffect(() => {
    if (!!users?.length && !!rooms?.length) {
      if (value === "location") {
        setSortedUsers(
          users?.sort((a, b) => ("" + a?.investment_location[0])?.localeCompare(b?.investment_location[0]))
        )
        setSortedRooms(
          rooms?.sort((a, b) => ("" + a?.investment_location[0])?.localeCompare(b?.investment_location[0]))
        )
        return
      }

      if (value === "type") {
        setSortedUsers(
          users?.sort((a, b) => ("" + a?.investment_types[0]?.type)?.localeCompare(b?.investment_location[0]?.type))
        )
        setSortedRooms(
          rooms?.sort((a, b) => ("" + a?.investment_types[0]?.type)?.localeCompare(b?.investment_location[0]?.type))
        )
        return
      }

      if (value === "size") {
        setSortedUsers(users?.sort((a, b) => ("" + a?.project_size)?.localeCompare(b?.project_size)))
        setSortedRooms(rooms?.sort((a, b) => ("" + a?.project_size)?.localeCompare(b?.project_size)))
        return
      }

      if (value === "investor_location") {
        setSortedUsers(users?.sort((a, b) => ("" + a?.investor_location)?.localeCompare(b?.investor_location)))
        setSortedRooms(rooms)
        return
      }

      if (value === "investor_experience") {
        setSortedUsers(users?.sort((a, b) => ("" + a?.investor_experience)?.localeCompare(b?.investor_experience)))
        setSortedRooms(rooms)
        return
      }

      if (value === "skills") {
        setSortedUsers(users?.sort((a, b) => ("" + a?.skills[0])?.localeCompare(b?.skills[0])))
        setSortedRooms(rooms)
        return
      }
    }
  }, [users, rooms, setSortedRooms, setSortedRooms, value])

  return { users: sortedUsers, rooms: sortedRooms }
}
