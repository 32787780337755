import {
  GET_GROUPS_SUCCESS,
  GET_ARCHIVED_SUCCESS,
  GET_CHATS_SUCCESS,
  GET_GROUPS_FAIL,
  GET_ARCHIVED_FAIL,
  GET_CHATS_FAIL,
  GET_CONTACTS_SUCCESS,
  GET_CONTACTS_FAIL,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAIL,
  POST_ADD_MESSAGE_SUCCESS,
  POST_ADD_MESSAGE_FAIL,
  REGISTER_ROOM,
  UPDATE_ROOMS,
  ALL_ROOMS,
  ALL_USERS,
  SET_ACTIVE_CATEGORY,
  SEARCH_QUERY,
} from "./actionTypes"

const INIT_STATE = {
  chats: [],
  rooms: [],
  activeCategory: 'groups',
  // archived: [],
  contacts: [],
  messages: [],
  error: {},
  settings: {},
  userSettings: {},
  allRooms: [],
  query: {
    name: "",
    types: [],
    size: null,
    locations: [],
  },
}

const Chat = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CHATS_SUCCESS:
      return {
        ...state,
        chats: action.payload,
      }

    case GET_CHATS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        rooms: action.payload,
      }

    case ALL_ROOMS:
      return {
        ...state,
        allRooms: action.payload,
      }

    case ALL_USERS:
      return {
        ...state,
        allUsers: action.payload,
      }

    case UPDATE_ROOMS:
      return {
        ...state,
        rooms: action.payload
      }

    case GET_GROUPS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ARCHIVED_SUCCESS:
      return {
        ...state,
        archived: action.payload,
      }

    case GET_ARCHIVED_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: action.payload,
      }

    case GET_CONTACTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.payload,
      }

    case GET_MESSAGES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case POST_ADD_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      }

    case POST_ADD_MESSAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case REGISTER_ROOM:
      state = {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload.settings,
        },
        userSettings: {
          ...state.userSettings,
          ...action.payload.userSettings,
        },
      }

    case SET_ACTIVE_CATEGORY:
      state = {
        ...state,
        activeCategory: action.payload
      }

    case SEARCH_QUERY:
      return {
        ...state,
        query: action.payload,
      }

    default:
      return state
  }
}

export default Chat
