import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Step } from '../../common/Step/Step'
import { ModalPillList } from '../../common/ModalPillList/ModalPillList'

import startOptions from "common/data/schemas/skills"

export const SkillsStep = ({
  active, currentTab, questionNumber = '5', onChange
}) => {

  const options = useMemo(() => {
    return startOptions.map(option => {
      return { id: option, name: option, active: active.includes(option) };
    })
  }, [startOptions, active]);

  return (
    <Step
      className={cx({ 'd-block': currentTab === 6, 'd-none': currentTab !== 6, })}
      heading='Skills'
      description='What are your skills?'
    >
      <ModalPillList items={options} onClick={onChange} />
    </Step>
  );
}

SkillsStep.propTypes = {
  currentTab: PropTypes.number,
  active: PropTypes.array,
  questionNumber: PropTypes.string,
  onChange: PropTypes.func
};
