import React from "react"
import { Modal, ModalHeader } from "reactstrap"
import PropTypes from "prop-types"

export const FileSizeModal = ({ opened }) => {
  return (
    <Modal className="file-size-modal" isOpen={opened} centered={true}>
      <ModalHeader className="file-size-modal__header p-4">
        <span className="font-size-18 file-size-modal__title">Max file size 5MB</span>
      </ModalHeader>
    </Modal>
  )
}

FileSizeModal.propTypes = {
  opened: PropTypes.bool,
}
