import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { useDispatch } from "react-redux"
import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/functions"
import "firebase/compat/database"
import { useCollectionDataOnce } from "react-firebase-hooks/firestore"
import { useSelector } from "react-redux"

import { MainMobileMenu } from "components/MainMobileMenu/MainMobileMenu"
import { ProfileMobileModal } from "components/ProfileMobileModal/ProfileMobileModal"
import { Loader } from "components/Loader/Loader"
import { UpdateProfileModal } from "modals/UpdateProfileModal/UpdateProfileModal"

import {
  changeLayout,
  changeTopbarTheme,
  changeLayoutWidth,
  getGroups,
  allRoomsAction,
  allUsersAction,
  openUserMobileMenu,
} from "../../store/actions"

import Header from "./Header"
import { WelcomeModal } from "modals/WelcomeModal/WelcomeModal"
import { ExpertsModal } from "modals/ExpertsModal/ExpertsModal"

const Layout = props => {
  const {
    children,
    isPreloader,
    location,
    topbarTheme,
    layoutWidth,
    userId,
    history,
    onChangeLayout,
    onChangeTopbarTheme,
    onChangeLayoutWidth,
    onGetGroups,
  } = props
  const dispatch = useDispatch()
  const isMobileUserMenuActive = useSelector(state => state.Layout.isMobileUserMenuOpened)
  const member = useSelector(state => state.Account.settings)

  const isOnProfile = useSelector(state => state.Layout.isOnProfile)

  const [menuOpened, setMenuOpened] = useState(false)
  const [isWelcomeScreenOpened, setIsWelcomeScreenOpened] = useState(false)
  const isWelcomeScreenOpenedFinal = isWelcomeScreenOpened & !isOnProfile

  useEffect(() => {
    if (isPreloader === true) {
      document.getElementById("preloader").style.display = "block"
      document.getElementById("status").style.display = "block"

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none"
        document.getElementById("status").style.display = "none"
      }, 2500)
    } else {
      document.getElementById("preloader").style.display = "none"
      document.getElementById("status").style.display = "none"
    }

    // Scrollto 0,0
    window.scrollTo(0, 0)

    const title = location.pathname
    let currentage = title.charAt(1).toUpperCase() + title.slice(2)

    document.title = currentage + " | Investium"

    onChangeLayout("horizontal")
    if (topbarTheme) {
      onChangeTopbarTheme(topbarTheme)
    }
    if (layoutWidth) {
      onChangeLayoutWidth(layoutWidth)
    }

    onGetGroups(userId)
  }, [])

  // Opens the menu - mobile
  const openMenu = () => {
    setMenuOpened(active => !active)
  }

  const usersData = useMemo(() => {
    return firebase.firestore().collection("users")
  }, [])
  const [usersList, , , docs] = useCollectionDataOnce(usersData)
  const usersWithId = useMemo(() => {
    return !!usersList
      ? usersList.map((user, index) => {
          return { ...user, id: docs.docs[index].id }
        })
      : []
  }, [usersList, docs])

  const roomsData = useMemo(() => {
    return firebase.firestore().collection("rooms").where("status", "==", "active")
  }, [])
  const [allRooms] = useCollectionDataOnce(roomsData)

  useEffect(() => {
    if (!!allRooms) {
      dispatch(allRoomsAction(allRooms))
    }
  }, [allRooms])

  useEffect(() => {
    if (!!usersWithId) {
      dispatch(allUsersAction(usersWithId))
    }
  }, [usersWithId])

  return (
    <React.Fragment>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>

      <div className="layout">
        <Header theme={topbarTheme} isMenuOpened={menuOpened} openLeftMenuCallBack={openMenu} history={history} />

        <div className="main-content">{children}</div>
        <ProfileMobileModal isOpened={isMobileUserMenuActive} onToggle={() => dispatch(openUserMobileMenu(false))} />
      </div>

      <MainMobileMenu />

      <UpdateProfileModal userId={userId} setIsWelcomeScreenOpened={setIsWelcomeScreenOpened} />

      <WelcomeModal isActive={isWelcomeScreenOpenedFinal} setIsWelcomeScreenOpened={setIsWelcomeScreenOpened} />

      <ExpertsModal isActive={false} />

      {!member && <Loader />}
    </React.Fragment>
  )
}

Layout.propTypes = {
  userId: PropTypes.string,
  onChangeLayout: PropTypes.func,
  onChangeLayoutWidth: PropTypes.func,
  onChangeTopbarTheme: PropTypes.func,
  children: PropTypes.object,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  location: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  history: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
  onGetGroups: PropTypes.func,
}

const mapDispatchToProps = dispatch => ({
  onGetGroups: userId => dispatch(getGroups(userId)),
  onChangeTopbarTheme: value => dispatch(changeTopbarTheme(value)),
  onChangeLayout: value => dispatch(changeLayout(value)),
  onChangeLayoutWidth: value => dispatch(changeLayoutWidth(value)),
})

const mapStateToProps = state => {
  return {
    userId: state.Account.user?.uid,
    ...state.Layout,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout))
