import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"

import { connect } from "react-redux"

import { isGoogleMapsScriptLoadedAction } from "store/actions"

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// layouts Format
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

// Import Firebase Configuration file
import { initFirebaseBackend } from "./helpers/firebase_helper"
import { useJsApiLoader } from "@react-google-maps/api"
import { Inner } from "shared/Inner/Inner"
import { Helmet } from "react-helmet"

// import fakeBackend from "./helpers/AuthType/fakeBackend"

// Activating fake backend
// fakeBackend()

const libraries = ["places"]
const mapKey = process.env.REACT_APP_MAPAPIKEY

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
}

// init firebase backend
initFirebaseBackend(firebaseConfig)

const App = props => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: mapKey,
    libraries,
  })

  const dispatch = useDispatch()

  useEffect(() => dispatch(isGoogleMapsScriptLoadedAction(isLoaded)), [isLoaded])

  function getLayout() {
    let layoutCls = HorizontalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = HorizontalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()

  return (
    <React.Fragment>
      <Helmet>
        <script src="https://www.dwin1.com/78568.js" type="text/javascript" defer="defer"></script>
      </Helmet>
      <Inner
        publicRoutes={publicRoutes}
        authProtectedRoutes={authProtectedRoutes}
        layout={Layout}
        nonAuthLayout={NonAuthLayout}
      />
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
