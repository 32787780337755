import { useEffect, useState } from 'react'

import { imageOnlyText, maxSizeText } from './texts'

const limitSize = 5

export const useFileUpload = (imageOnly, file, onUpload) => {
  const [ banner, setBanner ] = useState(false)
  const [ text, setText ] = useState('')

  useEffect(() => {
    if(!!file){
      const size = file?.size
      const type  =  file?.type.split('/')[0]

      if(imageOnly){
        if(size/1000000 <= limitSize && type === 'image'){
          onUpload()
        } else {
          setBanner(true)
          setText(size/1000000 > 5 ? maxSizeText : imageOnlyText)
          setTimeout(() => {
            setBanner(false)
          }, 1500)
        }
      } else {
        if(size/1000000 <= limitSize){
          onUpload()
        } else {
          setBanner(true)
          setText(maxSizeText)
          setTimeout(() => {
            setBanner(false)
          }, 1500)
        }
      }
    }
  }, [imageOnly, file])

  return { text, banner }
}

