import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Link } from "react-router-dom"

import { ActivityItem } from "components/Activity/components/ActivityItem/ActivityItem"

export const NotificationNewGroupMessage = ({
  isSmall,
  id,
  read,
  room_name,
  profile_image,
  time,
  room_id,
  onRead,
  onDelete,
  onRoomClick,
}) => {
  const title = (
    <>
      <Link to="/messages" className="activity-item__link" onClick={() => onRoomClick(room_id)}>
        {room_name}
      </Link>{" "}
      group has new message
    </>
  )

  return (
    <ActivityItem
      onDelete={onDelete}
      id={id}
      isSmall={isSmall}
      title={title}
      image={profile_image}
      time={time}
      dropdownOptions={
        <>
          {/* <button
            className={cx("border-0 dropdown-item font-size-11 notifications-block__button", {
              "is-disabled": read,
            })}
            onClick={() => onRead(id)}
          >
            Mark as read
          </button> */}

          {/* <button
            className="border-0 dropdown-item font-size-11 notifications-block__button"
            onClick={() => onDelete(id)}
          >
            Remove notification
          </button> */}
        </>
      }
    />
  )
}

NotificationNewGroupMessage.propTypes = {
  isSmall: PropTypes.bool,
  read: PropTypes.bool,
  id: PropTypes.number,
  room_id: PropTypes.string,
  time: PropTypes.number,
  profile_image: PropTypes.string,
  room_name: PropTypes.string,
  onRead: PropTypes.func,
  onDelete: PropTypes.func,
  onRoomClick: PropTypes.func,
}
