import React from "react"
import PropTypes from "prop-types"
import { TabPane } from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"

import { ChatLinkPreview } from "../ChatLinkPreview/ChatLinkPreview"

export const ChatRightTabPaneLinks = ({
    tabId,
    options,
  }) => {

  return (
    <TabPane tabId={tabId}>
      <ul className="overflow-hidden list-unstyled chat-right-tab-pane-links">
        <PerfectScrollbar className="chat-right-tab-pane-links__scroll" >
          {options.map((option, index) => {
            return (
              <li key={option + index} className="chat-right-tab-pane-links__list-item">
               <ChatLinkPreview option={option} />
              </li>
            );
            })}
        </PerfectScrollbar>
      </ul>
    </TabPane>
  )
}

ChatRightTabPaneLinks.propTypes = {
  tabId: PropTypes.string,
  options: PropTypes.array,
  onClick: PropTypes.func,
}
