import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { CardBody, Alert, FormFeedback } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import firebase from "firebase/compat/app"
import "firebase/compat/firestore"
import "firebase/compat/analytics"
import cx from "classnames"

import { PrimaryButton } from "components/PrimaryButton/PrimaryButton"
import { InputField } from "components/InputField/InputField"
import { isValidPhone } from "helpers/isValidPhone"

import { apiError } from "../../../store/actions"
import { getFirebaseBackend, getNonExistUsername } from "../../../helpers/firebase_helper"
import { Loader } from "components/Loader/Loader"
import { countryCodes } from "common/countryCodes"

const defaultCode = {
  first: "",
  second: "",
  third: "",
  fourth: "",
  fifth: "",
  sixth: "",
}

export const PhoneValidation = props => {
  const dispatch = useDispatch()
  const fireBaseBackend = getFirebaseBackend()

  const [final, setFinal] = useState(null)
  const [code, setCode] = useState(defaultCode)
  const [phone, setPhone] = useState("")
  const [phoneCode, setPhoneCode] = useState("1")
  const [validation, setValidation] = useState(false)
  const [errorValidation, setErrorValidation] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isWrongCode, setIsWrongCode] = useState(false)
  const [pixelInserted, setPixelInserted] = useState(false)

  const { registrationError } = useSelector(state => {
    return {
      registrationError: state.Account.registrationError,
    }
  })

  useEffect(() => {
    dispatch(apiError(""))
  }, [])

  function onSelect(codeNumber) {
    return () => {
      setPhoneCode(codeNumber)
    }
  }

  // eslint-disable-next-line react/prop-types
  const Dropdown = ({ options, onSelect }) => {
    return (
      <div className="phone-validation__countrySelectDropdown">
        <select id="countryselect">
          {
            // eslint-disable-next-line react/prop-types
            options?.map(option => (
              <option onClick={onSelect(option.codeNumber)} key={option.codeNumber}>
                {option.name}
              </option>
            ))
          }
        </select>
      </div>
    )
  }

  const handleOnChangePhone = event => {
    setPhone(event.target.value)

    if (event.target.value.length >= 10) {
      setErrorValidation(isValidPhone(event.target.value))
    } else {
      setErrorValidation(true)
    }
  }

  const handlePhoneBlur = () => {
    if (!!phone) {
      setErrorValidation(isValidPhone(phone))
    } else {
      setErrorValidation(true)
    }
  }

  const [isNewNumber, setIsNewNummber] = useState(true)
  useEffect(() => {
    if (!!phone) {
      firebase
        .firestore()
        .collection("users")
        .where("phone", "==", phone)
        .get()
        .then(res => {
          setIsNewNummber(!res.docs.length)
        })
    } else {
      setIsNewNummber(true)
    }
  }, [phone, errorValidation, setIsNewNummber])

  const handleClick = () => {
    if (isNewNumber && !!phone) {
      let verify = new firebase.auth.RecaptchaVerifier("sign-in-container")
      firebase
        .auth()
        .signInWithPhoneNumber(`+${phoneCode}${phone}`, verify)
        .then(result => {
          setFinal(result)
        })
        .catch(err => {
          console.log("ERROR", err)
        })

      setValidation(true)
    }
  }

  const phoneClasses = cx("profile-contacts__phone sign-up-modal__form-wrapper phone-validation__input", {
    "is-error": !errorValidation,
  })

  const buttonClasses = cx("w-100 btn btn-primary btn-block sign-up-modal__submit-button")

  const handleValidate = () => {
    setIsLoading(true)
    if (phone === null || final === null) {
      return
    }

    const value = Object.values(code).join("")

    final
      .confirm(value)
      .then(async result => {
        setCode(defaultCode)

        const collection = firebase.firestore().collection("users")
        const username = await getNonExistUsername(collection)

        fireBaseBackend.updateUser({
          username: username.toString(),
          email: props.data.email,
          firstName: props.data.firstName.toLowerCase(),
          lastName: props.data.lastName.toLowerCase(),
          phone: phone,
          profile_image_notified: false,
        })

        const credential = firebase.auth.EmailAuthProvider.credential(props.data.email, props.data.password)
        firebase.auth().currentUser.linkWithCredential(credential)

        const redirectLink = localStorage.getItem("redirectAfterLogin")

        setTimeout(() => {
          if (redirectLink) {
            props.history.push(redirectLink)
          } else {
            props.history.push("/home")
          }
          setIsLoading(false)
        }, 1500)

        fireBaseBackend.registrationSuccessEmail(
          props.data.email,
          props.data.firstName.toLowerCase(),
          props.data.lastName.toLowerCase()
        )
        fireBaseBackend.completeProfileReminder(props.data.email)

        // Trigger Share a sale
        if (!pixelInserted) {
          setPixelInserted(true)
        }
        
      })
      .catch(err => {
        setIsLoading(false)
        setIsWrongCode(true)
        setCode(defaultCode)
        console.log("Wrong code")
      })
  }

  const title = !validation
    ? "Please enter your phone number to receive a verification code"
    : "Please enter the verification code we sent to your phone number"

  const error = !isNewNumber ? (
    <FormFeedback type="invalid" className="font-size-11">
      This phone number is already associated with an existing account. Please try a different number.
    </FormFeedback>
  ) : null

  return (
    <CardBody className="sign-up-modal__body phone-validation">
      {isLoading && <Loader />}
      {/* Share a sale pixel */}
      {pixelInserted && (
        <div style={{ display: "none" }}>
          <img
            src={`https://www.shareasale.com/sale.cfm?tracking=${final.verificationId}+${phone}&amount=0.00&merchantID=154025&transtype=lead`}
            width="1"
            height="1"
            alt=""
          />
          <script src="https://www.dwin1.com/78568.js" type="text/javascript" defer="defer"></script>
        </div>
      )}
      <div className="sign-up-modal__form-wrapper">
        <h5 className="sign-up-modal__title fw-extra-bold">Verification</h5>

        <p className="sign-up-modal__subtitle">{title}</p>
      </div>

      {isWrongCode && <Alert color="danger">Wrong code, try again!</Alert>}

      {registrationError && registrationError ? <Alert color="danger">{registrationError}</Alert> : null}

      <div className="d-flex mb-4">
        <div id="sign-in-container" className={cx({ "d-none": !!final })} />

        {!validation && (
          <div>
            <Dropdown options={countryCodes} onSelect={onSelect} />
            <div className="d-flex">
              <br />

              <span className="mdi mdi-phone-outline phone-validation__icon"></span>

              <InputField
                className={phoneClasses}
                inputName="phone"
                type="phone"
                placeholder="Phone number"
                value={phone}
                onChange={handleOnChangePhone}
                onBlur={handlePhoneBlur}
                invalid={!errorValidation || !isNewNumber}
                error={error}
              />
            </div>
          </div>
        )}

        {validation && !!final && <Code code={code} onChangeCode={setCode} />}
      </div>

      <div className="sign-up-modal__form-wrapper">
        {!validation && (
          <PrimaryButton
            className={buttonClasses}
            text={errorValidation ? "Send code" : "Invalide number"}
            error={!errorValidation}
            onClick={handleClick}
          />
        )}

        {validation && !!final && <PrimaryButton className={buttonClasses} text="Validate" onClick={handleValidate} />}
      </div>
    </CardBody>
  )
}

PhoneValidation.propTypes = {
  history: PropTypes.object,
  data: PropTypes.object,
}

const Code = ({ code, onChangeCode }) => {
  const firstRef = useRef(null)
  const secondRef = useRef(null)
  const thirdRef = useRef(null)
  const fourthRef = useRef(null)
  const fifthRef = useRef(null)
  const sixthRef = useRef(null)

  const codeClasses = cx(
    "profile-contacts__phone sign-up-modal__form-wrapper phone-validation__input phone-validation__code"
  )

  useEffect(() => {
    firstRef?.current?.focus()
  }, [])

  const handleFocus = () => {
    const list = [firstRef, secondRef, thirdRef, fourthRef, fifthRef, sixthRef]

    const focused = list.find(item => item.current.className.split(" ").includes("is-focused"))

    if (!!focused && !!focused.current.value) {
      if (focused.current.id < 6) {
        const value = Number(focused.current.id)
        const next = list.find(item => item.current.id == value + 1)

        next.current.focus()
      }
    }
  }

  const handleCode = (e, id) => {
    const value = e.target.value
    const numberValue = value.replace(/[^0-9]/g, "")

    if (numberValue.length === 2) {
      onChangeCode({
        ...code,
        [id]: numberValue[1],
      })
      handleFocus()
    } else {
      onChangeCode({
        ...code,
        [id]: numberValue,
      })
      handleFocus()
    }
  }

  return (
    <div className="d-flex">
      <InputField
        className={codeClasses}
        ref={firstRef}
        id={"1"}
        inputName="first"
        type="text"
        maxLength="1"
        placeholder=""
        value={code.first}
        autoFocus={true}
        onChange={e => handleCode(e, "first")}
        onBlur={e => handleCode(e, "first")}
      />

      <InputField
        className={codeClasses}
        ref={secondRef}
        id={"2"}
        inputName="second"
        type="text"
        maxLength="1"
        placeholder=""
        value={code.second}
        onChange={e => handleCode(e, "second")}
        onBlur={e => handleCode(e, "second")}
      />

      <InputField
        className={codeClasses}
        ref={thirdRef}
        id={"3"}
        inputName="third"
        type="text"
        maxLength="1"
        placeholder=""
        value={code.third}
        onChange={e => handleCode(e, "third")}
        onBlur={e => handleCode(e, "third")}
      />

      <InputField
        className={codeClasses}
        ref={fourthRef}
        id={"4"}
        inputName="fourth"
        type="text"
        maxLength="1"
        placeholder=""
        value={code.fourth}
        onChange={e => handleCode(e, "fourth")}
        onBlur={e => handleCode(e, "fourth")}
      />

      <InputField
        className={codeClasses}
        ref={fifthRef}
        id={"5"}
        inputName="fifth"
        type="text"
        maxLength="1"
        placeholder=""
        value={code.fifth}
        onChange={e => handleCode(e, "fifth")}
        onBlur={e => handleCode(e, "fifth")}
      />

      <InputField
        className={codeClasses}
        ref={sixthRef}
        id={"6"}
        inputName="sixth"
        type="text"
        maxLength="1"
        placeholder=""
        value={code.sixth}
        onChange={e => handleCode(e, "sixth")}
        onBlur={e => handleCode(e, "sixth")}
      />
    </div>
  )
}

Code.propTypes = {
  code: PropTypes.object,
  onChangeCode: PropTypes.func,
}
