export const getFileSize = (number) => {
  const kb = number/1000
  if(kb < 1){
    return `${Math.trunc(number*100)/100}b`
  }

  const mb = kb/1000
  if(mb < 1){
    return `${Math.trunc(kb*100)/100}kb`
  }

  const gb = mb/1000
  if(gb < 1){
    return `${Math.trunc(mb*100)/100}Mb`
  }

  return `${Math.trunc(gb*100)/100}Gb`
}