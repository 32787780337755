import React, { useEffect, useState, useCallback } from 'react'
import firebase from 'firebase/compat/app'
import PropTypes from 'prop-types'

import { residential, roomSteps } from 'modals/variables'
import { roomModalSubtitle, roomModalTitle } from 'modals/text'
import { RoomModal } from 'modals/components/generic/RoomModal/RoomModal'
import { getTypes, onChangeLocation, onChangeSimpleValue, onChangeValue, updateRoomCoverImage, updateRoomFiles } from 'modals/helpers'
import { useDisabledButtons } from 'modals/hooks/useDisabledButtons'

export const UpdateRoomModal = ({ room, isOpened, onToggle }) => {
  const [currentTab, setCurrentTab] = useState(0)

  const defaultTypes = !!room?.investment_types.length ?
  room?.investment_types.map(item => item.type) : []
  const [dataTypes, setDataTypes] = useState(defaultTypes)

  const residentialTypes = !!room?.investment_types.length ?
  room?.investment_types.find(item => item.type === residential) : []
  const [dataPropertyTypes, setDataPropertyTypes] = useState(!!residentialTypes ? residentialTypes.property_type : [])
  const [dataInvestmentTypes, setDataInvestmentTypes] = useState(!!residentialTypes ? residentialTypes.investment_type : [])

  const [dataInvestmentLocation, setDataInvestmentLocation] = useState(room?.investment_location)
  const [dataBudget, setDataBudget] = useState(typeof room?.project_size === "string" ? null : room?.project_size)
  const [dataMain, setDataMain] = useState({
    name: room?.name,
    description: room?.description,
    cover_image: "",
  })
  const [dataFiles, setDataFiles] = useState([])

  const handleUpdateRoom = useCallback(() => {
    const types = getTypes(dataTypes, dataInvestmentTypes, dataPropertyTypes)

    firebase.firestore().collection('rooms').doc(room.id).update({
      investment_types: types,
      name: dataMain.name,
      description: dataMain.description,
      investment_location: dataInvestmentLocation,
      project_size: dataBudget,
    })

    if(!!dataMain.cover_image){
      updateRoomCoverImage(room.id, dataMain.cover_image)
    }

    if(!!dataFiles.length){
      updateRoomFiles(room.id, dataFiles)
    }
  })

  useEffect(() => {
    if (currentTab == roomSteps && !!dataMain.name) {
      setTimeout(() => {
        handleToggle()
      }, "2500")

      handleUpdateRoom()
    }
  }, [currentTab, roomSteps])

  const onMediaChange = file => {
    setDataFiles([...dataFiles, file])
  }

  const handleToggle = useCallback(() => {
    onToggle()

    setDataTypes([])
    setDataPropertyTypes([])
    setDataInvestmentTypes([])
    setDataInvestmentLocation([])
    setDataBudget("")
    setDataMain({ name: "", description: "", cover_image: "" })
    setDataFiles([])
  })

  const disabledButtons = useDisabledButtons(dataMain?.name, currentTab)

  return (
    <RoomModal
      isOpened={isOpened}
      title={roomModalTitle}
      subtitle={roomModalSubtitle}
      steps={roomSteps}
      currentTab={currentTab}
      types={dataTypes}
      investmentTypes={dataInvestmentTypes}
      propertyTypes={dataPropertyTypes}
      investmentLocation={dataInvestmentLocation}
      budget={dataBudget}
      form={dataMain}
      files={dataFiles}
      disabledButtons={disabledButtons}
      enabledMediaStep={true}
      onChangeForm={values => onChangeSimpleValue(values, setDataMain)}
      onChangeMedia={onMediaChange}
      onChangeTypes={(id) => onChangeValue(dataTypes, setDataTypes, id)}
      onChangePropertyTypes={(id) => onChangeValue(dataPropertyTypes, setDataPropertyTypes, id)}
      onChangeInvestmentTypes={(id) => onChangeValue(dataInvestmentTypes, setDataInvestmentTypes, id)}
      onChangeInvestmentLocation={location => onChangeLocation(location, dataInvestmentLocation, setDataInvestmentLocation)}
      onChangeBudget={(values) => onChangeSimpleValue(values, setDataBudget)}
      onToggle={onToggle}
      onChangeTab={setCurrentTab}
    />
  )
}

UpdateRoomModal.propTypes = {
  room: PropTypes.object,
  isOpened: PropTypes.bool,
  onToggle: PropTypes.func,
}
