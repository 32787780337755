import React, { useMemo } from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import { Icon } from "components/Icon/Icon"
import { useAddressByCoordinates } from "hooks/useAddressByCoordinates"
import { Members } from "components/Members/Members"

export const SearchResultsCard = ({
  zIndex = 1,
  image,
  alt,
  text,
  title,
  type,
  price,
  location,
  members,
  vertical = false,
  history,
  userPopover,
  onDotsClick,
  onClick = () => {},
}) => {
  const membersText = members.length === 1 ? "member" : "members"

  const classes = cx("w-100 search-results-card position-relative", {
    "search-results-card--vertical": vertical,
    "search-results-card--popover": userPopover,
  })

  const updatedLocation =
    location?.length > 1 ? `${useAddressByCoordinates(location[0])} & More` : useAddressByCoordinates(location[0])

  const styles = { zIndex: zIndex }

  return (
    <div className={classes} onClick={onClick} style={styles}>
      <div className="h-100 position-relative overflow-hidden search-results-card__image-wrapper">
        {!!image ? (
          <img className="h-100 position-absolute search-results-card__image" alt={alt} src={image} />
        ) : (
          <i className="mdi search-results-card__no-image mdi-image" />
        )}
      </div>

      <div className="search-results-card__body">
        <div className="d-flex search-results-card__top-content">
          <div className="d-flex search-results-card__avatar-list">
            <Members members={members} popover={userPopover} history={history} onClick={onDotsClick} />
          </div>

          <div className="search-results-card__heading">
            <span className="font-size-11 search-results-card__counter">
              {members.length} {membersText}
            </span>

            <h5 className="font-size-16 search-results-card__title">{title}</h5>
          </div>
        </div>

        <p className="font-size-13 search-results-card__description">{text}</p>

        <div className="d-flex align-items-center w-100 search-results-card__info">
          <span className="d-inline-block align-items-center font-size-11 search-results-card__info-item search-results-card__type">
            <Icon className="search-results-card__info-icon" name="investment-type" />
            <span className="search-results-card__label">{type}</span>
          </span>

          <span className="d-inline-blockalign-items-center font-size-11 search-results-card__info-item">
            <Icon className="search-results-card__info-icon" name="price" />
            {price}
          </span>

          <span className="d-inline-block align-items-center font-size-11 search-results-card__info-item">
            <Icon className="search-results-card__info-icon" name="location-24" />
            {updatedLocation}
          </span>
        </div>
      </div>
    </div>
  )
}

SearchResultsCard.propTypes = {
  zIndex: PropTypes.number,
  image: PropTypes.string,
  alt: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  price: PropTypes.string,
  location: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array]),
  onClick: PropTypes.func,
  members: PropTypes.array,
  vertical: PropTypes.bool,
  history: PropTypes.object,
  userPopover: PropTypes.bool,
  onDotsClick: PropTypes.func,
}
