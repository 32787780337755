import React from "react"
import PropTypes from "prop-types"
import {
  NavItem,
  NavLink,
} from "reactstrap"
import cx from "classnames"

export const ChatRightNavLink = ({ active, text, onClick = () => {} }) => {
  const classes = cx("overflow-hidden position-relative chat-right-nav-link", {
    active: active,
  });

  const onKeyPress = e => {
    const { key, value } = e
    if (key === "Enter") {
      onClick();
    }
  }

  return (
    <NavItem>
      <NavLink
        className={classes}
        onClick={onClick}
        onKeyPress={onKeyPress}
      >
        {text}
      </NavLink>
    </NavItem>
  )
}

ChatRightNavLink.propTypes = {
  active: PropTypes.bool,
  text: PropTypes.string,
  onClick: PropTypes.func,
}
