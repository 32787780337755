import React from "react"
import PropTypes from "prop-types"
import { Card, CardBody, CardImg } from "reactstrap"

import { SecondaryButton } from "components/SecondaryButton/SecondaryButton"
import { Icon } from "components/Icon/Icon"
import { useAddressByCoordinates } from "hooks/useAddressByCoordinates"

export const InvestmentCard = ({ image, alt, text, title, type, price, location, onClick }) => {
  const updatedLocation = useAddressByCoordinates(location)

  return (
    <Card className="m-0 investment-card" onClick={onClick}>
      {!!image ? (
        <CardImg className="w-100 h-auto investment-card__image" alt={alt} src={image} top width="100%" />
      ) : (
        <i className="mdi investment-card__no-image mdi-image" />
      )}

      <CardBody className="investment-card__body">
        <p className="font-size-11 investment-card__text">{text}</p>

        <h5 className="font-size-16 investment-card__title">{title}</h5>

        <div className="d-flex align-items-center w-100 investment-card__info">
          <span className="d-inline-block align-items-center font-size-11 investment-card__info-item investment-card__type">
            <Icon className="investment-card__info-icon" name="investment-type" />
            <span className="investment-card__label">{type}</span>
          </span>

          <span className="d-inline-block align-items-center font-size-11 investment-card__info-item">
            <Icon className="investment-card__info-icon" name="price" />
            {price}
          </span>

          <span className="d-inline-block align-items-center font-size-11 investment-card__info-item">
            <Icon className="investment-card__info-icon" name="location-24" />
            {updatedLocation}
          </span>
        </div>

        <SecondaryButton className="w-100 investment-card__button" size="small" text="Preview" onClick={onClick} />
      </CardBody>
    </Card>
  )
}

InvestmentCard.propTypes = {
  image: PropTypes.string,
  alt: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  price: PropTypes.string,
  location: PropTypes.oneOfType([
    PropTypes.object, PropTypes.string,
  ]),
  onClick: PropTypes.func,
}
