import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_THEME_IMAGE,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  SHOW_RIGHT_SIDEBAR,
  SHOW_SIDEBAR,
  CHANGE_PRELOADER,
  TOGGLE_LEFTMENU,
  OPEN_UPDATE_PROFILE_MODAL,
  UPDATE_SET_IS_ION_PROFILE,
  OPEN_PROMO_ALERT,
  OPEN_MESSAGES_RIGHT_SIDEBAR,
  OPEN_MESSAGES_LEFT_SIDEBAR,
  OPEN_ATTACHMENTS_MODAL,
  LOADED_MAPS_SCRIPT,
  OPEN_ADD_MEMBER_MODAL,
  OPENED_ROOM,
  SET_MOBILE_USER_MENU_ACTIVE,
} from "./actionTypes"

export const changeLayout = layout => ({
  type: CHANGE_LAYOUT,
  payload: layout,
})

export const changePreloader = layout => ({
  type: CHANGE_PRELOADER,
  payload: layout,
})

export const changeLayoutWidth = width => ({
  type: CHANGE_LAYOUT_WIDTH,
  payload: width,
})

export const changeSidebarTheme = theme => ({
  type: CHANGE_SIDEBAR_THEME,
  payload: theme,
})

export const changeSidebarThemeImage = themeimage => ({
  type: CHANGE_SIDEBAR_THEME_IMAGE,
  payload: themeimage,
})

export const changeSidebarType = (sidebarType, isMobile) => {
  return {
    type: CHANGE_SIDEBAR_TYPE,
    payload: { sidebarType, isMobile },
  }
}

export const changeTopbarTheme = topbarTheme => ({
  type: CHANGE_TOPBAR_THEME,
  payload: topbarTheme,
})

export const showRightSidebarAction = isopen => ({
  type: SHOW_RIGHT_SIDEBAR,
  payload: isopen,
})

export const showSidebar = isopen => ({
  type: SHOW_SIDEBAR,
  payload: isopen,
})

export const toggleLeftmenu = isopen => ({
  type: TOGGLE_LEFTMENU,
  payload: isopen,
})

export const showUpdateProfileModalAction = isopen => ({
  type: OPEN_UPDATE_PROFILE_MODAL,
  payload: isopen,
})

export const updateSetIsOnProfile = isopen => ({
  type: UPDATE_SET_IS_ION_PROFILE,
  payload: isopen,
})

export const showPromoAlertAction = isopen => ({
  type: OPEN_PROMO_ALERT,
  payload: isopen,
})

export const showMessagesRightSidebarAction = isopen => ({
  type: OPEN_MESSAGES_RIGHT_SIDEBAR,
  payload: isopen,
})

export const showMessagesLeftSidebarAction = isopen => ({
  type: OPEN_MESSAGES_LEFT_SIDEBAR,
  payload: isopen,
})

export const showAttachmentsModalAction = isopen => ({
  type: OPEN_ATTACHMENTS_MODAL,
  payload: isopen,
})

export const showAddMemberModalAction = isopen => ({
  type: OPEN_ADD_MEMBER_MODAL,
  payload: isopen,
})

export const isGoogleMapsScriptLoadedAction = isopen => ({
  type: LOADED_MAPS_SCRIPT,
  payload: isopen,
})

export const openedRoomAction = id => ({
  type: OPENED_ROOM,
  payload: id,
})

export const openUserMobileMenu = active => ({
  type: SET_MOBILE_USER_MENU_ACTIVE,
  payload: active,
})
