import { useEffect, useState } from 'react'

export const useDisabledButtons = (name, currentTab) => {
  const [disabledButtons, setDisabledButtons] = useState(false)

  useEffect(() => {
    if (currentTab === 4 && !name) {
      setDisabledButtons(true)
    }
  }, [currentTab, setDisabledButtons, name])

  useEffect(() => {
    if (!!name) {
      setDisabledButtons(false)
    }
  }, [name])

  return disabledButtons
}

