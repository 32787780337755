import React, { useState, useCallback } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import { Icon } from "components/Icon/Icon"

export const ProfileActionDropdown = ({
  isAdmin = false,
  onReportMember,
  onDeleteMember,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOnToggle = useCallback(() => setIsOpen(prev => !prev))

  return (
    <Dropdown
      className="profile-action-dropdown"
      isOpen={isOpen}
      toggle={handleOnToggle}
    >
      <DropdownToggle className="profile-action-dropdown__toggler">
        <Icon name="bx-dots-horizontal-rounded" className="profile-action-dropdown__icon" />
      </DropdownToggle>
      <DropdownMenu className="profile-action-dropdown__menu p-0" end>
        <DropdownItem
          className="profile-action-dropdown__item"
          onClick={onReportMember}
        >
          Report user
        </DropdownItem>
        {/* {isAdmin && (
          <DropdownItem
            className="profile-action-dropdown__item"
            onClick={onDeleteMember}
          >
            Delete
          </DropdownItem>
        )} */}
      </DropdownMenu>
    </Dropdown>
  )
}

ProfileActionDropdown.propTypes = {
  isAdmin: PropTypes.bool,
  onReportMember: PropTypes.func,
  onDeleteMember: PropTypes.func,
}
