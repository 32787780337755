import React from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"

import { showAddMemberModalAction } from "store/actions"

import { ChatRightSidebarComponent } from "./components/ChatRightSidebarComponent/ChatRightSidebarComponent"
import { getFirebaseBackend } from "helpers/firebase_helper"
import { getNotificationEntity } from "pages/Notifications/helpers/notifcations.helpers"

export const ChatRightSidebar = ({
  isLoading,
  room,
  users,
  onUpdateTasks,
  fileIsUploaded,
}) => {
  const dispatch = useDispatch()
  const fireBaseBackend = getFirebaseBackend()

  const currentUser = useSelector(state => state.Account.user)
  const currentUserSettings = useSelector(state => state.Account.settings)
  const isAdmin = currentUser?.uid === room?.admin_user

  const handleAddTask = name => {
    onUpdateTasks([...room.tasks, { name: name, completed: "active" }])

    const ids = [room.admin_user, ...room.users];
    const notifyUsersList = users.filter(user => {
      return user.id !== currentUser.uid && ids.includes(user.id)
    })

    notifyUsersList.forEach(user => {
      fireBaseBackend.updateUserNotificationById(
        user.id,
        getNotificationEntity("group-new-activity", {
          room,
          roomName: room.name,
          member: user,
          activityType: "task",
        })
      )
      fireBaseBackend.asyncNotifyUserByEmail(
        user.id,
        `Your group ${room.name} has new activity!`,
        `Dear ${user.firstName},
          Group ${room.name} has new activity. Click below to login and join the conversation.`,
        'Read messages',
        `${window.location.href}?room=${room.id}`
      )
    })
  }

  const handleChangeTask = name => {
    const newTasks = room.tasks.map(task => {
      if (task.name === name) {
        return {
          name: task.name,
          completed: task.completed === "closed" ? "active" : "closed",
        }
      }
      return task
    })
    onUpdateTasks(newTasks)
  }

  const handleOpenModal = () => {
    dispatch(showAddMemberModalAction(true))
  }

  return (
    <ChatRightSidebarComponent
      isLoading={isLoading}
      isAdmin={isAdmin}
      roomStatus={room?.status}
      files={!!room?.files ? room.files : []}
      links={!!room?.links ? room.links : []}
      tasks={!!room?.tasks ? room.tasks : []}
      users={users}
      fileIsUploaded={fileIsUploaded}
      currentUserId={currentUser.uid}
      onChangeTask={handleChangeTask}
      onAddTask={handleAddTask}
      onOpenModal={handleOpenModal}
    />
  )
}

ChatRightSidebar.propTypes = {
  isLoading: PropTypes.bool,
  room: PropTypes.object,
  users: PropTypes.array,
  fileIsUploaded: PropTypes.bool,
  onUpdateTasks: PropTypes.func,
}
