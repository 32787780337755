import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { CardBody, Card, Alert, Form, FormFeedback } from "reactstrap"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import firebase from "firebase/compat/app"
import "firebase/compat/firestore"
import "firebase/compat/analytics"

import { PrimaryButton } from "components/PrimaryButton/PrimaryButton"
import { InputField } from "components/InputField/InputField"
import { CaptchaBlock } from "components/CaptchaBlock/CaptchaBlock"

import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { signupValidationSchema } from "./Register.helpers"
import {
  registerUser,
  apiError,
  showUpdateProfileModalAction,
} from "../../../store/actions"
import { SocialsBlock } from "../components/SocialsBlock/SocialsBlock"
import { PhoneValidation } from "../PhoneValidation/PhoneValidation"

export const Register = props => {
  const fireBaseBackend = getFirebaseBackend()
  const dispatch = useDispatch()
  const [captchaEnabled, setCaptchaEnabled] = useState(false)
  const [formValues, setFormValues] = useState(null)
  const [captcha, setCaptcha] = useState(false)
  const [phoneEnabled, setPhoneEnabled] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
    },
    validationSchema: signupValidationSchema,
    onSubmit: values => {
      setFormValues(values)
      // dispatch(registerUser(values, props.history))

      setPhoneEnabled(true)
    },
  })

  const { user, registrationError } = useSelector(state => {
    return {
      user: state.Account.user,
      registrationError: state.Account.registrationError,
    }
  })

  useEffect(() => {
    dispatch(apiError(""))
  }, [])

  // const handleChangeCaptcha = async () => {
  //   dispatch(registerUser(formValues, props.history))
  //   setCaptcha(true)
  // }

  return (
    <Card className="sign-up-modal">
      {!phoneEnabled ? (
        <CardBody className="sign-up-modal__body">
          <div className="sign-up-modal__form-wrapper">
            <h5 className="sign-up-modal__title fw-extra-bold">Sign up</h5>

            <p className="sign-up-modal__subtitle">It’s easy and free!</p>
          </div>

          {!captchaEnabled ? (
            <Form
              className="form-horizontal sign-up-modal__form"
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                dispatch(showUpdateProfileModalAction(true))
                return false
              }}
            >
              {!!user && captcha ? (
                <Alert color="success">Registration successful</Alert>
              ) : null}

              {registrationError && registrationError ? (
                <Alert color="danger">{registrationError}</Alert>
              ) : null}

              <InputField
                className="sign-up-modal__form-wrapper"
                inputName="email"
                type="email"
                placeholder="Email address"
                value={validation.values.email}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={validation.touched.email && validation.errors.email ? true : false}
                error={
                  validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid" className="font-size-11">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null
                }
              />

              <div className="sign-up-modal__form-wrapper sign-up-modal__form-wrapper-name">
                <InputField
                  inputName="firstName"
                  type="text"
                  placeholder="First name"
                  value={validation.values.firstName}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={validation.touched.firstName && validation.errors.firstName ? true : false}
                  error={
                    validation.touched.firstName && validation.errors.firstName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.firstName}
                      </FormFeedback>
                    ) : null
                  }
                />

                <InputField
                  inputName="lastName"
                  type="text"
                  placeholder="Last name"
                  value={validation.values.lastName}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={validation.touched.lastName && validation.errors.lastName ? true : false}
                  error={
                    validation.touched.lastName && validation.errors.lastName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.lastName}
                      </FormFeedback>
                    ) : null
                  }
                />
              </div>

              <InputField
                className="sign-up-modal__form-wrapper"
                inputName="password"
                type="password"
                placeholder="Enter password"
                value={validation.values.password || ""}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={validation.touched.password && validation.errors.password ? true : false}
                error={
                  validation.touched.password && validation.errors.password ? (
                    <FormFeedback type="invalid">
                      {validation.errors.password}
                    </FormFeedback>
                  ) : null
                }
              />

              <div className="sign-up-modal__form-wrapper">
                <PrimaryButton
                  className="btn btn-primary btn-block sign-up-modal__submit-button"
                  type="submit"
                  text="Register"
                />
              </div>

              <SocialsBlock history={props.history} />
            </Form>
          ) : (
            // <CaptchaBlock onChange={handleChangeCaptcha} />
            <></>
          )}
        </CardBody>
      ) : (
        <PhoneValidation data={formValues} history={props.history} />
      )}

    </Card>
  )
}

Register.propTypes = {
  history: PropTypes.object,
}
