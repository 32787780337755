import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { TabPane } from "reactstrap"
import cx from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import firebase from "firebase/compat/app"
import "firebase/compat/storage";
import { getFileSize } from "helpers/getFileSize"
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const imageFormats = ["image/jpg", "image/png", "image/svg", "image/gif", "image/jpeg"];
const archiveFormats = ["application/zip", "application/rar"];

const getIcon = (file) => {
  const format = file?.contentType;
  const isImageFormat = imageFormats.includes(format);
  if(isImageFormat){
    return "mdi-image";
  }
  
  const isArchiveFormat = archiveFormats.includes(format);
  if(isArchiveFormat){
    return "mdi-folder";
  }

  return "mdi-text-box-outline";
};

export const ChatRightTabPane = ({ tabId, options, fileIsUploaded }) => {
  return (
    <TabPane tabId={tabId}>
      <ul className="overflow-hidden list-unstyled chat-right-tab-pane">
        <PerfectScrollbar className="chat-right-tab-pane__scroll" >
          {options.map((option, index) =>  <ChatFileItem key={index} fileIsUploaded={fileIsUploaded} option={option} />)}
        </PerfectScrollbar>
      </ul>
    </TabPane>
  )
}

ChatRightTabPane.propTypes = {
  tabId: PropTypes.string,
  options: PropTypes.array,
  fileIsUploaded: PropTypes.bool,
}

export const ChatFileItem = ({ option, fileIsUploaded }) => {
  const [ file, setFile ] = useState(null)

  const hadleGetFile = () => {
    setTimeout(() => {
      const storageRef = firebase.storage().ref()
      const ref = storageRef.child(option)
      ref.getMetadata()
        .then((metadata) => {
          setFile(metadata)})
        .catch((error) => {
          console.log(error)
          hadleGetFile()
        })
    }, 5000)
  }

  useEffect(() => {
    if(!file){
      const storageRef = firebase.storage().ref()
      const ref = storageRef.child(option)
      ref.getMetadata()
        .then((metadata) => {
          setFile(metadata)})
        .catch((error) => {
          console.log(error)
          hadleGetFile()
        })
    }
  }, [option, setFile, fileIsUploaded])

  const handleClick = () => {
    const storage = getStorage();
    getDownloadURL(ref(storage, file.fullPath))
      .then((url) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          const blob = xhr.response;

        };
        xhr.open('GET', url);
        xhr.send();

        downloadResource(url, file.name)

      })
      .catch((error) => {
        console.log("ERROR")
      });

  }

  const iconClasses = cx("font-size-22 mdi chat-right-tab-pane__button-icon", getIcon(file));
  const size = getFileSize(file?.size)

  return (
    <li className="chat-right-tab-pane__list-item">
      <button
        className="border-0 d-grid justify-content-between align-items-end font-size-13 chat-right-tab-pane__button"
        onClick={handleClick}
      >
        <span className="d-flex overflow-hidden align-items-center chat-right-tab-pane__name">
          <i className={iconClasses} />

          <span className="overflow-hidden chat-right-tab-pane__name-text">{file?.name ? file.name : "Loading..."}</span>
        </span>

        {!!file?.size && <span className="font-size-11 chat-right-tab-pane__size">{size}</span>}
      </button>
    </li>
  )
}

ChatFileItem.propTypes = {
  option: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  fileIsUploaded: PropTypes.bool,
}

function forceDownload(blob, filename) {
  const a = document.createElement('a');
  a.download = filename;
  a.href = blob;
  // For Firefox https://stackoverflow.com/a/32226068
  document.body.appendChild(a);
  a.click();
  a.remove();
}

// Current blob size limit is around 500MB for browsers
function downloadResource(url, filename) {

  fetch(url, {
      headers: new Headers({
        'Origin': location.origin
      }),
      mode: 'cors'
    })
    .then(response => response.blob())
    .then(blob => {
      let blobUrl = window.URL.createObjectURL(blob);
      forceDownload(blobUrl, filename);
    })
    .catch(e => console.error(e));
}
