import React from "react"
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Card, CardBody } from "reactstrap"

export const CardBlock = ({ className, title, text, children, header, bodyClass }) => {
  const classes = cx('card-block', className);
  const classesBody = cx('card-block__body', bodyClass);

  return (
  <Card className={classes}>
    <CardBody className={classesBody}>
      {header}

      {!!title && <h5 className="font-size-16 card-block__title">{title}</h5>}

      {!!text && <p className="font-size-13 card-block__text">{text}</p>}

      {children}
    </CardBody>
  </Card>
  )
}

CardBlock.propTypes = {
  className: PropTypes.string,
  bodyClass: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  header: PropTypes.node,
  children: PropTypes.node
};
