import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import startOptions from 'common/data/schemas/investment_types'

import { Step } from '../../common/Step/Step'
import { ModalButtonList } from '../../common/ModalButtonList/ModalButtonList'

export const TypesStep = ({
  active, currentTab, descriptionDisabled = false, isRoom, onChange
}) => {

  const options = startOptions.map(option => {
    return { id: option.type, icon: option.type, name: option.type, description: '', active: active.includes(option.type) };
  })

  const description = descriptionDisabled ? '' : 'What type of investment are you looking for?'
  const step = isRoom ? 0 : 1
  const classes = cx({ 'd-block': currentTab === step, 'd-none': currentTab !== step, })
  const heading = isRoom ? 'Investment type' : 'Investment type'

  return (
    <Step
      className={classes}
      heading={heading}
      description={description}
    >
      <ModalButtonList items={options} onClick={onChange} />
    </Step>
  )
}

TypesStep.propTypes = {
  currentTab: PropTypes.number,
  className: PropTypes.string,
  descriptionDisabled: PropTypes.bool,
  isRoom: PropTypes.bool,
  active: PropTypes.array,
  onChange: PropTypes.func,
}
