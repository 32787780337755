import React from "react"
import PropTypes from "prop-types"

import { UserName } from "components/UserName/UserName"
import { Avatar } from "components/Avatar/Avatar"
import { getFullName } from "components/Profile/Profile.helpers"
import { VerifiedSvgSmall } from "assets/icons/verifiedSmall"
import { capitalizeFirstLetter } from "helpers/capitalizeFirstLetter"

export const ChatModalList = ({ results, onClick }) => {
  return (
    <ul className="m-0 p-0 position-absolute chat-modal-list">
      {results.map((result, index) => {
        const name = getFullName(result.firstName, result.lastName)
        return (
          <li key={index} className="list-group p-0 flex-row">
            <button
              className="w-100 d-flex align-items-center border-0 chat-modal-list__button"
              onClick={() => onClick(result)}
            >
              {!!result.profile_image ? (
                <Avatar size="xs" className="chat-modal-list__avatar" image={result.profile_image} />
              ) : (
                <UserName size="xs" className="chat-modal-list__avatar" name={name[0]} />
              )}

              <span className="w-100 font-size-13 d-flex flex-column chat-modal-list__name-wrapper">
                <span className="chat-modal-list__name">
                  {capitalizeFirstLetter(name)} {result?.isVerified && <VerifiedSvgSmall />}
                </span>
              </span>
            </button>
          </li>
        )
      })}
    </ul>
  )
}

ChatModalList.propTypes = {
  results: PropTypes.array,
  onClick: PropTypes.func,
}
