import React, { useState, useRef } from "react"
import PropTypes from 'prop-types';
import cx from "classnames"

import { useOnClickOutside } from "helpers/clickOutside";
import { SearchSelectButton } from "../SearchSelectButton/SearchSelectButton";
import { PlacesAutocomplete } from "components/PlacesAutocomplete/PlacesAutocomplete";
import { Icon } from "components/Icon/Icon";

export const LocationSelect = ({ className, onSelect }) => {
  const ref = useRef(null);

  const [ isActive, setIsActive ] = useState(false);

  useOnClickOutside(ref, () => setIsActive(false));

  const onClick = () => {
    setIsActive(active => !active);
  };

  const classes = cx("position-relative location-select", className, {
    "is-active": isActive
  });

  const [ focused, setFocused ] = useState(false);

  const handleChange = (address) => {
    onSelect({
      value: Date.now(),
      label: address?.label
    });
    setIsActive(false)
  };

  const wrapperClasses = cx("location-select__input-wrapper", {
    "is-focused": focused
  });

  return (
    <div className={classes} ref={ref}>
      <SearchSelectButton className="location-select__button" text="Location" isActive={isActive} onClick={onClick} />

      {isActive && (
        <div className="position-absolute location-select__content">
          <p className="location-select__title">Select your location</p>

          <div className={wrapperClasses}>
            <Icon name="place" className="location-select__icon" />

              <PlacesAutocomplete
                placeholder="Search for a location"
                onChange={handleChange}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
              />
            </div>
        </div>
      )}
    </div>
  )
}

LocationSelect.propTypes = {
  className: PropTypes.string,
  onSelect: PropTypes.func
};
