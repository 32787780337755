import React from "react"
import PropTypes from 'prop-types';

import { terms } from "pages/Faq/content/terms";

export const FaqDisclaimer = ({ data }) => (
  <div className="faq-disclaimer" dangerouslySetInnerHTML={{__html: !!data ? data : terms}} />
)

FaqDisclaimer.propTypes = {
  data: PropTypes.string
};
