export const steps = 9
export const roomSteps = 6
export const newRoomSteps = 5
export const residential = 'residential'
export const userDefaultSettings = {
  role: '',
  investment_types: [],
  investor_location: null,
  investment_location: [],
  project_size: { min: 0, max: 5000000 },
  investor_experience: '',
  skills: [],
  strike: 0,
  bio: '',
  profile_image: ''
}
