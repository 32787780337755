import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useDispatch, connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { PropertyAlert } from "components/PropertyAlert/PropertyAlert"
import { Main } from "components/Main/Main"
import { PageTitle } from "components/PageTitle/PageTitle"
import { Search } from "components/Search/Search"
import { RecommendedUsers } from "shared/RecommendedUsers/RecommendedUsers"
import { Sidebar } from "components/Sidebar/Sidebar"
import { StartGroup } from "components/StartGroup/StartGroup"
import { PromoBanner } from "components/PromoBanner/PromoBanner"
import { MobileSearch } from "components/MobileSearch/MobileSearch"
import { useSearchUsersResults } from "hooks/useSearchUsersResults"
import { useSearchRoomsResults } from "hooks/useSearchRoomsResults"
import { useSort } from "hooks/useSort"
import { RecommendedUsersSlider } from "components/RecommendedUsersSlider/RecommendedUsersSlider"
import { useSearchQueryRoomsResults } from "hooks/useSearchQueryRoomsResults"
import { useSearchQueryUsersResults } from "hooks/useSearchQueryUsersResults"

import { showPromoAlertAction } from "../../store/layout/actions"
import { GroupResults } from "components/GroupResults/GroupResults"

const Explore = props => {
  const dispatch = useDispatch()
  const { allRooms, allUsers, user, settings, history, query, showPromoAlert } = props

  const [promoBannerOpened, setPromoBannerOpened] = useState(false)
  const [openedMobileSearch, setOpenedMobileSearch] = useState(false)

  const [searchQuery, setSearchQuery] = useState(null)

  const handleSearch = e => {
    setTimeout(() => {
      const sliderTrack = document.querySelector(".slick-track")
      if (sliderTrack) {
        sliderTrack.style.transform = "translate3d(0px, 0px, 0px)"
      }
    }, 200)
    const isEmptyQuery = !query.name && !query.types?.length && !query.size && !query.locations?.length

    if (isEmptyQuery) {
      setSearchQuery(null)
      return
    }
    setSearchQuery(query)
  }

  const isFromHome = history?.location?.search?.includes("fromHome=true")

  useEffect(() => {
    if (isFromHome) {
      handleSearch()
    }
  }, [isFromHome])

  const users = useSearchQueryUsersResults(allUsers, searchQuery, settings, user?.uid)
  const rooms = useSearchQueryRoomsResults(allRooms, searchQuery, settings, user?.uid)
  const recommendedUsers = useSearchUsersResults(allUsers, null, settings, user?.uid)
  const recommendedRooms = useSearchRoomsResults(allRooms, null, settings, user?.uid)

  const [sortedValue, setSortedValue] = useState(null)
  const { sortedUsers, sortedRooms } = useSort(
    searchQuery === null ? recommendedUsers : users,
    searchQuery === null ? recommendedRooms : rooms,
    sortedValue
  )

  return (
    <div className="explore">
      <PropertyAlert
        location="Old Port"
        url="#"
        isOpen={showPromoAlert}
        onCloseClick={() => dispatch(showPromoAlertAction(false))}
        onClick={() => setPromoBannerOpened(true)}
      />

      <Main className="explore__main">
        <PageTitle className="explore__title" text="What are you looking for today?" />

        <Search query={query} onOpenMobileSearch={() => setOpenedMobileSearch(true)} onSearch={handleSearch} />

        <GroupResults
          settings={settings}
          user={user}
          options={!!sortedRooms ? sortedRooms : rooms.length > 0 ? rooms : allRooms}
          // options={!!sortedRooms ? sortedRooms : searchQuery === null ? recommendedRooms : rooms}
          history={history}
          isExplore={true}
          onSort={setSortedValue}
        />

        <RecommendedUsersSlider
          options={users}
          history={history}
          id={user?.uid}
          isShownOnDesktop={true}
          title={
            <p className="font-size-13 search-results__header-text">
              <span className="search-results__counter">{users?.length}</span>
              {users?.length === 1 ? "user" : "users"} found
            </p>
          }
          text=""
        />

        <Sidebar className="explore__sidebar">
          <StartGroup history={history} />
          <RecommendedUsers
            className="explore__users"
            title="Users based on your preferences"
            list={recommendedUsers?.length > 0 ? recommendedUsers : allUsers}
          />
        </Sidebar>
      </Main>

      <PromoBanner
        isOpened={promoBannerOpened}
        image={""}
        alt=""
        text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac elementum etiam blandit vitae id. Sodales sollicitudin et adipiscing platea augue condimentum. Lectus sociis nibh imperdiet risus."
        title="Promotion Heading"
        link="https://www.google.com/"
        onToggle={() => setPromoBannerOpened(false)}
      />

      <MobileSearch
        query={query}
        isOpened={openedMobileSearch}
        onToggle={() => setOpenedMobileSearch(false)}
        onSearch={handleSearch}
      />
    </div>
  )
}

Explore.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
  settings: PropTypes.object,
  allRooms: PropTypes.array,
  allUsers: PropTypes.array,
  query: PropTypes.object,
  showPromoAlert: PropTypes.bool,
}

const mapStateToProps = ({ Account, chat, Layout }) => {
  return {
    user: Account.user,
    settings: Account.settings,
    allRooms: chat.allRooms,
    allUsers: chat.allUsers,
    query: chat.query,
    showPromoAlert: Layout.showPromoAlert,
  }
}

export default connect(mapStateToProps)(withRouter(Explore))
