import React from "react"
import PropTypes from "prop-types"

import { PrimaryButton } from "components/PrimaryButton/PrimaryButton"
import { useAddressByCoordinates } from "hooks/useAddressByCoordinates"

export const LocationPill = ({ address, className, onClick }) => {
  const data = useAddressByCoordinates(address)

  return (
    <li className={className}>
      <PrimaryButton
        size="extra-small"
        icon="clear"
        text={data}
        onIconClick={() => onClick(address)}
        onClick={() => {}}
      />
    </li>
  )
}

LocationPill.propTypes = {
  className: PropTypes.string,
  address: PropTypes.object || PropTypes.string,
  onClick: PropTypes.func,
}
