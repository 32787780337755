import React, { useState } from "react"
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap"

export const FaqAccordion = ({ data }) => {
  const [ activeButton, setActiveButton ] = useState("1");

  const onClick = (button) => {
    if(button === activeButton) {
      setActiveButton("0");
    } else {
      setActiveButton(button);
    }
  };

  return (
    <Accordion
      className="faq-accordion"
      open={activeButton}
      toggle={() => {}}
      >
      {data.map((item) => {
        return (
          <AccordionItem className="border-0" key={item.id}>
            <AccordionHeader className="faq-accordion__header" targetId={item.id} onClick={() => onClick(item.id)}>
              {item.header}
            </AccordionHeader>

            <AccordionBody className="faq-accordion__body" accordionId={item.id}>
              <div dangerouslySetInnerHTML={{__html: item.description}} />
            </AccordionBody>
          </AccordionItem>
        );
      })}
    </Accordion>
  )
}

FaqAccordion.propTypes = {
  data: PropTypes.array
};
