import React, { useEffect, useState, useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import firebase from "firebase/compat/app"
import PropTypes from "prop-types"

import { getFirebaseBackend } from "helpers/firebase_helper"
import { residential, steps, userDefaultSettings } from "modals/variables"
import { profileModalSubtitle, profileModalTitle } from "modals/text"
import { ProfileModal } from "modals/components/generic/ProfileModal/ProfileModal"
import {
  getTypes,
  logAllSteps,
  onChangeLocation,
  onChangeOneValue,
  onChangeSimpleValue,
  onChangeValue,
} from "modals/helpers"
import { useAddressByCoordinates } from "hooks/useAddressByCoordinates"
import { useDescription } from "modals/hooks/useDescription"

import { showUpdateProfileModalAction, updateUser } from "../../store/actions"

export const UpdateProfileModal = ({ userId, setIsWelcomeScreenOpened }) => {
  const firebaseBackend = getFirebaseBackend()
  const dispatch = useDispatch()
  const startSettings = useSelector(state => state.Account.settings)
  const isOpened = useSelector(state => state.Layout.showUpdateProfileModal)

  // States
  const [currentTab, setCurrentTab] = useState(0)

  const [dataRole, setDataRole] = useState("")
  const [dataTypes, setDataTypes] = useState([])
  const [dataPropertyTypes, setDataPropertyTypes] = useState([])
  const [dataInvestmentTypes, setDataInvestmentTypes] = useState([])
  const [dataUserLocation, setDataUserLocation] = useState(null)
  const [dataInvestmentLocation, setDataInvestmentLocation] = useState([])
  const [dataBudget, setDataBudget] = useState({ min: 0, max: 5000000 })
  const [dataExperience, setDataExperience] = useState("")
  const [dataSkills, setDataSkills] = useState([])
  const [dataImage, setDataImage] = useState("")
  const [dataFile, setDataFile] = useState(null)
  const [dataStrike, setDataStrike] = useState(0)
  const [dataDescription, setDataDescription] = useState("")

  // Effects
  useEffect(() => {
    if (currentTab == steps) {
      updateUserSettings()
    }
  }, [currentTab])

  useEffect(() => {
    if (startSettings) {
      setDataTypes(
        startSettings?.investment_types?.length > 0
          ? startSettings?.investment_types.map(item => {
              if (item.type === residential) {
                setDataInvestmentTypes(item.investment_type)
                setDataPropertyTypes(item.property_type)
              }
              return item.type
            })
          : []
      )
      setDataUserLocation(startSettings?.investor_location || null)
      setDataInvestmentLocation(startSettings?.investment_location || [])
      setDataBudget(typeof startSettings?.project_size === "string" ? null : startSettings?.project_size)
      setDataExperience(startSettings?.investor_experience || "")
      setDataSkills(startSettings?.skills || [])
      setDataImage(startSettings?.profile_image || "")
      setDataRole(startSettings?.role || "")
      setDataStrike(startSettings?.strike || 0)

      if (currentTab !== steps && currentTab !== 0 && currentTab !== 8) {
        setDataDescription(startSettings?.bio || "")
      }
    }
  }, [startSettings])

  const userLocation = useAddressByCoordinates(dataUserLocation)

  useDescription(dataRole, dataExperience, userLocation, dataTypes, currentTab, steps, setDataDescription)

  // Functions
  const updateUserSettings = useCallback(() => {
    const types = getTypes(dataTypes, dataInvestmentTypes, dataPropertyTypes)

    const settings = {
      ...userDefaultSettings,
      role: dataRole,
      investment_types: types,
      investor_location: dataUserLocation,
      investment_location: dataInvestmentLocation,
      project_size: dataBudget || { min: 0, max: 5000000 },
      investor_experience: dataExperience,
      skills: dataSkills,
      strike: dataStrike,
      bio: dataDescription,
      profile_image: dataImage,
    }

    dispatch(updateUser(settings))
    firebaseBackend.updateUser(settings)

    setTimeout(() => {
      handleToggle()
      setIsWelcomeScreenOpened(true)
    }, "2500")

    if (!!dataFile) {
      pushImageToUserStore(userId, dataFile)
    }

    if (currentTab == steps) {
      logAllSteps(settings, currentTab, steps, userId)
    }
  })

  const pushImageToUserStore = (id, file) => {
    const storageRef = firebase.storage().ref()
    const path = `files/${id}/${file.name}`
    const fileRef = storageRef.child(path)

    // push file to storage
    const task = fileRef.put(file)

    task.on(
      "state_changed",
      snapshot => {
        console.log("File upload..")
      },
      error => console.log(error),
      () => {
        console.log("Done")
        // get full link to file in storage
        fileRef
          .getDownloadURL()
          .then(url => {
            console.log("URL", url)
            dispatch(updateUser({ profile_image: url }))
            firebaseBackend.updateUser({ profile_image: url })
            console.log("URL updated")
          })
          .catch(error => console.log(error))
      }
    )
  }

  const onChangeImage = file => {
    setDataImage(URL.createObjectURL(file))
    setDataFile(file)
  }

  const handleToggle = () => {
    dispatch(showUpdateProfileModalAction(false))
  }

  const handleSkip = () => {
    updateUserSettings()
  }

  const onChangeOneUserLocation = location => {
    if (typeof location === "string") {
      dataUserLocation === location ? setDataUserLocation(null) : setDataUserLocation(location)
      return
    }

    if (typeof location === "object" && !!location?.lat) {
      dataUserLocation?.lat === location?.lat && dataUserLocation?.lng === location?.lng
        ? setDataUserLocation(null)
        : setDataUserLocation(location)
    }
  }

  return (
    <ProfileModal
      isOpened={isOpened}
      title={profileModalTitle}
      subtitle={profileModalSubtitle}
      steps={steps}
      currentTab={currentTab}
      role={dataRole}
      types={dataTypes}
      investmentTypes={dataInvestmentTypes}
      propertyTypes={dataPropertyTypes}
      investmentLocation={dataInvestmentLocation}
      userLocation={dataUserLocation}
      budget={dataBudget}
      experience={dataExperience}
      skills={dataSkills}
      image={dataImage}
      description={dataDescription}
      onChangeRole={id => onChangeOneValue(dataRole, setDataRole, id)}
      onChangeTypes={id => onChangeValue(dataTypes, setDataTypes, id)}
      onChangePropertyTypes={id => onChangeValue(dataPropertyTypes, setDataPropertyTypes, id)}
      onChangeInvestmentTypes={id => onChangeValue(dataInvestmentTypes, setDataInvestmentTypes, id)}
      onChangeUserLocation={onChangeOneUserLocation}
      onChangeInvestmentLocation={location =>
        onChangeLocation(location, dataInvestmentLocation, setDataInvestmentLocation)
      }
      onChangeBudget={values => onChangeSimpleValue(values, setDataBudget)}
      onChangeExperience={id => onChangeOneValue(dataExperience, setDataExperience, id)}
      onChangeSkills={id => onChangeValue(dataSkills, setDataSkills, id)}
      onChangeImage={onChangeImage}
      onChangeDescription={setDataDescription}
      onSkip={handleSkip}
      onChangeTab={setCurrentTab}
    />
  )
}

UpdateProfileModal.propTypes = {
  userId: PropTypes.string,
  setIsWelcomeScreenOpened: PropTypes.func,
}
