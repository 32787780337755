import React from "react"
import PropTypes from "prop-types"

import { PrimaryButton } from "components/PrimaryButton/PrimaryButton"
import { useAddressByCoordinates } from "hooks/useAddressByCoordinates"

import { LookingForLegend } from "../LookingForLegend/LookingForLegend"

export const LookingForModel = PropTypes.shape({
  type: PropTypes.string,
  budget: PropTypes.string,
  locations: PropTypes.string,
})

export const LookingFor = ({ isPrivate, lookingFor, onUpdateProfile }) => {
  const types = lookingFor?.type.join(", ")

  const locations = !!lookingFor?.locations?.length
    ? lookingFor?.locations?.map((item, index) => {
        return <LookingForLocation key={index} location={item} />
      })
    : []

  return (
    <div className="looking-for p-3">
      <h4 className="looking-for__title">Looking for</h4>
      <div className="looking-for__legends">
        {!!types?.length && <LookingForLegend legend={types} iconName="apartments" />}

        {!!lookingFor?.budget && <LookingForLegend legend={lookingFor?.budget} iconName="price" />}

        {!!locations?.length && <LookingForLegend legend={locations} iconName="place" />}
      </div>
      {isPrivate && (
        <PrimaryButton
          size="small"
          text="Update preferences"
          className="looking-for__button"
          onClick={() => {
            onUpdateProfile(true)
          }}
        />
      )}
    </div>
  )
}

LookingFor.propTypes = {
  isPrivate: PropTypes.bool,
  lookingFor: LookingForModel,
  onUpdateProfile: PropTypes.func,
}

const LookingForLocation = ({ location }) => {
  return <span className="looking-for__location">{useAddressByCoordinates(location)}</span>
}

LookingForLocation.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}
