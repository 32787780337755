import React from "react"
import PropTypes from "prop-types"
import { Media, TabPane } from "reactstrap"
import cx from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"

import { Avatar } from "components/Avatar/Avatar"
import { UserName } from "components/UserName/UserName"
import { useOptionData } from "./useOptionData"
import { VerifiedSvgSmall } from "assets/icons/verifiedSmall"

export const ChatTabPane = ({ tabId, room, options, onClick = () => {} }) => {
  return (
    <TabPane tabId={tabId}>
      <div className="chat-tab-pane">
        {!options.length && <h5 className="font-size-16 mb-3">Recent</h5>}

        <ul className="overflow-hidden list-unstyled chat-list chat-tab-pane__list">
          <PerfectScrollbar className="chat-tab-pane__scroll">
            {options.map(option => {
              const classes = cx("chat-tab-pane__list-item", {
                active: room?.id === option.id,
              })

              return (
                <li key={option.id} className={classes}>
                  <ChatTabPaneItem option={option} room={room} onClick={onClick} />
                </li>
              )
            })}
          </PerfectScrollbar>
        </ul>
      </div>
    </TabPane>
  )
}

ChatTabPane.propTypes = {
  tabId: PropTypes.string,
  options: PropTypes.array,
  room: PropTypes.object,
  onClick: PropTypes.func,
}

const ChatTabPaneItem = ({ room, option, onClick = () => {} }) => {
  const handleClick = (e, roomId) => {
    e.preventDefault()
    onClick(roomId)
  }

  const onKeyPress = e => {
    const { key } = e
    if (key === "Enter") {
      onClick(option.id)
    }
  }

  const { image, message, name, time } = useOptionData(room, option)

  return (
    <div
      className="border-0 chat-tab-pane__link"
      to="#"
      onClick={e => handleClick(e, option.id)}
      onKeyPress={onKeyPress}
    >
      <Media>
        {!!image ? (
          <Avatar image={image} className="chat-tab-pane__avatar" size="xs" />
        ) : (
          <UserName name={option.name?.[0] || "U"} className="chat-tab-pane__avatar" size="xs" />
        )}

        <Media className="overflow-hidden" body>
          <h5 className="text-truncate font-size-13 mb-1 text-color-primary chat-tab-pane__name d-flex align-items-center gap-1">
            {name === undefined ? "User not found" : name?.includes("undefined") ? "User not found" : name}
            {option.isVerified && <VerifiedSvgSmall />}
          </h5>

          <p className="text-truncate mb-0 chat-tab-pane__description" dangerouslySetInnerHTML={{ __html: message }} />
        </Media>

        <div className="font-size-11 d-flex chat-tab-pane__time">
          {time}

          {option.isOnline && (
            <div className="chat-tab-pane__status">
              <i className="mdi mdi-circle font-size-10 text-success" />
            </div>
          )}
        </div>
      </Media>
    </div>
  )
}

ChatTabPaneItem.propTypes = {
  tabId: PropTypes.string,
  option: PropTypes.object,
  room: PropTypes.object,
  onClick: PropTypes.func,
}
