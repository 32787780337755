export const countryCodes = [
  {
    codeNumber: "1",
    name: "Canada: +1",
  },
  {
    codeNumber: "1",
    name: "United States: +1",
  },
  {
    codeNumber: "7",
    name: "Russia: +7",
  },
  {
    codeNumber: "20",
    name: "Egypt: +20",
  },
  {
    codeNumber: "27",
    name: "South Africa: +27",
  },
  {
    codeNumber: "30",
    name: "Greece: +30",
  },
  {
    codeNumber: "31",
    name: "Netherlands: +31",
  },
  {
    codeNumber: "32",
    name: "Belgium: +32",
  },
  {
    codeNumber: "33",
    name: "France: +33",
  },
  {
    codeNumber: "34",
    name: "Spain: +34",
  },
  {
    codeNumber: "36",
    name: "Hungary: +36",
  },
  {
    codeNumber: "39",
    name: "Holy See: +39",
  },
  {
    codeNumber: "40",
    name: "Romania: +40",
  },
  {
    codeNumber: "41",
    name: "Switzerland: +41",
  },
  {
    codeNumber: "43",
    name: "Austria: +43",
  },
  {
    codeNumber: "44",
    name: "United Kingdom: +44",
  },
  {
    codeNumber: "45",
    name: "Denmark: +45",
  },
  {
    codeNumber: "46",
    name: "Sweden: +46",
  },
  {
    codeNumber: "47",
    name: "Norway: +47",
  },
  {
    codeNumber: "48",
    name: "Poland: +48",
  },
  {
    codeNumber: "49",
    name: "Germany: +49",
  },
  {
    codeNumber: "51",
    name: "Peru: +51",
  },
  {
    codeNumber: "52",
    name: "Mexico: +52",
  },
  {
    codeNumber: "53",
    name: "Cuba: +53",
  },
  {
    codeNumber: "54",
    name: "Argentina: +54",
  },
  {
    codeNumber: "55",
    name: "Brazil: +55",
  },
  {
    codeNumber: "56",
    name: "Chile: +56",
  },
  {
    codeNumber: "57",
    name: "Colombia: +57",
  },
  {
    codeNumber: "58",
    name: "Venezuela (Bolivarian Republic of): +58",
  },
  {
    codeNumber: "60",
    name: "Malaysia: +60",
  },
  {
    codeNumber: "61",
    name: "Christmas Island: +61",
  },
  {
    codeNumber: "62",
    name: "Indonesia: +62",
  },
  {
    codeNumber: "63",
    name: "Philippines: +63",
  },
  {
    codeNumber: "64",
    name: "Pitcairn: +64",
  },
  {
    codeNumber: "65",
    name: "Singapore: +65",
  },
  {
    codeNumber: "66",
    name: "Thailand: +66",
  },
  {
    codeNumber: "81",
    name: "Japan: +81",
  },
  {
    codeNumber: "82",
    name: "South Korea: +82",
  },
  {
    codeNumber: "84",
    name: "Vietnam: +84",
  },
  {
    codeNumber: "86",
    name: "China: +86",
  },
  {
    codeNumber: "90",
    name: "Turkey: +90",
  },
  {
    codeNumber: "91",
    name: "India: +91",
  },
  {
    codeNumber: "92",
    name: "Pakistan: +92",
  },
  {
    codeNumber: "93",
    name: "Afghanistan: +93",
  },
  {
    codeNumber: "94",
    name: "Sri Lanka: +94",
  },
  {
    codeNumber: "95",
    name: "Myanmar: +95",
  },
  {
    codeNumber: "98",
    name: "Iran (Islamic Republic of): +98",
  },
  {
    codeNumber: "211",
    name: "South Sudan: +211",
  },
  {
    codeNumber: "212",
    name: "Morocco: +212",
  },
  {
    codeNumber: "213",
    name: "Algeria: +213",
  },
  {
    codeNumber: "216",
    name: "Tunisia: +216",
  },
  {
    codeNumber: "218",
    name: "Libya: +218",
  },
  {
    codeNumber: "220",
    name: "Gambia: +220",
  },
  {
    codeNumber: "221",
    name: "Senegal: +221",
  },
  {
    codeNumber: "222",
    name: "Mauritania: +222",
  },
  {
    codeNumber: "223",
    name: "Mali: +223",
  },
  {
    codeNumber: "224",
    name: "Guinea: +224",
  },
  {
    codeNumber: "225",
    name: "Côte d'Ivoire: +225",
  },
  {
    codeNumber: "226",
    name: "Burkina Faso: +226",
  },
  {
    codeNumber: "227",
    name: "Niger: +227",
  },
  {
    codeNumber: "228",
    name: "Togo: +228",
  },
  {
    codeNumber: "229",
    name: "Benin: +229",
  },
  {
    codeNumber: "230",
    name: "Mauritius: +230",
  },
  {
    codeNumber: "231",
    name: "Liberia: +231",
  },
  {
    codeNumber: "232",
    name: "Sierra Leone: +232",
  },
  {
    codeNumber: "233",
    name: "Ghana: +233",
  },
  {
    codeNumber: "234",
    name: "Nigeria: +234",
  },
  {
    codeNumber: "235",
    name: "Chad: +235",
  },
  {
    codeNumber: "236",
    name: "Central African Republic: +236",
  },
  {
    codeNumber: "237",
    name: "Cameroon: +237",
  },
  {
    codeNumber: "238",
    name: "Cabo Verde: +238",
  },
  {
    codeNumber: "239",
    name: "Sao Tome and Principe: +239",
  },
  {
    codeNumber: "240",
    name: "Equatorial Guinea: +240",
  },
  {
    codeNumber: "241",
    name: "Gabon: +241",
  },
  {
    codeNumber: "242",
    name: "Republic of the Congo: +242",
  },
  {
    codeNumber: "243",
    name: "Democratic Republic of the Congo: +243",
  },
  {
    codeNumber: "244",
    name: "Angola: +244",
  },
  {
    codeNumber: "245",
    name: "Guinea-Bissau: +245",
  },
  {
    codeNumber: "246",
    name: "United States Minor Outlying Islands: +246",
  },
  {
    codeNumber: "248",
    name: "Seychelles: +248",
  },
  {
    codeNumber: "249",
    name: "Sudan: +249",
  },
  {
    codeNumber: "250",
    name: "Rwanda: +250",
  },
  {
    codeNumber: "251",
    name: "Ethiopia: +251",
  },
  {
    codeNumber: "252",
    name: "Somalia: +252",
  },
  {
    codeNumber: "253",
    name: "Djibouti: +253",
  },
  {
    codeNumber: "254",
    name: "Kenya: +254",
  },
  {
    codeNumber: "255",
    name: "United Republic of Tanzania: +255",
  },
  {
    codeNumber: "256",
    name: "Uganda: +256",
  },
  {
    codeNumber: "257",
    name: "Burundi: +257",
  },
  {
    codeNumber: "258",
    name: "Mozambique: +258",
  },
  {
    codeNumber: "260",
    name: "Zambia: +260",
  },
  {
    codeNumber: "261",
    name: "Madagascar: +261",
  },
  {
    codeNumber: "262",
    name: "Mayotte: +262",
  },
  {
    codeNumber: "263",
    name: "Zimbabwe: +263",
  },
  {
    codeNumber: "264",
    name: "Namibia: +264",
  },
  {
    codeNumber: "265",
    name: "Malawi: +265",
  },
  {
    codeNumber: "266",
    name: "Lesotho: +266",
  },
  {
    codeNumber: "267",
    name: "Botswana: +267",
  },
  {
    codeNumber: "268",
    name: "Eswatini: +268",
  },
  {
    codeNumber: "269",
    name: "Comoros: +269",
  },
  {
    codeNumber: "290",
    name: "Saint Helena, Ascension and Tristan da Cunha: +290",
  },
  {
    codeNumber: "291",
    name: "Eritrea: +291",
  },
  {
    codeNumber: "297",
    name: "Aruba: +297",
  },
  {
    codeNumber: "298",
    name: "Faroe Islands: +298",
  },
  {
    codeNumber: "299",
    name: "Greenland: +299",
  },
  {
    codeNumber: "350",
    name: "Gibraltar: +350",
  },
  {
    codeNumber: "351",
    name: "Portugal: +351",
  },
  {
    codeNumber: "352",
    name: "Luxembourg: +352",
  },
  {
    codeNumber: "353",
    name: "Ireland: +353",
  },
  {
    codeNumber: "354",
    name: "Iceland: +354",
  },
  {
    codeNumber: "355",
    name: "Albania: +355",
  },
  {
    codeNumber: "356",
    name: "Malta: +356",
  },
  {
    codeNumber: "357",
    name: "Cyprus: +357",
  },
  {
    codeNumber: "358",
    name: "Finland: +358",
  },
  {
    codeNumber: "359",
    name: "Bulgaria: +359",
  },
  {
    codeNumber: "370",
    name: "Lithuania: +370",
  },
  {
    codeNumber: "371",
    name: "Latvia: +371",
  },
  {
    codeNumber: "372",
    name: "Estonia: +372",
  },
  {
    codeNumber: "373",
    name: "Republic of Moldova: +373",
  },
  {
    codeNumber: "374",
    name: "Armenia: +374",
  },
  {
    codeNumber: "375",
    name: "Belarus: +375",
  },
  {
    codeNumber: "376",
    name: "Andorra: +376",
  },
  {
    codeNumber: "377",
    name: "Monaco: +377",
  },
  {
    codeNumber: "378",
    name: "Republic of San Marino: +378",
  },
  {
    codeNumber: "380",
    name: "Ukraine: +380",
  },
  {
    codeNumber: "381",
    name: "Serbia: +381",
  },
  {
    codeNumber: "382",
    name: "Montenegro: +382",
  },
  {
    codeNumber: "383",
    name: "Republic of Kosovo: +383",
  },
  {
    codeNumber: "385",
    name: "Croatia: +385",
  },
  {
    codeNumber: "386",
    name: "Slovenia: +386",
  },
  {
    codeNumber: "387",
    name: "Bosnia and Herzegovina: +387",
  },
  {
    codeNumber: "389",
    name: "North Macedonia: +389",
  },
  {
    codeNumber: "420",
    name: "Czechia: +420",
  },
  {
    codeNumber: "421",
    name: "Slovakia: +421",
  },
  {
    codeNumber: "423",
    name: "Liechtenstein: +423",
  },
  {
    codeNumber: "500",
    name: "Falkland Islands: +500",
  },
  {
    codeNumber: "501",
    name: "Belize: +501",
  },
  {
    codeNumber: "502",
    name: "Guatemala: +502",
  },
  {
    codeNumber: "503",
    name: "El Salvador: +503",
  },
  {
    codeNumber: "504",
    name: "Honduras: +504",
  },
  {
    codeNumber: "505",
    name: "Nicaragua: +505",
  },
  {
    codeNumber: "506",
    name: "Costa Rica: +506",
  },
  {
    codeNumber: "507",
    name: "Panama: +507",
  },
  {
    codeNumber: "508",
    name: "Saint Pierre and Miquelon: +508",
  },
  {
    codeNumber: "509",
    name: "Haiti: +509",
  },
  {
    codeNumber: "590",
    name: "Saint Martin (French part): +590",
  },
  {
    codeNumber: "591",
    name: "Bolivia (Plurinational State of): +591",
  },
  {
    codeNumber: "592",
    name: "Guyana: +592",
  },
  {
    codeNumber: "593",
    name: "Ecuador: +593",
  },
  {
    codeNumber: "594",
    name: "French Guiana: +594",
  },
  {
    codeNumber: "595",
    name: "Paraguay: +595",
  },
  {
    codeNumber: "596",
    name: "Martinique: +596",
  },
  {
    codeNumber: "597",
    name: "Suriname: +597",
  },
  {
    codeNumber: "598",
    name: "Uruguay: +598",
  },
  {
    codeNumber: "599",
    name: "Netherlands Antilles: +599",
  },
  {
    codeNumber: "670",
    name: "Timor-Leste: +670",
  },
  {
    codeNumber: "672",
    name: "French Southern and Antarctic Lands: +672",
  },
  {
    codeNumber: "673",
    name: "Brunei Darussalam: +673",
  },
  {
    codeNumber: "674",
    name: "Nauru: +674",
  },
  {
    codeNumber: "675",
    name: "Papua New Guinea: +675",
  },
  {
    codeNumber: "676",
    name: "Tonga: +676",
  },
  {
    codeNumber: "677",
    name: "Solomon Islands: +677",
  },
  {
    codeNumber: "678",
    name: "Vanuatu: +678",
  },
  {
    codeNumber: "679",
    name: "Fiji: +679",
  },
  {
    codeNumber: "680",
    name: "Palau: +680",
  },
  {
    codeNumber: "681",
    name: "Wallis and Futuna: +681",
  },
  {
    codeNumber: "682",
    name: "Cook Islands: +682",
  },
  {
    codeNumber: "683",
    name: "Niue: +683",
  },
  {
    codeNumber: "685",
    name: "Samoa: +685",
  },
  {
    codeNumber: "686",
    name: "Kiribati: +686",
  },
  {
    codeNumber: "687",
    name: "New Caledonia: +687",
  },
  {
    codeNumber: "688",
    name: "Tuvalu: +688",
  },
  {
    codeNumber: "689",
    name: "French Polynesia: +689",
  },
  {
    codeNumber: "690",
    name: "Tokelau: +690",
  },
  {
    codeNumber: "691",
    name: "Micronesia (Federated States of): +691",
  },
  {
    codeNumber: "692",
    name: "Republic of the Marshall Islands: +692",
  },
  {
    codeNumber: "767",
    name: "Dominica: +767",
  },
  {
    codeNumber: "850",
    name: "North Korea: +850",
  },
  {
    codeNumber: "852",
    name: "Hong Kong: +852",
  },
  {
    codeNumber: "853",
    name: "Macao: +853",
  },
  {
    codeNumber: "855",
    name: "Cambodia: +855",
  },
  {
    codeNumber: "856",
    name: "Lao People's Democratic Republic: +856",
  },
  {
    codeNumber: "868",
    name: "Trinidad and Tobago: +868",
  },
  {
    codeNumber: "876",
    name: "Jamaica: +876",
  },
  {
    codeNumber: "880",
    name: "Bangladesh: +880",
  },
  {
    codeNumber: "886",
    name: "Taiwan, Province of China: +886",
  },
  {
    codeNumber: "960",
    name: "Maldives: +960",
  },
  {
    codeNumber: "961",
    name: "Lebanon: +961",
  },
  {
    codeNumber: "962",
    name: "Jordan: +962",
  },
  {
    codeNumber: "963",
    name: "Syrian Arab Republic: +963",
  },
  {
    codeNumber: "964",
    name: "Iraq: +964",
  },
  {
    codeNumber: "965",
    name: "Kuwait: +965",
  },
  {
    codeNumber: "966",
    name: "Saudi Arabia: +966",
  },
  {
    codeNumber: "967",
    name: "Yemen: +967",
  },
  {
    codeNumber: "968",
    name: "Oman: +968",
  },
  {
    codeNumber: "970",
    name: "Palestine, State of: +970",
  },
  {
    codeNumber: "971",
    name: "United Arab Emirates: +971",
  },
  {
    codeNumber: "972",
    name: "Israel: +972",
  },
  {
    codeNumber: "973",
    name: "Bahrain: +973",
  },
  {
    codeNumber: "974",
    name: "Qatar: +974",
  },
  {
    codeNumber: "975",
    name: "Bhutan: +975",
  },
  {
    codeNumber: "976",
    name: "Mongolia: +976",
  },
  {
    codeNumber: "977",
    name: "Nepal: +977",
  },
  {
    codeNumber: "992",
    name: "Tajikistan: +992",
  },
  {
    codeNumber: "993",
    name: "Turkmenistan: +993",
  },
  {
    codeNumber: "994",
    name: "Azerbaijan: +994",
  },
  {
    codeNumber: "995",
    name: "Georgia: +995",
  },
  {
    codeNumber: "996",
    name: "Kyrgyzstan: +996",
  },
  {
    codeNumber: "998",
    name: "Uzbekistan: +998",
  },
  {
    codeNumber: "1242",
    name: "Commonwealth of The Bahamas: +1242",
  },
  {
    codeNumber: "1246",
    name: "Barbados: +1246",
  },
  {
    codeNumber: "1264",
    name: "Anguilla: +1264",
  },
  {
    codeNumber: "1268",
    name: "Antigua and Barbuda: +1268",
  },
  {
    codeNumber: "1284",
    name: "Virgin Islands (British): +1284",
  },
  {
    codeNumber: "1340",
    name: "Virgin Islands (U.S.): +1340",
  },
  {
    codeNumber: "1345",
    name: "Cayman Islands: +1345",
  },
  {
    codeNumber: "1441",
    name: "Bermuda: +1441",
  },
  {
    codeNumber: "1473",
    name: "Grenada: +1473",
  },
  {
    codeNumber: "1649",
    name: "Turks and Caicos Islands: +1649",
  },
  {
    codeNumber: "1664",
    name: "Montserrat: +1664",
  },
  {
    codeNumber: "1670",
    name: "Commonwealth of the Northern Mariana Islands: +1670",
  },
  {
    codeNumber: "1684",
    name: "American Samoa: +1684",
  },
  {
    codeNumber: "1721",
    name: "Sint Maarten (Dutch part): +1721",
  },
  {
    codeNumber: "1758",
    name: "Saint Lucia: +1758",
  },
  {
    codeNumber: "1784",
    name: "Saint Vincent and the Grenadines: +1784",
  },
  {
    codeNumber: "1869",
    name: "Saint Kitts and Nevis: +1869",
  },
  {
    codeNumber: "4779",
    name: "Svalbard and Jan Mayen: +4779",
  },
  {
    codeNumber: "5997",
    name: "Bonaire, Sint Eustatius and Saba: +5997",
  },
  {
    codeNumber: "61891",
    name: "Cocos (Keeling) Islands: +61891",
  },
]
