import React from "react"
import PropTypes from "prop-types"

export const ProfileContactsItem = ({ title, link }) => {
  return (
    <div className="profile-contacts-item mb-3">
      <h6 className="profile-contacts-item__title font-size-16 font-weight-semibold mb-1">
        {title}
      </h6>
      <a
        className="profile-contacts-item__link font-size-13"
        href={link}
        target="_blank"
        rel="noreferrer"
      >
        {link || '—'}
      </a>
    </div>
  )
}

ProfileContactsItem.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
}
