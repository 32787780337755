import React from "react"
import PropTypes from "prop-types"
import "firebase/compat/storage"
import { connect, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"

import { RecommendedUsers } from "shared/RecommendedUsers/RecommendedUsers"
import { Sidebar } from "components/Sidebar/Sidebar"
import { Profile } from "components/Profile/Profile"
import { showUpdateProfileModalAction, updateSetIsOnProfile, updateUser } from "store/actions"
import { useSearchUsersResults } from "hooks/useSearchUsersResults"
import { getFirebaseBackend } from "helpers/firebase_helper"

const PrivateProfile = ({ settings, user, allUsers }) => {
  const dispatch = useDispatch()
  const firebase = getFirebaseBackend()

  const users = useSearchUsersResults(allUsers, null, settings, user?.uid)

  const handleOnChangeAbout = value => {
    dispatch(
      updateUser({
        bio: value,
      })
    )
    firebase.updateUser({ bio: value || "" })
  }

  const handleOnUpdateContactDetails = contacts => {
    dispatch(
      updateUser({
        website: contacts.website,
        phone: contacts.phone,
        linkedin: contacts.linkedin,
      })
    )
    firebase.updateUser({
      website: contacts.website || "",
      phone: contacts.phone || "",
      linkedin: contacts.linkedin || "",
    })
  }

  const handleOnUpdateProfileAvatar = image => {
    dispatch(
      updateUser({
        profile_image: image,
      })
    )
    firebase.updateUser({ profile_image: image || "" })
  }

  const handleOnUpdateProfileCoverImage = image => {
    dispatch(
      updateUser({
        cover_image: image,
      })
    )
    firebase.updateUser({ cover_image: image || "" })
  }

  const handleOnUpdateProfileDetails = details => {
    const { firstName, lastName } = details
    dispatch(
      updateUser({
        firstName,
        lastName,
        profile_short_bio: details.bio,
        investor_location: details.location,
      })
    )
    firebase.updateUser({
      firstName: firstName || "",
      lastName: lastName || "",
      profile_short_bio: details.bio || "",
      investor_location: details.location || "",
    })
  }

  function handleOnUpdateProfile(isOnProfile) {
    dispatch(updateSetIsOnProfile(isOnProfile))
    dispatch(showUpdateProfileModalAction(true))
  }

  return (
    <main className="profile-page main">
      <Profile
        showIdentidyButton
        userId={user?.uid}
        isPrivate={true}
        profileSettings={settings}
        users={users}
        history={history}
        onUpdateProfile={handleOnUpdateProfile}
        onChangeAbout={handleOnChangeAbout}
        onUpdateContactDetails={handleOnUpdateContactDetails}
        onUpdateProfileAvatar={handleOnUpdateProfileAvatar}
        onUpdateProfileCoverImage={handleOnUpdateProfileCoverImage}
        onUpdateProfileDetails={handleOnUpdateProfileDetails}
      />
      <Sidebar className="profile-page__sidebar">
        <RecommendedUsers className="p-0 profile-page__users" list={users?.length > 0 ? users : allUsers} />
      </Sidebar>
    </main>
  )
}

PrivateProfile.propTypes = {
  settings: PropTypes.object, // TODO: Make real schema
  user: PropTypes.object,
  allUsers: PropTypes.array,
}

const mapStateToProps = ({ Account, chat }) => {
  return {
    settings: Account.settings,
    user: Account.user,
    allUsers: chat.allUsers,
  }
}

export default connect(mapStateToProps)(withRouter(PrivateProfile))
