import React from "react"
import PropTypes from "prop-types"
import { usePreviewData } from "@opengraphninja/react";

export const ChatLinkPreview = ({ option }) => {
  const previewData = usePreviewData(option);

  const isSuccess = previewData.status === "success"

  const value = option.startsWith("https") && option.startsWith("http") ? option : '//'+option

  return (
    <a
      className="border-0 justify-content-between align-items-center font-size-13 chat-link-preview"
      href={value}
      target="_blank"
      rel="noreferrer"
    > 
    {isSuccess && !!previewData.data.image ? (
      <img src={previewData.data.image.url} className="chat-link-preview__image" alt="Site Image" />
    ) : (
      <span className="chat-link-preview__no-image"></span>
    )}

    <span className="chat-link-preview__content">
      <span className="overflow-hidden chat-link-preview__title">
        {!!previewData.data?.title && isSuccess ? previewData?.data.title : "No Title"}
      </span>

      <span className="overflow-hidden chat-link-preview__url">{option}</span>
    </span>
  </a> 
  )
}

ChatLinkPreview.propTypes = {
  option: PropTypes.string,
}
