import React from "react"

export const DontsSvg = () => {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="32" height="32" rx="16" fill="#98E38D" />
      <path
        d="M16.5001 24.3334C15.3473 24.3334 14.264 24.1145 13.2501 23.6767C12.2362 23.2395 11.3542 22.6459 10.6042 21.8959C9.85425 21.1459 9.26064 20.2639 8.82341 19.25C8.38564 18.2361 8.16675 17.1528 8.16675 16C8.16675 14.8472 8.38564 13.7639 8.82341 12.75C9.26064 11.7361 9.85425 10.8542 10.6042 10.1042C11.3542 9.35419 12.2362 8.7603 13.2501 8.32252C14.264 7.8853 15.3473 7.66669 16.5001 7.66669C17.6529 7.66669 18.7362 7.8853 19.7501 8.32252C20.764 8.7603 21.6459 9.35419 22.3959 10.1042C23.1459 10.8542 23.7395 11.7361 24.1767 12.75C24.6145 13.7639 24.8334 14.8472 24.8334 16C24.8334 17.1528 24.6145 18.2361 24.1767 19.25C23.7395 20.2639 23.1459 21.1459 22.3959 21.8959C21.6459 22.6459 20.764 23.2395 19.7501 23.6767C18.7362 24.1145 17.6529 24.3334 16.5001 24.3334ZM16.5001 22.6667C18.3612 22.6667 19.9376 22.0209 21.2292 20.7292C22.5209 19.4375 23.1667 17.8611 23.1667 16C23.1667 15.25 23.0451 14.5278 22.8017 13.8334C22.559 13.1389 22.2084 12.5 21.7501 11.9167L12.4167 21.25C13.0001 21.7084 13.639 22.0589 14.3334 22.3017C15.0279 22.545 15.7501 22.6667 16.5001 22.6667ZM11.2501 20.0834L20.5834 10.75C20.0001 10.2917 19.3612 9.94113 18.6667 9.69835C17.9723 9.45502 17.2501 9.33335 16.5001 9.33335C14.639 9.33335 13.0626 9.97919 11.7709 11.2709C10.4792 12.5625 9.83341 14.1389 9.83341 16C9.83341 16.75 9.95508 17.4722 10.1984 18.1667C10.4412 18.8611 10.7917 19.5 11.2501 20.0834Z"
        fill="#1C1B1F"
      />
    </svg>
  )
}
