import React, { useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import { Members } from "components/Members/Members"
import { Avatar } from "components/Avatar/Avatar"
import { capitalizeFirstLetter } from "helpers/capitalizeFirstLetter"

export const ChooseRoomItem = ({ id, name, image, members, onClick }) => {
  const [isSelected, setIsSelected] = useState(false)
  const countText = membersCountMap(members.length)

  const handleOnClick = () => {
    setIsSelected(prev => {
      onClick({
        id,
        isSelected: !prev,
      })
      return !prev
    })
  }

  const classes = cx("choose-room-item p-3", { "is-selected": isSelected })

  return (
    <div className={classes} onClick={handleOnClick}>
      {/* {!!members.length && <Members className="me-3 choose-room-item__members" members={members} />} */}
      {!!image && <Avatar image={image} className="mr-2" />}
      <div className="choose-room-item__body">
        <div className="d-flex align-items-center mb-1">
          <h4 className="choose-room-item__name fw-medium mb-0 font-size-13">{capitalizeFirstLetter(name)}</h4>
        </div>
        <p className="choose-room-item__count fw-medium mb-0">{countText}</p>
      </div>
    </div>
  )
}

ChooseRoomItem.propTypes = {
  id: PropTypes.string,
  members: PropTypes.array,
  name: PropTypes.string,
  image: PropTypes.string,

  onClick: PropTypes.func,
}

const membersCountMap = count => {
  if (count === 0) {
    return "Only you"
  }

  if (count > 1) {
    return `${count} members`
  }

  if (count === 1) {
    return "1 member"
  }
}
