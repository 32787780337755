import React from "react"
import PropTypes from 'prop-types';
import { Card, CardBody, CardImg, Modal } from "reactstrap"

import { PrimaryLink } from "components/PrimaryLink/PrimaryLink"

export const PromoBanner = ({ link, alt, text, title, image, isOpened, onToggle }) => {
  return (
    <Modal className="promo-banner" contentClassName="promo-banner__content" centered={true} fade={false} isOpen={isOpened} toggle={onToggle}>
      <button className="border-0 rounded-circle position-absolute promo-banner__close" onClick={onToggle} />

      <Card className="w-100 m-0 overflow-hidden promo-banner__card">
        <CardImg
          className="w-auto promo-banner__image"
          alt={alt}
          src={image}
          top
          width="100%"
        />

        <CardBody className="promo-banner__body">
          <h5 className="font-size-16 promo-banner__title">{title}</h5>

          <p className="font-size-13 promo-banner__text">{text}</p>

          <PrimaryLink
            className="d-flex align-items-center justify-content-center w-100 promo-banner__link"
            text="Click here"
            to={link}
          />
        </CardBody>
      </Card>
    </Modal>
  )
}

PromoBanner.propTypes = {
  alt: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  isOpened: PropTypes.bool,
  onToggle: PropTypes.func
};
