import { useState, useEffect } from "react"

const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

export const useCurrentWidth = () => {
  let [width, setWidth] = useState(getWidth())

  useEffect(() => {
    const resizeListener = () => {
      setWidth(getWidth())
    }
    window.addEventListener("resize", resizeListener)

    return () => {
      window.removeEventListener("resize", resizeListener)
    }
  }, [])

  return width
}

export const replaceUrlMessageWithDefault = (param) => {
  const url = new URL(window.location)
  url.searchParams.delete(param)
  history.replaceState(null, null, url)
}
