import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { ChatTasksActionMenu } from "../ChatTasksActionMenu/ChatTasksActionMenu"
import { ChatTasks } from "../ChatTasks/ChatTasks"

export const ChatTasksBlock = ({
    addButtonOff,
    tasks,
    onTaskClick,
    onAddTask,
  }) => {
  
  const [ showedClosed, setShowedClosed ] = useState(true);
  const [ updatedTasks, setUpdatedTasks ] = useState([]);

  const handleShowClosed = () => {
    setShowedClosed(active => !active)
  };

  useEffect(() => {
    if(showedClosed){
      setUpdatedTasks(tasks)
    } else {
      setUpdatedTasks(tasks.filter(task => task.completed !== "closed"))
    }
  }, [showedClosed, tasks, setUpdatedTasks])

  return (
    <div className="position-relative chat-tasks-block">
      <h5 className="font-size-16 chat-tasks-block__heading">Tasks</h5>

      {tasks?.length > 0 && <ChatTasksActionMenu showedClosed={showedClosed} onClick={handleShowClosed} />}

      <ChatTasks
        addButtonOff={addButtonOff}
        tasks={updatedTasks}
        onClick={onTaskClick}
        onAdd={onAddTask}
      />
    </div>
  )
}

ChatTasksBlock.propTypes = {
  addButtonOff: PropTypes.bool,
  tasks: PropTypes.array,
  onTaskClick: PropTypes.func,
  onAddTask: PropTypes.func,
}
