import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import startOptions, { nameOfDescription } from 'common/data/schemas/investor_experience'

import { Step } from '../../common/Step/Step'
import { ModalButtonList } from '../../common/ModalButtonList/ModalButtonList'

export const ExperienceStep = ({
  active, currentTab, questionNumber = '4', onChange
}) => {

  const options = useMemo(() => {
    return startOptions.map((option) => {
      return {
        id: option,
        icon: `experience-${option.toLowerCase().split(' ')[0]}`,
        name: option,
        description:  "",
        active: active === option
      }
    })
  }, [startOptions, nameOfDescription, active]);

  return (
    <Step
      className={cx({ 'd-block': currentTab === 5, 'd-none': currentTab !== 5, })}
      heading='Experience'
      description='What is your level of experience investing in real estate?'
    >
      <ModalButtonList isLargeButtons={true} items={options} onClick={onChange} />
    </Step>
  );
}

ExperienceStep.propTypes = {
  currentTab: PropTypes.number,
  active: PropTypes.string,
  questionNumber: PropTypes.string,
  onChange: PropTypes.func,
};
