import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import Icons from "../../assets/icons/icons.svg"

const createInitialFromName = name => {
  
  if (!name || typeof name !== "string" || name === 'name') {
    return
  }

  if (name.includes('undefined')) return

  const nameArray = name.trim().split(" ")

  if (nameArray.length === 1) {
    return nameArray[0].substring(0, 2).toUpperCase()
  }

  const firstNameInitial = nameArray[0][0]
  const lastNameInitial = nameArray[nameArray.length - 1][0]

  return (firstNameInitial + lastNameInitial).toUpperCase()
}

const availableColors = ["#497C2E", "#AB60E5", "#DF4454", "#5481E8"]

const setBgColorBasedOnNameFirstLetter = name => {
  const firstLetter = name[0].toUpperCase()

  if (firstLetter >= "A" && firstLetter <= "G") {
    return availableColors[0]
  }

  if (firstLetter >= "H" && firstLetter <= "N") {
    return availableColors[1]
  }

  if (firstLetter >= "O" && firstLetter <= "U") {
    return availableColors[2]
  }

  if (firstLetter >= "V" && firstLetter <= "Z") {
    return availableColors[3]
  }
}

export const Icon = ({ className, name = "arrowRight", width, height, onClick, userName }) => {
  const classes = className ? `icon ${className}` : "icon"
  const [displayName, displaySetName] = useState("")
  const [bgColor, setBgColor] = useState("")

  useEffect(() => {
    const authUser = JSON.parse(localStorage.getItem("authUser"))
    const name = userName || authUser?.displayName;

    displaySetName(name ? createInitialFromName(name) : '')
    setBgColor(name ? setBgColorBasedOnNameFirstLetter(name) : "#497C2E")
  }, [userName])

  return (name && !name.includes("default-avatar")) ? (
    <svg className={classes} style={{ width, height }} onClick={onClick}>
      <use xlinkHref={`${Icons}#${name}`} />
    </svg>
  ) : (
    <div
      className={classes}
      style={{
        width,
        height,
        position: "relative",
        overflow: "hidden",
      }}
      onClick={onClick}
    >
      <div
        style={{
          backgroundColor: bgColor,
          width: "200px",
          height: "200px",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          top: "50%",
          left: "50%",
        }}
      ></div>
      <p className="display-name">{displayName}</p>
    </div>
  )
}

Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func,
  userName: PropTypes.string,
}