import React from "react"
import PropTypes from 'prop-types';
import { ModalFooter } from "reactstrap"
import cx from "classnames"

export const ChatDeleteModalFooter = ({ onDelete, onCancel }) => {
  return (
    <ModalFooter className="border-top-0 chat-delete-modal-footer">
      <button
        className="chat-delete-modal-footer__button chat-delete-modal-footer__button--cancel"
        onClick={onCancel}
      >Cancel</button>

      <button
        className="chat-delete-modal-footer__button chat-delete-modal-footer__button--delete"
        onClick={onDelete}
      >Delete room</button>
    </ModalFooter>
  )
}

ChatDeleteModalFooter.propTypes = {
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
};
