import React, { useState } from "react"

import PropTypes from "prop-types"
import { MainModal } from "modals/components/parts/MainModal/MainModal"
import { DosSvg } from "assets/icons/dosSvg"
import { DontsSvg } from "assets/icons/dontsSvg"
import { SecondaryButton } from "components/SecondaryButton/SecondaryButton"
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton"

export const WelcomeModal = ({ isActive, setIsWelcomeScreenOpened }) => {
  const [currentScreen, setCurrentScreen] = useState(1)

  return (
    <MainModal isOpened={isActive}>
      <div className="welcome-modal">
        <div className={`screen-2 ${currentScreen !== 1 && "d-none"}`}>
          <div className="welcome-modal__text">
            <h2>Welcome to Investium!</h2>
            <p>
              Investium is about connecting with the right people to take your real estate projects further. Some basics
              when using Investium:
            </p>
          </div>

          <div className="welcome-modal__cards">
            <div className="welcome-modal__card">
              <DosSvg />
              <h3>Do’s</h3>
              <ul>
                <li>Complete your profile and verify your identity</li>
                <li>Connect with others and work together </li>
                <li>Introduce yourself and share your objectives </li>
                <li>Be respectful</li>
              </ul>
            </div>
            <div className="welcome-modal__card">
              <DontsSvg />
              <h3>Dont’s</h3>
              <ul>
                <li>Don’t solicit</li>
                <li>Don’t spam</li>
                <li>Don’t try to defraud others</li>
                <li>Don’t create groups to solicit</li>
              </ul>
            </div>
          </div>

          <div className="welcome-modal__description">
            <p>
              We want to ensure Investium is a safe space for everyone. Users not following these guidelines will be
              banned. You can report suspicious behaviour on the profile page of a user.
            </p>
          </div>

          <div className="welcome-modal__buttons main-modal-footer d-flex justify-content-end">
            <PrimaryButton
              className="main-modal-footer__button main-modal-footer__button-next"
              text="Next"
              size="small"
              onClick={() => {
                setCurrentScreen(2)
              }}
            />
          </div>
        </div>

        <div className={`screen-2 ${currentScreen !== 2 && "d-none"}`}>
          <div className="welcome-modal__text">
            <h2>What’s next? Join a private group!</h2>
            <p className="second-text">
              Join a private group to start working towards your projects with new partners. You can request access to
              existing ones or create your own. To break the ice, introduce yourself and explain the type of project you
              are interesting in.
            </p>
          </div>

          <div className="welcome-modal__buttons main-modal-footer d-flex justify-content-end">
            <SecondaryButton
              className="main-modal-footer__button main-modal-footer__button-prev"
              size="small"
              text="Back"
              onClick={() => {
                setCurrentScreen(1)
              }}
            />
            <PrimaryButton
              className="main-modal-footer__button main-modal-footer__button-next"
              text="Start a tour"
              size="small"
              onClick={() => {
                setIsWelcomeScreenOpened(false)
              }}
            />
          </div>
        </div>
      </div>
    </MainModal>
  )
}

WelcomeModal.propTypes = {
  isActive: PropTypes.bool,
  setIsWelcomeScreenOpened: PropTypes.func,
}
