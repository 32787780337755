import React from 'react'
import PropTypes from 'prop-types'
import { Progress } from 'reactstrap'

export const MainModalIndicator = ({ allSteps, currentStep }) => {
  return (
    <Progress
      className='main-modal-indicator position-relative background-color-gray-200'
      max={allSteps}
      min='0'
      value={currentStep + 1}
    />
  );
};

MainModalIndicator.propTypes = {
  allSteps: PropTypes.number,
  currentStep: PropTypes.number,
};
