import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"

import { CardBlock } from "components/CardBlock/CardBlock"
import { SearchResultsCard } from "components/SearchResultsCard/SearchResultsCard"
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton"
import { NoResultsFound } from "components/NoResultsFound/NoResultsFound"
import { getBudget } from "helpers/getBudget"
import { SortSelect } from "components/SortSelect/SortSelect"

const defaultResults = 6

export const SearchResults = ({
  options,
  onSort,
  onOpenModal,
}) => {
  const [all, setAll] = useState(false)
  const results = useMemo(() => {
    if (all) {
      return options
    }
    return options.slice(0, defaultResults)
  }, [options, all])

  const propertiesText = options.length === 1 ? "group" : "groups"

  return (
    <>
      <CardBlock className="search-results" bodyClass="search-results__wrapper">
        <div className="d-flex align-items-center justify-content-between search-results__header">
          <p className="m-0 font-size-13 search-results__header-text">
            <span className="search-results__counter">{options.length}</span>
            {propertiesText} found
          </p>

        <SortSelect onSort={onSort} />
      </div>

        {results.length > 0 ? (
          <>
            <ul className="p-0 position-relative search-results__list">
              {results.map((option, index) => {                
                return (
                  <SearchResultsCard
                    key={option.id}
                    zIndex={results.length-index}
                    image={option.cover_image}
                    title={option.name}
                    text={option.description}
                    alt={option.name}
                    price={getBudget(option.project_size)}
                    type={option.investment_types?.[0]?.type}
                    location={option.investment_location}
                    members={[option.admin_user, ...option.users]}
                    onClick={() => onOpenModal(option)}
                  />
                )
              })}
            </ul>

            {options.length > defaultResults && (
              <PrimaryButton
                className="position-absolute search-results__button"
                text="Load more"
                size="small"
                onClick={() => setAll(active => !active)}
              />
            )}
          </>
        ) : (
          <NoResultsFound />
        )}
      </CardBlock>
    </>
  )
}

SearchResults.propTypes = {
  currentUserSettings: PropTypes.object,
  userId: PropTypes.string,
  options: PropTypes.array,
  history: PropTypes.object,
  onSort: PropTypes.func,
  onOpenModal: PropTypes.func,
}
