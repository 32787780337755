import React, { useEffect, useState, useCallback } from "react"
import PropTypes from "prop-types"
import firebase from "firebase/compat/app"
import "firebase/compat/storage"
import { withRouter } from "react-router-dom"
import { connect, useDispatch } from "react-redux"

import { RecommendedUsers } from "shared/RecommendedUsers/RecommendedUsers"
import { Sidebar } from "components/Sidebar/Sidebar"
import { Profile } from "components/Profile/Profile"
import { getFirebaseBackend } from "helpers/firebase_helper"
import { registerRoom, openedRoomAction, setActiveCategory } from "store/actions"
import { useSearchUsersResults } from "hooks/useSearchUsersResults"

import { ReportModal } from "./components/ReportModal/ReportModal"

const PublicProfile = ({ match, history, user, userSetings, allUsers }) => {
  const fireBaseBackend = getFirebaseBackend()
  const dispatch = useDispatch()

  const { username } = match.params

  const [settings, setSettings] = useState({})
  const [availableRoomsForUser, setAvailableRoomsForUser] = useState([])
  const [reportOpened, setReportOpened] = useState(false)

  const handleOnInviteToRooms = useCallback(rooms => {
    fireBaseBackend.inviteUserToRoomsByUsername(username, rooms)
  }, [])

  const handleReportMember = useCallback(value => {
    fireBaseBackend.reportUserToAdmin(userSetings, settings, value)
  })

  const handleOpenModal = useCallback(() => {
    setReportOpened(true)
  })

  const handleOnMessage = useCallback(() => {
    firebase
      .firestore()
      .collection("rooms")
      .where("admin_user", "in", [settings.userId, user?.uid])
      .get()
      .then(res => {
        const list = res.docs.filter(item => {
          const data = item.data()
          return data.status === "chat"
        })

        const chat = list.find(item => {
          const data = item.data()
          return data.users.includes(settings.userId) || data.users.includes(user?.uid)
        })

        if (!!chat) {
          dispatch(openedRoomAction(chat.id))
          dispatch(setActiveCategory("chats"))
          history.push("/messages")
        } else {
          const time = Date.now()
          const roomSettings = {
            tasks: [],
            admin_user: user?.uid,
            links: [],
            users: [settings.userId],
            status: "chat",
            chat: null,
            createdAt: time,
          }
          dispatch(registerRoom(roomSettings, []))

          firebase
            .firestore()
            .collection("rooms")
            .where("admin_user", "in", [settings.userId, user?.uid])
            .get()
            .then(res => {
              res.docs.find(item => {
                if (item.data().createdAt === time) {
                  dispatch(openedRoomAction(item.id))
                  dispatch(setActiveCategory("chats"))

                  setTimeout(() => {
                    history.push("/messages")
                  }, 1000)
                }
              })
            })
        }
      })
  })

  useEffect(() => {
    fireBaseBackend.getUsernameSettings(username).then(data => setSettings(data))
  }, [username])

  useEffect(async () => {
    const currentUserRooms = await fireBaseBackend.getRoomsByAdminId()
    const filteredRooms = currentUserRooms.map(async group => {
      const currentUsersInRoom = await fireBaseBackend.getUsersSettings(group.users)

      return {
        id: group.id,
        name: group.name,
        users: currentUsersInRoom.map(user => ({
          profile_image: user?.profile_image,
        })),
        image: group.cover_image,
      }
    })

    Promise.all(filteredRooms).then(rooms => {
      const filteredRooms = rooms?.filter(item => !settings.rooms?.includes(item.id))
      setAvailableRoomsForUser(filteredRooms)
    })
  }, [settings, settings?.rooms])

  const users = useSearchUsersResults(allUsers, null, userSetings, user?.uid)

  return (
    <main className="profile-page main">
      <Profile
        profileSettings={settings}
        rooms={availableRoomsForUser}
        users={users}
        id={user?.uid}
        history={history}
        onInviteToRooms={handleOnInviteToRooms}
        onMessage={handleOnMessage}
        onReportMember={handleOpenModal}
      />
      <Sidebar className="profile-page__sidebar">
        <RecommendedUsers className="p-0 profile-page__users" list={users?.length > 0 ? users : allUsers} />
      </Sidebar>

      <ReportModal isOpen={reportOpened} onToggle={() => setReportOpened(false)} onReport={handleReportMember} />
    </main>
  )
}

PublicProfile.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  user: PropTypes.object,
  userSetings: PropTypes.object,
  allUsers: PropTypes.array,
}

const mapStateToProps = ({ Account, chat }) => {
  return {
    user: Account.user,
    userSetings: Account.settings,
    allUsers: chat.allUsers,
  }
}

export default connect(mapStateToProps)(withRouter(PublicProfile))
