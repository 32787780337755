import React from "react"
import PropTypes from 'prop-types';
import { ModalFooter } from "reactstrap"

import { PrimaryButton } from "components/PrimaryButton/PrimaryButton"

export const ReportModalFooter = ({ isDisabled, onClick }) => {

  return (
    <ModalFooter className="p-4 pt-0 border-top-0 report-modal-footer">
      <PrimaryButton
        className="w-100 m-0 report-modal-footer__button"
        color="primary"
        text="Report"
        size="small"
        disabled={isDisabled}
        onClick={onClick}
      />
    </ModalFooter>
  )
}

ReportModalFooter.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
};
