import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { UploadProfileAvatar } from 'components/Profile/components/UploadProfileAvatar/UploadProfileAvatar'

import { Step } from '../../common/Step/Step'

export const ImageStep = ({
  src, currentTab, questionNumber = '6', onChange
}) => {
  const handleChange = async e => {
    const file = e?.target?.files?.[0]

    if(!!file){
      onChange(file)
    }
  }

  return (
    <Step
      className={cx({ 'd-block': currentTab === 7, 'd-none': currentTab !== 7, })}
      heading='Profile photo'
      description='Please add a recent image of yourself.'
    >
      <UploadProfileAvatar
        className='image-step position-relative m-auto justify-content-center d-flex top-0'
        image={src}
        onUpdateProfileAvatar={handleChange}
        isPrivate={true}
      />
    </Step>
  );
}

ImageStep.propTypes = {
  currentTab: PropTypes.number,
  src: PropTypes.string,
  questionNumber: PropTypes.string,
  onChange: PropTypes.func
};
