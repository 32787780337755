import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, logoutUserSuccess } from "./actions"

import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { showUpdateProfileModalAction, updateSetIsOnProfile, setCurrentUser } from "store/actions"

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(fireBaseBackend.loginUser, user.email, user.password)
    yield put(setCurrentUser(response))
    const redirectLink = localStorage.getItem("redirectAfterLogin")

    if (redirectLink) {
      history.push(redirectLink)
    } else {
      history.push("/home")
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    const response = yield call(fireBaseBackend.logout)
    yield put(logoutUserSuccess(response))
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    const fireBaseBackend = getFirebaseBackend()
    const response = yield call(fireBaseBackend.socialLoginUser, data, type)
    localStorage.setItem("authUser", JSON.stringify(response.user))
    const user = fireBaseBackend.getAuthenticatedUser()
    yield put(setCurrentUser(user))
    if (response.isFirstLogin) {
      yield put(showUpdateProfileModalAction(true))
    }
    const redirectLink = localStorage.getItem("redirectAfterLogin")

    if (redirectLink) {
      history.push(redirectLink)
    } else {
      history.push("/home")
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
