import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  UPDATE_USER,
  SET_CURRENT_USER,
  DELETE_USER_SETTINGS,
  SET_CURRENT_MEMBER
} from "./actionTypes"

export const updateUser = (settings) => {
  return {
    type: UPDATE_USER,
    payload: { settings },
  }
}

export const deleteUserSettings = () => {
  return {
    type: DELETE_USER_SETTINGS,
    payload: null
  }
}

export const registerUser = (user, history) => {
  return {
    type: REGISTER_USER,
    payload: { user, history },
  }
}

export const registerUserSuccessful = user => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: user,
  }
}

export const registerUserFailed = user => {
  return {
    type: REGISTER_USER_FAILED,
    payload: user,
  }
}

export const setCurrentMember = settings => {
  return {
    type: SET_CURRENT_MEMBER,
    payload: settings
  }
}

export const setCurrentUser = user => {
  return {
    type: SET_CURRENT_USER,
    payload: user
  }
}
