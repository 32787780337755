
import React from 'react';
import PropTypes from 'prop-types';
import cx from "classnames"

export const  PageTitle = ({ text, className }) => {
  const classes = cx("page-title", className);
  return <h2 className={classes}>{text}</h2>;
}

PageTitle.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};
