import { useEffect, useState } from "react"

const steps = [
  'role',
  'investment_types',
  'investor_location',
  'investment_location',
  'project_size',
  'investor_experience',
  'skills',
  'bio',
  'profile_image'
]

export const useProgress = (settings) => {
  const [opened, setOpened] = useState(false)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if(!!settings){
      const stepsValues = steps.map(item => settings[item])
      const progressValues = stepsValues.filter(item => {
        if(!!item){
          if(Array.isArray(item)){
            return item.length > 0
          }
          return item
        }
      })
      setProgress(progressValues.length)
    }
  }, [settings])

  useEffect(() => {
    setOpened(steps.length !== progress)
  }, [progress])

  return {
    steps: steps.length,
    progress,
    profileCardOpened: opened
  }
}

