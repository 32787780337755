import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import SimpleBar from "simplebar-react"

import { RecomentUser } from "components/RecomendUser/RecomendUser"
import { NoResultsFound } from "components/NoResultsFound/NoResultsFound"
import { getFullName } from "components/Profile/Profile.helpers"
import { capitalizeFirstLetter } from "helpers/capitalizeFirstLetter"

export const RecommendedUsers = ({ list = [], className, title, id }) => {
  const classes = cx("recommended-users", className, {
    "is-empty": !list.length,
  })

  const text = !!title ? title : "Users based on your preferences"

  return (
    <aside id={id} className={classes}>
      <h3 className="recommended-users__title font-weight-semibold font-size-16">{text}</h3>
      {list.length > 0 ? (
        <SimpleBar className="recommended-users__scroll">
          {list.map(user => {
            const name = getFullName(user.firstName, user.lastName)
            const href = user.username ? `/profile/${user.username}` : "#"

            return (
              <RecomentUser
                isVerified={user.isVerified}
                key={user.id}
                name={capitalizeFirstLetter(name)}
                image={user?.profile_image}
                description={user.bio}
                href={href}
              />
            )
          })}
        </SimpleBar>
      ) : (
        <NoResultsFound className="recommended-users__no-results" withImage={false} />
      )}
    </aside>
  )
}

RecommendedUsers.propTypes = {
  list: PropTypes.array,
  className: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
}
