import React, { useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"

import { InputField } from "components/InputField/InputField"

export const ChatTasks = ({
    addButtonOff,
    tasks = [],
    onClick,
    onAdd
  }) => {
  const [ editing, setEditing ] = useState(false);
  const [ value, setValue ] = useState("");

  const handleChange = (e) => {
    setValue(e.target.value)
  };

  const handleClick = (name) => {
    onClick(name);
  }

  const onKeyPress = (e, name) => {
    const { key } = e
    if (key === "Enter") {
      onClick(name);
    }
  }

  const handleKeyPressAdd = e => {
    const { key } = e
    if (key === "Enter") {
      onAdd(value);
      handleBlur();
    }
  }

  const handleBlur = () => {
    setValue("");
    setEditing(false);
  };

  return (
    <div className="chat-tasks">
      <ul className="overflow-hidden list-unstyled chat-tasks__list">
        <PerfectScrollbar className="chat-tasks__scroll">
          {tasks.map((task, index) => {
            const iconClasses = cx("font-size-18 mdi mdi-checkbox-blank-outline chat-tasks__icon", {
              "mdi-checkbox-marked": task.completed === "closed"
            });
            return (
              <li key={task.name + index} className="chat-tasks__list-item">
                <button
                  className="w-100 border-0 d-grid justify-content-between align-items-center font-size-13 chat-tasks__button"
                  onClick={() => handleClick(task.name)}
                  onKeyPress={(e) => onKeyPress(e, task.name)}
                >
                  <span className="d-flex overflow-hidden align-items-center chat-tasks__name">
                    <i className={iconClasses} />

                    <span className="overflow-hidden chat-tasks__name-text">{task.name}</span>
                  </span>

                </button>
              </li>
            );
            })}
        </PerfectScrollbar>
      </ul>

      {!addButtonOff && (
        <>
          {editing ? (
            <div className="overflow-hidden chat-tasks__input-wrapper">
              <InputField
                className="overflow-hidden chat-tasks__input"
                placeholder="Task name..."
                autoFocus
                onChange={handleChange}
                onKeyPress={handleKeyPressAdd}
                onBlur={handleBlur}
              />
            </div>
          ) : (
            <button
              className="w-100 border-0 d-grid justify-content-between align-items-center font-size-13 chat-tasks__button chat-tasks__add"
              onClick={() => setEditing(true)}
            >
              <span className="d-flex overflow-hidden align-items-center chat-tasks__name">
                <i className="font-size-22 mdi mdi-plus chat-tasks__add-icon" />

                <span className="overflow-hidden chat-tasks__add-text">New Task</span>
              </span>

            </button>
          )}
        </>
      )}
    </div>
  )
}

ChatTasks.propTypes = {
  addButtonOff: PropTypes.bool,
  tasks: PropTypes.array,
  onAdd: PropTypes.func,
  onClick: PropTypes.func,
}
