import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import { Icon } from "components/Icon/Icon"

export const Avatar = ({ image, size = "m", className, userName }) => {
  const classes = cx(
    className,
    "avatar d-flex justify-content-center align-items-center position-relative rounded-circle overflow-hidden",
    `avatar--size-${size}`,
    {
      "is-no-avatar": !image,
    }
  )

  return (
    <div className={classes}>
      {image ? (
        <img className="avatar__image img-responsive" src={image} alt="Member avatar" />
      ) : (
        <Icon width={null} height={null} className="avatar__default-avatar" name="default-avatar" userName={userName} />
      )}
    </div>
  )
}

Avatar.propTypes = {
  size: PropTypes.oneOfType(["xs" | "s" | "m" | "l" | "xl" | "xxl"]),
  image: PropTypes.string,
  className: PropTypes.string,
  userName: PropTypes.string,
}
