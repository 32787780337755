import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import { Icon } from "components/Icon/Icon"

export const EditButton = ({ onClick, className }) => {
  const classes = cx(
    className,
    "edit-button d-flex justify-content-center align-items-center position-absolute p-0"
  )
  return (
    <button
      className={classes}
      onClick={onClick}
    >
      <Icon name="pencil" className="edit-button__icon" />
    </button>
  )
}

EditButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}
