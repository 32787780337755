import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import { contactsModel, ProfileContacts } from "../ProfileContacts/ProfileContacts"
import { EditButton } from "../EditButton/EditButton"
import { EditDetailsModal } from "../EditDetailsModal/EditDetailsModal"
import { ProfileActionsButtons } from "../ProfileActionButtons/ProfileActionButtons"
import { getFullName } from "components/Profile/Profile.helpers"
import { SecondaryButton } from "components/SecondaryButton/SecondaryButton"
import { Loader } from "components/Loader/Loader"
import { VerifiedSvg } from "assets/icons/verified"
import { getFirebaseBackend } from "helpers/firebase_helper"

export const ProfileExtra = ({
  showIdentidyButton,
  settings,
  rooms,
  firstName,
  lastName,
  profileBio,
  contacts,
  location,
  isEditing,
  isPrivate,
  onUpdateContactDetails,
  onUpdateProfileDetails,
  onInviteToRooms,
  onMessage,
}) => {
  const fullName = getFullName(firstName, lastName)
  const [isEditDetailsOpened, setIsEditDetailsOpened] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isVerified, setIsVerified] = useState(false)

  const details = {
    firstName,
    lastName,
    bio: profileBio,
    location,
  }

  const firebase = getFirebaseBackend()

  // verify if user isVerified in firebase user profile (localstorage)
  useEffect(() => {
    if (!!settings) {
      setIsVerified(settings?.isVerified)
    }
  }, [settings])

  useEffect(() => {
    if (!isVerified) {
      const queryParams = new URLSearchParams(window.location.search)
      const queryInqId = queryParams.get("inquiry-id")
      const queryStatus = queryParams.get("status")

      if (settings?.inquiryID === queryInqId) {
        setLoading(true)
        if (queryInqId && queryStatus === "completed") {
          const baseUrl = "https://us-central1-investium-f691d.cloudfunctions.net/verifyPersonaInquiry"

          try {
            fetch(`${baseUrl}?inquiryID=${queryInqId}`)
              .then(response => response.json())
              .then(data => {
                if (data === "approved" || "completed") {
                  firebase.updateUser({ isVerified: true })
                  setLoading(false)
                }
              })
          } catch (error) {
            setLoading(false)
            console.log(error)
          }
        }
      }
    }
  }, [settings])

  const handleOnToggleEditDetailsModal = () => {
    setIsEditDetailsOpened(prev => !prev)
  }

  const handleOnOpenEditDetails = () => {
    setIsEditDetailsOpened(true)
  }

  const handleVerifyProfile = event => {
    event.preventDefault()

    const baseUrl = "https://us-central1-investium-f691d.cloudfunctions.net/sendPersonaInquiry"

    setLoading(true)

    try {
      fetch(`${baseUrl}?firstName=${firstName}&lastName=${lastName}`)
        .then(response => response.json())
        .then(data => {
          window.open(`https://inquiry.withpersona.com/verify?inquiry-id=${data}`, "_blank")
          firebase.updateUser({ inquiryID: data })
        })
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    } catch (error) {
      console.log(error)
    }
  }

  const classes = cx("profile-extra", {
    "is-private": isPrivate,
    "is-public": !isPrivate,
  })

  return (
    <div className={classes}>
      {loading && <Loader />}
      <div className="profile-extra__name-group">
        {firstName && lastName && <h2 className="profile-extra__name">{fullName}</h2>}
        {isVerified && (
          <div style={{ marginBottom: "5px", marginLeft: "-0.5rem" }}>
            <VerifiedSvg />
          </div>
        )}

        {showIdentidyButton && !isVerified && (
          <SecondaryButton
            className="start-group__button"
            size="small"
            text="Verify identity"
            onClick={handleVerifyProfile}
            isExternal
          />
        )}
      </div>
      {details.bio && <h3 className="profile-extra__short-description">{details.bio}</h3>}
      <ProfileContacts
        isPrivate={isPrivate}
        location={details.location}
        contacts={contacts}
        name={fullName}
        isEditing={isEditing}
        onUpdateDetails={onUpdateContactDetails}
      />
      {isPrivate && (
        <EditButton className="profile-extra__edit-button position-absolute" onClick={handleOnOpenEditDetails} />
      )}
      {!isPrivate && <ProfileActionsButtons rooms={rooms} onInviteToRooms={onInviteToRooms} onMessage={onMessage} />}
      <EditDetailsModal
        details={details}
        isOpen={isEditDetailsOpened}
        onUpdateDetails={onUpdateProfileDetails}
        onToggle={handleOnToggleEditDetailsModal}
      />
    </div>
  )
}

ProfileExtra.propTypes = {
  rooms: PropTypes.array,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  location: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  profileBio: PropTypes.string,
  contacts: contactsModel,
  isEditing: PropTypes.bool,
  isPrivate: PropTypes.bool,
  settings: PropTypes.object,
  showIdentidyButton: PropTypes.bool,

  onUpdateContactDetails: PropTypes.func,
  onUpdateProfileDetails: PropTypes.func,
  onInviteToRooms: PropTypes.func,
  onMessage: PropTypes.func,
}
