
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export const  PrimaryLink = ({
  text, to, size = 'medium', disabled, className, onClick
}) => {

  const classes = cx('primary-link', `primary-link--${size}`, className, {
    'is-disabled': disabled,
  });

  return (
    <a className={classes} target="_blank" rel="noreferrer" href={to} onClick={onClick}>
      {text}
    </a>
  );
}

PrimaryLink.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.oneOfType(['large' | 'medium' | 'small' | 'extra-small']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};
