import React from "react"
import PropTypes from 'prop-types';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"

export const ChatTasksActionMenu = ({ showedClosed, onClick  }) => {
  return (
    <div className="d-flex flex-column align-items-end position-absolute chat-tasks-action-menu">
      <UncontrolledDropdown className="chat-tasks-action-menu__dots">
        <DropdownToggle
          className="d-flex align-items-center justify-content-center  chat-tasks-action-menu__button"
          color="white"
          type="button"
        >
          <i className="mdi mdi-dots-vertical chat-tasks-action-menu__icon"></i>
        </DropdownToggle>

        <DropdownMenu direction="up" className="overflow-hidden p-0 chat-tasks-action-menu__menu">
          <DropdownItem
              tag="button"
              className="dropdown-item font-size-11 chat-tasks-action-menu__item"
              onClick={onClick}
            >
              {showedClosed ? "Hide closed" : "Show closed"}
            </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      </div>
  )
}

ChatTasksActionMenu.propTypes = {
  showedClosed: PropTypes.bool,
  onClick: PropTypes.func,
};
