import React from "react"
import PropTypes from 'prop-types';
import {
  TabContent,
  TabPane,
} from "reactstrap"

import { CardBlock } from "components/CardBlock/CardBlock"
import { privacyData } from "pages/Faq/content/privacy";
import { questionsData } from "pages/Faq/content/questions";

import { FaqAccordion } from "../FaqAccordion/FaqAccordion"
import { FaqDisclaimer } from "../FaqDisclaimer/FaqDisclaimer";
import { FaqContact } from "../FaqContact/FaqContact";

export const FaqTabs = ({ activeTab }) => {
  return (
    <TabContent className="faq-tabs" activeTab={activeTab}>
      <TabPane tabId="#questions">
        <CardBlock title="General Questions">
          <FaqAccordion data={questionsData} />
        </CardBlock>
      </TabPane>

      <TabPane tabId="#privacy">
        <CardBlock>
          <FaqDisclaimer data={privacyData} />
        </CardBlock>
      </TabPane>

      <TabPane tabId="#terms">
        <CardBlock>
          <FaqDisclaimer />
        </CardBlock>
      </TabPane>

      <TabPane tabId="#support">
        <CardBlock title="Contact us">
          <FaqContact />
        </CardBlock>
      </TabPane>
    </TabContent>
  )
}

FaqTabs.propTypes = {
  activeTab: PropTypes.string
};
