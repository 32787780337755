import React from "react"
import PropTypes from 'prop-types';

import { PrimaryButton } from "components/PrimaryButton/PrimaryButton";
import { getBudget } from "helpers/getBudget";

export const SelectedSearchCriteria = ({
  types,
  budget,
  locations,
  onTypeClick,
  onBudgetClick,
  onLocationClick
}) => {
  return (
    <div className="d-flex align-items-baseline selected-search-criteria">
      <span className="font-size-13 selected-search-criteria__label">Your criteria</span>

      <ul className="mb-0 p-0 d-flex selected-search-criteria__list">
        {types?.length > 0 && (
          types.map(type => {
            return (
              <li key={type} className="selected-search-criteria__list-item">
                <PrimaryButton
                  size={'extra-small'}
                  icon="clear"
                  text={type}
                  onIconClick={() => onTypeClick(type)}
                />
              </li>
            );
          })
        )}

        {!!budget && (
          <li className="selected-search-criteria__list-item">
            <PrimaryButton
              size={'extra-small'}
              icon="clear"
              text={getBudget(budget)}
              onIconClick={onBudgetClick}
            />
          </li>
        )}

        {locations?.length > 0 && (
          locations.map(location => {
            return (
            <li key={location} className="selected-search-criteria__list-item">
              <PrimaryButton
                size={'extra-small'}
                icon="clear"
                text={location}
                onIconClick={() => onLocationClick(location)}
              />
            </li>
            );
          }
        ))}
      </ul>
    </div>
  )
}

SelectedSearchCriteria.propTypes = {
  types: PropTypes.array,
  budget: PropTypes.object,
  locations: PropTypes.array,
  onTypeClick: PropTypes.func,
  onBudgetClick: PropTypes.func,
  onLocationClick: PropTypes.func
};
