import React, { useState, useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"
//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect, useDispatch, useSelector } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { openUserMobileMenu } from "store/actions"

import { ProfileMenuDropdownHeader } from "components/ProfileMenuDropdownHeader/ProfileMenuDropdownHeader"
import { ProfileDropdownExtraInfo } from "components/ProfileDropdownExtraInfo/ProfileDropdownExtraInfo"
import { getFullName } from "components/Profile/Profile.helpers"
import { useHash } from "pages/Faq/helpers/useHash"
import { OnboardingTooltip } from "components/OnboardingTooltip/OnboardingTooltip"

const ProfileMenu = ({ firstName, lastName, profileImage, profileBio, settings }) => {
  const [isActive, setIsActive] = useState(false)
  const dispatch = useDispatch()
  const isMobile = window.innerWidth < 576

  const fullName = getFullName(firstName, lastName)

  const handleOnToggle = useCallback(() => {
    if (isMobile) {
      dispatch(openUserMobileMenu(true))
      return
    }

    setIsActive(prev => !prev)
  })

  const [hash, setHash] = useHash();

  const [ onboardingProfile, setOnboardingProfile ] = useState(false)

  const isModalOpened = useSelector(state => state.Layout.showUpdateProfileModal)

  useEffect(() => {
    if(!!settings && !isModalOpened){
      const isPreviousClicked = !!settings?.onboarding?.groups &&
        !!settings?.onboarding.search &&
        !!settings?.onboarding.createGroup &&
        !!settings?.onboarding.explore &&
        !!settings?.onboarding.message;

      if(isPreviousClicked && !settings?.onboarding?.profile){
        setOnboardingProfile(true)
        return
      } else {
        setOnboardingProfile(false)
      }
    }
  }, [settings, isModalOpened, setOnboardingProfile])

  return (
    <Dropdown isOpen={isActive} toggle={handleOnToggle} className="d-inline-block profile-menu-dropdown">
      <DropdownToggle className="btn header-item p-0 h-100" tag="button">
        <ProfileMenuDropdownHeader id="TooltipProfile" isActive={isActive} image={profileImage} name={firstName} />

        <OnboardingTooltip
          text="The profile and your notifications can be accessed at any time from the navigation bar."
          opened={onboardingProfile}
          name="profile"
          target="TooltipProfile"
          settings={settings?.onboarding}
          placement="top"
          buttonText="Got it"
        />
      </DropdownToggle>
      <DropdownMenu className="p-4 profile-menu-dropdown__body" end>
        <div className="d-flex flex-column">
          <ProfileDropdownExtraInfo image={profileImage} name={fullName} description={profileBio} />
          <h4 className="profile-menu-dropdown__title font-weight-semibold font-size-16 mb-2">Account</h4>
          <Link onClick={() => setHash("questions")} to="/home/faq#questions" className="fw-medium profile-menu-dropdown__link mb-2">
            General Questions
          </Link>
          <Link onClick={() => setHash("privacy")} to="/home/faq#privacy" className="fw-medium profile-menu-dropdown__link mb-2">
            Privacy Policy
          </Link>
          <Link onClick={() => setHash("terms")} to="/home/faq#terms" className="fw-medium profile-menu-dropdown__link mb-2">
            Terms & Conditions
          </Link>
          <Link onClick={() => setHash("support")} to="/home/faq#support" className="fw-medium profile-menu-dropdown__link mb-2">
            Help
          </Link>
          <div className="dropdown-divider" />
          <Link to="/logout" className="fw-medium profile-menu-dropdown__link">
            Sign out
          </Link>
        </div>
      </DropdownMenu>
    </Dropdown>
  )
}

ProfileMenu.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  profileImage: PropTypes.string,
  profileBio: PropTypes.string,
  success: PropTypes.any,
  t: PropTypes.any,
  settings: PropTypes.object,
}

const mapStatetoProps = state => {
  const firstName = state.Account.settings?.firstName
  const lastName = state.Account.settings?.lastName
  const profileImage = state.Account.settings?.profile_image
  const profileBio = state.Account.settings?.profile_short_bio
  const settings = state.Account.settings

  return { firstName, lastName, profileImage, profileBio, settings }
}

export default withRouter(connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)))
