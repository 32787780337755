import React, { useEffect, useState } from "react"
import {
  filterItemsByPoints,
  filterItemsByName,
  filterItemsBySize,
  filterItemsByType,
  getParams,
  isSearch,
  sortItemsByPoints,
  filterItemsByLocation
} from "helpers/search_helpers"

export const useSearchRoomsResults = (rooms, query, settings, userId) => {
  const [ result, setResult ] = useState([])
  const newRooms = !!rooms && !!userId && rooms.filter(item => item.admin_user !== userId && !item.users.includes(userId))
  const isSearchEnabled = isSearch(query)

  useEffect(() => {
    if(!!rooms && !!rooms.length && !!settings && userId){
      const params = getParams(isSearchEnabled, settings, query)

      // filter by location
      const locationResults = filterItemsByLocation(isSearchEnabled, newRooms, params)

      console.log("locationResults", locationResults)

      // filter by type
      const typeResults = filterItemsByType(isSearchEnabled, locationResults, params)

      // filter by budget
      const sizeResults = filterItemsBySize(isSearchEnabled, typeResults, params)

      // search by name
      if(!!params.name){
        const nameResults = filterItemsByName(sizeResults, params)
        const sortResults = sortItemsByPoints(nameResults)

        setResult(sortResults)
      } else {
        const pointsResults = filterItemsByPoints(sizeResults, params)
        if(!!pointsResults.length){
          const sortResults = sortItemsByPoints(pointsResults)
          setResult(sortResults)
        } else if(!sizeResults.length){
          setResult([])
        } else if(sizeResults.length > 10){
          const sortByDate = sizeResults.sort((a, b) => b.createdAt - a.createdAt)
          sortByDate.length = 10
          setResult(sortByDate)
        } else {
          const sortByDate = sizeResults.sort((a, b) => b.createdAt - a.createdAt)
          setResult(sortByDate)
        }
      }
    }
  }, [rooms, query, settings, userId, setResult])

  return result
}
