import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Link } from "react-router-dom"

import { ActivityItem } from "components/Activity/components/ActivityItem/ActivityItem"
import { useDispatch } from "react-redux"
import { openedRoomAction, setActiveCategory } from "store/actions"

export const NotificationNewIndividualMessage = ({
  isSmall,
  id,
  read,
  time,
  full_name,
  profile_image,
  username,
  onRead,
  onDelete,
  room_id,
  onRoomClick,
}) => {
  const dispatch = useDispatch()
  const path = username ? `/profile/${username}` : "/"
  const title = (
    <>
      <Link to={path} className="activity-item__link">
        {full_name}
      </Link>{" "}
      has sent you a{" "}
      <Link
        to="/messages"
        className="activity-item__link"
        onClick={() => {
          dispatch(openedRoomAction(room_id))
          onRoomClick(room_id)
          setTimeout(dispatch(setActiveCategory("chats")), 1500)
        }}
      >
        message
      </Link>
    </>
  )

  return (
    <ActivityItem
      onDelete={onDelete}
      id={id}
      isSmall={isSmall}
      title={title}
      image={profile_image}
      time={time}
      dropdownOptions={
        <>
          {/* <button
            className={cx("border-0 dropdown-item font-size-11 notifications-block__button", {
              "is-disabled": read,
            })}
            onClick={() => onRead(id)}
          >
            Mark as read
          </button> */}

          {/* <button
            className="border-0 dropdown-item font-size-11 notifications-block__button"
            onClick={() => onDelete(id)}
          >
            Remove notification
          </button> */}
        </>
      }
    />
  )
}

NotificationNewIndividualMessage.propTypes = {
  isSmall: PropTypes.bool,
  time: PropTypes.number,
  id: PropTypes.number,
  room_id: PropTypes.string,
  full_name: PropTypes.string,
  username: PropTypes.string,
  profile_image: PropTypes.string,
  read: PropTypes.bool,
  onRead: PropTypes.func,
  onDelete: PropTypes.func,
  onRoomClick: PropTypes.func,
}
