import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { InputField } from "components/InputField/InputField"
import { ChatLeftSidebarNav } from "./components/ChatLeftSidebarNav/ChatLeftSidebarNav"

export const ChatLeftSidebar = ({
  room,
  chats,
  groups,
  archived,
  activeCategory,
  onClick,
  onToggleTab,
}) => {
  const [searchValue, setSearchValue] = useState("")
  const [searchChats, setSearchChats] = useState([])
  const [searchGroups, setSearchGroups] = useState([])
  const [searchArchived, setSearchArchived] = useState([])

  const handleSearch = e => {
    const currentValue = e.target.value
    setSearchValue(currentValue)
  }

  useEffect(() => {
    setSearchChats(chats)
  }, [chats])

  useEffect(() => {
    setSearchGroups(groups)
  }, [groups])

  useEffect(() => {
    setSearchArchived(archived)
  }, [archived])

  useEffect(() => {
    const newChats = chats.filter(chat => {
      const name = chat.name.toLowerCase()
      return name.includes(searchValue)
    })
    setSearchChats(newChats)

    const newGroups = groups.filter(group => {
      const name = group.name.toLowerCase()
      return name.includes(searchValue)
    })
    setSearchGroups(newGroups)

    const newArchived = archived.filter(item => {
      const name = item.name.toLowerCase()
      return name.includes(searchValue)
    })
    setSearchArchived(newArchived)
  }, [searchValue])

  const handleClearSearch = () => {
    setSearchValue("")
  }

  return (
    <div className="chat-left-sidebar">
      <div className="d-flex justify-content-between align-items-center chat-left-sidebar__header">
        <h1 className="mb-0 font-size-24 chat-left-sidebar__title">Chats</h1>
      </div>

      <div className="position-relative chat-left-sidebar__search-wrapper">
        <InputField
          className="chat-left-sidebar__search"
          inputName="search"
          type="text"
          placeholder="Search..."
          value={searchValue}
          onChange={handleSearch}
        />
        <i className="bx bx-search-alt chat-left-sidebar__search-icon position-absolute font-size-16" />
        {!!searchValue && (
          <i
            className="mdi mdi-close chat-left-sidebar__close-icon position-absolute font-size-16"
            onClick={handleClearSearch}
          />
        )}
      </div>

      <ChatLeftSidebarNav
        room={room}
        activeCategory={activeCategory}
        chats={searchChats}
        groups={searchGroups}
        archived={searchArchived}
        onClick={onClick}
        onToggleTab={onToggleTab}
      />
    </div>
  )
}

ChatLeftSidebar.propTypes = {
  room: PropTypes.object,
  chats: PropTypes.array,
  groups: PropTypes.array,
  archived: PropTypes.array,
  activeCategory: PropTypes.string,
  onClick: PropTypes.func,
  onToggleTab: PropTypes.func,
}
