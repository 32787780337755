import React, { useRef, useLayoutEffect } from "react"
import ContentEditable from "react-contenteditable"
import PropTypes from "prop-types"

export const ContentEditableWrapper = ({
  disabled,
  className,
  value,
  onBlur,
  onFocus,
  onChange,
}) => {
  const ref = useRef(null)

  useLayoutEffect(() => {
    if (!disabled && ref.current) {
      setCursorEndPositionContentEditable(ref.current)
    }
  }, [disabled])

  return (
    <ContentEditable
      disabled={disabled}
      className={className}
      html={value}
      innerRef={ref}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  )
}

ContentEditableWrapper.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
}

export const setCursorEndPositionContentEditable = el => {
  const range = document.createRange()
  const selection = window.getSelection()
  if (el?.childNodes?.length > 0) {
    range.setStart(el.childNodes[0], el.textContent.length)
    range.collapse(true)
  }
  selection.removeAllRanges()
  selection.addRange(range)
  el.focus()
  el.scrollLeft = el.scrollWidth
}
