import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRanger } from "react-ranger";

import { getBudgetString } from 'helpers/getBudgetString';

import { BudgetRangePoints } from './components/BudgetRangePoints/BudgetRangePoints';
import { getBudgetNumber } from 'helpers/getBudgetNumber';

export const BudgetRange = ({
  range = { min: 0, max: 5000000 },
  onChange
}) => {

  const handleChange = (values) => {
    onChange({
      min: getBudgetNumber(values[0]),
      max: getBudgetNumber(values[1])
    })
  }

  const { getTrackProps, handles, segments } = useRanger({
    min: 0,
    max: 5000000,
    stepSize: 100000,
    values: [!!range? range.min : 0, !!range ? range.max: 5000000],
    onDrag: handleChange
  });

  const minText = useMemo(() => {
    return getBudgetString(range?.min)
  }, [range?.min])

  const maxText = useMemo(() => {
    return getBudgetString(range?.max)
  }, [range?.max])

  return (
    <div className="budget-range d-grid align-items-center">
      <span className="font-size-16 text-color-primary text-right">{minText}</span>
      <div
        className="budget-range__range background-color-gray-300"
        {...getTrackProps()}
      >
        {handles.map(({ getHandleProps }, index) => (
          <button
            key={index}
            className="budget-range__button background-color-green-200 cursor-pointer rounded-circle"
            {...getHandleProps()}
          />
        ))}

        {segments.map(({ getSegmentProps }, index) => {
          if(index === 1){
            return (
              <span className="budget-range__line position-absolute background-color-green-200" key={index} {...getSegmentProps()} />
            )
          }
        })}

        <BudgetRangePoints />
      </div>
      <span className="font-size-16 text-color-primary">{maxText}</span>
    </div>
  );
}

BudgetRange.propTypes = {
  range: PropTypes.object,
  onChange: PropTypes.func
};
