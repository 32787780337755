import React, { useCallback, useState } from "react"
import { Avatar } from "components/Avatar/Avatar"
import PropTypes from "prop-types"
import cx from "classnames"
import SimpleBar from "simplebar-react"
import { Link } from "react-router-dom"
import { getFullName } from "components/Profile/Profile.helpers"
import { useDispatch, useSelector } from "react-redux"
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton"
import firebase from "firebase/compat/app"
import "firebase/compat/storage"
import { registerRoom, openedRoomAction, setActiveCategory } from "store/actions"
import { capitalizeFirstLetter } from "helpers/capitalizeFirstLetter"

export const AvatarPopover = ({ user, className, avatarSize, history }) => {
  const [opened, setOpened] = useState(false)
  const dispatch = useDispatch()

  const currentUser = useSelector(state => state.Account.user)

  const classes = cx(className, "avatar-popover position-relative")

  const classesBody = cx("avatar-popover__body position-absolute", {
    "d-block": opened,
    "d-none": !opened,
  })

  const handleClick = state => {
    setOpened(state)
  }

  const name = getFullName(user.firstName, user.lastName)
  const link = user.id === currentUser?.uid ? "profile" : `profile/${user.username}`

  const handleMessage = useCallback(() => {
    firebase
      .firestore()
      .collection("rooms")
      .where("admin_user", "in", [user.id, currentUser?.uid])
      .get()
      .then(res => {
        const list = res.docs.filter(item => {
          const data = item.data()
          return data.status === "chat"
        })

        const chat = list.find(item => {
          const data = item.data()
          return data.users.includes(user.id) || data.users.includes(currentUser?.uid)
        })

        if (!!chat) {
          dispatch(openedRoomAction(chat.id))
          dispatch(setActiveCategory("chats"))
          if (!!history) {
            history.push("/messages")
          }
        } else {
          const time = Date.now()
          const roomSettings = {
            tasks: [],
            admin_user: currentUser?.uid,
            links: [],
            users: [user.id],
            status: "chat",
            chat: null,
            createdAt: time,
          }
          dispatch(registerRoom(roomSettings, []))

          setTimeout(() => {
            firebase
              .firestore()
              .collection("rooms")
              .where("admin_user", "in", [user.id, currentUser?.uid])
              .get()
              .then(res => {
                res.docs.find(item => {
                  if (item.data().createdAt === time) {
                    dispatch(openedRoomAction(item.id))
                    dispatch(setActiveCategory("chats"))

                    setTimeout(() => {
                      if (!!history) {
                        history.push("/messages")
                      }
                    }, 1000)
                  }
                })
              })
          }, 1000)
        }
      })
  })

  return (
    <div className={classes} onMouseEnter={() => handleClick(true)} onMouseLeave={() => handleClick(false)}>
      <Avatar size={avatarSize} image={user.profile_image} userName={user.firstName + ' ' + user.lastName} />

      <div className={classesBody}>
        <SimpleBar className="avatar-popover__scroll background-color-white p-3 border-radius-4">
          <div className="list-group list-group-flush">
            <div className="d-flex mb-3">
              <Link to={link} key={user.username} className="avatar-popover__list-item border-radius-4">
                <Avatar image={user.profile_image} size="xl" className="avatar-popover__avatar" />
              </Link>

              <span className="d-flex flex-column justify-content-center">
                <span className="font-weight-bold font-size-16 text-color-primary line-height-140">
                  {capitalizeFirstLetter(name)}
                </span>

                {!!user.bio && (
                  <span className="avatar-popover__bio font-size-13 fw-medium overflow-hidden color-gray-400 line-height-140 mt-1">
                    {user.bio}
                  </span>
                )}
              </span>
            </div>
            <PrimaryButton className="w-100 font-size-13" text="Message" onClick={handleMessage} />
          </div>
        </SimpleBar>
      </div>
    </div>
  )
}

AvatarPopover.propTypes = {
  user: PropTypes.object,
  className: PropTypes.string,
  avatarSize: PropTypes.string,
  popover: PropTypes.bool,
  history: PropTypes.object,
}
