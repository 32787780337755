import React, { useState } from "react"
import PropTypes from 'prop-types'

import { SecondaryButton } from "components/SecondaryButton/SecondaryButton"
import { CardBlock } from 'components/CardBlock/CardBlock'
import { Icon } from "components/Icon/Icon"
import NewRoomModal from "modals/NewRoomModal/NewRoomModal"

export const StartGroup = ({ history, id }) => {
  const [ showModal, setShowModal ] = useState(false);

  return (
    <>
      <CardBlock
        className="start-group"
        header={(
          <div id={id} className="rounded-circle d-flex align-items-center justify-content-center start-group__header">
            <Icon name="chatrooms" className="start-group__icon" />
          </div>
        )}
        title="Start a private group"
        text="Create and manage your own group."
        >
          <SecondaryButton
            className="start-group__button"
            size="small"
            text="Start a private  group"
            onClick={() => setShowModal(true)}
          />
        </CardBlock>

        <NewRoomModal
          history={history}
          isOpened={showModal}
          onToggle={() => setShowModal(false)}
        />
    </>
  )
}

StartGroup.propTypes = {
  history: PropTypes.object,
  id: PropTypes.string,
};

