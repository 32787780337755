import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Step } from '../../common/Step/Step'
import { ModalPillList } from '../../common/ModalPillList/ModalPillList'

import startOptions from 'common/data/schemas/investment_types'

export const TypesAdditionalStep = ({
  currentTab, activeInvestment, activeProperty, isRoom, onChangeInvestmentTypes, onChangePropertyTypes,
}) => {
  const [ residentialTypes ] = startOptions

  const propertyOptions = useMemo(() => {
    return residentialTypes.property_type.map(option => {
      return { id: option, name: option, active: activeProperty?.includes(option) }
    })
  }, [residentialTypes, activeProperty])

  const investmentOptions = useMemo(() => {
    return residentialTypes.investment_type.map(option => {
      return { id: option, name: option, active: activeInvestment?.includes(option) }
    })
  }, [residentialTypes, activeInvestment])

  const step = isRoom ? 1 : 2
  const classes = cx({ 'd-block': currentTab === step, 'd-none': currentTab !== step, })
  const headingNumber = isRoom ? '2' : '3'

  return (
    <div className={classes}>
      <Step
         heading='Property type'
         description='What type of residential property are you looking for?'
      >
        <ModalPillList items={propertyOptions} onClick={onChangePropertyTypes} />
      </Step>

      <Step
          heading='Investment type'
          description='What type of investment are you looking for?'
      >
        <ModalPillList items={investmentOptions} onClick={onChangeInvestmentTypes} />
      </Step>
    </div>
  );
}

TypesAdditionalStep.propTypes = {
  currentTab: PropTypes.number,
  className: PropTypes.string,
  activeInvestment: PropTypes.array,
  activeProperty: PropTypes.array,
  isRoom: PropTypes.bool,
  onChangeInvestmentTypes: PropTypes.func,
  onChangePropertyTypes: PropTypes.func
};
