import React, { useLayoutEffect, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import "react-perfect-scrollbar/dist/css/styles.css"
import SimpleBar from "simplebar-react"

import { ChatMainMessage } from "../ChatMainMessage/ChatMainMessage"

export const ChatMainConversation = ({ messages, currentUser, height }) => {
  const scrollableNodeRef = useRef(null)

  useLayoutEffect(() => {
    if (!isEmpty(messages)) {
      const element = scrollableNodeRef?.current
      setTimeout(() => {
        element.scrollTo({
          top: element?.scrollHeight,
          behavior: "instant",
        })
      }, 50)
    }
  }, [messages])

  const currentDate = new Date(Date.now()).toDateString()

  const filteredMessages = useMemo(() => {
    return messages.map((message, index) => {
      const isCurrentUserAuthor = message.senderId === currentUser?.uid
      const messageDateData = new Date(message.created)
      const prevMessageDateData = index ? new Date(messages[index - 1].created) : ""

      const messageDate = messageDateData.toDateString()
      const messageDay = messageDateData.getDate()
      const messageMonth = messageDateData.getMonth()
      const messageYear = messageDateData.getFullYear()

      const prevMessageDay = index ? prevMessageDateData.getDate() : ""
      const prevMessageMonth = index ? prevMessageDateData.getMonth() : ""
      const prevMessageYear = index ? prevMessageDateData.getFullYear() : ""

      const isDataVisible =
        messageDay !== prevMessageDay || messageMonth !== prevMessageMonth || messageYear !== prevMessageYear

      const dateInfo = messageDate === currentDate ? "Today" : messageDate

      const senderName =
        index && !isDataVisible && message.senderId === messages[index - 1].senderId ? "" : message.senderName

      return {
        created: message.created,
        messageId: message.messageId,
        senderId: message.senderId,
        senderImage: message.senderImage,
        senderName: senderName,
        content: message.content,
        isDataVisible: isDataVisible,
        dateInfo: dateInfo,
        isLeft: !isCurrentUserAuthor,
      }
    })
  }, [messages, currentUser])

  return (
    <div className="chat-main-conversation">
      <SimpleBar
        style={{ height: height }}
        scrollableNodeProps={{ ref: scrollableNodeRef }}
        className="chat-main-conversation__scroll"
      >
        <ul className="mb-0 list-unstyled chat-main-conversation__list">
          {filteredMessages &&
            filteredMessages.map((message) => {
              return (
                <>
                  {message.isDataVisible && (
                    <li key={message.dateInfo} className="text-center chat-main-conversation__day-title-wrapper">
                      <span className="font-size-11 chat-main-conversation__day-title">{message.dateInfo}</span>
                    </li>
                  )}

                  <li key={message.messageId}>
                    <ChatMainMessage
                      text={message.content}
                      avatar={message.senderImage}
                      name={message.senderName}
                      isLeft={message.isLeft}
                    />
                  </li>
                </>
              )
            })}
        </ul>
      </SimpleBar>
    </div>
  )
}

ChatMainConversation.propTypes = {
  messages: PropTypes.array,
  currentUser: PropTypes.object,
  height: PropTypes.number,
}
