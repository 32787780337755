import React, { useState, useRef } from "react"
import PropTypes from 'prop-types';
import cx from "classnames"

import { useOnClickOutside } from "helpers/clickOutside";
import { SearchSelectButton } from "../SearchSelectButton/SearchSelectButton";
import { BudgetRange } from "components/BudgetRange/BudgetRange";

const defaultBudget = { min: 0, max: 5000000 }

export const BudgetSelect = ({ selected, className, onSelect }) => {
  const ref = useRef(null);

  const [ isActive, setIsActive ] = useState(false);

  useOnClickOutside(ref, () => setIsActive(false));

  const onClick = () => {
    setIsActive(active => !active);
  };

  const classes = cx("position-relative budget-select", className, {
    "is-active": isActive
  });

  return (
    <div className={classes} ref={ref}>
      <SearchSelectButton className="budget-select__button" text="Budget" isActive={isActive} onClick={onClick} />

      {isActive && (
        <div className="position-absolute budget-select__content">
          <p className="budget-select__title">Select your budget range</p>

          <BudgetRange range={selected || defaultBudget} onChange={onSelect} />
        </div>
      )}
    </div>
  )
}

BudgetSelect.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.object,
  onSelect: PropTypes.func
};
