import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { PrimaryButton } from 'components/PrimaryButton/PrimaryButton'

export const ModalPillList = ({
  items, onClick
}) => {

  return (
    <ul className='modal-pill-list p-0 d-flex flex-wrap justify-content-center'>
      {items.map((pill) => {
        const classes = cx('modal-pill-list__pill', {
          'is-active': pill.active
        });

        return (
          <li key={pill.id} className='modal-pill-list__item'>
            <PrimaryButton
              className={classes}
              text={pill.name}
              size="small"
              onClick={() => onClick(pill.id)}
            />
          </li>
        );
      })}
    </ul>
  );
};

ModalPillList.propTypes = {
  items: PropTypes.array,
  onClick: PropTypes.func,
};
