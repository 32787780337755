import React, { useState, useCallback } from "react"
import PropTypes from "prop-types"
import firebase from "firebase/compat/app"
import "firebase/compat/storage"
import "firebase/compat/analytics"
import { useDispatch } from "react-redux"

import { SearchResultsModal } from "components/SearchResultsModal/SearchResultsModal"
import { getFirebaseBackend } from "helpers/firebase_helper"
import { getFullName } from "components/Profile/Profile.helpers"
import { registerRoom, openedRoomAction, setActiveCategory } from "store/actions"
import { RecommendedGroups } from "components/RecommendedGroups/RecommendedGroups"
import { SearchResults } from "components/SearchResults/SearchResults"
import { getNotificationEntity } from "pages/Notifications/helpers/notifcations.helpers"

export const GroupResults = ({ options, user, settings, history, isExplore, onSort }) => {
  const dispatch = useDispatch()
  const fireBaseBackend = getFirebaseBackend()
  const [modalOpened, setModalOpened] = useState(false)
  const [modalData, setModalData] = useState(null)
  const analytics = firebase.analytics()

  const onOpenModal = data => {
    setModalData(data)
    setModalOpened(true)
  }

  const handleRequest = useCallback(() => {
    fireBaseBackend.updateUserNotificationById(
      modalData.admin_user,
      getNotificationEntity("request-to-join-in-your-group", {
        user,
        member: settings,
        room: modalData,
      })
    )
    fireBaseBackend.addUserToPendingRoom(modalData.id, user.uid)
    fireBaseBackend.notifyRoomAdmin(
      modalData.admin_user,
      getFullName(settings.firstName, settings.lastName),
      modalData.name
    )

    analytics.logEvent("requests_to_join_groups", {
      room_id: modalData.id,
      room_name: modalData.name,
    })
  }, [settings, user, modalData])

  const handleSendMessage = useCallback(() => {
    firebase
      .firestore()
      .collection("rooms")
      .where("admin_user", "in", [user.uid, modalData.admin_user])
      .get()
      .then(res => {
        const chat = res.docs.find(item => {
          const data = item.data()

          if (data.admin_user === user.uid) {
            return !data?.hasOwnProperty("investment_location") && data.users.includes(modalData.admin_user)
          }
          if (data.admin_user === modalData.admin_user) {
            return !data?.hasOwnProperty("investment_location") && data.users.includes(user.uid)
          }
        })

        if (!!chat) {
          dispatch(openedRoomAction(chat.id))
          dispatch(setActiveCategory("chats"))
          history.push("/messages")
        } else {
          const time = Date.now()
          const roomSettings = {
            tasks: [],
            admin_user: user.uid,
            links: [],
            users: [modalData.admin_user],
            status: "chat",
            chat: null,
            createdAt: time,
          }
          dispatch(registerRoom(roomSettings, []))

          setTimeout(() => {
            firebase
              .firestore()
              .collection("rooms")
              .where("admin_user", "==", user.uid)
              .get()
              .then(res => {
                res.docs.find(item => {
                  if (item.data().createdAt === time) {
                    dispatch(openedRoomAction(item.id))
                    dispatch(setActiveCategory("chats"))
                    setTimeout(() => {
                      history.push("/messages")
                    }, 1000)
                  }
                })
              })
          }, 1000)
        }
      })
  })

  return (
    <>
      {isExplore ? (
        <SearchResults options={options} onSort={onSort} onOpenModal={onOpenModal} />
      ) : (
        <RecommendedGroups options={options} onOpenModal={onOpenModal} />
      )}

      <SearchResultsModal
        option={!!modalData ? modalData : null}
        onRequest={handleRequest}
        onMessage={handleSendMessage}
        isOpened={modalOpened}
        history={history}
        onToggle={() => setModalOpened(false)}
        currentUser={user}
      />
    </>
  )
}

GroupResults.propTypes = {
  settings: PropTypes.object,
  user: PropTypes.object,
  options: PropTypes.array,
  history: PropTypes.object,
  isExplore: PropTypes.bool,
  onSort: PropTypes.func,
}
