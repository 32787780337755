import React, { useCallback } from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import { ActivityItem } from "components/Activity/components/ActivityItem/ActivityItem"
import { Link } from "react-router-dom"

export const NotificationRequestToInvite = props => {
  const {
    isSmall,
    id,
    read,
    decline,
    actor_id,
    room_name,
    room_id,
    full_name,
    username,
    profile_image,
    accepted,
    time,
    onClick,
    onRead,
    onDelete,
    onRoomClick,
  } = props

  const handleRoomClick = useCallback(() => {
    onRoomClick(room_id)
  }, [onRoomClick])

  const path = username ? `/profile/${username}` : "/"
  const title = (
    <>
      <Link className="activity-item__link" to={path}>
        {full_name}
      </Link>{" "}
      invited you to{" "}
      {accepted ? (
        <Link to="/messages" className="activity-item__link" onClick={handleRoomClick}>
          {room_name}
        </Link>
      ) : (
        <strong>{room_name}</strong>
      )}
    </>
  )

  return (
    <ActivityItem
      onDelete={onDelete}
      id={id}
      isSmall={isSmall}
      title={title}
      image={profile_image}
      invite={!accepted && !decline}
      time={time}
      onClick={action => onClick(action, actor_id, room_name, room_id, id)}
      dropdownOptions={
        <>
          {/* <button
            className={cx("border-0 dropdown-item font-size-11 notifications-block__button", {
              "is-disabled": read,
            })}
            onClick={() => onRead(id)}
          >
            Mark as read
          </button> */}

          {/* <button
            className="border-0 dropdown-item font-size-11 notifications-block__button"
            onClick={() => {
              onDelete(id)
            }}
          >
            Remove notification
          </button> */}
        </>
      }
    />
  )
}

NotificationRequestToInvite.propTypes = {
  isSmall: PropTypes.bool,
  decline: PropTypes.bool,
  accepted: PropTypes.bool,
  full_name: PropTypes.string,
  username: PropTypes.string,
  profile_image: PropTypes.string,
  read: PropTypes.bool,
  id: PropTypes.number,
  time: PropTypes.number,
  actor_id: PropTypes.string,
  room_id: PropTypes.string,
  room_name: PropTypes.string,
  username: PropTypes.string,
  onClick: PropTypes.func,
  onRead: PropTypes.func,
  onDelete: PropTypes.func,
  onRoomClick: PropTypes.func,
}
