import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Button } from "reactstrap"

import { Icon } from "../Icon/Icon"

export const SecondaryButton = ({ isExternal, text, icon, size = "medium", error, disabled, className, onClick }) => {
  const classes = cx("button secondary-button", `button--${size}`, className, {
    "is-error": error,
    "is-disabled": disabled,
  })

  return (
    <Button className={classes} onClick={onClick}>
      {text}

      {icon && <Icon className="button__icon secondary-button__icon" name={icon} />}

      {isExternal && (
        <svg
          style={{ marginLeft: "5px" }}
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.66667 1.73333H3.34667C2.52533 1.73333 2.11467 1.73333 1.8008 1.8932C1.52481 2.03377 1.30044 2.25814 1.15987 2.53413C1 2.848 1 3.25867 1 4.08V9.65333C1 10.4747 1 10.8853 1.15987 11.1992C1.30048 11.4752 1.52484 11.6995 1.8008 11.8401C2.11393 12 2.5246 12 3.34447 12H8.9222C9.74207 12 10.152 12 10.4651 11.8401C10.7416 11.6993 10.966 11.4749 11.1068 11.1992C11.2667 10.8853 11.2667 10.4754 11.2667 9.65553V8.33333M12 4.66667V1M12 1H8.33333M12 1L6.86667 6.13333"
            stroke="#666666"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </Button>
  )
}

SecondaryButton.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.oneOfType(["large" | "medium" | "small" | "extra-small"]),
  error: PropTypes.bool,
  isExternal: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}
