import React from "react"
import PropTypes from "prop-types"
import { Card, CardBody } from "reactstrap"

import { ProfileActionDropdown } from "../ProfileActionDropdown/ProfileActionDropdown"
import { ProfileExtra } from "../ProfileExtra/ProfileExtra"
import { LookingFor, LookingForModel } from "../LookingFor/LookingFor"
import { contactsModel } from "../ProfileContacts/ProfileContacts"
import { EditButton } from "../EditButton/EditButton"
import { UploadProfileAvatar } from "../UploadProfileAvatar/UploadProfileAvatar"

export const ProfileCard = ({
  showIdentidyButton,
  settings,
  rooms,
  firstName,
  lastName,
  profileBio,
  location,
  contacts,
  lookingFor,
  profileImage,
  coverImage,
  isPrivate = false,
  onUpdateProfile,
  onUpdateContactDetails,
  onUpdateProfileAvatar,
  onUpdateProfileCoverImage,
  onUpdateProfileDetails,
  onInviteToRooms,
  onMessage,
  onReportMember,
}) => {
  const shouldEditContacts = !contacts?.website || !contacts?.linkedin || !contacts?.phone

  return (
    <Card className="profile-card">
      <CardBody className="profile-card__body">
        <div className="profile-card__cover" style={{ backgroundImage: `url(${coverImage})` }}>
          {isPrivate && (
            <div className="profile-card__cover-button">
              <EditButton />
              <input
                className="profile-card__file-input"
                type="file"
                name="file"
                onChange={onUpdateProfileCoverImage}
              />
            </div>
          )}
        </div>
        <UploadProfileAvatar
          image={profileImage}
          userName={firstName + ' ' + lastName}
          onUpdateProfileAvatar={onUpdateProfileAvatar}
          isPrivate={isPrivate}
        />
        <div className="profile-card__content d-flex">
          {!isPrivate && <ProfileActionDropdown onReportMember={onReportMember} />}
          <ProfileExtra
            showIdentidyButton={showIdentidyButton}
            settings={settings}
            rooms={rooms}
            firstName={firstName}
            lastName={lastName}
            isPrivate={isPrivate}
            isEditing={shouldEditContacts}
            location={location}
            profileBio={profileBio}
            contacts={contacts}
            onUpdateContactDetails={onUpdateContactDetails}
            onUpdateProfileDetails={onUpdateProfileDetails}
            onInviteToRooms={onInviteToRooms}
            onMessage={onMessage}
          />

          <LookingFor isPrivate={isPrivate} lookingFor={lookingFor} onUpdateProfile={onUpdateProfile} />
        </div>
      </CardBody>
    </Card>
  )
}

ProfileCard.propTypes = {
  rooms: PropTypes.array,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  profileBio: PropTypes.string,
  location: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  contacts: contactsModel,
  isAdmin: PropTypes.bool,
  isPrivate: PropTypes.bool,
  profileImage: PropTypes.string,
  coverImage: PropTypes.string,
  lookingFor: LookingForModel,
  settings: PropTypes.object,

  showIdentidyButton: PropTypes.bool,

  onUpdateProfile: PropTypes.func,
  onUpdateProfileAvatar: PropTypes.func,
  onUpdateContactDetails: PropTypes.func,
  onUpdateProfileCoverImage: PropTypes.func,
  onUpdateProfileDetails: PropTypes.func,
  onInviteToRooms: PropTypes.func,
  onMessage: PropTypes.func,
  onReportMember: PropTypes.func,
}
