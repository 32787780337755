import React, { forwardRef, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Dropdown, DropdownToggle, UncontrolledTooltip } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

import {
  showMessagesRightSidebarAction,
  showMessagesLeftSidebarAction,
  showAttachmentsModalAction,
} from "store/actions"
import { useCurrentWidth } from "pages/Messages/helpers"
import { tabletWidth } from "pages/Messages/variables"
import { MembersPopover } from "components/MembersPopover/MembersPopover"
import { getFullName } from "components/Profile/Profile.helpers"

import { ChatMainSettings } from "../ChatMainSettings/ChatMainSettings"
import { ChatModal } from "../ChatModal/ChatModal"
import { VerifiedSvg } from "assets/icons/verified"
import { capitalizeFirstLetter } from "helpers/capitalizeFirstLetter"

export const ChatMainHeader = forwardRef(
  ({ userSettings, data, users, foundUsers, isAdmin, onAddUser, onFindUsers, onInviteUser, onAction }, ref) => {
    const dispatch = useDispatch()
    const showPanel = useSelector(state => state.Layout.showMessagesRightSidebar)

    const [settingsMenu, setSettingsMenu] = useState(false)
    const [enabledSettingTooltip, setEnabledSettingTooltip] = useState(false)

    let width = useCurrentWidth()

    const toggleSettings = () => {
      setSettingsMenu(open => !open)
      setEnabledSettingTooltip(false)
    }

    const onToggleSettingTooltip = () => {
      if (settingsMenu) {
        setEnabledSettingTooltip(false)
        return
      }
      setEnabledSettingTooltip(open => !open)
    }

    const onClick = () => {
      if (width <= tabletWidth) {
        dispatch(showAttachmentsModalAction(true))
      } else {
        dispatch(showMessagesRightSidebarAction(!showPanel))
      }
    }

    const showLeftSidebar = () => {
      if (width <= tabletWidth) {
        dispatch(showMessagesLeftSidebarAction(true))
      }
    }

    const getStatusClasses = status => {
      return status === "online" ? "text-success" : status === "offline" ? "text-warning" : ""
    }

    const isChat = data?.status === "chat"

    const userList = useMemo(() => {
      return users.filter(user => {
        return user.username !== userSettings.username
      })
    }, [users, userSettings])

    const name = useMemo(() => {
      if (!!data?.name) {
        return data?.name
      }

      const chatUser = userList[0]
      if (!!chatUser) {
        return getFullName(chatUser?.firstName, chatUser?.lastName)
      }

      return ""
    }, [users, data])

    const isVerified = useMemo(() => {
      if (!!data?.isVerified) {
        return data?.isVerified
      }

      const chatUser = userList[0]
      if (!!chatUser) {
        return chatUser?.isVerified
      }

      return false
    }, [users, data])

    return (
      <div ref={ref} className="chat-main-header">
        {!isChat && data && data.cover_image && <img className="chat-main-header__image" src={data?.cover_image} />}

        <div className="position-relative d-flex justify-content-between chat-main-header__content">
          <button
            className="position-absolute border-0 chat-main-header__arrow-button"
            onClick={showLeftSidebar}
          ></button>

          <div className="d-flex align-items-center chat-main-header__user">
            {userList.length > 0 && (
              <MembersPopover
                className="chat-main-header__avatar"
                avatarSize={isChat ? "m" : "xs"}
                members={userList}
              />
            )}

            <div className="d-flex flex-row gap-2 justify-content-center align-items-center">
              <h5 className="mb-0 chat-main-header__user-name">{capitalizeFirstLetter(name)}</h5>
              {isVerified & isChat ? <VerifiedSvg /> : null}
            </div>
          </div>

          <div className="chat-main-header__toolbar">
            <ul className="list-inline user-chat-nav text-right mb-0 text-nowrap">
              {data?.status == "active" && isAdmin && (
                <>
                  <li className="list-inline-item d-none d-sm-inline-block" id="userTooltip">
                    <ChatModal
                      isDisabled={data?.status === "archived" || data?.status === "chat" || !isAdmin}
                      foundUsers={foundUsers}
                      userSettings={userSettings}
                      onFind={onFindUsers}
                      onAdd={onAddUser}
                      onInvite={onInviteUser}
                    />
                  </li>
                  <UncontrolledTooltip
                    innerClassName="font-size-13 chat-main-header__tooltip-text"
                    popperClassName="p-0 overflow-hidden  d-flex align-items-center chat-main-header__tooltip-wrapper"
                    autohide={false}
                    placement="bottom"
                    target="userTooltip"
                  >
                    Add User
                  </UncontrolledTooltip>
                </>
              )}

              <li className="list-inline-item  d-none d-sm-inline-block" id="settingsTooltip">
                <ChatMainSettings
                  roomName={capitalizeFirstLetter(name)}
                  isOpen={settingsMenu}
                  isAdmin={isAdmin}
                  isArchived={data?.status === "archived"}
                  isChat={isChat}
                  onToggle={toggleSettings}
                  onClick={onAction}
                />
              </li>
              <UncontrolledTooltip
                innerClassName="font-size-13 chat-main-header__tooltip-text"
                popperClassName="p-0 overflow-hidden  d-flex align-items-center chat-main-header__tooltip-wrapper"
                autohide={false}
                isOpen={enabledSettingTooltip}
                placement="bottom"
                target="settingsTooltip"
                toggle={onToggleSettingTooltip}
              >
                Settings
              </UncontrolledTooltip>

              <li className="list-inline-item" id="toolsTooltip">
                <Dropdown toggle={onClick}>
                  <DropdownToggle
                    className="d-flex align-items-center justify-content-center btn nav-btn chat-main-header__icon chat-main-header__exclamation-icon"
                    tag="i"
                  >
                    <i className="mdi mdi-exclamation" />
                  </DropdownToggle>
                </Dropdown>
              </li>
              <UncontrolledTooltip
                innerClassName="font-size-13 chat-main-header__tooltip-text"
                popperClassName="p-0 overflow-hidden  d-flex align-items-center chat-main-header__tooltip-wrapper"
                autohide={false}
                placement="bottom"
                target="toolsTooltip"
              >
                Collapse Right Sidebar
              </UncontrolledTooltip>
            </ul>
          </div>
        </div>
      </div>
    )
  }
)

ChatMainHeader.displayName = "ChatMainHeader"

ChatMainHeader.propTypes = {
  userSettings: PropTypes.object,
  data: PropTypes.object,
  users: PropTypes.array,
  foundUsers: PropTypes.array,
  isAdmin: PropTypes.bool,
  onAddUser: PropTypes.func,
  onFindUsers: PropTypes.func,
  onInviteUser: PropTypes.func,
  onAction: PropTypes.func,
  isVerified: PropTypes.bool,
}
