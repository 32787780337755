import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import { InputField } from "components/InputField/InputField"
import { isValidEmail } from "helpers/isValidEmail"

export const ShareModalItem = ({ onEmailChange, onNameChange }) => {
  const [valueName, setValueName] = useState("")
  const [valueEmail, setValueEmail] = useState("")
  const [emailError, setEmailError] = useState(false)

  const handleChangeName = e => {
    setValueName(e.target.value)
  }

  const handleNameBlur = () => {
    if (!!valueName) {
      onNameChange(valueName)
    }
  }

  const handleChangeEmail = e => {
    setValueEmail(e.target.value)
    if (isValidEmail(e.target.value)) {
      setEmailError(false)
      onEmailChange(e.target.value)
    } else {
      onEmailChange('')
      setEmailError(true)
    }
  }

  const handleEmailBlur = () => {
    if (isValidEmail(valueEmail)) {
      setEmailError(false)
      return
    }
    setEmailError(true)
  }

  useEffect(() => {
    if (!emailError && !!valueEmail) {
      onEmailChange(valueEmail)
    }
  }, [emailError])

  return (
    <div className="share-modal-item">
      <InputField
        type="email"
        placeholder="Email Address"
        className={cx({ "is-error": emailError }, "share-modal-item__form")}
        value={valueEmail}
        onChange={handleChangeEmail}
        onFocus={() => {}}
        onBlur={handleEmailBlur}
      />

      <InputField
        type="text"
        placeholder="Name (Optional)"
        className="share-modal-item__form"
        value={valueName}
        onChange={handleChangeName}
        onFocus={() => {}}
        onBlur={handleNameBlur}
      />
    </div>
  )
}

ShareModalItem.propTypes = {
  onEmailChange: PropTypes.func,
  onNameChange: PropTypes.func,
}
