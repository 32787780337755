import React, { useEffect } from "react"
import { Link } from "react-router-dom"

import { FaqNav } from "./conponents/FaqNav/FaqNav"
import { FaqTabs } from "./conponents/FaqTabs/FaqTabs"
import { useHash } from "./helpers/useHash"

const Faq = () => {
  const [hash, setHash] = useHash();

  useEffect(()=> {
    if(!hash){
      setHash("questions")
    }
  }, [])

  return (
    <div className="w-100 faq">
      <div className="faq__crumbs">
        <Link to="/home" className="faq__crumbs-link">Home</Link>
        <span className="faq__crumbs-text">FAQs</span>
      </div>

      <div className="w-100 h-100 position-relative faq__main">
          <FaqNav activeTab={hash} onClick={setHash} />

          <FaqTabs activeTab={hash} />
      </div>
    </div>
  )
}

export default Faq
