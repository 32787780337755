import React from 'react'
import PropTypes from 'prop-types'

import { ModalUploadFile } from '../ModalUploadFile/ModalUploadFile'

export const ModalUploadFileList = ({ files }) => {
  return (
    <div>
      {files.map((file, index) => <ModalUploadFile key={index} file={file} />)}
    </div>
  )
}

ModalUploadFileList.propTypes = {
  files: PropTypes.array,
}
