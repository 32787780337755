export const terms = `
<h1>Terms & Conditions:</h1>
<h3>1. Introduction</h3>
<h5>1.1 Contract</h5>
<p>When you use the services of Investium, you agree to all of these terms. Your use of our services is also subject to our Privacy Policy , which covers how we collect, use, share, and store your personal information.</p>
<p>You agree that by joining Investium, registering, accessing or using our services (described below), you are agreeing to enter into a legally binding contract with Investium (even if you are using our services on behalf of a company). If you do not agree to this contract (“Contract” or “User Agreement”), do not click do not access or otherwise use any of our services. If you wish to terminate this contract, at any time you can do so by closing your account and no longer accessing or using our services.</p>
<p><b>Services</b></p>
<p>This Contract applies to Investium.ai and other services that state that they are offered under this Contract (“Services”), including the offsite collection of data  for those Services.</p>
<p><b>Investium</b></p>
<p>You are entering into this Contract with Investium (also referred to as “we” and “us”).</p>
<ol>
  <li>Investium is a platform intended solely to permit users having like interests in the real estate field to connect to each other in furtherance of any such objectives; </li>
  <li>This site serves only as a registry of such users; </li>
  <li>Investium does not in any way warrant the accuracy, completeness, usefulness or truthfulness of any information provided by such users;</li>
  <li>Investium shall have no responsibility or liability for any errors or omissions with respect to any such information or for any other information provided by the users to each other or to any third users in whatever manner, nor for the use of same by any party or for any loss of any nature whatsoever that may be incurred as a result of any transaction carried out by any party, whether in respect of any information available on this site or as may otherwise be provided by the users to each other.  </li>
</ol>
<h3>2. Obligations</h3>
<h5>2.1 Service Eligibility</h5>
<p>The Services are not for use by anyone under the age of 18.</p>
<p>To use the Services, you agree that: (1) you must be the 18 years old or older at the moment of creating an account with Investium; (2) you will only have one Investium account, which must be in your real name; and (3) you are not already restricted by Investium from using the Services. Creating an account with false information is a violation of our terms, including accounts registered on behalf of others or persons under the age of 18.</p>
<h5>2.2 Notices and Messages</h5>
<p>You’re okay with us providing notices and messages to you through our website, app, and contact information. If your contact information is out of date, you may miss out on important notices.</p>
<p>You agree that we will provide notices and messages to you in the following ways: (1) within the Services, or (2) sent to the contact information you provided us (e.g., email, mobile number). You agree to keep your contact information up to date and you agree to notify us within ten (10) days of any such change in your contact information.</p>
<h5>2.3 Sharing</h5>
<p>When you share information on our Services, others can see, copy and use that information.</p>
<p>Our Services allow messaging, instant communication and sharing of information in many ways, such as your profile and messages. Information and content that you share and/or messages may be seen by others. Where we have made settings available, we will honor the choices you make about who can see content or information </p>
<p>We are not obligated to publish any information or content on our Services and can remove it with or without notice at the sole discretion of Investium.</p>
<h3>3. Rights and Limits</h3>
<h5>3.1. Your License </h5>
<p>As between you and us, you are only granting Investium the following non-exclusive license:</p>
<dl>
  <dt>A worldwide, transferable and sublicensable right to use, copy, modify, distribute, publish and process, information and content that you provide through our Services and the services of others, without any further consent, notice and/or compensation to you or others. These rights are limited in the following ways:</dt>
  <dd>•	You can end this license for specific content by deleting such content from the Services, or generally by closing your account, except (a) to the extent you shared it with others as part of the Service and they copied, re-shared it or stored it and (b) for the reasonable time it takes to remove from backup and other systems.</dd>
  <dd>•	We will not include your content in advertisements for the products and services of third parties to others without your separate consent (including sponsored content).  However, we have the right, without payment to you or others, to serve ads near your content and information, and your social actions may be visible and included with ads, as noted in the Privacy Policy. If you use a Services feature, we may mention that with your name or photo to promote that feature within our Services, subject to your settings.</dd>
  <dd>•	We will get your consent if we want to give others the right to publish your content beyond the Services.</dd>
</dl>
<p>You agree that we may access, store, process and use any information and personal data that you provide in accordance with the Privacy Policy and your choices (including settings).</p>
<p>You agree to only provide content or information that does not violate the law nor anyone’s rights (including intellectual property rights). You also agree that your profile information will be truthful. Investium may be required by law to remove certain information or content in certain countries, without your approval.</p>
<h5>3.2 Service Availability</h5>
<p>We may change, suspend or discontinue any of our Services. We don’t promise to store or keep showing any information and content that you’ve shared. Investium is not a storage service. You agree that we have no obligation to store, maintain or provide you a copy of any content or information that you or others provide, except to the extent required by applicable law and as noted in our Privacy Policy.</p>
<h5>3.3 Other Content, Site and App</h5>
<p>By using Investium, you may encounter content or information that might be inaccurate, incomplete, delayed, misleading, illegal, offensive or otherwise harmful. Investium generally does not review content provided by our users or others. You agree that we are not responsible for others’ (including other users) content or information. We cannot always prevent this misuse of our services, and you agree that we are not responsible for any such misuse. </p>
<p>Investium may help connect users offering their services with users seeking services. Investium does not perform nor employs individuals to perform these services.  You acknowledge that Investium does not supervise, direct, control or monitor users in the performance of these services and agree that (1) Investium is not responsible for the offering, performance or procurement of these services, and (2) nothing shall create an employment, agency, or joint venture relationship between Investium and any users offering services. </p>
<h5>3.4 Limits</h5>
<p>Investium reserves the unilateral right to limit your use of the services, including your ability to contact other users. Investium reserves the right to restrict, suspend, or terminate your account if you breach this Contract or the law or are misusing the services.</p>
<h5>3.5 Automated Processing</h5>
<p>We use the information and data that you provide and that we have about users to make recommendations for connections, content and features that may be useful to you. Keeping your profile accurate and up to date helps us to make these recommendations more accurate and relevant.</p>
<h3>4. Disclaimer and Limit of Liability</h3>
<h5>4.1 No Warranty</h5>
<p>INVESTIUM MAKE NO REPRESENTATION OR WARRANTY ABOUT THE SERVICES, INCLUDING ANY REPRESENTATION THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, AND PROVIDE THE SERVICES (INCLUDING CONTENT AND INFORMATION) ON AN “AS IS” AND “AS AVAILABLE” BASIS. TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, INVESTIUM DISCLAIMS ANY IMPLIED OR STATUTORY WARRANTY, INCLUDING ANY IMPLIED WARRANTY OF TITLE, ACCURACY OF DATA, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.</p>
<h5>4.2 Exclusion of Liability</h5>
<p>TO THE FULLEST EXTENT PERMITTED BY LAW, INVESTIUMWILL NOT BE LIABLE IN CONNECTION WITH THIS CONTRACT FOR LOST PROFITS OR LOST BUSINESS OPPORTUNITIES, REPUTATION (E.G., OFFENSIVE OR DEFAMATORY STATEMENTS), LOSS OF DATA (E.G., DOWN TIME OR LOSS, USE OF, OR CHANGES TO, YOUR INFORMATION OR CONTENT) OR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR PUNITIVE DAMAGES.</p>
<h3>5. Termination</h3>
<dl>
  <dt>Both you and Investium may terminate this Contract at any time with notice to the other in writing by email with immediate effect upon receipt of said notice by the other party. On termination, you lose the right to access or use the Services. The following shall survive termination:</dt>
  <dd>• Our discretionary rights to use and disclose your feedback;</dd>
  <dd>•	Users’ rights to further re-share content and information you shared;</dd>
  <dd>•	Sections 4, 6, 7, and 8 of this Contract;</dd>
  <dd>•	Any amounts owed by either party prior to termination remain owed after termination.</dd>
</dl>
<h3>6. Governing Law and Dispute Resolution</h3>
<p>In the unlikely event we end up in a legal dispute, you and Investium agree that this Contract is of the jurisdiction of the Montréal Quebec court legal district and using Quebec law. All parties agree that should a conflict arise with regards to the Contract, the parties will attempt to resolve the matter amicably by the mean of a dispute resolution (mediation – virtual or in person) trigger by a written notice sent to the other party, requesting one (1) session of a maximum of tree (3) hours, costs being share 50% being parties, before one (1) mediator duly licenced with a professional association in the Province of Quebec. </p>
<h3>7. Investium “Dos and Don’ts”</h3>
<h5>7.1. Dos</h5>
<dl>
  <dt><b>You agree that you will:</b></dt>
  <dd>• Comply with all applicable laws, including, without limitation, privacy laws, intellectual property laws, anti-spam laws, export control laws, tax laws, and regulatory requirements;</dd>
  <dd>•	Provide accurate information to us and keep it updated;</dd>
  <dd>•	Use your real name on your profile;</dd>
  <dd>• Use Investium in a professional manner.</dd>
</dl>
<h5>7.2. Don’ts</h5>
<dl>
  <dt><b>You agree that you will <i>not</i>:</b></dt>
  <dd>• Create a false identity on Investium, misrepresent your identity, create a user profile for anyone other than yourself (a real person), or use or attempt to use another’s account;</dd>
  <dd>•	Develop, support or use software, devices, scripts, robots or any other means or processes (including crawlers, browser plugins and add-ons or any other technology) to scrape the Services or otherwise copy profiles and other data from the Services;</dd>
  <dd>•	Override any security feature or bypass or circumvent any access controls or use limits of the Service (such as caps on keyword searches or profile views);</dd>
  <dd>• Copy, use, disclose or distribute any information obtained from the Services, whether directly or through third parties (such as search engines), without the consent of Investium;/dd>
  <dd>•	Disclose information that you do not have the consent to disclose (such as confidential information of others (including your employer));</dd>
  <dd>•	Violate the intellectual property rights of others, including copyrights, patents, trademarks, trade secrets or other proprietary rights. For example, do not copy or distribute (except through the available sharing functionality) the posts or other content of others without their permission, which they may give by posting under a Creative Commons license;</dd>
  <dd>•	Violate the intellectual property or other rights of Investium, including, without limitation, (i) copying or distributing our learning videos or other materials or (ii) copying or distributing our technology, unless it is released under open-source licenses; (iii) using the word “Investium” or our logos in any business name, email, or URL;</dd>
  <dd>•	Post anything that contains software viruses, worms, or any other harmful code;</dd>
  <dd>•	Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for the services or any related technology that is not open source;</dd>
  <dd>•	Imply or state that you are affiliated with or endorsed by Investium without our express consent (e.g., representing yourself as an accredited Investium representative);</dd>
  <dd>•	Rent, lease, loan, trade, sell/re-sell or otherwise monetize the services or related data or access to the same, without Investium’s consent;</dd>
  <dd>•	Use bots or other automated methods to access the services, add or download contacts, send or redirect messages;</dd>
  <dd>•	Monitor the services’ availability, performance or functionality for any competitive purpose;</dd>
  <dd>•	Engage in “framing,” “mirroring,” or otherwise simulating the appearance or function of the services;</dd>
  <dd>•	Overlay or otherwise modify the services or their appearance (such as by inserting elements into the services or removing, covering, or obscuring an advertisement included on the services);</dd>
  <dd>•	Interfere with the operation of, or place an unreasonable load on, the services (e.g., spam, denial of service attack, viruses, gaming algorithms); </dd>
</dl>
<h3>8. Complaints Regarding Content</h3>
<p>We respect the intellectual property rights of others. We require that information posted by users be accurate and not in violation of the intellectual property rights or other rights of third parties. </p>
<h3>9. How To Contact Us</h3>
<p>For general inquiries, you may contact us online at the contact information published on <a target="_blank" href="https://www.investium.ai">www.investium.ai</a></p>
`