import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Tooltip } from "reactstrap"
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton"
import { getFirebaseBackend } from "helpers/firebase_helper"

const args = {
  autohide: true,
  flip: true,
}

export const OnboardingTooltip = ({
  text,
  image,
  alt,
  target,
  opened,
  name,
  settings,
  placement = 'top',
  buttonText = 'Next'
}) => {
  const fireBaseBackend = getFirebaseBackend()

  const handleClick = () => {
    if(!!settings){
      const data = { onboarding: { ...settings, [name]: true }}
      fireBaseBackend.updateUser(data)
    } else {
      const data = { onboarding: { [name]: true }}
      fireBaseBackend.updateUser(data)
    }
  }

  const classes = cx('onboarding-tooltip position-fixed', {
    'd-none': !opened
  })

  return (
    <div className={classes}>
      <Tooltip
        {...args}
        isOpen={opened}
        placement={placement}
        target={target}
        popperClassName='onboarding-tooltip__body'
        innerClassName='onboarding-tooltip__content p-0 w-100 d-flex align-items-center justify-content-center background-color-white'
      >
        <div className="onboarding-tooltip__body color-black font-size-13 overflow-hidden border-radius-4">
          {!!image && <img className="onboarding-tooltip__image" src={image} alt={alt} />}

          <p className="m-0 py-2 px-3">{text}</p>

          <PrimaryButton className="onboarding-tooltip__button mb-2" text={buttonText} size="small" onClick={handleClick} />
        </div>
      </Tooltip>
    </div>
  );
}

OnboardingTooltip.propTypes = {
  text: PropTypes.string,
  image: PropTypes.string,
  alt: PropTypes.string,
  target: PropTypes.string,
  name: PropTypes.string,
  placement: PropTypes.string,
  opened: PropTypes.bool,
  settings: PropTypes.object,
  buttonText: PropTypes.string,
}
