import React from 'react'

import loader from 'assets/images/check-verde.gif'

export const  MainModalLastScreen = () => {
  return (
    <div className='main-modal-last-screen text-center'>
      <p className='main-modal-last-screen__title font-size-24 line-height-140 text-center fw-bolder'>You’re all set up!</p>

      <img className='main-modal-last-screen__loader' src={loader} alt='loader' />
    </div>
  );
}
