import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import { Avatar } from "components/Avatar/Avatar"
import { SecondaryButton } from "components/SecondaryButton/SecondaryButton"
import { Link } from "react-router-dom"
import { VerifiedSvgSmall } from "assets/icons/verifiedSmall"
import { capitalizeFirstLetter } from "helpers/capitalizeFirstLetter"

export const RecomentUser = ({
  image,
  name,
  description,
  href = "#",
  enabledMessageButton = false,
  onClick,
  isVerified,
}) => {
  const classes = cx("recomend-user p-3 d-flex", {
    "recomend-user--message-button": enabledMessageButton,
  })

  const handleClick = e => {
    e.preventDefault()
    e.stopPropagation()
    onClick()
  }

  return (
    <Link to={href} className={classes}>
      <Avatar className="recomend-user__image" size={enabledMessageButton ? "xl" : "m"} image={image} userName={name} />
      <div className="recomend-user__body">
        <div className="recomend-user__name-div mb-1">
          <h5 className="recomend-user__title font-size-16 font-weight-semibold ">{capitalizeFirstLetter(name)}</h5>
          {!!isVerified && <VerifiedSvgSmall />}
        </div>
        <p className="recomend-user__description mb-0">{description}</p>
      </div>

      {enabledMessageButton && (
        <SecondaryButton size="small" className="recomend-user__button" text="Message" onClick={e => handleClick(e)} />
      )}
    </Link>
  )
}

export const RecomentUserType = (RecomentUser.propTypes = {
  enabledMessageButton: PropTypes.bool,
  image: PropTypes.string,
  name: PropTypes.string,
  href: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
  isVerified: PropTypes.bool,
})
