import React, { useEffect, useState } from "react"
import {
  filterItemsByPoints,
  filterItemsByUserName,
  filterItemsBySize,
  filterItemsByType,
  getParams,
  isSearch,
  sortItemsByPoints,
  filterItemsByLocation,
  filterItemsByInvestorLocation,
  filterItemsByExperience
} from "helpers/search_helpers"

export const useSearchUsersResults = (users, query, settings, userId) => {
  const [ result, setResult ] = useState([])
  const newUsers = !!users && !!userId && users.filter(item => item?.username !== settings?.username)
  const isSearchEnabled = isSearch(query)

  useEffect(() => {
    if(!!newUsers && !!settings && userId){
      const params = getParams(isSearchEnabled, settings, query)

      // filter by location
      const locationResults = filterItemsByLocation (isSearchEnabled, newUsers, params)

      // filter by type
      const typeResults = filterItemsByType(isSearchEnabled, locationResults, params)

      // filter by budget
      const sizeResults = filterItemsBySize(isSearchEnabled, typeResults, params)

      // filter by investor location
      const investorLocationResults = filterItemsByInvestorLocation(isSearchEnabled, sizeResults, params)

      // filter by experience
      const experienceResults = filterItemsByExperience(isSearchEnabled, investorLocationResults, params)

      // search by name
      if(!!params.name){
        const nameResults = filterItemsByUserName(experienceResults, params)
        const sortResults = sortItemsByPoints(nameResults)

        setResult(sortResults)
      } else {
        const pointsResults = filterItemsByPoints(experienceResults, params)
        const sortResults = sortItemsByPoints(pointsResults)

        setResult(sortResults)
      }
    }
  }, [users, query, settings, userId, setResult])

  return result
}
