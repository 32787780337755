import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Link } from "react-router-dom"

import { Avatar } from "components/Avatar/Avatar"
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton"
import { capitalizeFirstLetter } from "helpers/capitalizeFirstLetter"

export const ProfileDropdownExtraInfo = ({ className, avatarSize = "s", image, name, description }) => {
  const classes = cx("profile-dropdown-extra-info mb-2", {
    [`${className}`]: className,
  })
  const bodyClasses = cx("profile-dropdown-extra-info__body mb-2 d-flex", {
    [`${className}-body`]: className,
  })
  const avatarClasses = cx("me-3", {
    [`${className}-avatar`]: className,
  })

  return (
    <div className={classes}>
      <div className={bodyClasses}>
        <Avatar size={avatarSize} image={image} className={avatarClasses} />
        <div className="profile-dropdown-extra-info__content">
          <h4 className="profile-dropdown-extra-info__name font-size-16 font-weight-semibold mb-1">
            {capitalizeFirstLetter(name)}
          </h4>
          {description && <p className="profile-dropdown-extra-info__description mb-0">{description}</p>}
        </div>
      </div>
      <Link to="/profile">
        <PrimaryButton size="small" text="View Profile" className="w-100 fw-medium" />
      </Link>
    </div>
  )
}

ProfileDropdownExtraInfo.propTypes = {
  avatarSize: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
}
