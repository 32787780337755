import React from "react"
import PropTypes from "prop-types"
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap"

import { EditButton } from "../EditButton/EditButton"
import { ExperienceSkillsSectionItem } from "../ExperienceSkillsSectionItem/ExperienceSkillsSectionItem"

export const ExperienceSkillsSection = ({
  skills,
  experience,
  isPrivate,
  onEdit,
}) => {
  const filteredSkills = skills?.join(", ")
  return (
    <Card className="experience-skills-section p-4 pb-2">
      <CardHeader className="p-0 experience-skills-section__header d-flex align-items-center justify-content-between">
        <CardTitle
          tag="h4"
          className="experience-skills-section__title font-size-18"
        >
          Experience & Skills
        </CardTitle>
        {isPrivate && (
          <EditButton
            className="experience-skills-section__edit position-relative"
            onClick={onEdit}
          />
        )}
      </CardHeader>
      <CardBody className="experience-skills-section__body p-0">
        <ExperienceSkillsSectionItem
          iconName="house-outline"
          title="Investing experience level"
          value={experience}
        />
        <ExperienceSkillsSectionItem title="Skills" iconName="skills" value={filteredSkills} />
      </CardBody>
    </Card>
  )
}

ExperienceSkillsSection.propTypes = {
  skills: PropTypes.array,
  experience: PropTypes.string,
  isPrivate: PropTypes.bool,
  onEdit: PropTypes.func,
}
