import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export const Sidebar = ({
  className,
  children,
}) => {
  const classes = cx("sidebar", className);
  return (
    <aside className={classes}>{children}</aside>
  )
}

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};