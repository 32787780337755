import React from "react"

export const DosSvg = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill="#98E38D" />
      <path
        d="M13.957 21.0101L9.20703 16.2601L10.3945 15.0726L13.957 18.6351L21.6029 10.9893L22.7904 12.1768L13.957 21.0101Z"
        fill="#1C1B1F"
      />
    </svg>
  )
}
