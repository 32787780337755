import React from 'react'
import PropTypes from 'prop-types'

export const MainModalBody = ({ children }) => {
  return (
    <div className='main-modal-body'>
      {children}
    </div>
  );
};

MainModalBody.propTypes = {
  children: PropTypes.node,
};
