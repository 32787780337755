import React from 'react'
import PropTypes from 'prop-types'

export const ModalUploadBanner = ({ text }) => {
  return (
    <div className='modal-upload-banner position-absolute d-flex align-items-center justify-content-center background-color-white'>
      <span className='color-gray-400 font-size-16'>{text}</span>
    </div>
  )
}

ModalUploadBanner.propTypes = {
  text: PropTypes.string,
}
