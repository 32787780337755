import React from "react"

export const VerifiedSvgSmall = () => {
  return (
    <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.34375 8.8075L7.875 5.135L6.98438 4.20875L4.34375 6.955L3.03125 5.59L2.14062 6.51625L4.34375 8.8075ZM5 13C3.55208 12.6209 2.35688 11.7567 1.41438 10.4078C0.471458 9.05924 0 7.56164 0 5.915V1.95L5 0L10 1.95V5.915C10 7.56164 9.52875 9.05924 8.58625 10.4078C7.64331 11.7567 6.44794 12.6209 5 13Z"
        fill="#36D93D"
      />
    </svg>
  )
}
