import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

export const Step = ({
  className, heading, description, children
}) => {
  const classes = cx('step', className)
  const classesDescription = cx('step__description color-black font-size-13 line-height-140 fw-medium color-black', `${className}-description`)

  return (
    <div className={classes}>
      <p className='step__heading font-size-13 line-height-140 fw-medium color-gray-300'>{heading}</p>

      <p className={classesDescription}>{description}</p>

      {children}
  </div>
  );
};

Step.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node
};
