import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Step } from '../../common/Step/Step'

export const DescriptionStep = ({
  value, currentTab, questionNumber, onChange
}) => {
  const [ focusedTextarea, setFocusedTextarea ] = useState(false)

  const textareaClasses = cx('w-100 mb-4 py-2 px-3 color-black border-gray font-size-13 line-height-140 border-radius-4 description-step', {
    'is-focused': focusedTextarea
  });

  return (
    <Step
      className={cx({ 'd-block': currentTab === 8, 'd-none': currentTab !== 8, })}
      heading='Description'
      description='Please create a short description of yourself or use the one we created for you.'
    >
      <textarea
        placeholder='Write here...'
        className={textareaClasses}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onFocus={()=> setFocusedTextarea(true)}
        onBlur={() => setFocusedTextarea(false)}
      />
    </Step>
  );
}

DescriptionStep.propTypes = {
  currentTab: PropTypes.number,
  value: PropTypes.string,
  questionNumber: PropTypes.string,
  onChange: PropTypes.func,
};
