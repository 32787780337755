import React, { useEffect, useState } from "react"
import {
  filterItemsByName,
  filterItemsBySize,
  filterItemsByType,
  getParams,
  sortItemsByPoints,
  filterItemsByLocation,
  getGeoCode,
} from "helpers/search_helpers"

export const useSearchQueryRoomsResults = (rooms, query, settings, userId) => {

  const [result, setResult] = useState([])
  const newRooms =
    !!rooms && !!userId && rooms.filter(item => item.admin_user !== userId && !item.users.includes(userId))

  const onChange = coordinates => {
    const params = getParams(true, settings, query)

    // filter by location
    const locationResults = coordinates?.coordinates ? filterItemsByLocation(true, newRooms, coordinates) : newRooms

    // filter by type
    const typeResults = filterItemsByType(true, locationResults, params)

    // filter by budget

    const sizeResults = filterItemsBySize(true, typeResults, params)

    // search by name
    const nameResults = filterItemsByName(sizeResults, params)
    // const sortResults = sortItemsByPoints(nameResults)

    setResult(nameResults)
  }

  useEffect(async () => {
    if (!!rooms && !!rooms.length && !!settings && userId) {
      if (!!query) {
        if (query?.locations?.length === 0) {
          onChange({ coordinates: null })
        } else {
          getGeoCode(query?.locations, onChange)
        }
      } else {
        onChange({ coordinates: null })
      }
    }
  }, [rooms, query, settings, userId, setResult])

  return result
}
