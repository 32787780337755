import { useEffect } from "react"

export const useDescription = (role, experience, location, types, currentTab, steps, setDescription) => {
  useEffect(() => {
    const curRole = !!role ? role : "person"
    const prefix = curRole === "Investor" ? "An" : "A"
    const curExperience = !!experience ? experience : ""
    const type = types.length === 1 ? `${types[0]} ` : types.length > 1 ? `${types.join(", ")} ` : ""

    const noneExperienced = curExperience === "None" ? "with no investing experience " : ""
    const experienced = curExperience === "Seasoned" ? "with expert investing experience " : ""
    const someExperienced = curExperience === "Some" ? "with some investing experience " : ""
    const updatedRole = curRole === "Other" ? "person" : curRole.toLowerCase()
    const updatedLocation = !!location ? `from ${location?.split(",")[0]} ` : ""

    if (currentTab !== steps && currentTab !== 0) {
      setDescription(
        `${prefix} ${updatedRole} ${updatedLocation}${noneExperienced}${experienced}${someExperienced}interested in ${type}real estate`
      )
    }
  }, [currentTab])
}
