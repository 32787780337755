import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Icon } from 'components/Icon/Icon'
import { PlacesAutocomplete } from 'components/PlacesAutocomplete/PlacesAutocomplete'
import { LocationPill } from './components/LocationPill/LoctionPill'

export const Location = ({
  items, onClick
}) => {
  const [ focused, setFocused ] = useState(false)

  const handleChange = address => {
    onClick(address?.coordinates)
  }

  const handleIconClick = coordinates => {
    onClick(coordinates)
  }

  const wrapperClasses = cx('location__input-wrapper position-relative', {
    'is-focused': focused
  })

  return (
    <div className='location'>
      <div className={wrapperClasses}>
        <Icon name='place' className='location__icon color-gray-300 position-absolute' />

        <PlacesAutocomplete
          onChange={handleChange}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          />
      </div>

      {items.length !== 0 && (
        <div className='location__pill-wrapper d-flex'>
          <span className='location__text fw-medium line-height-140 color-gray-400 font-size-13 d-inline-flex align-items-center'>Your locations</span>

          <ul className='location__list p-0 d-flex flex-wrap list-unstyled'>
            {items.map((item, index) => {
              return (
                <LocationPill
                  key={index}
                  className='location__list-item'
                  address={item}
                  onClick={handleIconClick}
                />
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}

Location.propTypes = {
  items: PropTypes.array,
  onClick: PropTypes.func,
}
