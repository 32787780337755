import React, { useEffect, useState } from "react"
import { Dropdown, DropdownToggle, Modal, ModalBody, ModalHeader } from "reactstrap"
import cx from "classnames"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import "firebase/compat/firestore"

import { showAddMemberModalAction } from "store/actions"
import { CloseModalButton } from "components/CloseModalButton/CloseModalButton"
import { InputField } from "components/InputField/InputField"
import { getFullName } from "components/Profile/Profile.helpers"

import { ChatModalFooter } from "./components/ChatModalFooter/ChatModalFooter"
import { ChatModalList } from "./components/ChatModalList/ChatModalList"
import { capitalizeFirstLetter } from "helpers/capitalizeFirstLetter"

export const ChatModal = ({ isDisabled, foundUsers, onAdd, onFind }) => {
  const dispatch = useDispatch()

  const isOpen = useSelector(state => state.Layout.showAddMemberModal)

  const [value, setValue] = useState("")
  const [findUser, setFindUser] = useState(null)
  const [sendedInvitation, setSendedInvitation] = useState(false)

  const handleOnToggle = (isActive = false) => {
    dispatch(showAddMemberModalAction(isActive))

    setValue("")
    setFindUser(null)
    setSendedInvitation(false)
  }

  const handleChange = event => {
    setValue(event.target.value)
  }

  useEffect(() => {
    onFind(value)
  }, [value])

  const handleClickResult = result => {
    setFindUser(result)
    setValue("")
  }

  const handleFindUserClick = () => {
    setFindUser(null)
  }

  const handleClick = () => {
    onAdd(findUser.uid, findUser.firstName)
    setSendedInvitation(true)
    setTimeout(handleOnToggle, 2500)
  }

  const iconClasses = cx("d-flex align-items-center justify-content-center  btn nav-btn chat-main-header__icon", {
    "is-disabled": isDisabled,
  })

  const name = !!findUser ? getFullName(findUser.firstName, findUser.lastName) : ""

  return (
    <>
      <Dropdown disabled={isDisabled} toggle={() => handleOnToggle(true)}>
        <DropdownToggle className={iconClasses} tag="i">
          <i className="bx bx-plus font-size-18" />
        </DropdownToggle>
      </Dropdown>

      <Modal className="chat-modal" isOpen={isOpen} centered={true} toggle={() => handleOnToggle(false)}>
        <ModalHeader
          className="chat-modal__modal-header p-4"
          close={<CloseModalButton onClick={() => handleOnToggle(false)} />}
        >
          <span className="font-size-18 chat-modal__title">Add people</span>
        </ModalHeader>

        <ModalBody className="p-4 pt-0">
          <div className="position-relative chat-modal__input-wrapper">
            {findUser ? (
              <div className="chat-modal__mask">
                <button className="d-flex border-0 chat-modal__pill">
                  {capitalizeFirstLetter(name)}
                  <i className="mdi mdi-close chat-modal__pill-icon" onClick={handleFindUserClick} />
                </button>
              </div>
            ) : (
              <InputField
                className="chat-modal__input"
                placeholder="Email or name"
                value={value}
                onChange={handleChange}
              />
            )}

            {foundUsers.length > 0 && <ChatModalList results={foundUsers} onClick={handleClickResult} />}
          </div>
        </ModalBody>

        <ChatModalFooter isDisabled={!findUser} isSended={sendedInvitation} onClick={handleClick} />
      </Modal>
    </>
  )
}

ChatModal.propTypes = {
  userSettings: PropTypes.object,
  isDisabled: PropTypes.bool,
  foundUsers: PropTypes.array,
  onAdd: PropTypes.func,
  onFind: PropTypes.func,
  onInvite: PropTypes.func,
}
