import React, { useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import SimpleBar from "simplebar-react"
import { Link } from "react-router-dom"

import { Avatar } from "components/Avatar/Avatar"
import { getFullName } from "components/Profile/Profile.helpers"
import { capitalizeFirstLetter } from "helpers/capitalizeFirstLetter"

export const ChatMembers = ({ addButtonOff, users = [], currentUserId, onClick }) => {
  const [opened, setOpened] = useState(true)

  const classes = cx("position-relative chat-members", {
    "is-opened": opened,
  })

  const handleOpen = () => {
    setOpened(active => !active)
  }

  return (
    <div className={classes}>
      <button className="font-size-16 chat-members__heading" onClick={handleOpen}>
        Members
        <i className="mdi mdi-chevron-down chat-members__heading-icon" />
      </button>

      <div className="position-relative chat-members__body">
        {users.length > 0 && (
          <SimpleBar className="chat-members__scroll">
            <div className="list-group list-group-flush chat-members__list">
              {users.map(user => {
                const name = getFullName(user.firstName, user.lastName)
                const link = user.id === currentUserId ? "profile" : `profile/${user.username}`
                return (
                  <Link to={link} key={user.username} className="chat-members__list-item">
                    <Avatar image={user.profile_image} size="xs" className="chat-members__avatar" userName={name} />

                    <div className="chat-members__info">
                      <span className="chat-members__name">{capitalizeFirstLetter(name)}</span>
                    </div>
                  </Link>
                )
              })}
            </div>
          </SimpleBar>
        )}

        {!addButtonOff && (
          <button className="chat-members__add-button" onClick={onClick}>
            <i className="bx bx-plus chat-members__add-button-icon" />
            Add people
          </button>
        )}
      </div>
    </div>
  )
}

ChatMembers.propTypes = {
  addButtonOff: PropTypes.bool,
  users: PropTypes.array,
  currentUserId: PropTypes.string,
  onClick: PropTypes.func,
}
