import React from 'react';
import PropTypes from "prop-types"

import { Icon } from 'components/Icon/Icon';

export const LookingForLegend = ({
  iconName,
  legend,
}) => {
  return (
    <div className="looking-for-legend me-2">
      <Icon className="looking-for-legend__icon" name={iconName} width={24} height={24} />
      {legend}
    </div>
  )
}

LookingForLegend.propTypes = {
  iconName: PropTypes.string,
  legend: PropTypes.oneOfType([
    PropTypes.array, PropTypes.string,
  ]),
}