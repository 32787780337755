import React from "react"
import PropTypes from "prop-types"

import { SecondaryButton } from "components/SecondaryButton/SecondaryButton"

export const ActivityInvite = ({ onAcceptClick, onDenyClick }) => {
  return (
    <div className="activity-invite">
      <a
        className="activity-invite__accept d-inline-flex button primary-button button--small activity-invite__accept btn btn-secondary"
        onClick={onAcceptClick}
      >
        Accept
      </a>

      <SecondaryButton
        size="small"
        className="activity-invite__deny"
        text="Deny"
        onClick={onDenyClick}
      />
    </div>
  )
}

ActivityInvite.propTypes = {
  onDenyClick: PropTypes.func,
  onAcceptClick: PropTypes.func,
}
