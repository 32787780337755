import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

export const NotificationsBlock = ({ title, isNew = false, children }) => {
  const classes = cx("notifications-block", {
    "is-new": isNew,
  })
  return (
    <div className={classes}>
      <span className="notifications-block__title">{title}</span>

      <ul className="list-group list-group-flush notifications-block__list">
        {children}
      </ul>
    </div>
  )
}

NotificationsBlock.propTypes = {
  children: PropTypes.object,
  isNew: PropTypes.bool,
  title: PropTypes.string,
}
