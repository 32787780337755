import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import Slider from "react-slick"
import firebase from "firebase/compat/app"
import "firebase/compat/storage"
import { useDispatch } from "react-redux"
import cx from "classnames"

import { registerRoom, openedRoomAction, setActiveCategory } from "store/actions"
import { CardBlock } from "components/CardBlock/CardBlock"
import { RecomentUser } from "components/RecomendUser/RecomendUser"
import { getFullName } from "components/Profile/Profile.helpers"

export const RecommendedUsersSlider = ({
  options,
  id,
  history,
  isShownOnDesktop,
  title = "Users based on your preferences",
  text = "Browse existing private groups",
}) => {
  const dispatch = useDispatch()
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1.1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1.5,
        },
      },
      {
        breakpoint: 744,
        settings: {
          slidesToShow: 2.25,
        },
      },
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 2.76,
        },
      },
    ],
  }

  const slider = React.useRef(null)

  const [isPrevShown, setIsPrevShown] = useState(false)

  const handleClick = useCallback(user => {
    firebase
      .firestore()
      .collection("rooms")
      .where("admin_user", "in", [user.id, id])
      .get()
      .then(res => {
        const list = res.docs.filter(item => {
          const data = item.data()
          return data.status === "chat"
        })

        const chat = list.find(item => {
          const data = item.data()
          return data.users.includes(user.id) || data.users.includes(id)
        })

        if (!!chat) {
          dispatch(openedRoomAction(chat.id))
          dispatch(setActiveCategory("chats"))
          history.push("/messages")
        } else {
          const time = Date.now()
          const roomSettings = {
            tasks: [],
            admin_user: id,
            links: [],
            users: [user.id],
            status: "chat",
            chat: null,
            createdAt: time,
          }
          dispatch(registerRoom(roomSettings, []))

          setTimeout(() => {
            firebase
              .firestore()
              .collection("rooms")
              .where("admin_user", "in", [user.id, id])
              .get()
              .then(res => {
                res.docs.find(item => {
                  if (item.data().createdAt === time) {
                    dispatch(openedRoomAction(item.id))
                    dispatch(setActiveCategory("chats"))

                    setTimeout(() => {
                      history.push("/messages")
                    }, 1000)
                  }
                })
              })
          }, 1000)
        }
      })
  })

  const handleAfterChange = useCallback(
    index => {
      setIsPrevShown(index > 0)
    },
    [isPrevShown]
  )

  const className = cx("recommended-users-slider", {
    "is-shown-on-desktop": isShownOnDesktop,
  })

  useEffect(() => {
    setTimeout(() => {
      const sliderTracks = document.querySelectorAll(".slick-track")
      if (sliderTracks.length) {
        sliderTracks.forEach(item => {
          if (item) {
            item.style.transform = "translate3d(0px, 0px, 0px)"
          }
        })
      }
    }, 500)
  }, [])

  return (
    <CardBlock className={className} title={title} text={text}>
      <div className="overflow-hidden recommended-users-slider__slider-wrapper">
        <div className="position-relative recommended-users-slider__slider">
          {isPrevShown && (
            <button
              className="border-0 rounded-circle position-absolute recommended-users-slider__slider-arrow recommended-users-slider__slider-arrow-prev"
              onClick={() => slider?.current?.slickPrev()}
            />
          )}

          <button
            className="border-0 rounded-circle position-absolute recommended-users-slider__slider-arrow"
            onClick={() => slider?.current?.slickNext()}
          />

          <Slider ref={slider} {...settings} afterChange={handleAfterChange}>
            {options?.map(user => {
              const name = getFullName(user.firstName, user.lastName)
              const href = user.username ? `/profile/${user.username}` : "#"

              return (
                <RecomentUser
                  isVerified={user.isVerified}
                  enabledMessageButton={true}
                  key={user.id}
                  name={name}
                  image={user.profile_image}
                  description={user.bio}
                  href={href}
                  onClick={() => handleClick(user)}
                />
              )
            })}
          </Slider>
        </div>
      </div>
    </CardBlock>
  )
}

RecommendedUsersSlider.propTypes = {
  options: PropTypes.array,
  id: PropTypes.string,
  history: PropTypes.object,
  isShownOnDesktop: PropTypes.bool,
  title: PropTypes.string | PropTypes.node,
  text: PropTypes.string,
}
