import { Link } from "react-router-dom"
import React, { useMemo } from "react"
import PropTypes from "prop-types"

import { CardBlock } from "components/CardBlock/CardBlock"
import { useNotifications } from "pages/Notifications/hooks/useNotifications"

const defaultRenderedActivities = 5

export const Activity = ({ user, member, history }) => {
  const { getAllNotifcations } = useNotifications(user, member, true, history)

  const cuttedNotifications = useMemo(() => {
    return getAllNotifcations.slice(0, defaultRenderedActivities)
  }, [getAllNotifcations])

  return (
    <CardBlock className="activity" title="Your activity" text="Recent messages, requests and activity">
      <Link
        to="/notifications"
        className="activity__button button secondary-button button--small position-absolute activity__button btn btn-secondary"
      >
        View all activity
      </Link>

      <ul className="list-group list-group-flush activity__list">{cuttedNotifications}</ul>
    </CardBlock>
  )
}

Activity.propTypes = {
  user: PropTypes.object,
  member: PropTypes.object,
  history: PropTypes.object,
  onClick: PropTypes.func,
}
