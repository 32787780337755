import React, { useState } from "react"
import PropTypes from "prop-types"
import { Modal, Nav, TabContent, TabPane, } from "reactstrap"
import { showAttachmentsModalAction } from "store/actions";
import { useDispatch, useSelector } from "react-redux"

import { getFirebaseBackend } from "helpers/firebase_helper";
import { getNotificationEntity } from "pages/Notifications/helpers/notifcations.helpers";

import { ChatRightNavLink } from "../ChatRightSidebar/components/ChatRightNavLink/ChatRightNavLink";
import { ChatRightTabPane } from "../ChatRightSidebar/components/ChatRightTabPane/ChatRightTabPane";
import { ChatRightTabPaneLinks } from "../ChatRightSidebar/components/ChatRightTabPaneLinks/ChatRightTabPaneLinks";
import { ChatTasks } from "../ChatRightSidebar/components/ChatTasks/ChatTasks";

const navTabs = [
  { id: "1", name: "Files" },
  { id: "2", name: "Links" },
  { id: "3", name: "Tasks" },
];

export const AttachmentsModal = ({
  isOpened,
  room,
  onUpdateTasks,
  fileIsUploaded,
  users,
}) => {
  const dispatch = useDispatch();

  const [activeTab, setactiveTab] = useState("1")

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  const onToggle = () => {
    dispatch(showAttachmentsModalAction(false))
  };

  const fireBaseBackend = getFirebaseBackend()

  const currentUser = useSelector(state => state.Account.user)

  const handleAddTask = name => {
    onUpdateTasks([...room.tasks, { name: name, completed: "active" }])


    const ids = [room.admin_user, ...room.users];
    const notifyUsersList = users.filter(user => {
      return user.id !== currentUser.uid && ids.includes(user.id)
    })

    notifyUsersList.forEach(user => {
      const firstName = user?.firstName;

      fireBaseBackend.updateUserNotificationById(
        user.id,
        getNotificationEntity("group-new-activity", {
          room,
          roomName: room.name,
          member: user,
          activityType: "task",
        })
      )
      fireBaseBackend.asyncNotifyUserByEmail(
        user.id,
        `Your group ${room.name} has new activity!`,
        `Dear ${firstName},
          Group ${room.name} has new activity. Click below to login and join the conversation.`,
        'Read messages',
        `${window.location.href}?room=${room.id}`
      )
    })
  }

  const handleChangeTask = name => {
    const newTasks = room.tasks.map(task => {
      if (task.name === name) {
        return {
          name: task.name,
          completed: task.completed === "closed" ? "active" : "closed",
        }
      }
      return task
    })
    onUpdateTasks(newTasks)
  }


  return (
    <Modal
      className="attachments-modal"
      contentClassName="attachments-modal__content"
      centered={true}
      fade={false}
      isOpen={isOpened}
      toggle={onToggle}
    >
      <Nav justified>
        {navTabs.map((tab) => {
          return (
            <ChatRightNavLink
              key={tab.id}
              text={tab.name}
              active={activeTab === tab.id}
              onClick={() => {toggleTab(tab.id)}}
            />
          );
          })}
      </Nav>

      <TabContent activeTab={activeTab} className="attachments-modal__tab-content">
        <ChatRightTabPane
          tabId={navTabs[0].id}
          options={!!room?.files ? room.files : []}
          fileIsUploaded={fileIsUploaded}
        />

        <ChatRightTabPaneLinks
          tabId={navTabs[1].id}
          options={!!room?.links ? room.links : []}
        />

        <TabPane tabId={navTabs[2].id}>
          <ChatTasks
            addButtonOff={room?.status === "archived"}
            tasks={!!room?.tasks ? room.tasks : []}
            onClick={handleChangeTask}
            onAdd={handleAddTask}
          />
        </TabPane>
      </TabContent>
    </Modal>
  )
}

AttachmentsModal.propTypes = {
  isOpened: PropTypes.bool,
  room: PropTypes.object,
  fileIsUploaded: PropTypes.bool,
  users: PropTypes.array,
  onUpdateTasks: PropTypes.func,
}