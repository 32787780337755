import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { InputField } from 'components/InputField/InputField'
import { ModalUpload } from 'modals/components/common/ModalUpload/ModalUpload'

import { Step } from '../../common/Step/Step'

export const FormStep = ({
  currentTab,
  questionNumber = '3',
  data,
  onChange
}) => {
  const [ valueName, setValueName ] = useState(!!data?.name ? data?.name : "")
  const [ valueTextarea, setValueTextarea ] = useState(!!data?.description ? data?.description : "")
  const [ focusedTextarea, setFocusedTextarea ] = useState(false)
  const [ loader, setLoader ] = useState(false)

  const handleChangeName = (e) => {
    const value = e.target.value
    setValueName(value)
    onChange({
      ...data,
      name: value
    })
  }

  const handleChangeTextarea = (e) => {
    setValueTextarea(e.target.value)
  }

  const textareaClasses = cx("w-100 form-step__textarea", {
    "is-focused": focusedTextarea
  });

  const handleChange = (key, value) => {
    onChange({
      ...data,
      [key]: value
    })
  }

  const fileInputChange = async file => {
    if(!!file){
      handleChange("cover_image", file)
    }
  }

  return (
    <div className={cx({ 'd-block': currentTab === 4, 'd-none': currentTab !== 4, })}>
      <Step
        heading='Title'
        description='Name of the room'
        className='form-step'
      >
        <InputField
          type="text"
          placeholder="Title of room"
          className="form-step__input"
          value={valueName}
          onChange={handleChangeName}
          onFocus={()=> {}}
          onBlur={() => handleChange("name", valueName)}
        />
      </Step>

      <Step
        heading='Description'
        description='Provide a brief description of the group'
        className='form-step'
      >
        <textarea
          placeholder="Brief description of the group"
          className={textareaClasses}
          value={valueTextarea}
          onChange={handleChangeTextarea}
          onFocus={()=> setFocusedTextarea(true)}
          onBlur={() => {
            setFocusedTextarea(false);
            handleChange("description", valueTextarea)
          }}
        />
      </Step>

      <Step
        heading='Photo'
        description='Upload a cover photo'
        className='mb-4 form-step'
      >
        <ModalUpload
          files={!!data.cover_image ? [data.cover_image] : []}
          loader={loader}
          onUpload={fileInputChange}
        />
      </Step>
    </div>
  )
}

FormStep.propTypes = {
  currentTab: PropTypes.number,
  questionNumber: PropTypes.string,
  data: PropTypes.object,
  onChange: PropTypes.func
}
