import React from "react"
import PropTypes from "prop-types"

export const ProfilePhoneItem = ({ tel }) => {
  const telHref = `tel:${tel}`
  return (
    <div className="profile-phone-item">
      <h6 className="profile-phone-item__title font-size-16 font-weight-semibold mb-1">
        Phone
      </h6>
      <a className="profile-phone-item__link font-size-13" href={telHref}>
        {tel || '—'}
      </a>
    </div>
  )
}

ProfilePhoneItem.propTypes = {
  tel: PropTypes.string,
}
