import PropTypes from "prop-types"
import React, { forwardRef, useState } from "react"
import { Input } from "reactstrap"
import cx from "classnames"

export const InputField = forwardRef(
  (
    {
      className,
      invalid,
      error,
      inputName,
      type,
      placeholder,
      value,
      disabled,
      autoFocus,
      id,
      onBlur = () => {},
      onFocus = () => {},
      onChange,
      onKeyPress = () => {},
    },
    ref
  ) => {
    const [focused, setFocused] = useState(false)

    const handleFocus = () => {
      onFocus()
      setFocused(true)
    }

    const handleBlur = e => {
      onBlur(e)
      setFocused(false)
    }

    const classes = cx("form-control input-field__input", {
      "is-focused": focused,
      [`${className}-input`]: !!className,
    })

    return (
      <div className={cx("input-field", className)}>
        <Input
          name={inputName}
          id={id}
          innerRef={ref}
          className={classes}
          placeholder={placeholder}
          type={type}
          onChange={onChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          value={value}
          invalid={invalid}
          disabled={disabled}
          autoFocus={autoFocus}
          onKeyPress={onKeyPress}
        />

        {!!error && error}
      </div>
    )
  }
)

InputField.displayName = "InputField"

InputField.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.node,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  inputName: PropTypes.string,
  value: PropTypes.string,
  autoFocus: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
}
