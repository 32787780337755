import firebase from "firebase/compat/app"
import { getAnalytics } from "firebase/analytics"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/functions"
import "firebase/compat/database"
import "firebase/compat/storage"
import "firebase/compat/analytics"
import { getNotificationEntity } from "pages/Notifications/helpers/notifcations.helpers"
import { getFullName } from "components/Profile/Profile.helpers"
import { capitalizeFirstLetter } from "./capitalizeFirstLetter"

const passwordResponse = "auth/wrong-password"

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      const app = firebase.initializeApp(firebaseConfig)
      getAnalytics(app)

      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          localStorage.setItem("authUser", JSON.stringify(user))
          this.userStatus(user.uid)
        } else {
          localStorage.removeItem("authUser")
        }
      })
    }
  }

  userStatus = uid => {
    const userStatusDatabaseRef = firebase.database().ref("/status/" + uid)

    const isOfflineForDatabase = {
      state: "offline",
      last_changed: firebase.database.ServerValue.TIMESTAMP,
    }

    const isOnlineForDatabase = {
      state: "online",
      last_changed: firebase.database.ServerValue.TIMESTAMP,
    }

    firebase
      .database()
      .ref(".info/connected")
      .on("value", () => {
        userStatusDatabaseRef
          .onDisconnect()
          .set(isOfflineForDatabase)
          .then(() => {
            userStatusDatabaseRef.set(isOnlineForDatabase)
          })
      })
  }

  /**
   * Registers the user with given details
   */
  registerUser = (email, firstName, lastName, password, phone) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          async () => {
            const collection = firebase.firestore().collection("users")

            const username = await getNonExistUsername(collection)

            collection.doc(firebase.auth().currentUser.uid).set({
              username: username.toString(),
              firstName: firstName.toLowerCase(),
              lastName: lastName.toLowerCase(),
              email,
              profile_image_notified: false,
              phone: !!phone ? phone : "",
            })

            this.registrationSuccessEmail(email, firstName.toLowerCase(), lastName.toLowerCase())

            this.completeProfileReminder(email)
            resolve(firebase.auth().currentUser)
          },
          error => {
            reject(this._handleError(error))
          }
        )
    })
  }

  /**
   * validate the uniqueness of username
   */
  validationUsername = async username => {
    const collection = firebase.firestore().collection("users")
    // check within the collection if a doc with the same username exists
    const doc = await collection.where("username", "==", username).get()

    if (doc.empty) {
      // if no doc exists, then the username is valid
      return true
    } else {
      // if a doc exists, then the username is invalid
      return false
    }
  }

  /**
   * Registers the user with given details
   */
  editProfileAPI = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser)
          },
          error => {
            reject(this._handleError(error))
          }
        )
    })
  }

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser)
          },
          error => {
            reject(this._handleError(error))
          }
        )
    })
  }

  /**
   * forget Password user with given details
   */
  forgetPassword = email => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url: window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          resolve(true)
        })
        .catch(error => {
          reject(this._handleError(error))
        })
    })
  }

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true)
        })
        .catch(error => {
          reject(this._handleError(error))
        })
    })
  }

  /**
   * Social Login user with given details
   */
  socialLoginUser = (data, type) => {
    let credential = {}
    if (type === "google") {
      credential = firebase.auth.GoogleAuthProvider.credential(data.idToken, data.token)
    } else if (type === "facebook") {
      credential = firebase.auth.FacebookAuthProvider.credential(data.token)
    }
    return new Promise((resolve, reject) => {
      if (!!credential) {
        firebase
          .auth()
          .signInWithCredential(credential)
          .then(async user => {
            const email = user.user.email
            const collection = firebase.firestore().collection("users")

            const isUserExist = await collection.where("email", "==", email).get()

            if (isUserExist.empty) {
              const username = await getNonExistUsername(collection)
              const firstName = user.user.displayName.split(" ")[0].toLocaleLowerCase()
              const lastName = user.user.displayName.split(" ")[1].toLocaleLowerCase()

              const settings = {
                username: username.toString(),
                firstName,
                lastName,
                email,
              }
              collection.doc(user.user.uid).set(settings)
              this.registrationSuccessEmail(email, firstName, lastName)
            }

            resolve({
              user: user.user,
              isFirstLogin: isUserExist.empty,
            })
          })
          .catch(error => {
            reject(this._handleError(error))
          })
      } else {
        reject(this._handleError(error))
      }
    })
  }

  updateUser = data => {
    const user = this.getAuthenticatedUser()

    const collections = firebase.firestore().collection("users").doc(user.uid)

    collections.set(data, { merge: true })
  }

  updateUserNotificationById = async (id, notifications) => {
    const collection = firebase.firestore().collection("users").doc(id)
    const userSettings = await collection.get()
    const availableNotifications = userSettings.data()?.notifications

    collection.update({
      notifications:
        !!availableNotifications && availableNotifications.length
          ? [...availableNotifications, notifications]
          : [notifications],
    })
  }

  updateUserNotificationReadStatus = async (id, userId) => {
    if (!!userId) {
      const collection = firebase.firestore().collection("users").doc(userId)
      const userData = await collection.get()
      const userNotifications = userData.data()

      if (userNotifications.notifications.length) {
        const newNotifications = userNotifications.notifications.map(notification =>
          notification.id === id ? { ...notification, read: true } : notification
        )

        collection.update({
          notifications: newNotifications,
        })
      }
    }
  }

  removeUserNotification = async (id, userId) => {
    const collection = firebase.firestore().collection("users").doc(userId)
    const userData = await collection.get()
    const userNotifications = userData.data().notifications.filter(notification => notification.id !== id)

    collection.update({
      notifications: userNotifications,
    })
  }

  getCurrentUser = async () => {
    if (!localStorage.getItem("authUser")) return null

    const { uid } = this.getAuthenticatedUser()
    const collection = firebase.firestore().collection("users")
    const userDataDoc = collection.doc(uid)
    return userDataDoc
  }

  getUsersSettings = async ids => {
    const users = []
    const collection = firebase.firestore().collection("users")
    for (const id of ids) {
      const userDataDoc = collection.doc(id)
      const userData = await userDataDoc.get()
      users.push(userData.data())
    }

    return users
  }

  getUserSettings = async id => {
    const user = await firebase.firestore().collection("users").doc(id).get()

    return user.data()
  }

  getUsersByQuery = (query, currentUserId) => {
    return new Promise(resolve => {
      const users = []

      const updateSearchResults = doc => {
        const isNotDuplicate = !users.find(user => user.uid === doc.id)
        if (currentUserId !== doc.id && isNotDuplicate) {
          users.push({ ...doc.data(), uid: doc.id })
        }
      }

      ;["firstName", "lastName", "email"].forEach(child => {
        firebase
          .firestore()
          .collection("users")
          .orderBy(child)
          .startAt(query.toLowerCase())
          .endAt(query.toLowerCase() + "\uf8ff")
          .get()
          .then(res => {
            res.forEach(doc => updateSearchResults(doc))

            if (child === "email") {
              resolve(users)
            }
          })
      })
    })
  }

  addUserToPendingRoom = async (roomId, userId) => {
    const collection = firebase.firestore().collection("rooms").doc(roomId)
    const availablePending = await collection.get().pending_users

    collection.update({
      pending_users: !!availablePending ? [...availablePending, userId] : [userId],
    })
  }

  addUserToRoom = async (user, member, room, notifyUserId, name) => {
    const collection = firebase.firestore().collection("rooms")

    collection.doc(room.id).update({
      ...room,
      pending_users:
        !!room.pending_users && room.pending_users.length ? [...room.pending_users, notifyUserId] : [notifyUserId],
    })

    const usersCollection = firebase.firestore().collection("users")
    const [notifyUserSettings] = await this.getUsersSettings([notifyUserId])

    usersCollection.doc(notifyUserId).update({
      ...notifyUserSettings,
      notifications:
        !!notifyUserSettings.notifications && notifyUserSettings.notifications.length
          ? [
              ...notifyUserSettings.notifications,
              getNotificationEntity("have-new-request-to-join", {
                member,
                room,
                user,
              }),
            ]
          : [
              getNotificationEntity("have-new-request-to-join", {
                member,
                room,
                user,
              }),
            ],
    })

    this.notifyUserByEmail(
      notifyUserSettings.email,
      "You have received a new request!",
      `Dear ${capitalizeFirstLetter(name)},
        You have recevied an invitation to join group ${room.name}. Click below to find out more.`,
      notifyUserSettings.status,
      "View request",
      `${window.location.origin}/messages?accept_room=${room.id}`
    )
  }

  addUserToRoomById = async (userId, roomId) => {
    const collection = firebase.firestore().collection("rooms")

    const room = collection.doc(roomId)
    const roomData = await room.get()
    const data = roomData.data()

    collection.doc(roomId).update({
      ...data,
      users: [...data.users, userId],
    })
  }

  notifyUserByEmail = (email, subject, body, status, actionText, actionLink) => {
    if (status === "offline") {
      const onEmailNotifications = firebase.functions().httpsCallable("onEmailNotifications")

      onEmailNotifications({
        email,
        subject,
        body,
        actionLink,
        actionText,
      })
    }
  }

  setAllNotificationsToRead = async id => {
    const collection = firebase.firestore().collection("users").doc(id)
    const userData = await collection.get()
    const data = userData.data()
    const readedNotifications = data?.notifications?.map(notification => ({
      ...notification,
      read: true,
    }))

    if (readedNotifications) {
      collection.update({
        notifications: readedNotifications,
      })
    }
  }

  asyncNotifyUserByEmail = async (id, subject, body, actionText, actionLink) => {
    const collection = firebase.firestore().collection("users").doc(id)

    const userData = await collection.get()
    const data = userData.data()

    this.notifyUserByEmail(data.email, subject, body, data.status, actionText, actionLink)
  }

  notifyAdminEmailSupport = (email, firstName, lastName, description) => {
    const supportEmailAdmin = firebase.functions().httpsCallable("supportEmailAdmin")

    supportEmailAdmin({
      email,
      firstName,
      lastName,
      description,
    })
  }

  notifyRoomAdmin = async (id, userName, roomName) => {
    const collection = firebase.firestore().collection("users").doc(id)

    const roomAdminData = await collection.get()
    const data = roomAdminData.data()

    this.notifyUserByEmail(
      data.email,
      `You have received a new request!`,
      `Dear ${data.firstName},
        ${userName} requested to join group ${roomName}. Click below to accept or decline the request.`,
      data.status,
      "View request",
      `${window.location.origin}/notifications`
    )
  }

  acceptUserToRoom = async (id, userId, notificationId, targetUserId, accept = false, byAdmin) => {
    const room = firebase.firestore().collection("rooms").doc(id)
    const roomData = await room.get()
    const existPendingUsers = roomData.data()?.pending_users.filter(user => {
      const searchUserId = byAdmin ? targetUserId : userId
      return user !== searchUserId
    })

    const collection = firebase.firestore().collection("users").doc(userId)
    const userSettings = await collection.get()
    const userData = userSettings.data()

    const notifications = userData?.notifications.map(notification =>
      notification.id === notificationId
        ? {
            ...notification,
            accepted: accept,
            decline: !accept,
            read: true,
          }
        : notification
    )

    collection.update({
      rooms: !!userData?.rooms && userData?.rooms.length ? [...userData.rooms, id] : [id],
      notifications,
    })

    const targetUser = firebase.firestore().collection("users").doc(targetUserId)
    const targetUserSettings = await targetUser.get()
    const targetUserData = targetUserSettings.data()

    this.notifyUserByEmail(
      targetUserData.email,
      accept
        ? `Your request to join group ${roomData.name} has been accepted!`
        : `Your request to join group ${roomData.name} has been declined!`,
      accept
        ? `Dear ${targetUserData.firstName},
          Congratulations, your request to join group ${roomData.name} has been accepted. Click below to login and join the conversation.`
        : `Dear ${targetUserData.firstName},
          We are sorry to inform you that your request to join group ${roomData.name} has been declined. Not to worry, there are many other groups for you. Click below to login and keep exploring.`,
      targetUserData.status,
      accept ? "View group" : "Explore groups",
      `${window.location.origin}/notifications`
    )

    if (accept) {
      const existUsers = roomData.data().users
      room.update({
        pending_users: existPendingUsers,
        users: [...existUsers, byAdmin ? targetUserId : userId],
      })
      return
    }

    room.update({
      pending_users: existPendingUsers,
    })
  }

  inviteUserToRoomsByUsername = (username, newRooms) => {
    return new Promise(resolve => {
      const collection = firebase.firestore().collection("users")
      collection
        .where("username", "==", username)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(async doc => {
            newRooms.forEach(item => this.addUserToRoomById(doc.id, item))

            const { rooms } = doc.data()
            collection.doc(doc.id).update({
              rooms: [...rooms, ...newRooms],
            })

            const currentUser = await collection.doc(doc.id).get()
            resolve(currentUser.data())
          })
        })
    })
  }

  deleteFiles = id => {
    const storageRef = firebase.storage().ref(`files/${id}`)
    storageRef.listAll().then(listResults => {
      const promises = listResults.items.map(item => {
        return item.delete()
      })
      Promise.all(promises)
    })
  }

  deleteRoom = (room, existRooms) => {
    const collection = firebase.firestore().collection("rooms")
    collection.doc(room.id).delete(room.id)

    this.updateUser({
      rooms: existRooms.filter(id => id !== room.id),
    })

    this.deleteFiles(room.id)
  }

  leaveRoom = async (userId, room) => {
    const collection = firebase.firestore().collection("rooms")

    collection.doc(room.id).update({
      ...room,
      users: room.users.filter(item => item !== userId),
    })

    const userCollection = firebase.firestore().collection("users").doc(userId)

    const user = await userCollection.get()
    const useData = user.data()

    const updatedNotifications = useData.notifications.filter(notification => notification?.room_id !== room.id)

    userCollection.set(
      {
        rooms: useData?.rooms.filter(item => item !== room.id),
        notifications: updatedNotifications,
      },
      { merge: true }
    )
  }

  updateChatStatus = chat => {
    const collection = firebase.firestore().collection("rooms")
    const isActive = chat.status === "chat"

    collection.doc(room.id).update({
      ...room,
      status: isActive ? "archived" : "chat",
    })
  }

  updateRoomStatus = room => {
    const collection = firebase.firestore().collection("rooms")
    const isActive = room.status === "active"

    collection.doc(room.id).update({
      ...room,
      status: isActive ? "archived" : "active",
    })
  }

  addRoomTask = (room, name) => {
    const collection = firebase.firestore().collection("rooms")

    collection.doc(room.id).update({
      ...room,
      tasks: [...data.tasks, { name: name, completed: "active" }],
    })
  }

  updateRoomTask = (id, list) => {
    const collection = firebase.firestore().collection("rooms")
    collection.doc(id).update({
      tasks: list,
    })
  }

  setLoggeedInUser = user => {
    localStorage.setItem("authUser", JSON.stringify(user))
  }

  getUsernameSettings = username => {
    return new Promise(resolve => {
      const collection = firebase.firestore().collection("users")
      collection
        .where("username", "==", username)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            resolve({
              ...doc.data(),
              userId: doc.id,
            })
          })
        })
    })
  }

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem("authUser")) return null
    return JSON.parse(localStorage.getItem("authUser"))
  }

  /**
   *  Create Room
   */
  registerRoom = async (settings, existRooms) => {
    const collection = firebase.firestore().collection("rooms")
    collection
      .add(settings)
      .then(docRef => {
        this.updateUser({
          rooms: existRooms?.length ? [...existRooms, docRef.id] : [docRef.id],
        })

        collection.doc(docRef.id).set(
          {
            id: docRef.id,
          },
          { merge: true }
        )
      })
      .catch(error => console.log(error))
  }

  /**
   *  Get Active rooms by admin created id
   */

  getRoomsByAdminId = async () => {
    let rooms = []
    const collection = firebase.firestore().collection("rooms")

    await collection
      .where("admin_user", "==", this.getAuthenticatedUser().uid)
      .where("status", "==", "active")
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          rooms.push({
            ...doc.data(),
            id: doc.id,
          })
        })
      })

    return rooms
  }

  getRoomById = async id => {
    const room = await firebase.firestore().collection("rooms").doc(id).get()

    return room.data()
  }

  /**
   *  Get Active Rooms
   */
  getGroups = async id => {
    const allRooms = firebase.firestore().collection("rooms")
    const adminUserRooms = allRooms.where("admin_user", "==", id)
    const userContainsRooms = allRooms.where("users", "array-contains", id)
    const adminRooms = await adminUserRooms.get()
    const entireRooms = await userContainsRooms.get()

    return [...adminRooms.docs, ...entireRooms.docs].map(room => room.data())
  }

  /**
   *  Get Archived Rooms
   */
  getArchived = async list => {
    const collection = firebase.firestore().collection("rooms")
    const activeGroups = await collection.where("status", "==", "archived").get()
    const array = []
    for (const doc of activeGroups.docs) {
      if (list.includes(doc.id)) {
        const newGroup = {
          ...doc.data(),
          roomId: doc.id,
        }
        array.push(newGroup)
      }
    }
    return array
  }

  getChatData = id => {
    const room = firebase.firestore().collection("rooms").doc(id)
    return room
  }

  reportRoomToAdmin = (room, member) => {
    const reportToAdminEmailFunction = firebase.functions().httpsCallable("reportToAdmin")

    reportToAdminEmailFunction({
      category: "room",
      data: {
        userFullName: getFullName(member.firstName, member.lastName),
        roomData: {
          name: room.name,
          adminUser: room.admin_user,
        },
      },
    })
  }

  reportUserToAdmin = (member, reportedMember, reason) => {
    const reportToAdminEmailFunction = firebase.functions().httpsCallable("reportToAdmin")

    reportToAdminEmailFunction({
      category: "user",
      data: {
        userFullName: getFullName(member.firstName, member.lastName),
        reportedUser: {
          fullName: getFullName(reportedMember.firstName, reportedMember.lastName),
          profileLink: window.location.href,
        },
        reportedReason: reason,
      },
    })
  }

  registrationSuccessEmail = (email, _firstName, _lastName) => {
    const registrationSuccess = firebase.functions().httpsCallable("registrationSuccessEmail")

    registrationSuccess({
      email,
      profileLink: `${window.location.origin}/profile`,
      exploreLink: `${window.location.origin}/explore`,
    })
  }

  completeProfileReminder = email => {
    const date = new Date()
    const delay = date.setMinutes(date.getMinutes() + 2880)
    const result = Math.trunc(delay / 1000)

    this.updateUser({
      notified: {
        completed: false,
        id: `${result}`,
      },
    })

    const getReminder = firebase.functions().httpsCallable("completeProfileReminder")

    getReminder({ email, time: result, profileLink: `${window.location.origin}/profile` })
  }

  // cancelProfileReminder = (id) => {
  //   const getReminder = firebase.functions().httpsCallable("cancelProfileReminder")

  //   getReminder({ id })

  //   this.updateUser({ notified: {
  //     id: notified.id,
  //     completed: true,
  //   }})
  // }

  shareWithFriends = items => {
    items.forEach(item => {
      const shareWithFriendsFn = firebase.functions().httpsCallable("shareWithFriends")

      shareWithFriendsFn({
        email: item.email,
        name: item.name,
        link: window.location.origin,
      })
    })
  }

  getInqId = (firstName, lastName) => {
    const sendPersonaInquiry = firebase.functions().httpsCallable("sendPersonaInquiry")

    sendPersonaInquiry({
      firstName,
      lastName,
    })
  }

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    const errorMessage = "The password you have entered is invalid or the email does not exist. Please try again."

    if (error?.toString()?.includes("already")) {
      errorMessage =
        "An account already exists with this email address, but different sign-in credentials. Please, sign in using a provider associated with this email address."
    }

    // if (error.code === passwordResponse) {
    //   errorMessage = "The password you have entered is invalid or the email does not exist. Please try again."
    // } else {
    //   errorMessage = error.message
    // }

    return errorMessage
  }
}

let _fireBaseBackend = null

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = config => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config)
  }
  return _fireBaseBackend
}

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend
}

export { initFirebaseBackend, getFirebaseBackend }

export const getNonExistUsername = async collection => {
  let randomUsername = ""

  while (!randomUsername) {
    const randomNumber = Math.floor(Math.random() * 90000) + 10000
    const isUsernameExist = await collection.where("username", "==", randomNumber).get()

    if (isUsernameExist.empty) {
      randomUsername = randomNumber
    }
  }

  return randomUsername
}
