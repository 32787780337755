import React, { useState, useCallback, useRef } from "react"
import PropTypes from "prop-types"
import Slider from "react-slick"

import { CardBlock } from "components/CardBlock/CardBlock"
import { InvestmentCard } from "components/InvestmentCard/InvestmentCard"
import { NoResultsFound } from "components/NoResultsFound/NoResultsFound"
import { getBudget } from "helpers/getBudget"

const sliderSettings = {
  infinite: false,
  speed: 500,
  slidesToShow: 3.5,
  slidesToScroll: 1,
  arrows: false,
  responsive: [
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1.1,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1.5,
      },
    },
    {
      breakpoint: 744,
      settings: {
        slidesToShow: 2.25,
      },
    },
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 2.76,
      },
    },
  ],
}

export const RecommendedGroups = ({ options, onOpenModal }) => {
  const slider = useRef(null)

  const [isPrevShown, setIsPrevShown] = useState(false)

  const handleAfterChange = useCallback(
    index => {
      setIsPrevShown(index > 0)
    },
    [isPrevShown]
  )

  return (
    <>
      <CardBlock
        className="recommended-groups"
        title="Private groups based on your preferences"
        text="Browse existing private groups"
      >
        {options.length > 0 ? (
          <div className="overflow-hidden recommended-groups__slider-wrapper">
            <div className="position-relative recommended-groups__slider">
              <button
                className="border-0 rounded-circle position-absolute recommended-groups__slider-arrow"
                onClick={() => slider?.current?.slickNext()}
              />
              {isPrevShown && (
                <button
                  className="border-0 rounded-circle position-absolute recommended-groups__slider-arrow-prev"
                  onClick={() => slider?.current?.slickPrev()}
                />
              )}

              <Slider ref={slider} {...sliderSettings} afterChange={handleAfterChange}>
                {options.map(option => {
                  return (
                    <InvestmentCard
                      key={option.id}
                      image={option.cover_image}
                      title={option.name}
                      text={option.description}
                      alt={option.name}
                      price={getBudget(option.project_size)}
                      type={option.investment_types?.[0]?.type}
                      location={option.investment_location?.[0]}
                      onClick={() => onOpenModal(option)}
                    />
                  )
                })}
              </Slider>
            </div>
          </div>
        ) : (
          <NoResultsFound />
        )}
      </CardBlock>
    </>
  )
}

RecommendedGroups.propTypes = {
  options: PropTypes.array,
  onOpenModal: PropTypes.func,
}
