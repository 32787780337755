import React from "react"
import PropTypes from 'prop-types';
import { ModalFooter } from "reactstrap"
import cx from "classnames"

import { PrimaryButton } from "components/PrimaryButton/PrimaryButton"

export const ChatModalFooter = ({ isDisabled, isError, isEmail, isSended, isNotFoundedEmail, onClick }) => {
  const text = isSended ? 
    <span className="chat-modal-footer__text"><i className="bx bx-check chat-modal-footer__icon" /> Invitation sent</span> :
    isError ?
    "Invalid email" :
    isNotFoundedEmail ?
    "Email not found" :
    "Add people";

  const classes = cx("w-100 m-0 chat-modal-footer__button", {
    "is-error": isError
  });

  return (
    <ModalFooter className="p-4 pt-0 border-top-0 chat-modal-footer">
      <PrimaryButton
        className={classes}
        color="primary"
        text={text}
        size="small"
        disabled={isDisabled}
        onClick={onClick}
      />
    </ModalFooter>
  )
}

ChatModalFooter.propTypes = {
  isNotFoundedEmail: PropTypes.bool,
  isSended: PropTypes.bool,
  isEmail: PropTypes.bool,
  isError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
};
