import React, { useEffect } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { GoogleLogin } from "react-google-login"
import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"

import { gapi } from "gapi-script"
import { facebook, google } from "../../../../config"
import { socialLogin } from "../../../../store/actions"

export const SocialsBlock = ({ history }) => {
  const dispatch = useDispatch()
  //handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, "google")
  }

  //handleTwitterLoginResponse
  const twitterResponse = response => {
    signIn(response, "twitter")
  }

  //handleFacebookLoginResponse
  const facebookResponse = response => {
    signIn(response, "facebook")
  }

  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name.toLowerCase(),
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      }

      dispatch(socialLogin(postData, history, type))
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        // idToken: res.id,
      }

      dispatch(socialLogin(postData, history, type))
    }
  }

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: google.CLIENT_ID,
        scope: "email",
      })
    }

    gapi.load("client:auth2", start)
  }, [])

  return (
    <div className="socials-block text-center">
      <h5 className="font-size-13 socials-block__title">Sign up using</h5>

      <ul className="m-0 list-inline socials-block__list">
        <li className="list-inline-item socials-block__list-item">
          <FacebookLogin
            appId={facebook.APP_ID}
            autoLoad={false}
            fields="name, email"
            callback={facebookResponse}
            render={renderProps => (
              <Link
                to="#"
                className="social-list-item text-white socials-block__facebook"
                onClick={renderProps.onClick}
              >
                <i className="mdi mdi-facebook" />
              </Link>
            )}
          />
        </li>

        {/* <li className="list-inline-item socials-block__list-item">
          <TwitterLogin
            loginUrl="http://localhost:4000/api/v1/auth/twitter"
            onSuccess={twitterResponse}
            onFailure={() => {}}
            requestTokenUrl="http://localhost:4000/api/v1/auth/twitter/revers"
            showIcon={false}
            tag="div"
          >
            <Link
              to="#"
              className="social-list-item bg-info text-white border-info"
            >
              <i className="mdi mdi-twitter"/>
            </Link>
          </TwitterLogin>
        </li> */}

        <li className="list-inline-item socials-block__list-item">
          <GoogleLogin
            clientId={google.CLIENT_ID}
            render={renderProps => (
              <Link
                to="#"
                className="social-list-item bg-danger text-white border-danger"
                onClick={renderProps.onClick}
              >
                <i className="mdi mdi-google" />
              </Link>
            )}
            onSuccess={googleResponse}
            onFailure={e => console.log(e)}
          />
        </li>
      </ul>
    </div>
  )
}

SocialsBlock.propTypes = {
  history: PropTypes.any,
}
