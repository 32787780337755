import React, { useState, useRef } from "react"
import PropTypes from 'prop-types';
import cx from "classnames"

import { useOnClickOutside } from "helpers/clickOutside";
import { SearchSelectButton } from "components/Search/components/SearchSelectButton/SearchSelectButton";

const options = [
  { label: "Market to invest", value: "location" },
  { label: "Type of investment", value: "type" },
  { label: "Funds", value: "size" },
  { label: "Place of residence", value: "investor_location" },
  { label: "Experience", value: "investor_experience" },
  { label: "Skills", value: "skills" },
]

export const SortSelect = ({ className, onSort }) => {
  const ref = useRef(null);

  const [ isActive, setIsActive ] = useState(false);
  const [ selected, setSelected] = useState(null)

  useOnClickOutside(ref, () => setIsActive(false));

  const onClick = () => {
    setIsActive(active => !active);
  };

  const handleChange = option => {
    onSort(option.value)
    onClick()
    if(selected?.value === option.value){
      setSelected(null)
      return
    }
    setSelected(option)
  }

  const classes = cx("position-relative sort-select", className, {
    "is-active": isActive
  });

  return (
    <div className={classes} ref={ref}>
      <SearchSelectButton
        className="sort-select__button"
        text={selected?.label ? selected.label : "Sort by"}
        isActive={isActive}
        onClick={onClick}
      />

      {isActive && (
        <ul className="position-absolute mb-0 sort-select__list">
          {options.map(option => {
            const isSelected = selected?.value === option.value;
            const classes = cx("w-100 font-size-11 sort-select__list-button", { "is-active": isSelected });

            return (
              <li key={option.value} className="sort-select__list-item">
                <button
                  className={classes}
                  onClick={() => handleChange(option)}
                >{option.label}</button>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  )
}

SortSelect.propTypes = {
  className: PropTypes.string,
  onSort: PropTypes.func
};
