import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import PropTypes from "prop-types"

import { NotificationsBlock } from "./components/NotificationsBlock/NotificationsBlock"
import { useNotifications } from "./hooks/useNotifications"

const Notifications = ({ user, member, history }) => {
  const { newNotifications, readNotifications } = useNotifications(user, member, false, history)

  return (
    <div className="notifications">
      <div className="notifications__main">
        <h1 className="notifications__title">Notifications</h1>
        <NotificationsBlock title="New" isNew={true}>
          {newNotifications}
        </NotificationsBlock>
        <NotificationsBlock title="Earlier" isNew={false}>
          {readNotifications}
        </NotificationsBlock>
      </div>
    </div>
  )
}

Notifications.propTypes = {
  user: PropTypes.object,
  member: PropTypes.object,
  history: PropTypes.object,
}

const mapStateToProps = state => {
  return { user: state.Account.user, member: state.Account.settings }
}

export default connect(mapStateToProps)(withRouter(Notifications))
