// @flow
import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_SIDEBAR_THEME_IMAGE,
  CHANGE_PRELOADER,
  TOGGLE_LEFTMENU,
  SHOW_SIDEBAR,
  OPEN_UPDATE_PROFILE_MODAL,
  UPDATE_SET_IS_ION_PROFILE,
  OPEN_PROMO_ALERT,
  OPEN_MESSAGES_RIGHT_SIDEBAR,
  OPEN_MESSAGES_LEFT_SIDEBAR,
  OPEN_ATTACHMENTS_MODAL,
  LOADED_MAPS_SCRIPT,
  OPEN_ADD_MEMBER_MODAL,
  OPENED_ROOM,
  SET_MOBILE_USER_MENU_ACTIVE,
} from "./actionTypes"

//constants
import {
  layoutTypes,
  layoutWidthTypes,
  topBarThemeTypes,
  leftBarThemeImageTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
} from "../../constants/layout"

const INIT_STATE = {
  layoutType: layoutTypes.HORIZONTAL,
  layoutWidth: layoutWidthTypes.FLUID,
  leftSideBarTheme: leftSideBarThemeTypes.DARK,
  leftSideBarThemeImage: leftBarThemeImageTypes.NONE,
  leftSideBarType: leftSidebarTypes.DEFAULT,
  topbarTheme: topBarThemeTypes.DARK,
  isPreloader: false,
  showRightSidebar: false,
  isMobile: false,
  showSidebar: true,
  leftMenu: false,
  showUpdateProfileModal: false,
  isOnProfile: false,
  showPromoAlert: true,
  showMessagesRightSidebar: true,
  showMessagesLeftSidebar: true,
  showAttachmentsModal: false,
  isGoogleMapsScriptLoaded: false,
  showAddMemberModal: false,
  openedRoom: "",
  isMobileUserMenuOpened: false,
}

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      }
    case CHANGE_PRELOADER:
      return {
        ...state,
        isPreloader: action.payload,
      }

    case CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidth: action.payload,
      }
    case CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSideBarTheme: action.payload,
      }
    case CHANGE_SIDEBAR_THEME_IMAGE:
      return {
        ...state,
        leftSideBarThemeImage: action.payload,
      }
    case CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType,
      }
    case CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarTheme: action.payload,
      }
    case SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: action.payload,
      }
    case SHOW_SIDEBAR:
      return {
        ...state,
        showSidebar: action.payload,
      }
    case TOGGLE_LEFTMENU:
      return {
        ...state,
        leftMenu: action.payload,
      }
    case OPEN_UPDATE_PROFILE_MODAL:
      return {
        ...state,
        showUpdateProfileModal: action.payload,
      }
    case UPDATE_SET_IS_ION_PROFILE:
      return {
        ...state,
        isOnProfile: action.payload,
      }
    case OPEN_PROMO_ALERT:
      return {
        ...state,
        showPromoAlert: action.payload,
      }
    case OPEN_MESSAGES_RIGHT_SIDEBAR:
      return {
        ...state,
        showMessagesRightSidebar: action.payload,
      }
    case OPEN_MESSAGES_LEFT_SIDEBAR:
      return {
        ...state,
        showMessagesLeftSidebar: action.payload,
      }
    case OPEN_ATTACHMENTS_MODAL:
      return {
        ...state,
        showAttachmentsModal: action.payload,
      }
    case OPEN_ADD_MEMBER_MODAL:
      return {
        ...state,
        showAddMemberModal: action.payload,
      }
    case LOADED_MAPS_SCRIPT:
      return {
        ...state,
        isGoogleMapsScriptLoaded: action.payload,
      }
    case OPENED_ROOM:
      return {
        ...state,
        openedRoom: action.payload,
      }
    case SET_MOBILE_USER_MENU_ACTIVE:
      return {
        ...state,
        isMobileUserMenuOpened: action.payload,
      }
    default:
      return state
  }
}

export default Layout
