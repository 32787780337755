import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { ModalUpload } from 'modals/components/common/ModalUpload/ModalUpload'

import { Step } from '../../common/Step/Step'

export const MediaStep = ({
  currentTab,
  data,
  questionNumber = '5',
  onChange
}) => {
  const fileInputChange = file => {
    if(!!file){
      onChange(file)
    }
  }

  return (
    <Step
      className={cx('mb-4', { 'd-block': currentTab === 5, 'd-none': currentTab !== 5, })}
      heading='Media'
      description='Upload any relevant files'
    >
      <ModalUpload
        files={data}
        className='media-step'
        imageOnly={false}
        onUpload={fileInputChange}
      />
    </Step>
  )
}

MediaStep.propTypes = {
  currentTab: PropTypes.number,
  data: PropTypes.array,
  questionNumber: PropTypes.string,
  onChange: PropTypes.func,
}
