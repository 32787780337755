export default [
  "None",
  "Some",
  "Expert"
];

export const nameOfDescription = {
  "None": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  "Some": "Lorem ipsum dolor sir.",
  "Expert": "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
};