import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Avatar } from "components/Avatar/Avatar"
import { capitalizeFirstLetter } from "helpers/capitalizeFirstLetter"

export const ProfileMenuDropdownHeader = ({ name, image, isActive, id }) => {
  const classes = cx("profile-menu-dropdonw-header d-flex align-items-center", {
    "is-active": isActive,
  })

  return (
    <div className={classes} id={id}>
      <div className="profile-menu-dropdonw-header__avatar">
        <Avatar size="s" image={image} />
      </div>

      <span className="profile-menu-dropdonw-header__name">{capitalizeFirstLetter(name)}</span>
    </div>
  )
}

ProfileMenuDropdownHeader.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.string,
  isActive: PropTypes.bool,
}
