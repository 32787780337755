import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useDispatch, useSelector } from "react-redux"
import { getFirebaseBackend } from "helpers/firebase_helper"
import { useDocumentData, useCollectionData } from "react-firebase-hooks/firestore"
import firebase from "firebase/compat/app"
import "firebase/compat/storage"
import "firebase/compat/analytics"

import {
  addMessage,
  getChats,
  getGroups,
  getMessages,
  getArchived,
  openedRoomAction,
  setActiveCategory,
} from "store/actions"
import { showMessagesLeftSidebarAction, showMessagesRightSidebarAction } from "store/actions"

import { getFullName, getProfileImageAvailability } from "components/Profile/Profile.helpers"
import { getNotificationEntity } from "pages/Notifications/helpers/notifcations.helpers"

import { FileSizeModal } from "./components/FileSizeModal/FileSizeModal"
import { maxFileSize, tabletWidth } from "./variables"
import { ChatLeftSidebar } from "./components/ChatLeftSidebar/ChatLeftSidebar"
import { ChatMain } from "./components/ChatMain/ChatMain"
import { ChatRightSidebar } from "./components/ChatRightSidebar/ChatRightSidebar"
import { useCurrentWidth } from "./helpers"
import { AttachmentsModal } from "./components/AttachmentsModal/AttachmentsModal"
import { ChatNoMessages } from "./components/ChatNoMessages/ChatNoMessages"

const Messages = props => {
  const { onGetGroups, userId, rooms = [], activeCategory, currentUserSettings } = props

  const fireBaseBackend = getFirebaseBackend()
  const dispatch = useDispatch()
  const showRightSidebar = useSelector(state => state.Layout.showMessagesRightSidebar)
  const showLeftSidebar = useSelector(state => state.Layout.showMessagesLeftSidebar)
  const showAttachmentsModal = useSelector(state => state.Layout.showAttachmentsModal)
  const currentUser = useSelector(state => state.Account.user)
  const analytics = firebase.analytics()

  // ID current room from store
  const currentRoomId = useSelector(state => state.Layout.openedRoom)

  // Data of selected room
  const roomData = useMemo(() => {
    if (!!currentRoomId) {
      return firebase.firestore().collection("rooms").doc(currentRoomId)
    }
  }, [currentRoomId])
  const [updatedRoom, loading] = useDocumentData(roomData)

  const [chats, setChats] = useState([])
  const filteredChats = useMemo(() => {
    const chats = rooms.filter(room => room.status === "chat")

    return chats.map(async chat => {
      const [user] =
        chat?.users[0] === userId
          ? await fireBaseBackend.getUsersSettings([chat?.admin_user])
          : await fireBaseBackend.getUsersSettings(chat?.users)
      return {
        ...chat,
        cover_image: getProfileImageAvailability(user?.profile_image),
        name: getFullName(user?.firstName, user?.lastName),
        isOnline: user?.status === "online",
        isVerified: user?.isVerified,
      }
    })
  }, [rooms, fireBaseBackend])

  useEffect(() => {
    Promise.all(filteredChats).then(allChats => setChats(allChats))
  }, [filteredChats, setChats])

  const archives = useMemo(() => {
    return rooms.filter(room => room.status === "archived")
  }, [rooms])

  const filteredRooms = useMemo(() => {
    const activeRooms = rooms.filter(room => room.status === "active")

    // return list with our rooms only
    return activeRooms.sort((a, b) => b.createdAt - a.createdAt)
  }, [rooms, updatedRoom])

  // select one option
  const updateActiveOption = () => {
    if (!currentRoomId && !!userId) {
      if (filteredRooms.length > 0) {
        dispatch(openedRoomAction(filteredRooms[0].id))
        dispatch(setActiveCategory("groups"))
      } else if (chats.length > 0) {
        dispatch(openedRoomAction(chats[0].id))
        dispatch(setActiveCategory("chats"))
      } else if (archives.length > 0) {
        dispatch(openedRoomAction(archives[0].id))
        dispatch(setActiveCategory("archived"))
      }
    }
  }

  useEffect(() => {
    updateActiveOption()
  }, [currentRoomId, updatedRoom, rooms, userId])

  // width for mobile
  let width = useCurrentWidth()

  useEffect(() => {
    if (width <= tabletWidth) {
      dispatch(showMessagesRightSidebarAction(false))
    } else {
      dispatch(showMessagesLeftSidebarAction(true))
    }
  }, [width])

  const handleSidebarClick = id => {
    dispatch(openedRoomAction(id))

    if (width <= tabletWidth) {
      dispatch(showMessagesLeftSidebarAction(false))
    }
  }

  const handleToggleCategoryTab = tab => {
    dispatch(setActiveCategory(tab))
  }

  const handleOnUpdateRoom = () => {
    onGetGroups(userId)
  }

  const handleDeleteRoom = id => {
    if (filteredRooms.length > 0) {
      if (filteredRooms[0].id === id && filteredRooms.length >= 2) {
        dispatch(openedRoomAction(filteredRooms[1].id))
      } else if (filteredRooms[0].id !== id && filteredRooms.length >= 2) {
        dispatch(openedRoomAction(filteredRooms[0].id))
      }
    }
    onGetGroups(userId)
  }

  // GET MESSAGES
  const sortedMessages = useMemo(() => {
    if (updatedRoom && !!updatedRoom.chat) {
      return updatedRoom.chat.sort((a, b) => a.created - b.created)
    }
  }, [updatedRoom])

  // SEND MESSAGES
  const onAddMessage = async (data, linksData) => {
    const collection = firebase.firestore().collection("rooms")
    if (!updatedRoom.chat) {
      await collection.doc(roomData.id ? roomData.id : "").update({
        chat: [data],
        links: linksData.length > 0 ? [...updatedRoom.links, ...linksData] : [...updatedRoom.links],
      })
    } else {
      await collection.doc(roomData.id ? roomData.id : "").update({
        chat: [...updatedRoom.chat, data],
        links: linksData.length > 0 ? [...updatedRoom.links, ...linksData] : [...updatedRoom.links],
      })
    }

    if (linksData.length > 0) {
      const ids = [updatedRoom.admin_user, ...updatedRoom.users]
      const notifyUsersList = users.filter(user => {
        return user.id !== currentUser.uid && ids.includes(user.id)
      })

      notifyUsersList.forEach(user => {
        const firstName = user?.firstName
        const lastName = user?.lastName

        fireBaseBackend.updateUserNotificationById(
          user.id,
          getNotificationEntity("group-new-activity", {
            room: updatedRoom,
            roomName: updatedRoom.status === "chat" ? getFullName(firstName, lastName) : updatedRoom.name,
            member: user,
            activityType: "link",
          })
        )
        fireBaseBackend.asyncNotifyUserByEmail(
          user.id,
          `Your ${updatedRoom.status === "chat" ? "chat" : "group"} ${
            updatedRoom.status === "chat" ? firstName + " " + lastName : updatedRoom.name
          } has new links!`,
          `Dear ${firstName},
            ${updatedRoom.status === "chat" ? "chat" : "group"}
            ${
              updatedRoom.status === "chat" ? firstName + " " + lastName : updatedRoom.name
            } has a new link. Click below to login and check it out.`,
          `View ${updatedRoom.status === "chat" ? "chat" : "group"}`,
          `${window.location.href}?${updatedRoom.status === "chat" ? "chat" : "room"}=${updatedRoom.id}`
        )
      })
    }

    analytics.logEvent("messages_sent", {
      room_id: roomData.id,
      room_name: roomData.name,
    })
  }

  // GET USERS
  const usersData = useMemo(() => {
    if (!!updatedRoom && !!updatedRoom?.users) {
      return firebase.firestore().collection("users")
    }
  }, [updatedRoom?.users])

  const [usersList, , , docs] = useCollectionData(usersData)

  const users = useMemo(() => {
    const usersListWithId = !!usersList
      ? usersList.map((user, index) => {
          return { ...user, id: docs.docs[index].id }
        })
      : []
    return usersListWithId.filter(user => {
      if (!!updatedRoom?.users) {
        return updatedRoom.users.includes(user.id) || updatedRoom.admin_user === user.id
      }
    })
  }, [usersList, docs, updatedRoom])

  // UPDATE ROOM TASKS
  const handleOnUpdateTasks = async data => {
    const collection = firebase.firestore().collection("rooms")
    await collection.doc(roomData.id ? roomData.id : "").update({
      tasks: data,
    })
  }

  // UPLOAD FILES
  const [fileIsUploaded, setFileIsUploaded] = useState(false)
  const handleOnUploadFiles = async e => {
    const file = e.target.files[0]

    if (file.size > maxFileSize) {
      setSizeAlertOpened(true)

      setTimeout(() => {
        setSizeAlertOpened(false)
      }, 2500)

      return
    }

    if (!!file) {
      const storageRef = firebase.storage().ref()
      const fileRef = storageRef.child(`files/${updatedRoom.id}/${file.name}`)

      const task = fileRef.put(file)

      task.on(
        "state_changed",
        snapshot => console.log("UPLOAD...", snapshot),
        error => console.log(error),
        () => {
          console.log("DONE")
          setFileIsUploaded(true)
        }
      )

      const filePath = `files/${updatedRoom.id}/${file.name}`
      setFileIsUploaded(false)

      const collection = firebase.firestore().collection("rooms")
      if (!!updatedRoom.files) {
        await collection.doc(roomData.id ? roomData.id : "").update({
          files: [...updatedRoom.files, filePath],
        })
      } else {
        await collection.doc(roomData.id ? roomData.id : "").update({
          files: [filePath],
        })
      }

      const ids = [updatedRoom.admin_user, ...updatedRoom.users]
      const notifyUsersList = users.filter(user => {
        return user.id !== currentUser.uid && ids.includes(user.id)
      })

      notifyUsersList.forEach(user => {
        const firstName = user?.firstName
        const lastName = user?.lastName

        fireBaseBackend.updateUserNotificationById(
          user.id,
          getNotificationEntity("group-new-activity", {
            room: updatedRoom,
            roomName: updatedRoom.status === "chat" ? getFullName(firstName, lastName) : updatedRoom.name,
            member: user,
            activityType: "file",
          })
        )
        fireBaseBackend.asyncNotifyUserByEmail(
          user.id,
          `Your ${updatedRoom.status === "chat" ? getFullName(firstName, lastName) : updatedRoom.name} ${
            updatedRoom.status === "chat" ? "chat" : "group"
          } has new files!`,
          `Dear ${firstName},
          ${updatedRoom.status === "chat" ? "chat" : "group"} ${
            updatedRoom.status === "chat" ? getFullName(firstName, lastName) : updatedRoom.name
          } has a new file. Click below to login and check it out.`,
          `View ${updatedRoom.status === "chat" ? "chat" : "group"}`,
          `${window.location.href}?${updatedRoom.status === "chat" ? "chat" : "room"}=${updatedRoom.id}`
        )
      })
    }
  }

  // FILES INCORRECT SIZE ALERT
  const [sizeAlertOpened, setSizeAlertOpened] = useState(false)

  return (
    <div className="w-100 d-flex messages">
      {showLeftSidebar && (
        <ChatLeftSidebar
          room={updatedRoom}
          activeCategory={activeCategory}
          chats={!!chats ? chats : []}
          groups={!!filteredRooms ? filteredRooms : []}
          archived={!!archives ? archives : []}
          onClick={handleSidebarClick}
          onToggleTab={handleToggleCategoryTab}
        />
      )}

      {rooms.length > 0 ? (
        <ChatMain
          user={currentUserSettings}
          isLoading={loading}
          messages={sortedMessages ? sortedMessages : []}
          room={!!updatedRoom ? updatedRoom : null}
          users={users}
          onAddMessage={onAddMessage}
          onUpdateRoom={handleOnUpdateRoom}
          onDeleteRoom={handleDeleteRoom}
          onUpload={handleOnUploadFiles}
        />
      ) : (
        <ChatNoMessages buttonText={activeCategory === "chats" ? "Explore other users" : "Explore existing groups"} />
      )}

      {rooms.length > 0 && showRightSidebar && (
        <ChatRightSidebar
          isLoading={loading}
          room={!!updatedRoom ? updatedRoom : null}
          users={users}
          fileIsUploaded={fileIsUploaded}
          onUpdateTasks={handleOnUpdateTasks}
        />
      )}

      <AttachmentsModal
        isOpened={showAttachmentsModal}
        isLoading={loading}
        room={!!updatedRoom ? updatedRoom : null}
        users={users}
        fileIsUploaded={fileIsUploaded}
        onUpdateTasks={handleOnUpdateTasks}
      />

      <FileSizeModal opened={sizeAlertOpened} />
    </div>
  )
}

Messages.propTypes = {
  userId: PropTypes.string,
  currentUserSettings: PropTypes.object,
  showRightSidebar: PropTypes.bool,
  chats: PropTypes.array,
  rooms: PropTypes.array,
  archived: PropTypes.array,
  activeCategory: PropTypes.string,
  messages: PropTypes.array,
  onGetChats: PropTypes.func,
  onGetGroups: PropTypes.func,
  onGetArchived: PropTypes.func,
  onGetMessages: PropTypes.func,
  onAddMessage: PropTypes.func,
}

const mapStateToProps = ({ chat, Account }) => {
  return {
    userId: Account.user?.uid,
    currentUserSettings: Account.settings,
    chats: chat.chats,
    rooms: chat.rooms,
    activeCategory: chat.activeCategory,
    archived: chat.archived,
    messages: chat.messages,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetChats: () => dispatch(getChats()),
  onGetGroups: userId => dispatch(getGroups(userId)),
  onGetArchived: roomsIds => dispatch(getArchived(roomsIds)),
  onGetMessages: roomId => dispatch(getMessages(roomId)),
  onAddMessage: message => dispatch(addMessage(message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Messages)
