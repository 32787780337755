import React, { useCallback, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Card } from "reactstrap"

import { Loader } from "components/Loader/Loader"

import { ChatMainHeader } from "../ChatMainHeader/ChatMainHeader"
import { ChatMainConversation } from "../ChatMainConversation/ChatMainConversation"
import { ChatMainFooter } from "../ChatMainFooter/ChatMainFooter"

const headerHeight = 90
const parentPaddingTop = 41
const headerHeightMobile = 60

export const ChatMainComponent = props => {
  const {
    userSettings,
    isLoading,
    room,
    messages,
    users,
    currentUser,
    foundUsers,
    isAdmin,
    onAddMessage,
    onAddUser,
    onFindUsers,
    onInviteUser,
    onAction,
    onUpload,
  } = props

  const [curMessage, setCurMessage] = useState("")

  const addMessage = () => {
    onAddMessage(curMessage)
    setCurMessage("")
  }

  const onKeyPress = e => {
    const { key, value } = e
    if (key === "Enter") {
      setCurMessage(value)
      addMessage()
    }
  }

  // TODO should find better way to know room is from chats or from groups
  const isChat = !room?.hasOwnProperty("investment_location")

  const handleChange = useCallback(
    e => {
      setCurMessage(e.target.value)
    },
    [setCurMessage]
  )

  const headerRef = useRef(null)
  const footerRef = useRef(null)

  const height = useMemo(() => {
    if (window.innerWidth <= 576) {
      return window.innerHeight - headerHeightMobile - headerRef.current?.offsetHeight - footerRef.current?.offsetHeight
    }
    return (
      window.innerHeight -
      headerHeight -
      parentPaddingTop -
      headerRef.current?.offsetHeight -
      footerRef.current?.offsetHeight
    )
  }, [window.innerHeight, window.innerWidth, headerRef.current?.offsetHeight, footerRef.current?.offsetHeight])

  return (
    <div className="w-100 user-chat chat-main">
      {isLoading ? (
        <Loader className="chat-main__loader" />
      ) : (
        <Card className="chat-main__card">
          <ChatMainHeader
            userSettings={userSettings}
            ref={headerRef}
            data={room}
            users={users}
            foundUsers={foundUsers}
            isAdmin={isAdmin}
            onAddUser={onAddUser}
            onFindUsers={onFindUsers}
            onInviteUser={onInviteUser}
            onAction={onAction}
          />

          <div>
            <ChatMainConversation isChat={isChat} messages={messages} currentUser={currentUser} height={height} />

            <ChatMainFooter
              ref={footerRef}
              disabled={room?.status === "archived"}
              value={curMessage}
              onChange={handleChange}
              onKeyPress={onKeyPress}
              onUpload={onUpload}
            />
          </div>
        </Card>
      )}
    </div>
  )
}

ChatMainComponent.propTypes = {
  userSettings: PropTypes.object,
  isLoading: PropTypes.bool,
  room: PropTypes.object,
  messages: PropTypes.array,
  users: PropTypes.array,
  foundUsers: PropTypes.array,
  isAdmin: PropTypes.bool,
  currentUser: PropTypes.object,
  onAddMessage: PropTypes.func,
  onAddUser: PropTypes.func,
  onFindUsers: PropTypes.func,
  onInviteUser: PropTypes.func,
  onAction: PropTypes.func,
  onUpload: PropTypes.func,
}
