import React from "react"
import PropTypes from "prop-types"
import { Modal } from "reactstrap"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { ProfileDropdownExtraInfo } from "components/ProfileDropdownExtraInfo/ProfileDropdownExtraInfo"
import { getFullName } from "components/Profile/Profile.helpers"
import { useHash } from "pages/Faq/helpers/useHash"

export const ProfileMobileModal = ({ isOpened, onToggle }) => {
  const member = useSelector(state => state.Account.settings)
  const fullName = getFullName(member?.firstName, member?.lastName)

  const [hash, setHash] = useHash();

  return (
    <Modal
      className="profile-mobile-modal"
      contentClassName="profile-mobile-modal__content"
      centered={true}
      fade={false}
      isOpen={isOpened}
      toggle={onToggle}
    >
      <button className="border-0 rounded-circle position-absolute profile-mobile-modal__close" onClick={onToggle} />

      <div className="d-flex flex-column">
        <ProfileDropdownExtraInfo
          className="profile-mobile-modal__info"
          avatarSize="m"
          image={member?.profile_image}
          name={fullName}
          description={member?.profile_short_bio}
        />
        <h4 className="profile-menu-dropdown__title font-weight-semibold font-size-16 mb-2">Account</h4>
        <Link onClick={() => setHash("questions")} to="/home/faq#questions" className="fw-medium profile-menu-dropdown__link mb-2">
          General Questions
        </Link>
        <Link onClick={() => setHash("privacy")} to="/home/faq#privacy" className="fw-medium profile-menu-dropdown__link mb-2">
          Privacy Policy
        </Link>
        <Link onClick={() => setHash("terms")} to="/home/faq#terms" className="fw-medium profile-menu-dropdown__link mb-2">
          Terms & Conditions
        </Link>
        <Link onClick={() => setHash("support")} to="/home/faq#support" className="fw-medium profile-menu-dropdown__link mb-2">
          Help
        </Link>
        <div className="dropdown-divider" />
        <Link to="/logout" className="fw-medium profile-menu-dropdown__link">
          Sign out
        </Link>
      </div>
    </Modal>
  )
}

ProfileMobileModal.propTypes = {
  isOpened: PropTypes.bool,
  onToggle: PropTypes.func,
}
