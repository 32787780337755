import React from "react"
import PropTypes from "prop-types"

import { Icon } from "components/Icon/Icon"

export const ExperienceSkillsSectionItem = ({ value, iconName, title }) => {
  return (
    <div className="experience-skills-section-item d-flex pb-3 pt-3">
      <Icon name={iconName} width={24} height={24} />
      <div className="experience-skills-section-item__body ps-3">
        <h5 className="experience-skills-section-item__title font-size-16 font-weight-bold mb-2">{title}</h5>
        <p className="experience-skills-section-item__value mb-0">
          {value ? value : ""}
        </p>
      </div>
    </div>
  )
}

ExperienceSkillsSectionItem.propTypes = {
  title: PropTypes.string,
  iconName: PropTypes.string,
  value: PropTypes.string,
}
