import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Nav,
  TabContent,
} from "reactstrap"
import SimpleBar from "simplebar-react"

import { Loader } from "components/Loader/Loader"

import { ChatRightTabPane } from "../ChatRightTabPane/ChatRightTabPane"
import { ChatRightNavLink } from "../ChatRightNavLink/ChatRightNavLink"
import { ChatTasksBlock } from "../ChatTasksBlock/ChatTasksBlock"
import { ChatMembers } from "../ChatMembers/ChatMembers"
import { ChatRightTabPaneLinks } from "../ChatRightTabPaneLinks/ChatRightTabPaneLinks"

const navTabs = [
  { id: "1", name: "Files" },
  { id: "2", name: "Links" },
];

export const ChatRightSidebarComponent = ({
  isLoading,
  isAdmin,
  roomStatus,
  files,
  links,
  tasks,
  users,
  fileIsUploaded,
  currentUserId,
  onChangeTask,
  onAddTask,
  onOpenModal,
}) => {
  const [activeTab, setactiveTab] = useState("1")

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  return (
    <div className="chat-right-sidebar-component">
      {isLoading ? (
        <Loader className="chat-right-sidebar-component__loader" />
      ) : (
        <SimpleBar className="chat-right-sidebar-component__scroll">
          {roomStatus !== "chat" && (
            <div className="chat-right-sidebar-component__wrapper">
              <ChatMembers
                users={users}
                currentUserId={currentUserId}
                addButtonOff={roomStatus === "archived" || !isAdmin}
                onClick={onOpenModal}
              />
            </div>
          )}

          <div className="chat-right-sidebar-component__wrapper">
            <Nav justified>
              {navTabs.map(tab => {
                return (
                  <ChatRightNavLink
                    key={tab.id}
                    text={tab.name}
                    active={activeTab === tab.id}
                    onClick={() => {
                      toggleTab(tab.id)
                    }}
                  />
                )
              })}
            </Nav>

            <TabContent activeTab={activeTab} className="chat-right-sidebar-component__tab-content">
              <ChatRightTabPane tabId={navTabs[0].id} options={files} fileIsUploaded={fileIsUploaded} />

              <ChatRightTabPaneLinks tabId={navTabs[1].id} options={links} />
            </TabContent>
          </div>

          {roomStatus !== "chat" && (
            <div className="chat-right-sidebar-component__wrapper">
              <ChatTasksBlock
                tasks={tasks}
                addButtonOff={roomStatus === "archived"}
                onTaskClick={onChangeTask}
                onAddTask={onAddTask}
              />
            </div>
          )}

          <button id="experts-modal-chatroom" data-element="experts-modal" className="search__link">
            Talk to an expert
          </button>
        </SimpleBar>
      )}
    </div>
  )
}

ChatRightSidebarComponent.propTypes = {
  isLoading: PropTypes.bool,
  isAdmin: PropTypes.bool,
  roomStatus: PropTypes.string,
  files: PropTypes.array,
  links: PropTypes.array,
  tasks: PropTypes.array,
  users: PropTypes.array,
  fileIsUploaded: PropTypes.bool,
  currentUserId: PropTypes.string,
  onChangeTask: PropTypes.func,
  onAddTask: PropTypes.func,
  onOpenModal: PropTypes.func,
}
