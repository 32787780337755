import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"
import { useDispatch } from "react-redux"

import { deleteUserSettings, logoutUser, openedRoomAction, openUserMobileMenu, setCurrentUser } from "../../store/actions"

const Logout = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(logoutUser(props.history))
    dispatch(deleteUserSettings())
    dispatch(openUserMobileMenu(false))
    dispatch(setCurrentUser(null))
    dispatch(openedRoomAction(null))
  }, [dispatch, openUserMobileMenu, deleteUserSettings, logoutUser, props.history, setCurrentUser])

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Logout)
