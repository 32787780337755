import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"

const avatarColors = ["#E41E3F", "#EB5757", "#556EE6", "#3D7E2E"]

const generateColor = () => {
  const randomNumber = Math.floor(Math.random() * avatarColors.length - 1) + 1
  return avatarColors[randomNumber]
}

export const UserName = ({ size = "m", className, name }) => {
  const classes = cx(
    className,
    "user-name d-flex justify-content-center align-items-center position-relative rounded-circle overflow-hidden",
    `user-name--size-${size}`
  )

  const [value, setValue] = useState()

  useEffect(() => {
    setValue(generateColor())
  }, [])

  const styles = {
    backgroundColor: value,
    color: value,
  }

  return (
    <div className={classes} style={styles}>
      <span className="h-100 w-100 d-flex align-items-center justify-content-center user-name__text">
        {name?.toUpperCase()}
      </span>
    </div>
  )
}

UserName.propTypes = {
  size: PropTypes.oneOfType(["xs" | "s" | "m" | "l" | "xxl"]),
  className: PropTypes.string,
  name: PropTypes.string,
}
