import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login/Login"
import Logout from "../pages/Authentication/Logout"
import { Register } from "../pages/Authentication/Register/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword/ForgetPassword"

// MainMenu pages
import Home from "../pages/Home/index"
import Explore from "../pages/Explore/index"
import Messages from "../pages/Messages/index"
import Faq from "../pages/Faq/index"
import Notifications from "../pages/Notifications/index"

//Pages
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

// Profiles
import PublicProfile from "pages/PublicProfile/PublicProfile"
import PrivateProfile from "pages/PrivateProfile/PrivateProfile"

const authProtectedRoutes = [
  // MainMenu pages
  { path: "/home", component: Home },
  { path: "/explore", component: Explore },
  { path: "/messages", component: Messages },
  { path: "/home/faq", component: Faq },
  { path: "/notifications", component: Notifications },

  // Profiles
  {
    path: "/profile/:username",
    component: PublicProfile,
  },
  { path: "/profile", component: PrivateProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/home" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
]

export { publicRoutes, authProtectedRoutes }
