import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import { ChatDeleteModal } from "../ChatDeleteModal/ChatDeleteModal"

export const ChatMainSettings = ({
  roomName,
  isOpen,
  isAdmin,
  isArchived,
  isChat,
  onToggle,
  onClick,
}) => {
  const [ opened, setOpened ] = useState(false)

  const handleClick = () => {
    setOpened(true)
  }

  const handleCancel = () => {
    setOpened(false)
  }

  const handleDelete = () => {
    onClick("delete")
    setOpened(false)
  }

  return (
    <Dropdown isOpen={isOpen} toggle={onToggle} className="chat-main-settings">
      <DropdownToggle className="d-flex align-items-center justify-content-center btn nav-btn chat-main-settings__icon" tag="i">
        <i className="bx bx-cog" />
      </DropdownToggle>
      <DropdownMenu end className="chat-main-settings__menu">
        {isAdmin && !isChat && (
          <DropdownItem
            tag="button"
            onClick={() => onClick("archive")}
          >{isArchived ? "Unarchive room" : "Archive room"}</DropdownItem>
        )}

        {!isAdmin && (
          <DropdownItem
            tag="button"
            onClick={() => onClick("report")}
            >Report room</DropdownItem>
        )}

        {isAdmin && !isChat && (
          <DropdownItem
            tag="button"
            onClick={() => onClick("update")}
          >Update details</DropdownItem>
        )}

        {!isAdmin && (
          <DropdownItem
            tag="button"
            onClick={() => onClick("leave")}
          >Leave room</DropdownItem>
        )}

        {isAdmin && (
          <DropdownItem
            tag="button"
            onClick={handleClick}
          >Delete</DropdownItem>
        )}
      </DropdownMenu>

      <ChatDeleteModal
        opened={opened}
        roomName={roomName}
        onCancel={handleCancel}
        onDelete={handleDelete}
      />
    </Dropdown>
  )
}

ChatMainSettings.propTypes = {
  roomName: PropTypes.string,
  isOpen: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isArchived: PropTypes.bool,
  isChat: PropTypes.bool,
  onToggle: PropTypes.func,
  onClick: PropTypes.func,
}