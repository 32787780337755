export default [
  {
    type: "residential",
    property_type: [
       'Single Family', 
       'Condo', 
       'Multi-Family', 
       'Other'
    ],
    investment_type: [
       'Buy and hold',
       'Flip',
       'Development',
       'BRRRR (Buy, Rehab, Rent, Refinance, Repeat)', 
       'Other'
    ],
  },
  {
    type: "commercial",
    property_type: [],
    investment_type: []
  },
  {
    type: "industrial",
    property_type: [],
    investment_type: []
  },
  {
    type: "lot",
    property_type: [],
    investment_type: []
  },
  {
    type: "farm",
    property_type: [],
    investment_type: []
  }
]