import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import cx from "classnames"
import { OnboardingTooltip } from "components/OnboardingTooltip/OnboardingTooltip"
import { connect, useSelector } from "react-redux"
import { capitalizeFirstLetter } from "helpers/capitalizeFirstLetter"

const homeUrl = "/home"
const exploreUrl = "/explore"
const messagesUrl = "/messages"

const exploreIcon = (
  <svg className="main-menu__icon" width="33" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.1367 26.0674c2.5368-.0006 5.0005-.8498 6.9988-2.4125l6.2827 6.2827 2.0209-2.0209-6.2827-6.2827c1.5635-1.9985 2.4133-4.4628 2.4139-7.0002 0-6.30421-5.1294-11.4336-11.4336-11.4336-6.30419 0-11.43358 5.12939-11.43358 11.4336 0 6.3042 5.12939 11.4336 11.43358 11.4336Zm0-20.00881c4.7292 0 8.5752 3.84597 8.5752 8.57521 0 4.7292-3.846 8.5752-8.5752 8.5752-4.7292 0-8.57518-3.846-8.57518-8.5752 0-4.72924 3.84598-8.57521 8.57518-8.57521Z"
      fill="currentColor"
    />
  </svg>
)

const homeIcon = (
  <svg className="main-menu__icon" width="33" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.49765 14.667h1.33333v9.3333c0 1.4707 1.196 2.6667 2.66667 2.6667H24.4976c1.4707 0 2.6667-1.196 2.6667-2.6667V14.667h1.3333c.5387 0 1.0267-.3253 1.232-.8227.2067-.4986.092-1.072-.2893-1.4533l-12-12c-.5213-.521333-1.364-.521333-1.8853 0l-12.00002 12c-.38133.3813-.496.9547-.28933 1.4533.20666.4974.69333.8227 1.232.8227ZM16.498 3.21875l8 7.99995v6.1147l.0014 6.6667H8.49805v-12.78L16.498 3.21875Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4974 22.6667c2.9413 0 5.3333-2.392 5.3333-5.3334 0-2.9413-2.392-5.3333-5.3333-5.3333-2.9413 0-5.3333 2.392-5.3333 5.3333 0 2.9414 2.392 5.3334 5.3333 5.3334Zm-.0007-8.0002c1.4707 0 2.6667 1.196 2.6667 2.6667 0 1.4706-1.196 2.6666-2.6667 2.6666-1.4706 0-2.6666-1.196-2.6666-2.6666 0-1.4707 1.196-2.6667 2.6666-2.6667Z"
      fill="currentColor"
    />
  </svg>
)

const chatroomsIcon = (
  <svg className="main-menu__icon" width="33" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.01841 26.2148v5.4171l2.17919-1.3075 6.8498-4.1096h6.7937c1.5866 0 2.8769-1.2903 2.8769-2.8769V11.8305c0-1.5866-1.2903-2.87689-2.8769-2.87689H6.57998c-1.58658 0-2.87686 1.29029-2.87686 2.87689v11.5074c0 1.5866 1.29028 2.8769 2.87686 2.8769h1.43843ZM6.57998 11.8305H23.8411v11.5074h-7.5906l-5.3552 3.2135v-3.2135H6.57998V11.8305Z"
      fill="currentColor"
    />
    <path
      d="M29.5911 3.2002H12.33c-1.5866 0-2.87688 1.29027-2.87688 2.87685H26.7143c1.5866 0 2.8768 1.29028 2.8768 2.87686V20.4613c1.5866 0 2.8769-1.2902 2.8769-2.8768V6.07705c0-1.58658-1.2903-2.87685-2.8769-2.87685Z"
      fill="#999"
    />
  </svg>
)

const tooltipExploreText = "Browse existing users and groups and see the results of your search on the Explore tab."
const tooltipMessageText =
  "The Messages screen is the communication hub where you can manage your groups, participate in the groups you are part of and send message to other users."

const MainMenu = ({ settings }) => {
  // available pages : '/home' | '/explore' | '/messages'
  const [currentPage, setCurrentPage] = useState("")

  useEffect(() => {
    const page = window.location.pathname
    setCurrentPage(page)
  }, [window.location.pathname])

  const [onboardingExplore, setOnboardingExplore] = useState(false)
  const [onboardingMessage, setOnboardingMessage] = useState(false)

  const isModalOpened = useSelector(state => state.Layout.showUpdateProfileModal)
  useEffect(() => {
    if (!!settings && !isModalOpened) {
      const isPreviousClicked =
        !!settings?.onboarding?.groups && !!settings?.onboarding.search && !!settings?.onboarding.createGroup

      if (isPreviousClicked && !settings?.onboarding?.explore) {
        setOnboardingExplore(true)
        return
      } else {
        setOnboardingExplore(false)
      }
      if (isPreviousClicked && !settings?.onboarding?.message) {
        setOnboardingMessage(true)
        return
      } else {
        setOnboardingMessage(false)
      }
    }
  }, [settings, isModalOpened, setOnboardingExplore, setOnboardingMessage])

  return (
    <div className="d-flex main-menu">
      <MainMenuLink isActive={currentPage === homeUrl} name="Home" icon={homeIcon} url={homeUrl} />

      <MainMenuLink
        isActive={currentPage === exploreUrl}
        name="Explore"
        icon={exploreIcon}
        url={exploreUrl}
        id="TooltipExplore"
      />
      <OnboardingTooltip
        text={tooltipExploreText}
        opened={onboardingExplore}
        name="explore"
        target="TooltipExplore"
        settings={settings?.onboarding}
        placement="bottom"
      />

      <MainMenuLink
        isActive={currentPage === messagesUrl}
        name="Messages"
        icon={chatroomsIcon}
        url={messagesUrl}
        id="TooltipMessage"
      />
      <OnboardingTooltip
        text={tooltipMessageText}
        opened={onboardingMessage}
        name="message"
        target="TooltipMessage"
        settings={settings?.onboarding}
        placement="bottom"
      />
    </div>
  )
}

MainMenu.propTypes = {
  settings: PropTypes.object,
}

const mapStateToProps = ({ Account }) => {
  return {
    settings: Account.settings,
  }
}

export default connect(mapStateToProps)(MainMenu)

const MainMenuLink = ({ isActive, name, url, icon, id }) => {
  const classes = cx(
    "h-100 d-inline-flex overflow-hidden position-relative flex-column align-items-center main-menu__link",
    {
      "is-active": isActive,
    }
  )

  return (
    <Link id={id} className={classes} to={url}>
      {icon}

      <span className="font-size-13 font-weight-500 main-menu__text">{capitalizeFirstLetter(name)}</span>
    </Link>
  )
}

MainMenuLink.propTypes = {
  isActive: PropTypes.bool,
  name: PropTypes.string,
  url: PropTypes.string,
  id: PropTypes.string,
  icon: PropTypes.node,
}
