import React, { useEffect, useState, useCallback } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import cx from "classnames"
import { useDispatch } from "react-redux"

import { Icon } from "../Icon/Icon"
import NotificationDropdown from "components/CommonForBoth/TopbarDropdown/NotificationDropdown"
import { openUserMobileMenu } from "store/actions"
import { capitalizeFirstLetter } from "helpers/capitalizeFirstLetter"

const homeUrl = "/home"
const exploreUrl = "/explore"
const messagesUrl = "/messages"

export const MainMobileMenu = () => {
  // available pages : '/home' | '/explore' | '/messages'
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState("")

  useEffect(() => {
    const page = window.location.pathname
    setCurrentPage(page)
  }, [window.location.pathname])

  const handleOnToggle = useCallback(() => {
    dispatch(openUserMobileMenu(true))
  }, [])

  return (
    <div className="d-flex d-sm-none position-fixed main-mobile-menu">
      <div className="d-flex main-mobile-menu__links">
        <MainMobileMenuLink isActive={currentPage === homeUrl} name="Home" iconName="home" url={homeUrl} />

        <MainMobileMenuLink isActive={currentPage === exploreUrl} name="Explore" iconName="explore" url={exploreUrl} />

        <MainMobileMenuLink
          isActive={currentPage === messagesUrl}
          name="Messages"
          iconName="chatrooms"
          url={messagesUrl}
        />

        <Link
          to="notifications"
          className="border-0 d-flex flex-column p-0 align-items-center h-100 justify-content-center main-mobile-menu__notification-wrapper"
        >
          <NotificationDropdown className="main-mobile-menu__notification" />

          <span className="font-size-11 font-weight-500 main-mobile-menu__text">Notifications</span>
        </Link>
        <button
          className="border-0 d-flex flex-column align-items-center h-100 justify-content-center main-mobile-menu__menu"
          onClick={handleOnToggle}
        >
          <Icon className="main-mobile-menu__menu-icon" name="menu" />

          <span className="font-size-11 font-weight-500 main-mobile-menu__text">Menu</span>
        </button>
      </div>
    </div>
  )
}

const MainMobileMenuLink = ({ isActive, name, url, iconName, onClick }) => {
  const classes = cx(
    "h-100 d-inline-flex overflow-hidden position-relative flex-column align-items-center main-mobile-menu__link",
    {
      "is-active": isActive,
    }
  )

  return (
    <Link className={classes} to={url}>
      <Icon className="main-mobile-menu__icon" name={iconName} />

      <span className="font-size-11 font-weight-500 main-mobile-menu__text">{capitalizeFirstLetter(name)}</span>
    </Link>
  )
}

MainMobileMenuLink.propTypes = {
  isActive: PropTypes.bool,
  name: PropTypes.string,
  url: PropTypes.string,
  iconName: PropTypes.string,
  onClick: PropTypes.func,
}
