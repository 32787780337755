import React, { useState } from "react"
import PropTypes from "prop-types"

import { PrimaryButton } from "components/PrimaryButton/PrimaryButton"
import { SecondaryButton } from "components/SecondaryButton/SecondaryButton"

import { InviteRoomModal } from "../InviteRoomModal/InviteRoomModal"

export const ProfileActionsButtons = ({
  rooms,
  onInviteToRooms,
  onMessage,
}) => {
  const [inviteRoomIsActive, setInviteRoomIsActive] = useState(false)

  const handleOnToggleInviteModal = () => {
    setInviteRoomIsActive(prev => !prev)
  }
  return (
    <>
      <div className="profile-actions-buttons ms-auto mt-auto">
        <PrimaryButton
          text="Message"
          size="small"
          className="me-3"
          onClick={onMessage}
        ></PrimaryButton>
        <SecondaryButton
          text="Invite to room"
          size="small"
          onClick={handleOnToggleInviteModal}
        />
      </div>

      <InviteRoomModal
        rooms={rooms}
        isOpen={inviteRoomIsActive}
        onToggleModal={handleOnToggleInviteModal}
        onInviteToRooms={onInviteToRooms}
      />
    </>
  )
}

ProfileActionsButtons.propTypes = {
  rooms: PropTypes.array,
  onInviteToRooms: PropTypes.func,
  onMessage: PropTypes.func,
}