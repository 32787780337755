import React from 'react'
import 'firebase/compat/analytics'
import PropTypes from 'prop-types'

import { residential } from 'modals/variables'
import { BudgetStep } from 'modals/components/steps/BudgetStep/BudgetStep'
import { LocationsStep } from 'modals/components/steps/LocationsStep/LocationsStep'
import { MainModal } from 'modals/components/parts/MainModal/MainModal'

import { MainModalBody } from 'modals/components/parts/MainModalBody/MainModalBody'
import { MainModalIndicator } from 'modals/components/parts/MainModalIndicator/MainModalIndicator'
import { TypesStep } from 'modals/components/steps/TypesStep/TypesStep'
import { TypesAdditionalStep } from 'modals/components/steps/TypesAdditionalStep/TypesAdditionalStep'
import { MainModalFooter } from 'modals/components/parts/MainModalFooter/MainModalFooter'
import { MainModalHeader } from 'modals/components/parts/MainModalHeader/MainModalHeader'
import { MainModalLastScreen } from 'modals/components/parts/MainModalLastScreen/MainModalLastScreen'
import { FormStep } from 'modals/components/steps/FormStep/FormStep'
import { MediaStep } from 'modals/components/steps/MediaStep/MediaStep'

export const RoomModal = ({
  isOpened,
  title,
  subtitle,
  steps,
  currentTab,
  types,
  investmentTypes,
  propertyTypes,
  investmentLocation,
  form,
  files,
  budget,
  disabledButtons,
  enabledMediaStep,
  onChangeTab,
  onChangeForm,
  onChangeMedia,
  onChangeTypes,
  onChangePropertyTypes,
  onChangeInvestmentTypes,
  onChangeInvestmentLocation,
  onChangeBudget,
  onToggle,
}) => {
  const isResidential = types.includes(residential)

  // Functions
  const onPrev = () => {
    if(currentTab === 2 && !isResidential) {
      onChangeTab(currentTab - 2)
      return;
    }
    onChangeTab(currentTab - 1)
  }

  const onNext = () => {
    if(currentTab === 0 && !isResidential) {
      onChangeTab(currentTab + 2)
      return
    }
    onChangeTab(currentTab + 1)
  }

  return (
    <MainModal isOpened={isOpened} toggle={onToggle}>
      <MainModalHeader title={title} subtitle={subtitle} />

      <MainModalBody>
        <MainModalIndicator allSteps={steps} currentStep={currentTab} />

        <TypesStep
          currentTab={currentTab}
          active={types}
          isRoom={true}
          onChange={onChangeTypes}
        />

        <TypesAdditionalStep
          currentTab={currentTab}
          activeInvestment={investmentTypes}
          activeProperty={propertyTypes}
          isRoom={true}
          onChangePropertyTypes={onChangePropertyTypes}
          onChangeInvestmentTypes={onChangeInvestmentTypes}
        />

        <LocationsStep
          currentTab={currentTab}
          questionNumber={isResidential ? '3' : '2'}
          userLocationEnabled={false}
          investmentLocation={investmentLocation}
          isRoom={true}
          onChangeInvestmentLocation={onChangeInvestmentLocation}
        />

        <BudgetStep
          currentTab={currentTab}
          questionNumber={isResidential ? '4' : '3'}
          active={budget}
          isRoom={true}
          onChange={onChangeBudget}
        />

        <FormStep
          currentTab={currentTab}
          questionNumber={isResidential ? '5' : '4'}
          data={form}
          onChange={onChangeForm}
        />

        {enabledMediaStep && (
          <MediaStep
            currentTab={currentTab}
            questionNumber={isResidential ? '6' : '5'}
            data={files}
            onChange={onChangeMedia}
          />
        )}

        {currentTab !== steps && (
          <MainModalFooter
            isDisabled={disabledButtons}
            enabledSkipButton={false}
            enabledPrevButton={currentTab !== 0}
            onPrevClick={onPrev}
            onNextClick={onNext}
          />
        )}

        {currentTab === steps && (
          <MainModalLastScreen />
        )}
      </MainModalBody>
    </MainModal>
  )
}

RoomModal.propTypes = {
  isOpened: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  steps: PropTypes.number,
  currentTab: PropTypes.number,
  types: PropTypes.array,
  investmentTypes: PropTypes.array,
  propertyTypes: PropTypes.array,
  investmentLocation: PropTypes.array,
  form: PropTypes.object,
  files: PropTypes.array,
  budget: PropTypes.object,
  disabledButtons: PropTypes.bool,
  enabledMediaStep: PropTypes.bool,
  onChangeForm: PropTypes.func,
  onChangeTypes: PropTypes.func,
  onChangePropertyTypes: PropTypes.func,
  onChangeInvestmentTypes: PropTypes.func,
  onChangeInvestmentLocation: PropTypes.func,
  onChangeMedia: PropTypes.func,
  onSkip: PropTypes.func,
  onChangeTab: PropTypes.func,
  onChangeBudget: PropTypes.func,
  onToggle: PropTypes.func,
}
