import React, { useState } from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import firebase from "firebase/compat/app"
import "firebase/compat/analytics"

import { getFirebaseBackend } from "helpers/firebase_helper"
import { isValidLink } from "helpers/isValidLink"
import { getProfileImageAvailability } from "components/Profile/Profile.helpers"
import { getNotificationEntity } from "pages/Notifications/helpers/notifcations.helpers"

import { ChatMainComponent } from "./components/ChatMainComponent/ChatMainComponent"
import { UpdateRoomModal } from "modals/UpdateRoomModal/UpdateRoomModal"

const fiveMinutes = 5
const oneHourInMinutes = 60000

export const ChatMain = props => {
  const { user, isLoading, room, users, messages, onAddMessage, onUpdateRoom, onDeleteRoom, onUpload } = props

  const fireBaseBackend = getFirebaseBackend()
  const analytics = firebase.analytics()

  const [foundUsers, setFoundUsers] = useState([])
  const [showRoomModal, setShowRoomModal] = useState(false)

  const currentUser = useSelector(state => state.Account.user)
  const userSettings = useSelector(state => state.Account.settings)
  const isAdmin = currentUser?.uid === room?.admin_user

  const handleFindUsers = async query => {
    if (!!query) {
      const results = await fireBaseBackend.getUsersByQuery(query, currentUser?.uid)

      setFoundUsers(results)
    } else {
      setFoundUsers([])
    }
  }

  const handleAddUser = (id, name) => {
    fireBaseBackend.addUserToRoom(currentUser, userSettings, room, id, name)
    onUpdateRoom({
      ...room,
      pending_users: room?.pending_users && room?.pending_users.length ? [...room?.pending_users, id] : [id],
    })

    analytics.logEvent("invites_to_join_groups", {
      room_id: room.id,
      room_name: room.name,
    })
  }

  const handleAction = action => {
    if (action === "archive" && room?.hasOwnProperty("investment_location")) {
      fireBaseBackend.updateRoomStatus(room)
      onUpdateRoom()
    }
    if (action === "archive" && !room?.hasOwnProperty("investment_location")) {
      fireBaseBackend.updateChatStatus(room)
      onUpdateRoom()
    }
    if (action === "delete") {
      fireBaseBackend.deleteRoom(room, userSettings.rooms)
      onDeleteRoom(room.id)
    }
    if (action === "leave") {
      fireBaseBackend.leaveRoom(currentUser.uid, room)
      onDeleteRoom(room.id)
    }
    if (action === "update") {
      setShowRoomModal(true)
    }
    if (action === "report") {
      fireBaseBackend.reportRoomToAdmin(room, userSettings)
    }
  }

  const handleAddMessage = message => {
    const now = Date.now()
    const links = []
    const textArray = message.split(" ")
    const canUpdateUserNotifications = canReciveNotifications(room, now)

    const newMessage = textArray.map(item => {
      const isLink = isValidLink(item)

      const value = item.startsWith("https") && item.startsWith("http") ? item : "//" + item

      if (isLink) {
        links.push(item)
        return `<a class="chat-main-message__link" target="_blank" href="${value}">${item}</a>`
      }
      return item
    })

    if (room.status === "chat" && canUpdateUserNotifications) {
      const [notifyUser] = users.filter(user => user.email !== userSettings.email)

      fireBaseBackend.updateUserNotificationById(
        notifyUser.id,
        getNotificationEntity("individual-message", {
          user: currentUser,
          member: userSettings,
          room: room,
        })
      )
      fireBaseBackend.notifyUserByEmail(
        notifyUser.email,
        "You have received a new message!",
        `Dear ${notifyUser?.firstName},
          You have received a new message. Click below to login and join the conversation.`,
        notifyUser.status,
        "Read messages",
        `${window.location.href}?chat=${room.id}`
      )
    }

    if (room.status === "active" && canUpdateUserNotifications) {
      const notifyUsers = users.filter(user => user.email !== userSettings.email)
      notifyUsers.forEach(user => {
        fireBaseBackend.updateUserNotificationById(
          user.id,
          getNotificationEntity("group-new-message", {
            user: currentUser,
            member: userSettings,
            room,
          })
        )
        fireBaseBackend.notifyUserByEmail(
          user.email,
          `Your group ${room.name} has new messages!`,
          `Dear ${user?.firstName},
            Group ${room.name} has a new message. Click below to login and join the conversation.`,
          user.status,
          "Read messages",
          `${window.location.href}?room=${room.id}`
        )
      })
    }

    onAddMessage(
      {
        messageId: now,
        created: now,
        content: newMessage.join(" "),
        senderName: userSettings.firstName,
        senderId: currentUser.uid,
        senderImage: getProfileImageAvailability(userSettings.profile_image),
      },
      links.length > 0 ? links : []
    )
  }

  return (
    <>
      <ChatMainComponent
        userSettings={userSettings}
        isLoading={isLoading}
        room={room}
        messages={messages}
        users={users}
        currentUser={currentUser}
        foundUsers={foundUsers}
        isAdmin={isAdmin}
        onAddUser={handleAddUser}
        onAddMessage={handleAddMessage}
        onFindUsers={handleFindUsers}
        onAction={handleAction}
        onUpload={onUpload}
      />

      {showRoomModal && (
        <UpdateRoomModal room={room} isOpened={showRoomModal} onToggle={() => setShowRoomModal(false)} />
      )}
    </>
  )
}

ChatMain.propTypes = {
  user: PropTypes.object,
  isLoading: PropTypes.bool,
  room: PropTypes.object,
  users: PropTypes.array,
  messages: PropTypes.array,
  onAddMessage: PropTypes.func,
  onUpdateRoom: PropTypes.func,
  onDeleteRoom: PropTypes.func,
  onUpload: PropTypes.func,
}

const canReciveNotifications = (room, currentTime) => {
  if (room.chat && room.chat.length) {
    const lastMessageTimeDate = new Date(room.chat[room?.chat.length - 1].created)
    const currentTimeDate = new Date(currentTime)
    const diff = currentTimeDate.getTime() - lastMessageTimeDate.getTime()

    return Math.round(diff / oneHourInMinutes) > fiveMinutes
  }

  return true
}
