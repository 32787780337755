import React from 'react'
import PropTypes from 'prop-types'

export const MainModalHeader = ({ title, subtitle }) => {

  return (
    <div className='main-modal-header'>
      <h5 className='main-modal-header__title mb-2 color-black font-size-18 line-height-140 fw-bolder'>{title}</h5>

      <p className='main-modal-header__subtitle mb-0 color-gray-300 font-size-13 line-height-140 fw-medium'>{subtitle}</p>
    </div>
  );
};

MainModalHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
