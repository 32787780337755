import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Button } from 'reactstrap'

import { Icon } from 'components/Icon/Icon'

export const ModalButton = ({
  className, title, icon, description, active, isLarge, onClick
}) => {
  const classes = cx('modal-button background-color-gray-100 color-black border-radius-4 box-sizing-box', className, {
    'is-active': active,
    'modal-button--description': !!description,
    'is-large': isLarge
  });

  return (
    <Button className={classes} onClick={onClick}>
      <span className='modal-button__icon-wrapper text-center background-color-green-200 d-flex align-items-center justify-content-center rounded-circle mx-auto mb-3'>
        <Icon className='modal-button__icon' name={icon} />
      </span>

      <span className='modal-button__title font-size-16 line-height-140 font-weight-bold text-capitalize'>{title}</span>

      {description && <span className='modal-button__description'>{description}</span>}
    </Button>
  );
}

ModalButton.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  description: PropTypes.string,
  active: PropTypes.bool,
  isLarge: PropTypes.bool,
  onClick: PropTypes.func,
};
