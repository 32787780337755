import React, { useState, useRef } from "react"
import PropTypes from 'prop-types';
import cx from "classnames"

import { useOnClickOutside } from "helpers/clickOutside";

import { SearchSelectButton } from "components/Search/components/SearchSelectButton/SearchSelectButton";

export const ReportModalSelect = ({ options, selected, className, onSelect }) => {
  const ref = useRef(null);

  const [ isActive, setIsActive ] = useState(false);

  useOnClickOutside(ref, () => setIsActive(false));

  const onClick = () => {
    setIsActive(active => !active);
  };

  const classes = cx("position-relative report-modal-select", className, {
    "is-active": isActive
  });

  return (
    <div className={classes} ref={ref}>
      <SearchSelectButton className="report-modal-select__button" text={selected} isActive={isActive} onClick={onClick} />

      {isActive && (
        <ul className="position-absolute mb-0 report-modal-select__list">
          {options.map(option => {
            const isSelected = selected === option;
            const classes = cx("w-100 font-size-11 report-modal-select__list-button", { "is-active": isSelected });

            return (
              <li key={option} className="report-modal-select__list-item">
                <button
                  className={classes}
                  onClick={() => {
                    onSelect(option)
                    setIsActive(false)
                  }}
                >{option}</button>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  )
}

ReportModalSelect.propTypes = {
  options: PropTypes.array,
  className: PropTypes.string,
  selected: PropTypes.string,
  onSelect: PropTypes.func
};
