import React, { useEffect, useState } from "react"
import {
  filterItemsByPoints,
  filterItemsByUserName,
  filterItemsBySize,
  filterItemsByType,
  getParams,
  isSearch,
  sortItemsByPoints,
  filterItemsByLocation,
  filterItemsByInvestorLocation,
  filterItemsByExperience,
  getGeoCode,
} from "helpers/search_helpers"

export const useSearchQueryUsersResults = (users, query, settings, userId) => {
  const [result, setResult] = useState([])
  const newUsers = !!users && !!userId && users.filter(item => item?.username !== settings?.username)

  const onChange = coordinates => {
    const params = getParams(true, settings, query)

    // filter by location
    const locationResults = coordinates?.coordinates ? filterItemsByLocation(true, newUsers, coordinates) : newUsers

    // filter by investor location
    const investorLocationResults = filterItemsByInvestorLocation(query?.locations, newUsers, params)

    // merge location and investor location results arrays and remove duplicates
    const mergedLocations = [...new Set([...locationResults, ...investorLocationResults])]

    // filter by type
    const typeResults = filterItemsByType(true, mergedLocations, params)

    // filter by budget
    const sizeResults = filterItemsBySize(true, typeResults, params)

    // // filter by experience
    // const experienceResults = filterItemsByExperience(true, investorLocationResults, params)

    // // search by name
    const nameResults = filterItemsByUserName(sizeResults, params)
    // const sortResults = sortItemsByPoints(nameResults)

    setResult(nameResults)
  }

  useEffect(() => {
    if (!!newUsers && !!settings && userId) {
      if (!!query) {
        if (query?.locations?.length === 0) {
          onChange({ coordinates: null })
        } else {
          getGeoCode(query?.locations, onChange)
        }
      } else {
        onChange({ coordinates: null })
      }
    }
  }, [users, query, settings, userId, setResult])

  return result
}
