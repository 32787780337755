
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export const  Main = ({
  children, className
}) => {

  return (
    <div className={cx('w-100 position-relative main', className)}>
      {children}
    </div>
  );
}

Main.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};
