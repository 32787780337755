import React, { useEffect, useRef } from "react"
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete"
import { useSelector } from "react-redux"
import { useOnClickOutside } from "helpers/clickOutside"
import { InputField } from "components/InputField/InputField"
import PropTypes from "prop-types"
import cx from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"

export const PlacesAutocomplete = ({
  isClearableAfterComplete = true,
  placeholder = "Search for a country, province or city",
  defaultValue,
  className,
  onChange,
  onFocus,
  onBlur,
}) => {
  const {
    ready,
    value,
    init,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    defaultValue,
    initOnMount: false,
    debounce: 300,
    requestOptions: {
      language: "en",
    },
  })

  const ref = useRef(null)
  useOnClickOutside(ref, () => {
    clearSuggestions()
  })

  const handleInput = e => {
    setValue(e.target.value)
  }

  const handleSelect =
    ({ description }) =>
    () => {
      if (isClearableAfterComplete) {
        setValue("", false)
      } else {
        setValue(description)
      }

      clearSuggestions()

      getGeocode({ address: description }).then(results => {
        const { lat, lng } = getLatLng(results[0])

        onChange({
          coordinates: { lat, lng },
          label: description,
        })
      })
    }

  const isGoogleMapsScriptLoaded = useSelector(state => state.Layout.isGoogleMapsScriptLoaded)

  useEffect(() => {
    if (isGoogleMapsScriptLoaded) {
      init()
    }
  }, [isGoogleMapsScriptLoaded, init])

  const renderSuggestions = () =>
    data.map(suggestion => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion

      return (
        <li key={place_id} className="places-autocomplete__list-item" onClick={handleSelect(suggestion)}>
          <span>
            {main_text} {secondary_text}
          </span>
        </li>
      )
    })

  const classes = cx("places-autocomplete", className)

  return (
    <div ref={ref} className={classes}>
      <InputField
        type="text"
        placeholder={placeholder}
        className="places-autocomplete__input"
        value={value}
        disabled={!ready}
        onChange={handleInput}
        onFocus={onFocus}
        onBlur={onBlur}
      />

      {status === "OK" && (
        <ul className="w-100 overflow-hidden p-0 m-0 places-autocomplete__list">
          <PerfectScrollbar className="places-autocomplete__scroll">{renderSuggestions()}</PerfectScrollbar>
        </ul>
      )}
    </div>
  )
}

PlacesAutocomplete.propTypes = {
  defaultValue: PropTypes.string,
  isClearableAfterComplete: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
}
