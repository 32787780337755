import React, { useState } from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import PropTypes from "prop-types"
import "firebase/compat/firestore"

import { CloseModalButton } from "components/CloseModalButton/CloseModalButton"

import { ReportModalFooter } from "./components/ReportModalFooter/ReportModalFooter"
import { ReportModalSelect } from "./components/ReportModalSelect/ReportModalSelect"

const options = ["Inappropriate content", "Spam", "Fraudulent", "Privacy"]

export const ReportModal = ({ isOpen, onToggle, onReport }) => {
  const [selected, setSelected] = useState("Select a problem")

  const handleClick = () => {
    onReport(selected)

    setTimeout(() => {
      onToggle(false)
    }, 2000)
  }

  return (
    <Modal className="report-modal" isOpen={isOpen} centered={true} toggle={() => onToggle(false)}>
      <ModalHeader
        className="report-modal__modal-header p-4"
        close={<CloseModalButton onClick={() => onToggle(false)} />}
      >
        <span className="font-size-18 report-modal__title">Report User</span>
      </ModalHeader>

      <ModalBody className="p-4 pt-0">
        <div className="position-relative report-modal__input-wrapper">
          <ReportModalSelect options={options} selected={selected} onSelect={setSelected} />
        </div>
      </ModalBody>

      <ReportModalFooter isDisabled={!options.includes(selected)} onClick={handleClick} />
    </Modal>
  )
}

ReportModal.propTypes = {
  isOpen: PropTypes.bool,
  onReport: PropTypes.func,
  onToggle: PropTypes.func,
}
