import React from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import PropTypes from "prop-types"

import { CloseModalButton } from "components/CloseModalButton/CloseModalButton"

import { ChatDeleteModalFooter } from "./components/ChatDeleteModalFooter/ChatDeleteModalFooter"

export const ChatDeleteModal = ({ opened, roomName, onCancel, onDelete }) => {

  return (
    <Modal className="chat-delete-modal" isOpen={opened} centered={true} toggle={onCancel}>
      <ModalHeader
        className="chat-delete-modal__modal-header"
        close={<CloseModalButton onClick={onCancel} />}
      >
        <span className="font-size-18 chat-delete-modal__title">Delete room</span>
      </ModalHeader>

      <ModalBody className="chat-delete-modal__body">
        <p className="chat-delete-modal__text">
          Are you sure you want to delete {roomName}? You can’t undo this action
        </p>
      </ModalBody>

      <ChatDeleteModalFooter
        onDelete={onDelete}
        onCancel={onCancel}
      />
    </Modal>
  )
}

ChatDeleteModal.propTypes = {
  opened: PropTypes.bool,
  roomName: PropTypes.string,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
}
