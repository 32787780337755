import React, { useState, useCallback } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import cx from "classnames"
import { getFirebaseBackend } from "helpers/firebase_helper"
import { useNotifications } from "pages/Notifications/hooks/useNotifications"

const NotificationDropdown = ({ className, user, member, history }) => {
  const fireBaseBackend = getFirebaseBackend()
  const { newNotifications, getAllNotifcations } = useNotifications(user, member, true, history)

  const [menu, setMenu] = useState(false)

  const classes = cx("dropdown d-inline-block notification-dropdown", className)
  const buttonClasses = cx("btn header-item noti-icon waves-effect", {
    [`${className}-button`]: !!className,
  })
  const badgeClasses = cx("badge badge-danger badge-pill notification-dropdown__badge", {
    [`${className}-badge`]: !!className,
  })

  const bellClasses = cx("bx bx-bell", {
    "bx-tada": !!newNotifications?.length,
  })

  const handleClick = useCallback(() => {
    fireBaseBackend.setAllNotificationsToRead(user?.uid)
  }, [user, fireBaseBackend])

  const handleToggle = useCallback(() => {
    setMenu(!menu)
  }, [menu])

  return (
    <>
      <Dropdown isOpen={menu} toggle={handleToggle} className={classes} tag="li">
        <DropdownToggle
          className={buttonClasses}
          tag="button"
          id="page-header-notifications-dropdown"
          onClick={handleClick}
        >
          <i className={bellClasses} />
          {!!newNotifications?.length && <span className={badgeClasses}>{newNotifications.length}</span>}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg notification-dropdown__menu" end>
          <div className="notification-dropdown__header">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 notification-dropdown__title">Notifications</h6>
              </Col>
              <div className="col-auto">
                <Link to="/notifications" className="small notification-dropdown__link">
                  View All
                </Link>
              </div>
            </Row>
          </div>

          <SimpleBar className="notification-dropdown__scroll">
            <ul className="list-group list-group-flush notification-dropdown__list">{getAllNotifcations}</ul>
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

NotificationDropdown.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  history: PropTypes.object,
  member: PropTypes.object,
}

const mapStateToProps = state => {
  return { user: state.Account.user, member: state.Account.settings }
}

export default connect(mapStateToProps)(NotificationDropdown)
