export default [
  "Investor",
  "Real Estate Agent",
  "Real Estate Broker",
  "Developer",
  "Mortgage Broker",
  "Other"
];

export const rolesIconsMap = {
  ['Investor']: "residential",
  ['Real Estate Agent']: "agent",
  ['Real Estate Broker']: "agent",
  ['Developer']: "engineering",
  ['Mortgage Broker']: "balance",
  ['Other']: "houses",
}