import React, { useState } from "react"
import PropTypes from "prop-types"

import { connect, useSelector } from "react-redux"

import { Link } from "react-router-dom"

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions"

import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../ProfileMenu/ProfileMenu"

import logoSvg from "../../assets/images/logo.svg"
import logoLightSvg from "../../assets/images/logo-light.svg"
import MainMenu from "components/MainMenu/MainMenu"

const Header = props => {
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={""} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={""} alt="" height="17" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-xs">
                  <img src={logoLightSvg} alt="" height="20" />
                </span>
                <span className="logo-sm">
                  <img src={logoSvg} alt="" height="32" />
                </span>
                <span className="logo-lg">
                  <img src={logoLightSvg} alt="" height="24" />
                </span>
              </Link>
            </div>
          </div>

          <div className="h-100 d-none d-sm-block">
            <MainMenu />
          </div>

          <div className="d-flex">
            <NotificationDropdown className="navbar-header__notification-dropdown" history={props.history} />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  leftMenu: PropTypes.any,
  history: PropTypes.object,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout
  return { layoutType, showRightSidebar, leftMenu }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(Header)
