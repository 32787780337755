import React from 'react'
import PropTypes from 'prop-types'

import { getFileSize } from 'helpers/getFileSize'

import { loadingText } from '../../texts'

export const ModalUploadFile = ({ file }) => {
  return (
    <div className='modal-upload-file d-flex align-items-center background-color-gray-100 border-radius-4'>
      <div className='modal-upload-file__image border-radius-4 mr-2'></div>

      <div className='d-flex flex-column justify-cintent-center'>
        <span className='font-size-11 color-gray-300 pb-1'>{file.name ? file.name : loadingText}</span>

        {file.size && <span className='font-size-11'>{getFileSize(file.size)}</span>}
      </div>
    </div>
  )
}

ModalUploadFile.propTypes = {
  file: PropTypes.object,
}
