import React from "react"
import PropTypes from "prop-types"
import { UncontrolledTooltip } from "reactstrap"
import cx from "classnames"

export const ChatMainFooter = React.forwardRef(({
  disabled, value, onChange, onKeyPress, onUpload }, ref) => {
  const classes = cx("chat-main-footer", {
    "is-disabled": disabled
  })

  return (
    <div className={classes} ref={ref}>
      <div className="position-relative">
        <input
          type="text"
          value={value}
          onKeyPress={onKeyPress}
          onChange={onChange}
          className="font-size-13 form-control chat-main-footer__input"
          placeholder="Enter Message..."
        />

        <div className="position-absolute d-flex align-items-center h-100 chat-main-footer__icons">
          <div id="file-tooltip" className="position-relative chat-main-footer__icon">
            <input
              id="file-upload"
              type="file"
              name="file"
              className="chat-main-footer__upload"
              onChange={(e) => onUpload(e)}
            />
            <label
              htmlFor="file-upload"
              className="font-size-16 d-flex align-items-center justify-content-center mb-0 chat-main-footer__label"
            >
              <i className="mdi mdi-paperclip" />
            </label>
          </div>
          <UncontrolledTooltip
            innerClassName="font-size-13 chat-main-footer__tooltip-text"
            popperClassName="p-0 overflow-hidden  d-flex align-items-center chat-main-footer__tooltip-wrapper"
            placement="bottom"
            target="file-tooltip"
          >
            Add Files
          </UncontrolledTooltip>
        </div>
      </div>
    </div>
  )
})

ChatMainFooter.displayName = 'ChatMainFooter';

ChatMainFooter.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onUpload: PropTypes.func,
} 
