import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Card, CardBody, CardText, CardTitle } from "reactstrap"

import { ContentEditableWrapper } from "components/ContentEditable/ContentEditable"

import { EditButton } from "../EditButton/EditButton"

export const AboutProfileSection = ({ about, isPrivate, onChange }) => {
  const [currentAbout, setCurrentAbout] = useState("")
  const [isEditModeDisabled, setIsEditModeDisabled] = useState(true)

  const handleOnChange = event => {
    const value = event.target.value
    setCurrentAbout(value)
  }

  const handleOnBlur = event => {
    const textContent = event.target.textContent
    onChange(textContent)
    setIsEditModeDisabled(true)
  }

  useEffect(() => {
    setCurrentAbout(about)
  }, [about])

  return (
    <Card className="about-profile-section p-4">
      <CardBody className="about-profile-section__body p-0">
        <CardTitle
          tag="h4"
          className="about-profile-section__title font-size-18 mb-3 d-flex align-items-center"
        >
          About
        </CardTitle>
        <CardText className="about-profile-section__description">
          <ContentEditableWrapper
            value={currentAbout || ''}
            disabled={isEditModeDisabled}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          />
        </CardText>
      </CardBody>
      {isPrivate && (
        <EditButton
          className="about-profile-section__edit"
          onClick={() => setIsEditModeDisabled(false)}
        />
      )}
    </Card>
  )
}

AboutProfileSection.propTypes = {
  about: PropTypes.string,
  isPrivate: PropTypes.bool,
  onChange: PropTypes.func,
}
