import React, { useState, useEffect } from "react"

import { timeSince } from "components/Activity/components/ActivityItem/ActivityItem"

export const useOptionData = (selectedOption, option) => {
  const [data, setData] = useState({ image: "", message: "", name: "", time: "" })

  const isChat = !selectedOption?.hasOwnProperty("investment_location")

  useEffect(() => {
    if (selectedOption?.id === option?.id) {
      setData({
        image: isChat ? option.cover_image : selectedOption.cover_image,
        message: getMessage(selectedOption),
        name: isChat ? option.name : selectedOption.name,
        time: getTime(selectedOption),
      })
      return
    }
    setData({
      image: option.cover_image,
      message: getMessage(option),
      name: option.name,
      time: getTime(option),
    })
  }, [setData, isChat, option, selectedOption])

  return data
}

const getMessage = item => {
  if (!!item?.chat && item?.chat?.length > 0) {
    return item.chat[item.chat.length - 1].content
  }
  return ""
}

const getTime = item => {
  if (!!item?.chat) {
    return timeSince(item?.chat[item?.chat.length - 1]?.created)
  }
  return ""
}
