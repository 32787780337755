import React, { useState, useEffect, useRef } from "react"
import { MainModal } from "modals/components/parts/MainModal/MainModal"
import { useLocation } from "react-router-dom"

const serviceItems = [
  {
    id: "real-estate-broker",
    title: "Real Estate Broker",
    description: "Expert guidance in property transactions, offering personalized advice and deep market knowledge.",
    iconUrl:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/e97f0e476187251756f4b4a2d7ab0ec2ff26b473223df91ade5eaaee1c38d2ac?apiKey=28e7f9353ed44b01a930c51b168e9c17&",
    agents: [
      {
        profile_photo:
          "https://cdn.builder.io/api/v1/image/assets/TEMP/07be30709486c1737c676f81a75cae7932387dd1212c2515dfa94f11f4dfe353?apiKey=28e7f9353ed44b01a930c51b168e9c17&",
        position: "Broker at Remax",
        name: "Jimmy Lemieux",
        email: "jimmylemieux@remax-quebec.com",
        phone: "514-825-9488",
        description:
          "I can help with: prospecting, negotiating and closing deals. Top broker at Remax with more than 10 years of experience.",
      },
    ],
  },
  {
    id: "residential-mortgage",
    title: "Residential Mortgage Specialist",
    description: "Navigating mortgage options to secure the best rates for your residential property needs.",
    iconUrl:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/e97f0e476187251756f4b4a2d7ab0ec2ff26b473223df91ade5eaaee1c38d2ac?apiKey=28e7f9353ed44b01a930c51b168e9c17&",
    agents: [
      {
        profile_photo:
          "https://cdn.builder.io/api/v1/image/assets/TEMP/20563d68844611f803369a7fc9da3f05a2521c9e1ec2ff73e380547a4dbba028?apiKey=28e7f9353ed44b01a930c51b168e9c17&",
        position: "Specialist at Multi-Prets",
        name: "Marie-Josée Garceau",
        email: "mjgarceau@multi-prets.ca",
        phone: "514-803-8752",
        description:
          "I can help with: Securing the lowest rates for residential properties. 20 years of experience working with Canada's largest banks.",
      },
    ],
  },
  {
    id: "commercial-mortgage",
    title: "Commercial Mortgage Specialist",
    description:
      "Assistance in financing commercial projects, leveraging extensive banking experience for optimal solutions.",
    iconUrl:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/ad72acac90170444c0bef32945c9f85372ed20ee389ddbd0a5146964517c8546?apiKey=28e7f9353ed44b01a930c51b168e9c17&",
    agents: [
      {
        profile_photo:
          "https://cdn.builder.io/api/v1/image/assets%2F28e7f9353ed44b01a930c51b168e9c17%2Ff528dba604a54f13bf1859ac119e964c",
        position: "Senior Manager at TD",
        name: "Hadi Hussayni",
        email: "Hadi.hussayni@td.com",
        phone: "514-335-6825",
        description:
          "I can help with: Financing commercial real estate projects. Experienced business banker with a strong track record in financing larger projects. ",
      },
    ],
  },
  {
    id: "investment-advice-coaching",
    title: "Investment Coach",
    description:
      "Expert coaching to navigate and grow your real estate investments, fostering success in your portfolio.",
    iconUrl:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/89bb4008d52ed6739426c44b98c6c2c153cf3b3b5a208e4fb1e957759c739b80?apiKey=28e7f9353ed44b01a930c51b168e9c17&",
    agents: [
      {
        profile_photo:
          "https://cdn.builder.io/api/v1/image/assets%2F28e7f9353ed44b01a930c51b168e9c17%2Fea3fc7df364a497bb4a0c39364820c4e",
        position: "President at Real Estate Effect",
        name: "Axel Monsaingeon",
        email: "axel@realestateeffect.ca",
        phone: "514-836-5519",
        description:
          "I can help with: Starting and growing your real estate portfolio. Real estate coach with more than 10 years of experience guiding investors.",
      },
    ],
  },
]


export const ExpertsModal = props => {
  const [isActive, setIsActive] = useState(false)
  const modalRef = useRef(null)
  // State to manage the currently active service item and visibility of agents
  const [activeServiceId, setActiveServiceId] = useState(null)
  // New state to manage if we're viewing agents or the list of services
  const [viewingAgents, setViewingAgents] = useState(false)
  const location = useLocation()

  const handleClickOutside = event => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsActive(false) // Close the modal if click is outside
      setViewingAgents(false)
    }
  }

  const handleEscPress = event => {
    if (event.key === "Escape") {
      setIsActive(false) // Close the modal on ESC press
      setViewingAgents(false)
    }
  }

  const showAgentsForService = id => {
    setActiveServiceId(id)
    setViewingAgents(true) // Show agents for the selected service
  }

  const goBackToServices = () => {
    setViewingAgents(false) // Go back to viewing the list of all services
  }

  useEffect(() => {
    
    setTimeout(() => {
      const expertModals = document.querySelectorAll('[data-element="experts-modal"]')
      console.log(expertModals)

      expertModals.forEach(modal => {
        modal.addEventListener("click", e => {
          e.preventDefault()
          setIsActive(true)
        })
      })

      // Attach event listeners
      document.addEventListener("mousedown", handleClickOutside)
      document.addEventListener("keydown", handleEscPress)
    }, 1000);

    return () => {
      // Clean up event listeners
      document.removeEventListener("mousedown", handleClickOutside)
      document.removeEventListener("keydown", handleEscPress)
    }
  }, [location])

  return (
    <MainModal className="experts-modal__container" isOpened={isActive}>
      <img role="button" data-element="close-modal" src="/close-icon.svg" alt="Close Icon" />
      <div className="welcome-modal" ref={modalRef}>
        {!viewingAgents ? (
          // Show service items when not viewing agents
          <>
            <div className={`screen-1`}>
              <div className="welcome-modal__text">
                <h2>Real Estate Professionals</h2>
                <p>Create the perfect team of real estate professionals to guide you towards real estate success.</p>
              </div>
            </div>
            {serviceItems.map(item => (
              <article
                key={item.id}
                role="button"
                data-element={item.id}
                onClick={() => showAgentsForService(item.id)}
                className="service-item highlighted"
              >
                <img src={item.iconUrl} alt={`${item.title} icon`} className="service-icon" />
                <div className="service-details">
                  <h3 className="service-title">{item.title}</h3>
                  <p className="service-description">{item.description}</p>
                </div>
              </article>
            ))}
          </>
        ) : (
          // When viewing agents, show the "Go Back" link and agent details
          <>
            <a onClick={goBackToServices} className="go-back-link" style={{ cursor: "pointer", marginBottom: "20px" }}>
              Go Back
            </a>
            {serviceItems
              .filter(item => item.id === activeServiceId)
              .flatMap(item =>
                item.agents.map((agent, index) => (
                  <div key={index} className="agent-info">
                    <header className="agent-header">
                      {agent.profile_photo && (
                        <img src={agent.profile_photo} alt={`${agent.name} photo profile`} className="agent-avatar" />
                      )}
                      {agent.name && (
                        <div className="agent-details">
                          <h2 className="agent-name">{agent.position}</h2>
                          <p className="agent-title">{agent.name}</p>
                        </div>
                      )}
                    </header>
                    <p className="agent-title">{agent.description}</p>
                    <div className="agent-actions">
                      <a href={`mailto:${agent.email}`} className="email-button">
                        <img src="/carbon_email.svg" alt={`email icon`} className="icon email-icon" />
                        <span>{agent.email}</span>
                      </a>
                      <a href={`tel:${agent.phone}`} className="call-button">
                        <img src="/bi_phone.svg" alt={`phone icon`} className="icon phone-icon" />
                        <span>{agent.phone}</span>
                      </a>
                    </div>
                  </div>
                ))
              )}
          </>
        )}
      </div>
    </MainModal>
  )
}
