import PropTypes from "prop-types";
import React from "react";
import { Row, Col, Alert, FormFeedback, Label, Form } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { userForgetPassword } from "store/actions";

import { InputField } from "components/InputField/InputField";
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton";

const ForgetPasswordPage = props => {
  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: (values) => {
      dispatch(userForgetPassword(values, props.history));
    }
  });

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }));

  return (
    <div className="forget-password">
      {forgetError && forgetError ? (
        <Alert color="danger">
          If the user exists you will receive an email with a link to reset the password.
        </Alert>
      ) : null}
      {forgetSuccessMsg ? (
        <Alert color="success">
          {forgetSuccessMsg}
        </Alert>
      ) : null}

    <Form
      className="form-horizontal"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <div className="mb-3">
        <Label className="form-label">Email</Label>

        <InputField
          className=""
          inputName="email"
          type="email"
          placeholder="Enter email"
          value={validation.values.email || ""}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          invalid={validation.touched.email && validation.errors.email ? true : false}
          error={validation.touched.email && validation.errors.email ? (
            <FormFeedback type="invalid" className="login__error-text font-size-11">{validation.errors.email}</FormFeedback>
          ) : null}
        />
      </div>
      <Row className="mb-0">
        <Col className="text-end">

          <PrimaryButton
            className="w-100 btn btn-primary btn-block"
            type="submit"
            text="Reset"
          />
        </Col>
      </Row>
    </Form>

    <button
      className="position-absolute font-size-13 text-decoration-underline border-0 forget-password__login"
      onClick={props.onClick}
    >Go back to Login</button>
  </div>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
  onClick: PropTypes.func,
};

export default withRouter(ForgetPasswordPage);
