import firebase from 'firebase/compat/app'
import 'firebase/compat/analytics'
import 'firebase/compat/storage'

import { residential } from './variables'

export const logAllSteps = (settings, currentTab, steps, userId) => {
  const analytics = firebase.analytics()

  const settingsSteps = [
    !!settings?.roles,
    settings?.investment_location?.length > 0,
    settings?.investment_types?.length > 0,
    !!settings?.investor_experience,
    !!settings?.investor_location,
    !!settings?.project_size,
    settings?.skills?.length > 0,
    !!settings?.profile_image,
    !!settings?.bio,
  ]

  const progress = settingsSteps.filter(item => !!item)

  if(settingsSteps?.length === progress.length && currentTab === steps){
    analytics.logEvent('users_completed_all_steps', { user_id: userId });
  }
}

export const updateRoomCoverImage = (id, image) => {
  const storageRef = firebase.storage().ref()
  const path = `files/${id}/${image.name}`
  const fileRef = storageRef.child(path)

  // push file to storage
  const task = fileRef.put(image)

    task.on('state_changed',
    () => console.log("File upload.."),
    (error) => console.log(error),
    () => {
      console.log("Done")
      // get full link to file in storage to add it in room object
      fileRef.getDownloadURL()
        .then((url) => {
          firebase.firestore().collection("rooms").doc(id).update({ cover_image: url })
          console.log("URL updated")
        })
        .catch((error) => console.log(error));
    })
}

export const updateRoomFiles = (id, files) => {
  const links = []

  const storageRef = firebase.storage().ref()
  files.forEach(file => {
    const path = `files/${id}/${file.name}`
    const fileRef = storageRef.child(path)

    links.push(path)

    if(links.length === files.length){
      firebase.firestore().collection("rooms").doc(id).update({ files: links })
    }

    // push file to storage
    const task = fileRef.put(file)
    task.on('state_changed',
    () => console.log("File upload.."),
    (error) => console.log(error),
    () => console.log("Done"))
  })
}

export const getRoomDefaultSettings = id => {
  return {
    investment_types: [],
    investment_location: [],
    tasks: [],
    admin_user: id,
    links: [],
    users: [],
    status: 'active',
    chat: null,
  }
}

export const onChangeValue = (values, setValues, id) => {
  if(values.includes(id)){
    setValues(values.filter(item => item !== id))
    return
  }
  setValues([ ...values, id ])
}

export const onChangeOneValue = (value, setValue, id) => {
  if(value === id){
    setValue("")
    return
  }
  setValue(id)
}

export const onChangeSimpleValue = (value, setValue) => {
  setValue(value)
}

export const onChangeLocation = (location, data, setData) => {
  if(typeof location === 'string'){
    const list = data.filter(item => {
      return item !== location
    })
    data.length === list.length ?
      setData([ ...data, list ]) :
      setData(list)
    return
  }
  
  if(typeof location === 'object' && !!location?.lat){
    const list = data.filter(item => {
      return item?.lat !== location?.lat && item?.lng !== location?.lng
    })
    data.length === list.length ?
      setData([ ...data, location ]) :
      setData(list)
  }
}

export const getTypes = (types, investmentTypes, propertyTypes) => {
  return types.map(type => {
    if (type === residential) {
      return {
        type: type,
        investment_type: investmentTypes,
        property_type: propertyTypes,
      }
    }
    return { type: type, investment_type: [], property_type: [] }
  })
}
