import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Location } from '../../common/Location/Location'
import { Step } from '../../common/Step/Step'

export const LocationsStep = ({
  currentTab,
  questionNumber = '3',
  userLocation, 
  investmentLocation,
  userLocationEnabled = true,
  isRoom,
  onChangeUserLocation,
  onChangeInvestmentLocation
}) => {
  const text = userLocationEnabled ? 'Location' : 'Location'
  const step = isRoom ? 2 : 3
  const classes = cx({ 'd-block': currentTab === step, 'd-none': currentTab !== step, })

  return (
    <div className={classes}>
      {userLocationEnabled && (
        <Step
          heading='Location'
          description='Where are you located?'
        >
          <Location items={!!userLocation ? [userLocation] : []} onClick={onChangeUserLocation} />
        </Step>
      )}

      <Step
        heading={text}
        description='Where are you looking to invest?'
      >
        <Location items={investmentLocation} onClick={onChangeInvestmentLocation} />
      </Step>
    </div>
  );
};

LocationsStep.propTypes = {
  currentTab: PropTypes.number,
  questionNumber: PropTypes.string,
  userLocation: PropTypes.oneOfType([
    PropTypes.object, PropTypes.string,
  ]),
  investmentLocation: PropTypes.array,
  isRoom: PropTypes.bool,
  userLocationEnabled: PropTypes.bool,
  onChangeUserLocation: PropTypes.func,
  onChangeInvestmentLocation: PropTypes.func,
};
