/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT"
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH"
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME"
export const CHANGE_SIDEBAR_THEME_IMAGE = "CHANGE_SIDEBAR_THEME_IMAGE"
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE"

// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME"

// show sidebar
export const SHOW_SIDEBAR = "SHOW_SIDEBAR"
export const TOGGLE_LEFTMENU = "TOGGLE_LEFTMENU"

/* RIGHT SIDEBAR */
export const SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR"

// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER"

// Update Profile Modal
export const OPEN_UPDATE_PROFILE_MODAL = "OPEN_UPDATE_PROFILE_MODAL"

// Update Welcome Modal
export const UPDATE_SET_IS_ION_PROFILE = "UPDATE_SET_IS_ION_PROFILE"

// Promo Banner
export const OPEN_PROMO_ALERT = "OPEN_PROMO_ALERT"

// Messages Right Sidebar
export const OPEN_MESSAGES_RIGHT_SIDEBAR = "OPEN_MESSAGES_RIGHT_SIDEBAR"

// Messages Left Sidebar
export const OPEN_MESSAGES_LEFT_SIDEBAR = "OPEN_MESSAGES_LEFT_SIDEBAR"

// Attacment modal
export const OPEN_ATTACHMENTS_MODAL = "OPEN_ATTACHMENTS_MODAL"

// Google maps script loaded
export const LOADED_MAPS_SCRIPT = " LOADED_MAPS_SCRIPT"

// Add member modal
export const OPEN_ADD_MEMBER_MODAL = "OPEN_ADD_MEMBER_MODAL"

// Current opened room
export const OPENED_ROOM = "OPENED_ROOM"

export const SET_MOBILE_USER_MENU_ACTIVE = "SET_MOBILE_USER_MENU_ACTIVE"
