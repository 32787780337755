import { getFullName } from "components/Profile/Profile.helpers"
import { getGeocode, getLatLng } from "use-places-autocomplete"

const smallRadius = 100
const mediumRadius = 1000
const largeRadius = 5000

export const isSearch = query => {
  return !!query?.name || !!query?.locations?.length || !!query?.size || !!query?.types?.length
}

export const getParams = (search, settings, query) => {
  return search
    ? {
        investment_location: query?.locations,
        project_size: query?.size,
        investment_type: query?.types,
        investor_experience: "",
        skills: "",
        investor_location: "",
        name: query?.name,
      }
    : {
        // investment_location: settings.investment_location,
        investment_location: ["Canada"],
        project_size: settings.project_size,
        investment_type: settings.investment_types,
        investor_experience: settings.investor_experience,
        skills: settings.skills,
        investor_location: settings.investor_location,
        name: "",
      }
}

export const getGeoCode = async (descriptions, onChange) => {
  let finalCoordinates = []
  const onResult = coordinates => {
    finalCoordinates.push(coordinates)
  }
  if (descriptions.length > 1) {
    const mapArray = await descriptions.map(description => {
      getGeocode({ address: description }).then(results => {
        const { lat, lng } = getLatLng(results[0])

        onResult({ lat, lng })
      })
    })

    Promise.all(mapArray).then(() => {
      onChange({ coordinates: finalCoordinates })
    })
  } else {
    getGeocode({ address: descriptions[0] }).then(results => {
      const { lat, lng } = getLatLng(results[0])

      onChange({
        coordinates: { lat, lng },
      })
    })
  }
}

const getDistanceByOneLocation = (items, coordinates) => {
  let finalRooms = []
  items?.map(room => {
    if (room.investment_location?.length === 1) {
      const distance = geoDistance(
        room?.investment_location[0]?.lat,
        room?.investment_location[0]?.lng,
        coordinates?.coordinates?.lat,
        coordinates?.coordinates?.lng
      )

      if (distance <= smallRadius) {
        finalRooms.push(room)
      }
    } else if (room.investment_location?.length > 1) {
      room.investment_location?.map(location => {
        const distance = geoDistance(
          location.lat,
          location.lng,
          coordinates?.coordinates?.lat,
          coordinates?.coordinates?.lng
        )

        if (distance <= smallRadius) {
          finalRooms.push(room)
        }
      })
    }
  })

  const smallRadiusRooms = finalRooms?.filter((room, index, self) => self.findIndex(r => r.id === room.id) === index)

  if (smallRadiusRooms.length < 10) {
    items?.map(room => {
      if (room.investment_location?.length === 1) {
        const distance = geoDistance(
          room?.investment_location[0]?.lat,
          room?.investment_location[0]?.lng,
          coordinates?.coordinates?.lat,
          coordinates?.coordinates?.lng
        )

        if (distance <= mediumRadius) {
          finalRooms.push(room)
        }
      } else if (room.investment_location?.length > 1) {
        room.investment_location?.map(location => {
          const distance = geoDistance(
            location.lat,
            location.lng,
            coordinates?.coordinates?.lat,
            coordinates?.coordinates?.lng
          )

          if (distance <= mediumRadius) {
            finalRooms.push(room)
          }
        })
      }
    })
  } else {
    return smallRadiusRooms
  }

  const mediumRadiusRooms = finalRooms?.filter((room, index, self) => self.findIndex(r => r.id === room.id) === index)

  if (mediumRadiusRooms.length < 10) {
    items?.map(room => {
      if (room.investment_location?.length === 1) {
        const distance = geoDistance(
          room?.investment_location[0]?.lat,
          room?.investment_location[0]?.lng,
          coordinates?.coordinates?.lat,
          coordinates?.coordinates?.lng
        )

        if (distance <= largeRadius) {
          finalRooms.push(room)
        }
      } else if (room.investment_location?.length > 1) {
        room.investment_location?.map(location => {
          const distance = geoDistance(
            location.lat,
            location.lng,
            coordinates?.coordinates?.lat,
            coordinates?.coordinates?.lng
          )

          if (distance <= largeRadius) {
            finalRooms.push(room)
          }
        })
      }
    })
  } else {
    return mediumRadiusRooms
  }

  const largeRadiusRooms = finalRooms?.filter((room, index, self) => self.findIndex(r => r.id === room.id) === index)

  return largeRadiusRooms
}

export const filterItemsByLocation = (search, items, coordinates) => {
  if (search && !coordinates?.coordinates?.length) {
    return getDistanceByOneLocation(items, coordinates)
  } else {
    return items
  }
}

export const filterItemsByType = (search, items, params) => {
  const filterTypes = params?.investment_type
  if (!filterTypes?.length) {
    return items
  }

  const filteredItems = items?.filter(item => {
    const hasMatchingType = item?.investment_types?.some(type => {
      return filterTypes?.includes(type?.type)
    })

    return hasMatchingType
  })

  return filteredItems
}

export const filterItemsBySize = (search, items, params) => {
  if (!params?.project_size) {
    return items
  }

  const filteredItems = items.map(item => {
    const min =
      (item?.project_size?.min >= params?.project_size?.min) & (item?.project_size?.min <= params?.project_size?.max)
    const max = item?.project_size?.max <= params?.project_size?.max

    const matchesBudget = min && max

    if (matchesBudget) {
      return item
    }
  })

  return filteredItems.filter(item => item !== undefined)
}

export const filterItemsByName = (items, params) => {
  return items?.filter(item => {
    return item?.name?.toLowerCase()?.includes(params?.name?.toLowerCase()) || item?.description?.toLowerCase()?.includes(params?.name?.toLowerCase())
  })
}

export const filterItemsByUserName = (items, params) => {
  return items?.filter(item => {
    const fullName = getFullName(item?.firstName, item?.lastName)
    return fullName?.toLowerCase()?.includes(params?.name?.toLowerCase())
  })
}

export const sortItemsByPoints = items => {
  return items.sort((a, b) => b.points - a.points)
}

export const filterItemsByPoints = (items, params) => {
  const resultsSmallRadius = getResultsByDistanceWithPoints(items, params, smallRadius)

  if (resultsSmallRadius.length >= 50) {
    return resultsSmallRadius
  }

  const resultsMediumRadius = getResultsByDistanceWithPoints(items, params, mediumRadius)

  if (resultsMediumRadius.length >= 50) {
    return resultsMediumRadius
  }

  const resultsLargeRadius = getResultsByDistanceWithPoints(items, params, largeRadius)

  if (resultsLargeRadius.length >= 50) {
    return resultsLargeRadius
  }

  return items.filter(item => {
    return !!item.points || !!item.investment_location?.length
  })
}

export const filterItemsByInvestorLocation = (location, items, params) => {
  if (location?.length) {
    const locationArray = location[0].split(",")
    const locationArrayLowerCase = locationArray.map(item => item.toLowerCase())

    const filteredItems = items.filter(item => {
      const investorLocation = item?.investor_location

      if (investorLocation) {
        const investorString = investorLocation.toString()
        const investorStringLower = investorString.toLowerCase()

        const hasMatchingLocation = locationArrayLowerCase.some(location => investorStringLower.includes(location))

        return hasMatchingLocation
      }

      return false
    })

    return filteredItems
  }

  return items
}

export const filterItemsByExperience = (search, items, params) => {
  return items.map(item => {
    if (item.investor_experience === params.investor_experience) {
      return { ...item, points: search ? item.points : item.points + 0.5 }
    }
    return item
  })
}

const getResultsByDistanceWithPoints = (items, params, radius) => {
  const roomsRadius = getResultsByDistance(items, params, radius)

  return roomsRadius.filter(item => !!item.points)
}

const getResultsByDistance = (rooms, params, searchDistance) => {
  const roomsWithLocation = rooms.filter(room => !room.points && !!room.investment_location?.length)

  return roomsWithLocation.map(roomWithLocation => {
    const result = !!roomWithLocation.investment_location?.find(location => {
      return !!params.investment_location?.find(paramsLocation => {
        if (typeof location === "object" && typeof paramsLocation === "object") {
          const distance = geoDistance(location.lat, location.lng, paramsLocation.lat, paramsLocation.lng)

          if (distance < searchDistance) {
            return paramsLocation
          }
        }
      })
    })

    if (result) {
      return { ...roomWithLocation, points: roomWithLocation.points + 1 }
    }
    return roomWithLocation
  })
}

export const geoDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371e3,
    π = Math.PI
  const { sin, cos, atan2 } = Math
  const φ1 = (lat1 * π) / 180,
    λ1 = (lon1 * π) / 180
  const φ2 = (lat2 * π) / 180,
    λ2 = (lon2 * π) / 180
  const Δφ = φ2 - φ1,
    Δλ = λ2 - λ1

  const a = sin(Δφ / 2) ** 2 + cos(φ1) * cos(φ2) * sin(Δλ / 2) ** 2
  const c = 2 * atan2(a ** 0.5, (1 - a) ** 0.5)
  const d = R * c

  return d / 1000
}
