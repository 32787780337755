import { useDispatch } from "react-redux"
import { useMemo, useCallback } from "react"
import { notificationComponentsRegistry } from "../constants/notificationRegistry.registry"
import { openedRoomAction, setActiveCategory } from "store/actions"
import { getFirebaseBackend } from "helpers/firebase_helper"
import { getNotificationEntity } from "../helpers/notifcations.helpers"

export const useNotifications = (user, member, isSmall = false, history) => {
  const fireBaseBackend = getFirebaseBackend()
  const dispatch = useDispatch()

  const handleOnReadNotification = useCallback(
    notificationId => {
      fireBaseBackend.updateUserNotificationReadStatus(notificationId, user?.uid)
    },
    [user?.uid]
  )

  const handleOnDeleteNotification = useCallback(
    notificationId => {
      fireBaseBackend.removeUserNotification(notificationId, user?.uid)
    },
    [user?.uid]
  )

  const handleRoomClickNotification = useCallback(roomId => {
    dispatch(openedRoomAction(roomId))
    dispatch(setActiveCategory("groups"))
  }, [])

  const handleOnRequestToInviteAction = useCallback(
    (action, actorId, roomName, roomId, notificationId, byAdmin = false, room) => {
      if (action === "accept") {
        fireBaseBackend.updateUserNotificationById(
          actorId,
          getNotificationEntity("request-to-join-accepted", {
            user,
            member,
            actorId,
            roomId,
            roomName,
            room,
          })
        )

        fireBaseBackend.acceptUserToRoom(roomId, user.uid, notificationId, actorId, true, byAdmin)
        setTimeout(() => {
          dispatch(openedRoomAction(roomId))
          dispatch(setActiveCategory("groups"))
          history.push("/messages")
        }, 500)
      }
      if (action === "deny") {
        fireBaseBackend.updateUserNotificationById(
          actorId,
          getNotificationEntity("request-to-join-declined", {
            user,
            member,
            actorId,
            roomId,
            roomName,
            room,
          })
        )
        fireBaseBackend.acceptUserToRoom(roomId, user.uid, notificationId, actorId, false, byAdmin)
      }
    }
  )

  const newNotifications = useMemo(() => {
    return getFilteredNotifications({
      isSmall,
      notifications: member?.notifications,
      filterByReaded: false,
      onDelete: handleOnDeleteNotification,
      onRoomClick: handleRoomClickNotification,
      onRead: handleOnReadNotification,
      onClick: handleOnRequestToInviteAction,
    })
  }, [member?.notifications])

  const readNotifications = useMemo(() => {
    return getFilteredNotifications({
      isSmall,
      notifications: member?.notifications,
      filterByReaded: true,
      onDelete: handleOnDeleteNotification,
      onRoomClick: handleRoomClickNotification,
      onRead: handleOnReadNotification,
      onClick: handleOnRequestToInviteAction,
    })
  }, [member?.notifications])

  const getAllNotifcations = useMemo(() => {
    return getFilteredNotifications({
      isSmall,
      notifications: member?.notifications,
      filterByReaded: null,
      getAll: true,
      onDelete: handleOnDeleteNotification,
      onRoomClick: handleRoomClickNotification,
      onRead: handleOnReadNotification,
      onClick: handleOnRequestToInviteAction,
    })
  }, [member?.notifications])

  return {
    getAllNotifcations,
    newNotifications,
    readNotifications,
  }
}

const getFilteredNotifications = ({
  isSmall,
  notifications,
  getAll = false,
  filterByReaded,
  onRead,
  onDelete,
  onRoomClick,
  onClick,
}) => {
  if (!!notifications && notifications.length) {
    return notifications
      .filter(notification => (getAll ? notification : notification.read === filterByReaded))
      .sort((a, b) => {
        const dateA = new Date(a.time).getTime()
        const dateB = new Date(b.time).getTime()
        return dateA > dateB ? 1 : -1
      })
      .map(notification => {
        return notificationComponentsRegistry[notification.type].component({
          ...notification,
          isSmall,
          key: notification.id,
          time: notification.createdAt,
          onRead,
          onDelete,
          onRoomClick,
          onClick,
        })
      })
  }

  return []
}
