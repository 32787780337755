export const questionsData = [
  {
    id: "1",
    header: "How does it work?",
    description: `<p>Investium is the platform where people meet to invest together in real estate. After you create your profile, you will be able to find other investors based on your search criteria or you can browse through personalized recommendations. You can then chat with users to get to know them, invite them to join you on a private group with other partners, or request access to an existing group.</p><p>Once you are part of a group with other users you will have many tools to work, plan and research with them. You can be in many groups at the same time and the creator of the group will decide who can join and how many people can be in a group. The groups are private, meaning that no one can join, read the content or participate without approval.</p>`,
  },
  {
    id: "2",
    header: "How do I find the right people?",
    description: `<p>Investium offers you different ways to find people to invest with. The first and faster, is to browse through your personalized recommendations. Based on your profile and activity you will receive recommendations of users and existing groups for you to explore and contact.</p><p>The second option is by using the search tool, in which you can enter multiple criteria and keywords to find existing groups and users that match your preferences. Once you find a group you are interested in, you can send a private message to the admin to know more about the group and request access. In the case of a user, you can send a message to start a conversation and propose to join you in a group.</p>`,
  },
  {
    id: "3",
    header: "What do I do after registering? ",
    description: `<p>The first step after registering is completing your profile as this will allow others to find and message you. The next step is to browse our recommendations or use the search option to get in touch with other users that have similar goals as you do. The last step is to join them in private groups, where you will be able to work together to complete your real estate projects.</p>`,
  },
  {
    id: "4",
    header: "Is Investium safe? ",
    description: `<p>Your security is very important to us. We use state of the art encryption to protect your data and we don’t ask or store financial information. Additionally, there are no financial transactions carried out on the platform.</p><p>We recommend you to exercise caution when sharing details with others and to protect yourself by working with recognized financial institutions and accredited professionals.</p>`,
  },
  {
    id: "5",
    header: "Is Investium free?",
    description: `<p>Yes, Investium is completely free to use.</p>`,
  },
  {
    id: "6",
    header: "How do I delete my personal data?",
    description: `<p>You have the right to request the deletion of your personal information at any time. To initiate this request, please email <a href="mailto:admin@investium.ai">admin@investium.ai</a>. We will respond to and process your request within 30 days.</p>`,
  },
]
