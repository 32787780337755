import React, { useState } from "react";
import PropTypes from 'prop-types';
import cx from 'classnames';

import { getFirebaseBackend } from "helpers/firebase_helper"

import { InputField } from "components/InputField/InputField";
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton";
import { isValidEmail } from "helpers/isValidEmail";

export const FaqContact = () => {
  const fireBaseBackend = getFirebaseBackend()

  const [ valueFirstName, setValueFirstName ] = useState("");
  const [ firstNameError, setFirstNameError ] = useState(false);

  const [ valueLastName, setValueLastName ] = useState("");
  const [ valueTextarea, setValueTextarea ] = useState("");

  const [ valueEmail, setValueEmail ] = useState("");
  const [ emailError, setEmailError ] = useState(false);

  const [ focusedTextarea, setFocusedTextarea ] = useState(false);
  const [ textareaError, setTextareaError ] = useState(false);

  const handleChangeFirstName = (e) => {
    setValueFirstName(e.target.value);
  };

  const handleFirstNameBlur = () => {
    if(!valueFirstName){
      setFirstNameError(true)
      return
    }
    setFirstNameError(false)
  }

  const handleChangeLastName = (e) => {
    setValueLastName(e.target.value);
  };


  const handleChangeEmail = (e) => {
    setValueEmail(e.target.value);
  };

  const handleEmailBlur = () => {
    if(isValidEmail(valueEmail)){
      setEmailError(false)
      return
    }
    setEmailError(true)
  }

  const handleChangeTextarea = (e) => {
    setValueTextarea(e.target.value);
  };

  const handleTextareaBlur = () => {
    setFocusedTextarea(false)
    if(!valueTextarea){
      setTextareaError(true)
      return
    }
    setTextareaError(false)
  }

  const textareaClasses = cx("w-100 faq-contact__textarea", {
    "is-focused": focusedTextarea,
    "is-error": textareaError
  });

  const handleSubmit = () => {
    if(!valueFirstName || !isValidEmail(valueEmail) || !valueTextarea){
      if(!valueFirstName){
        setFirstNameError(true)
      }
      if(!isValidEmail(valueEmail)){
        setEmailError(true)
      }
      if(!valueTextarea){
        setTextareaError(true)
      }
    } else {
      setTextareaError(false)
      setEmailError(false)
      setFirstNameError(false)
      setValueFirstName('')
      setValueLastName('')
      setValueEmail('')
      setValueTextarea('')
      fireBaseBackend.notifyAdminEmailSupport(valueEmail, valueFirstName, valueLastName, valueTextarea)
    }
  }

  return (
    <div className="faq-contact">
      <div className="faq-contact__wrapper">
        <div className="faq-contact__names">
          <InputField
            type="text"
            placeholder="First name*"
            className={cx({ "is-error": firstNameError }, "faq-contact__form")}
            value={valueFirstName}
            onChange={handleChangeFirstName}
            onFocus={()=> {}}
            onBlur={handleFirstNameBlur}
          />

          <InputField
            type="text"
            placeholder="Last name"
            className="faq-contact__form"
            value={valueLastName}
            onChange={handleChangeLastName}
          />
        </div>
      </div>

      <div className="faq-contact__wrapper">
        <InputField
          type="email"
          placeholder="Email*"
          className={cx({ "is-error": emailError }, "faq-contact__form")}
          value={valueEmail}
          onChange={handleChangeEmail}
          onFocus={()=> {}}
          onBlur={handleEmailBlur}
        />
      </div>

      <div className="faq-contact__wrapper">
        <textarea
          placeholder="Enter your message here*"
          className={textareaClasses}
          value={valueTextarea}
          onChange={handleChangeTextarea}
          onFocus={()=> setFocusedTextarea(true)}
          onBlur={handleTextareaBlur}
        />
      </div>

      <PrimaryButton
        className="faq-contact__submit"
        text="Submit"
        onClick={handleSubmit}
      />
  </div>
  );
};

FaqContact.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
};
