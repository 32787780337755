import React, { useCallback, useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"

import { Modal } from "reactstrap"
import { SearchResultsCard } from "components/SearchResultsCard/SearchResultsCard"
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton"
import { SecondaryButton } from "components/SecondaryButton/SecondaryButton"
import { getBudget } from "helpers/getBudget"
import SimpleBar from "simplebar-react"
import { Link } from "react-router-dom"
import { getFullName } from "components/Profile/Profile.helpers"
import { Avatar } from "components/Avatar/Avatar"
import firebase from "firebase/compat/app"
import "firebase/compat/storage"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useDispatch } from "react-redux"
import { registerRoom, openedRoomAction, setActiveCategory } from "store/actions"
import { capitalizeFirstLetter } from "helpers/capitalizeFirstLetter"

export const SearchResultsModal = ({ option, isOpened, history, onRequest, onMessage, onToggle, currentUser }) => {
  const [requested, setRequested] = useState(false)
  const dispatch = useDispatch()

  const handleRequest = () => {
    onRequest()
    setRequested(true)
  }

  useEffect(() => {
    if (requested) {
      setTimeout(() => {
        onToggle()
        setRequested(false)
      }, 2000)
    }
  }, [requested])

  const [openedPanel, setOpenedPanel] = useState(false)
  const handleClick = () => {
    setOpenedPanel(true)
  }

  const handleMessage = useCallback(user => {
    firebase
      .firestore()
      .collection("rooms")
      .where("admin_user", "in", [user.id, currentUser?.uid])
      .get()
      .then(res => {
        const list = res.docs.filter(item => {
          const data = item.data()
          return data.status === "chat"
        })

        const chat = list.find(item => {
          const data = item.data()
          return data.users.includes(user.id) || data.users.includes(currentUser?.uid)
        })

        if (!!chat) {
          dispatch(openedRoomAction(chat.id))
          dispatch(setActiveCategory("chats"))
          if (!!history) {
            history.push("/messages")
          }
        } else {
          const time = Date.now()
          const roomSettings = {
            tasks: [],
            admin_user: currentUser?.uid,
            links: [],
            users: [user.id],
            status: "chat",
            chat: null,
            createdAt: time,
          }
          dispatch(registerRoom(roomSettings, []))

          setTimeout(() => {
            firebase
              .firestore()
              .collection("rooms")
              .where("admin_user", "in", [user.id, currentUser?.uid])
              .get()
              .then(res => {
                res.docs.find(item => {
                  if (item.data().createdAt === time) {
                    dispatch(openedRoomAction(item.id))
                    dispatch(setActiveCategory("chats"))

                    setTimeout(() => {
                      if (!!history) {
                        history.push("/messages")
                      }
                    }, 1000)
                  }
                })
              })
          }, 1000)
        }
      })
  })

  const usersData = useMemo(() => {
    return firebase.firestore().collection("users")
  }, [option])

  const [usersList, , , docs] = useCollectionData(usersData)

  const users = useMemo(() => {
    if (!!option) {
      const list = [option?.admin_user, ...option?.users]

      const usersListWithId = !!usersList
        ? usersList.map((user, index) => {
            return { ...user, id: docs.docs[index].id }
          })
        : []
      return usersListWithId.filter(user => {
        if (!!list.length) {
          return list.includes(user.id)
        }
      })
    }
    return []
  }, [usersList, docs, option])

  return (
    <Modal
      className="search-results-modal"
      contentClassName="search-results-modal__content"
      centered={true}
      fade={false}
      isOpen={isOpened}
      toggle={onToggle}
    >
      <button className="border-0 rounded-circle position-absolute search-results-modal__close" onClick={onToggle} />

      {!!option && (
        <SearchResultsCard
          vertical
          key={option.id}
          image={option.cover_image}
          title={option.name}
          text={option.description}
          alt={option.name}
          price={getBudget(option.project_size)}
          type={option.investment_types?.[0]?.type}
          location={option.investment_location?.[0]}
          members={users}
          history={history}
          userPopover={true}
          onDotsClick={handleClick}
        />
      )}

      <div className="d-flex flex-column search-results-modal__button-wrapper">
        <PrimaryButton
          className="search-results-modal__request"
          text={requested ? "Request has been sent" : "Request to join"}
          onClick={handleRequest}
        />

        <SecondaryButton className="search-results-modal__message" text="Message group admin" onClick={onMessage} />
      </div>

      {openedPanel && (
        <div className="search-results-modal__panel p-4">
          <div
            className="font-size-16 fw-bold text-color-primary cursor-pointer search-results-modal__panel-button"
            onClick={() => setOpenedPanel(false)}
          >
            <span className="search-results-modal__arrow"></span>
            Members
          </div>

          <span className="font-size-11 color-gray-400">
            {users.length} {users.length === 1 ? "member" : "members"}
          </span>

          <div className="search-results-modal__body">
            <SimpleBar className="search-results-modal__scroll background-color-white p-3 border-radius-4">
              <div className="list-group list-group-flush">
                {users.map(user => {
                  const name = getFullName(user?.firstName, user?.lastName)
                  const link = user.id === currentUser?.uid ? "profile" : `profile/${user.username}`
                  return (
                    <div key={user.id} className="search-results-modal__card">
                      <div className="d-flex align-items-center">
                        <Link to={link} key={user.username} className="search-results-modal__list-item border-radius-4">
                          <Avatar image={user.profile_image} size="m" className="search-results-modal__avatar" />
                        </Link>

                        <span className="d-flex flex-column justify-content-center">
                          <span className="font-weight-bold font-size-16 text-color-primary line-height-140">
                            {capitalizeFirstLetter(name)}
                          </span>

                          {!!user.bio && (
                            <span className="search-results-modal__bio font-size-13 fw-medium overflow-hidden color-gray-400 line-height-140 mt-1">
                              {user.bio}
                            </span>
                          )}
                        </span>
                      </div>
                      <SecondaryButton
                        className="w-100 font-size-13 search-results-modal__submit"
                        text="Message"
                        onClick={() => handleMessage(user)}
                      />
                    </div>
                  )
                })}
              </div>
            </SimpleBar>
          </div>
        </div>
      )}
    </Modal>
  )
}

SearchResultsModal.propTypes = {
  option: PropTypes.object,
  onRequest: PropTypes.func,
  onMessage: PropTypes.func,
  isOpened: PropTypes.bool,
  onToggle: PropTypes.func,
  history: PropTypes.object,
  currentUser: PropTypes.object,
}
